<template>
  <div class="game-apply ground-white">
    <el-page-header
      @back="$router.back()"
      content=""
      style="margin-bottom: 15px"
      v-if="$choosePlantorm().platformType === 0"
    >
    </el-page-header>
    <p class="title">待出账单</p>
    <div class="top">
      待出账金额：<span>{{ topData.unsettledAmount }}</span> 出账日：<span>{{
        topData.billingDay && topData.billingDay.split(" ")[0]
      }}</span>
      账期：<span>T+{{ topData.settlePeriod }}</span> 结算期间：<span
        >{{
          topData.settleStartDate && topData.settleStartDate.split(" ")[0]
        }}~{{
          topData.settleEndDate && topData.settleEndDate.split(" ")[0]
        }}</span
      >
    </div>
    <p class="title">已出账单</p>
    <el-form :inline="true" :model="formInline">
      <el-form-item label="结算ID">
        <el-input v-model="formInline.settleNo" placeholder="结算ID"></el-input>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker
          v-model="formInline.time"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.page.pageIndex = 1;
            getList();
          "
          >查询</el-button
        >
        <el-button type="primary" v-if="1" @click="exportList">导出 </el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="settleNoStr"
        label="结算ID"
        width="100"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="settleTime" label="出账时间">
        <!-- <template slot-scope="scope">
          {{ $moment(scope.row.serviceTime).format("YYYY-MM-DD HH:mm:ss") }}
        </template> -->
      </el-table-column>
      <el-table-column align="center" prop="settleDesc" label="结算信息">
      </el-table-column>
      <el-table-column
        align="center"
        prop="settlePeriodStr"
        width="100"
        label="账期"
      >
      </el-table-column>
      <el-table-column align="center" prop="capacity" label="结算周期">
        <template slot-scope="scope">
          {{ scope.row.settleStartTime }}~{{ scope.row.settleEndTime }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="chargeAmount" label="充值金额">
      </el-table-column>
      <el-table-column align="center" prop="settleAmount" label="结算金额">
      </el-table-column>
      <el-table-column align="center" prop="" label="查看" width="120">
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="openEditDialog(scope.row)"
            >结算单详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.page.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.page.totalElements"
    >
    </el-pagination>

    <!-- dialog -->
    <el-dialog
      :title="editDialogShow ? '编辑计划' : '新建计划'"
      :visible.sync="dialogFormVisible"
    >
      <!-- {{ queryForm }} -->

      <el-form :model="queryForm" status-icon ref="queryForm" :inline="true">
        <el-form-item label="游戏名称" prop="gameId">
          <el-select v-model="queryForm.gameId" placeholder="请选择游戏名称">
            <el-option
              v-for="item in getGameListData"
              :key="item.id"
              :label="item.gameName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开服时间" prop="serviceTime">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="queryForm.serviceTime"
            type="datetime"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="服务器名称" prop="serviceName">
          <el-input v-model="queryForm.serviceName"></el-input> </el-form-item
        ><el-form-item label="峰值" prop="capacity">
          <el-input-number
            :min="1"
            v-model="queryForm.capacity"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="editDialogShow" type="primary" @click="updateUnion"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="addUnion">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      value1: "",
      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      topData: [],
      getGameListData: [],
      dialogFormVisible: false,
      editDialogShow: false,
      editUnionId: "",
      formInline: {
        settleNo: null,
        page: {
          pageIndex: 1,
          pageSize: 20,
          totalElements: 0,
        },
        time: ["", ""],
      },
      queryForm: {
        serviceTime: "",
        gameId: null,
        capacity: 1,
        serviceName: "",
      },
      tableData: [],
    };
  },
  mounted() {
    // this.getPermission();
    // this.getGameList();
    this.getList();
    this.getListTop();
    // this.exportList();
  },
  methods: {
    getListTop() {
      this.$api.billingApi
        .queryDetailSecondSum(
          this.$choosePlantorm().platformType === 0
            ? +this.$route.query.unionId
            : ""
        )
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.topData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    goSecond(data) {
      this.$router.push({
        path: "/billing/bm/settle/union",
        query: {
          unionId: data.unionId,
        },
      });
    },
    exportList() {
      this.formInline.page.pageSize = 50000;
      // 获取公会列表
      this.$api.billingApi
        .exportDetailSecond({
          ...this.formInline,
          unionId: +this.$route.query.unionId,
          settleStartTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          settleEndTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          this.formInline.page.pageSize = 20;
          // 导出Excel
          this.$exportExcel(
            res,
            `已出数据_${this.$moment().format("YYYY-MM-DD")}.xlsx`
          );
        });
    },
    getList() {
      // 获取公会列表
      this.$api.billingApi
        .queryDetailSecond({
          ...this.formInline,
          unionId: +this.$route.query.unionId,
          settleStartTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          settleEndTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.page.totalElements = res.data.totalElements;
            this.formInline.page.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.organizationApi
        .getUnionList({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content.list : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    resetUnionPassword() {
      this.$api.organizationApi
        .resetPassword({
          unionId: this.editUnionId,
        })
        .then((res) => {
          console.log("resetPassword", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    deleteUnion(data) {
      this.$api.organizationApi
        .deleteUnion({
          unionId: data.id,
          isDelete: 1,
        })
        .then((res) => {
          console.log("deleteUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    changeUnionStatus(data) {
      this.$api.organizationApi
        .changeUnionStatus({
          unionId: data.id,
          status: data.status === 0 ? 1 : 0,
        })
        .then((res) => {
          console.log("changeUnionStatus", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    updateUnion() {
      this.$api.organizationApi
        .updateAreaPlan({ ...this.queryForm, id: this.editUnionId })
        .then((res) => {
          console.log("updateUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.dialogFormVisible = false;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    addUnion() {
      this.$api.organizationApi.createAreaPlan(this.queryForm).then((res) => {
        console.log("createUnion", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.dialogFormVisible = false;
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleCurrentChange(val) {
      this.formInline.page.pageIndex = val;
      this.getList();
    },
    onSubmit() {
      console.log("submit!");
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/organization/bm/staff/list",
        query: {
          unionId: e.id,
        },
      });
    },
    resetPassWord(pId) {
      this.$confirm("重置密码:默认密码123456", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 重置密码
        this.editUnionId = pId.id;
        this.resetUnionPassword();
      });
    },
    changeStatus(pId) {
      this.$confirm("确定要更改状态吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 改变状态
        this.changeUnionStatus(pId);
      });
    },
    deleteItem(pId) {
      this.$confirm("确定要删除数据吗？", "", {
        type: "error",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 删除
        this.deleteUnion(pId);
      });
    },
    openEditDialog(data) {
      this.$router.push({
        path: "/billing/bm/settle/detail",
        query: {
          ...data,
          unionId: this.$route.query.unionId,
        },
        // "/billing/bm/settle/bill",
      });
      // this.dialogFormVisible = true;
      // this.editDialogShow = true;
      // this.editUnionId = data.id;
      // setTimeout(() => {
      //   this.queryForm.serviceTime = this.$moment(data.serviceTime).format(
      //     "YYYY-MM-DD HH:mm:ss"
      //   );
      //   this.queryForm.gameId = data.gameId;
      //   this.queryForm.capacity = data.capacity;
      //   this.queryForm.serviceName = data.serviceName;
      // }, 1);
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  .title {
    color: #3a4a65;
    font-size: 22px;
    font-weight: bold;
    margin-right: 60px;
    margin-bottom: 20px;
  }
  .top {
    border-radius: 6px;
    width: calc(99% - 6px);
    height: 100px;
    line-height: 100px;
    background: #f5f5f5;
    color: #646e80;
    font-size: 16px;
    box-sizing: border-box;
    padding-left: 40px;
    margin-bottom: 20px;
    span {
      color: #3a4a65;
      font-size: 22px;
      font-weight: bold;
      margin-right: 60px;
    }
  }
}
</style>
