<template>
  <div class="show_account_icon">
    <span v-if="accountName"
      >{{ accountName }}
      <el-tooltip class="item" content="Top Center 提示文字" placement="top">
        <div slot="content" class="tip_wrap">
          <div style="margin-bottom: 6px">ID: {{ inviterAccountId }}</div>
          <div style="margin-bottom: 6px">部门: {{ departmentName }}</div>
          <div>角色: {{ roleName }}</div>
        </div>
        <i class="el-icon-tickets" style="color: #255ec0" />
      </el-tooltip>
    </span>
  </div>
</template>
<script>
export default {
  props: ["accountName", "inviterAccountId", "departmentName", "roleName"],
};
</script>
<style lang="less" scoped>
.show_account_icon {
  .tip_wrap {
    display: flex;
    flex-direction: column;
    line-height: 32px;

    div {
      marign-bottom: 10px;
    }
  }
}
</style>
