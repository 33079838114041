<template>
  <div class="unionList ground-white">
    <el-page-header
      @back="$router.back()"
      content=""
      style="margin-bottom: 15px"
    >
    </el-page-header>
    <el-form :inline="true" :model="formInline">
      <el-form-item label="昵称">
        <el-input
          v-model="formInline.nickName"
          placeholder="请输入昵称"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.pageIndex = 1;
            getList();
          "
          >查询</el-button
        >
        <el-button
          type="primary"
          v-if="$route.query.roleType == 1"
          @click="
            dialogFormVisible = true;
            editDialogShow = false;
            resetForm('roleForm');
          "
          >新建人员</el-button
        >
      </el-form-item>
    </el-form>

    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column align="center" prop="nickName" label="昵称" width="150">
      </el-table-column>

      <el-table-column align="center" prop="status" label="状态" width="150">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" size="small" type="success"
            >启用</el-tag
          >
          <el-tag v-else size="small" type="danger">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="updateTime"
        label="更新时间"
        width="150"
      >
        <template slot-scope="scope">
          {{ $moment(scope.row.updateTime).format("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="$route.query.roleType == 2"
        prop="unionName"
        label="所在公会"
        width="200"
      >
      </el-table-column>

      <el-table-column align="center" prop="" label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="
              permissionCode.includes('update') && $route.query.roleType == 1
            "
            type="text"
            size="small"
            @click="openEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-button
            v-if="permissionCode.includes('update')"
            type="text"
            size="small"
            @click="openEditDialogNew(scope.row)"
            >设置权限</el-button
          >
          <el-button
            v-if="permissionCode.includes('status')"
            type="text"
            size="small"
            @click="changeStatus(scope.row)"
            >{{ scope.row.status === 0 ? "禁用" : "启用" }}</el-button
          >
          <el-button
            v-if="permissionCode.includes('password')"
            type="text"
            size="small"
            @click="resetPassWord(scope.row)"
            >重置密码</el-button
          >
          <el-button
            v-if="0"
            type="text"
            size="small"
            @click="deleteItem(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="formInline.pageSize"
      :current-page.sync="formInline.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.totalElements"
    >
    </el-pagination>

    <!-- dialog -->
    <el-dialog
      :title="editDialogShow ? '编辑人员' : '新建人员'"
      :visible.sync="dialogFormVisible"
    >
      <!-- {{ roleForm }} -->

      <el-form :model="roleForm" status-icon ref="roleForm" :inline="true">
        <el-form-item label="昵称" required prop="nickName">
          <el-input v-model="roleForm.nickName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="登录账号"
          v-if="!editDialogShow"
          required
          prop="accountName"
        >
          <el-input
            v-model="roleForm.accountName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="登录密码"
          v-if="!editDialogShow"
          required
          prop="accountPassword"
        >
          <el-input
            v-model="roleForm.accountPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" required prop="realName">
          <el-input v-model="roleForm.realName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="roleForm.sex">
            <el-radio-button :label="1">男</el-radio-button>
            <el-radio-button :label="2">女</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="手机号码" required prop="mobile">
          <el-input v-model="roleForm.mobile"></el-input> </el-form-item
        ><el-form-item label="电子邮箱" prop="mail">
          <el-input v-model="roleForm.mail"></el-input>
        </el-form-item>
        <el-form-item label="QQ号码" prop="qq">
          <el-input v-model="roleForm.qq"></el-input>
        </el-form-item>
        <!-- <el-form-item label="权限列表" required1 prop="resourceIdList">
          <div style="display: flex; flex-wrap: wrap">
            <el-tree
              :ref="`tree`"
              :data="treeData"
              :props="defaultProps"
              node-key="id"
              :check-strictly="true"
              default-expand-all
              :default-checked-keys="queryForm.resourceIdList"
              @check="handleCheck"
              show-checkbox
              @node-click="handleNodeClick"
            ></el-tree>
          </div>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="editDialogShow" type="primary" @click="updateUnion"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="addUnion">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 权限dialog -->
    <el-dialog :visible.sync="dialogFormVisibleNew">
      <!-- {{ permissionForm }} -->
      <div style="display: flex; flex-wrap: wrap">
        <el-tree
          :ref="`tree`"
          :data="treeData"
          :props="defaultProps"
          node-key="id"
          :check-strictly="true"
          default-expand-all
          :default-checked-keys="permissionForm.resourceIdList"
          @check="handleCheck"
          show-checkbox
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleNew = false">取 消</el-button>
        <el-button type="primary" @click="updatePermission">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      permissionForm: {
        memberId: "",
        resourceIdList: [],
      },
      dialogFormVisibleNew: false,
      permissionCode: ["update", "status", "delete", "password"],
      cusChecked: [],
      dialogFormVisible: false,
      editDialogShow: false,
      editUnionId: "",
      roleForm: {
        nickName: "",
        realName: "",
        sex: 1,
        roleId: this.$route.query.roleId,
        accountName: "",
        accountPassword: "",
        mobile: "",
        mail: "",
        qq: "",
      },
      formInline: {
        roleId: this.$route.query.roleId,
        nickName: "",
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      queryForm: {
        roleName: "",
        roleDesc: "",
        roleType: 1,
        resourceIdList: [],
      },
      tableData: [],
      unionTreeData: [
        {
          label: "我是云鼎（11）",
          id: "1",
          children: [
            {
              label: "云鼎部门1（2）",
              id: 11,
              children: [
                {
                  id: 111,
                  label: " 1-1-1",
                  disabled: true,
                },
                {
                  id: 12211,
                  label: " 1-1-1",
                  disabled: true,
                },
              ],
            },
            {
              label: "云鼎部门2（4）",
              id: 12,
              children: [
                {
                  label: "1-2-2",
                  id: 22222,
                  disabled: true,
                },
                {
                  label: "1-2-2",
                  id: 22255522,
                },
              ],
            },
          ],
        },
      ],
      adminTreeData: [
        {
          label: "我是云鼎（11）",
          id: "1312321321321",
        },
      ],
      // treeData: [],

      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  mounted() {
    this.getPermissionTree(1);
    this.getPermissionTree(2);
    this.getList();
  },
  computed: {
    treeData: function () {
      return this.$route.query.roleType == 1
        ? this.adminTreeData
        : this.unionTreeData;
    },
  },
  methods: {
    getList() {
      // 获取公会列表
      this.$api.organizationApi.queryRoleMember(this.formInline).then((res) => {
        console.log("queryRoleMember", res);

        const result = res.data.content ? res.data.content : [];

        this.tableData = result;
        this.formInline.totalElements = res.data.totalElements;
        this.formInline.pageIndex = res.data.pageIndex;
      });
    },
    handleNodeClick(data, node) {
      console.log(data);
      console.log(node);
    },
    arrayTreeAddDisabled(
      array,
      levelName = "disabled",
      childrenName = "children",
      disabledList = []
    ) {
      if (!Array.isArray(array)) return [];
      const recursive = (array, level = 0) => {
        level++;
        return array.map((v) => {
          if (disabledList.includes(v.id)) {
            v[levelName] = true;
          }
          const child = v[childrenName];
          if (child && child.length) recursive(child, level);
          return v;
        });
      };
      return recursive(array);
    },
    openEditDialogNew(e) {
      this.dialogFormVisibleNew = true;
      this.permissionForm.memberId = e.id;
      this.permissionForm.resourceIdList = e.resourceIdList;
      // 每个人员的权限列表 都有不一样的权限树置灰操作
      this.getPermissionTree(2, e.roleResourceIdList);
    },
    getPermissionTree(pType, disabledList = []) {
      console.log(pType, disabledList);
      this.$api.organizationApi
        .getPermissionTree({
          resourceOwner: pType,
        })
        .then((res) => {
          console.log("getPermissionTree", res);
          res.data = this.arrayTreeAddDisabled(
            res.data,
            "disabled",
            "children",
            disabledList
          );
          if (pType === 1) {
            this.adminTreeData = res.data;
          } else {
            this.unionTreeData = res.data;
          }
        });
    },
    resetUnionPassword(pId) {
      this.$api.organizationApi
        .resetMemberPassword({
          memberId: pId,
        })
        .then((res) => {
          console.log("resetUnionPassword", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    deleteUnion(data) {
      this.$api.organizationApi
        .deleteMember({
          memberId: data.id,
          isDelete: 1,
        })
        .then((res) => {
          console.log("deleteUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeUnionStatus(data) {
      this.$api.organizationApi
        .changeMemberStatus({
          memberId: data.id,
          status: data.status === 0 ? 1 : 0,
        })
        .then((res) => {
          console.log("changeUnionStatus", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    updatePermission() {
      this.$api.organizationApi
        .updateRoleMember(this.permissionForm)
        .then((res) => {
          console.log("updateRole", res);
          if (res.code === 0) {
            this.dialogFormVisibleNew = false;
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    updateUnion() {
      this.$api.organizationApi
        .updateMember({ ...this.roleForm, memberId: this.editUnionId })
        .then((res) => {
          console.log("updateRole", res);
          this.dialogFormVisible = false;
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    addUnion() {
      this.$api.organizationApi
        .createRoleMember({
          ...this.roleForm,
          roleId: this.$route.query.roleId,
        })
        .then((res) => {
          console.log("createRoleMember", res);
          if (res.code === 0) {
            this.dialogFormVisible = false;
            this.$message.success("操作成功!");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
    onSubmit() {
      console.log("submit!");
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/organization/bm/bmrole/list",
        query: {
          roleId: e.id,
          roleType: e.roleType,
        },
      });
    },
    resetPassWord(pId) {
      this.$confirm("重置密码:默认密码123456", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 重置密码
        this.editUnionId = pId.id;
        this.resetUnionPassword(pId.id);
      });
    },
    changeStatus(pId) {
      this.$confirm("确定要更改状态吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 改变状态
        this.changeUnionStatus(pId);
      });
    },
    deleteItem(pId) {
      this.$confirm("确定要删除数据吗？", "", {
        type: "error",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 删除
        this.deleteUnion(pId);
      });
    },
    openEditDialog(e) {
      this.editDialogShow = true;
      this.dialogFormVisible = true;
      this.editUnionId = e.id;
      setTimeout(() => {
        this.roleForm.nickName = e.nickName;
        this.roleForm.realName = e.realName;
        this.roleForm.sex = e.sex;
        this.roleForm.mail = e.mail;
        this.roleForm.mobile = e.mobile;
        this.roleForm.qq = e.qq;
        this.roleForm.departmentId = e.departmentId;
      }, 1);
    },

    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },

    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
    handleCheck(currentNode, treeStatus) {
      /**
       * @des 根据父元素的勾选或取消勾选，将所有子级处理为选择或非选中状态
       * @param { node: Object }  当前节点
       * @param { status: Boolean } （true ： 处理为勾选状态 ； false： 处理非选中）
       */
      const setChildStatus = (node, status) => {
        /* 这里的 id children 也可以是其它字段，根据实际的业务更改 */
        this.$refs.tree.setChecked(node.id, status);
        if (node.children) {
          /* 循环递归处理子节点 */
          console.log(node.children);
          for (let i = 0; i < node.children.length; i++) {
            // if (node.children[i].disabled === false) {
            setChildStatus(node.children[i], status);
            // }
          }
        }
      };
      /* 设置父节点为选中状态 */
      const setParentStatus = (nodeObj) => {
        /* 拿到tree组件中的node,使用该方法的原因是第一次传入的 node 没有 parent */
        const node = this.$refs.tree.getNode(nodeObj);
        if (node.parent.key) {
          this.$refs.tree.setChecked(node.parent, true);
          setParentStatus(node.parent);
        }
      };
      /*设置父节点为取消状态*/
      const offParentStatus = (nodeObj) => {
        const node = this.$refs.tree.getNode(nodeObj);
        if (node.parent.key) {
          if (
            node.parent.childNodes.every((item) => {
              return item.checked == false;
            }) == true
          ) {
            this.$refs.tree.setChecked(node.parent, false);
            offParentStatus(node.parent);
          }
        }
      };

      /* 判断当前点击是选中还是取消选中操作 */
      if (treeStatus.checkedKeys.includes(currentNode.id)) {
        /*当前节点选中,*/
        setParentStatus(currentNode);
        /*当前节点选中，子级全部选中*/
        // setChildStatus(currentNode, true);
      } else {
        /* 当前节点取消选中, 判断父级的所有子级是否全部取消,如果全部取消，则父级也取消*/
        offParentStatus(currentNode);
        if (currentNode.children) {
          /*当前节点取消选中时，当前节点的子级全部取消*/
          setChildStatus(currentNode, false);
        }
      }
      this.permissionForm.resourceIdList = [
        ...this.$refs.tree.getCheckedKeys(),
      ];
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .mr15 {
    margin-right: 15px;
  }
}
</style>
