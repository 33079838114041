/**
 * 判断用户操作环境：超管后台-公会后台-客服后台
 * @param {Number} pType 登录类型：0超管，1公会，2客服
 * @returns
 * @pathUrl      登录后跳转Router Path
 * @requestUrl   后端接口请求前缀
 * @platformType 登录类型：0超管，1公会，2客服
 */
function choosePlantorm(pType = 1) {
  let result = {
    pathUrl: "",
    requestUrl: "",
    platformType: pType,
  };
  const url = window.location.href;
  const environmentOptions = {
    testUnion: "web.zhongyiyf.com/test",
    testCustomer: "k.zyyf.vip/test",
    testBm: "g.zyyf.vip/test",
    prdUnion: "web.zhongyiyf.com",
    prdCustomer: "k.zyyf.vip",
    prdBm: "g.zyyf.vip",
  };
  if (url.indexOf(environmentOptions.testBm) > -1) {
    //测试环境后管
    result.requestUrl = "https://testadmin.zyyf.vip/admin/v1";
    result.pathUrl = "/dashboard/bm/statistics";
    result.platformType = 0;
  } else if (url.indexOf(environmentOptions.testUnion) > -1) {
    //测试环境公会
    result.requestUrl = "https://testapi.zhongyiyf.com/union/v1";
    result.tocRequestUrl = "https://testapi.zhongyiyf.com/user/v1";
    result.pathUrl = "/dashboard/union/statistics";
    result.platformType = 1;
  } else if (url.indexOf(environmentOptions.testCustomer) > -1) {
    //测试环境客服
    result.requestUrl = "https://testadmin.zyyf.vip/support/v1";
    result.pathUrl = "/support/resources/manage";
    result.platformType = 2;
  } else if (url.indexOf(environmentOptions.prdBm) > -1) {
    //生产环境后管
    result.requestUrl = "https://admin.zyyf.vip/admin/v1";
    result.pathUrl = "/dashboard/bm/statistics";
    result.platformType = 0;
  } else if (url.indexOf(environmentOptions.prdUnion) > -1) {
    //生产环境公会
    result.requestUrl = "https://api.zhongyiyf.com/union/v1";
    result.tocRequestUrl = "https://api.zhongyiyf.com/user/v1";
    result.pathUrl = "/dashboard/union/statistics";
    result.platformType = 1;
  } else if (url.indexOf(environmentOptions.prdCustomer) > -1) {
    //生产环境客服
    result.requestUrl = "https://admin.zyyf.vip/support/v1";
    result.pathUrl = "/support/resources/manage";
    result.platformType = 2;
  } else if (
    url.indexOf("localhost") > -1 ||
    url.indexOf("192.168.1.111") > -1
  ) {
    //开发环境 通过platformType去区分接口前缀，默认测试环境接口地址，联调后端本地接口，修改requestUrl
    if (result.platformType === 0) {
      result.pathUrl = "/dashboard/bm/statistics";
      result.requestUrl = "https://testadmin.zyyf.vip/admin/v1";
    } else if (result.platformType === 1) {
      result.pathUrl = "/dashboard/union/statistics";
      result.requestUrl = "https://testapi.zhongyiyf.com/union/v1";
      result.tocRequestUrl = "https://testapi.zhongyiyf.com/user/v1";
      // result.requestUrl = "http://192.168.1.189:18080/union/v1";
    } else {
      result.pathUrl = "/support/resources/manage";
      result.requestUrl = "https://testadmin.zyyf.vip/support/v1";
    }
  } else {
    // 截流兜底用公网环境信息
    result.requestUrl = "https://api.zhongyiyf.com/union/v1";
    result.tocRequestUrl = "https://api.zhongyiyf.com/user/v1";
    result.pathUrl = "/dashboard/union/statistics";
    result.platformType = 1;
  }
  return result;
}

export default choosePlantorm;
