import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loadingStatus: 0,
    userInfo: {},
    menuTree: [],
  },
  getters: {},
  mutations: {
    showLoading(state) {
      state.loadingStatus = true;
    },
    hideLoading(state) {
      state.loadingStatus = false;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setMenuTree(state, list) {
      state.menuTree = list;
    },
  },
  actions: {},
  modules: {},
});
