import base from "../base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例

const taskApi = {
  // 后管公会列表查询
  queryTask(params) {
    return axios.post(`${base.sq}/task/queryTask`, params);
  },
  // 后管公会任务查询
  queryUnion(params) {
    return axios.post(`${base.sq}/task/queryUnion`, params);
  },
  // 后管公会推广员查询
  queryMember(params) {
    return axios.post(`${base.sq}/task/queryMember`, params);
  },

  // 公会端=-创建任务
  createTask(params) {
    return axios.post(`${base.sq}/task/create`, params);
  },
  // 公会端=-修改任务状态
  changeTaskStatus(params) {
    return axios.post(`${base.sq}/task/status`, params);
  },
  // 公会端=-删除任务
  deleteTask(params) {
    return axios.post(`${base.sq}/task/delete`, params);
  },
  // 公会端=-更新团长
  updateHead(params) {
    return axios.post(`${base.sq}/task/updateHead`, params);
  },
  // 公会端=-更新推广员
  updateInviters(params) {
    return axios.post(`${base.sq}/task/updateInviters`, params);
  },
};

export default taskApi;
