<template>
  <el-dialog
    title="推广查询"
    :visible="true"
    class="promotion"
    width="1000px"
    :before-close="() => $emit('close')"
  >
    <!-- 查询条件 -->
    <el-form ref="formdata" :model="formdata" inline size="small">
      <el-form-item label="推广ID:" prop="accountId">
        <el-input
          v-model="formdata.accountId"
          placeholder="请输入推广ID"
          @input="(val) => (formdata.accountId = val.replace(/[^\d]/, ''))"
          clearable
          @change="search"
        />
      </el-form-item>
      <el-form-item label="昵称:" prop="nickName">
        <el-input
          v-model="formdata.nickName"
          placeholder="请输入昵称"
          clearable
          @change="search"
        />
      </el-form-item>
      <el-form-item>
        <el-button plain icon="el-icon-refresh-left" @click="reset"
          >重置
        </el-button>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >查询
        </el-button>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table border v-loading="loading" :data="tabledata">
      <el-table-column prop="accountId" label="推广ID" />
      <el-table-column prop="nickName" label="昵称" />
      <el-table-column prop="mobile" label="手机号" />
      <el-table-column prop="roleName" label="角色名称" />
      <el-table-column prop="departmentName" label="部门" />
    </el-table>

    <!-- 分页器 -->
    <el-pagination
      :page-size="pagination.pageSize"
      :current-page="pagination.pageIndex"
      :total="pagination.total"
      @current-change="handleCurrentChange"
    />
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      formdata: {
        accountId: "",
        nickName: "",
      },
      tabledata: [],
      pagination: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    handleCurrentChange(pageIndex) {
      this.pagination.pageIndex = pageIndex;
      this.getData();
    },
    getData() {
      if (this.loading) return;
      this.loading = true;
      const params = {
        ...this.formdata,
        pageIndex: this.pagination.pageIndex,
        pageSize: this.pagination.pageSize,
      };
      this.$api.supportApi
        .allRangeQuery(params)
        .then((res = {}) => {
          const { code, data = {}, msg } = res;
          if (code === 0) {
            const { content = [], totalElements = 0 } = data;
            this.tabledata = content;
            this.pagination.total = totalElements;
          } else {
            msg && this.$message.error(msg);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.pagination.pageIndex = 1;
      this.getData();
    },
    reset() {
      this.$refs.formdata.resetFields();
      this.search();
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-dialog__body {
  padding: 20px;
}

::v-deep .el-table__header th {
  background: #f7f9fd;
  font-weight: bold;
  height: 40px;
  padding: 0;
  color: #95a3bb;
}

::v-deep .el-table__body-wrapper {
  height: 480px;
}

.el-pagination {
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
}
</style>
