<template>
  <div class="unionList ground-white">
    <el-page-header @back="$router.back()"> </el-page-header>
    <br />
    <el-radio-group
      v-model="gameRoleId"
      size="mini"
      @input="getPage"
      v-if="$route.query.gameAccountId"
    >
      <el-radio-button
        v-for="(item, index) in tabList"
        :key="index"
        :label="item.roleId"
        >{{ item.roleName }}</el-radio-button
      >
    </el-radio-group>
    <!-- {{ formInline }} -->
    <div class="data_bar">
      <p>
        角色：<span>{{ topData.roleName }}</span>
      </p>
      <p>
        游戏：<span>{{ topData.gameName }}</span>
      </p>
      <p>
        区服：<span>{{ topData.gameServiceName }}</span>
      </p>
      <p>
        等级：<span>{{ topData.roleLevel }}</span>
      </p>
      <p>
        今日登录：<span>{{ topData.loginInNum }}</span>
      </p>
      <p>
        今日在线时间：<span>{{ topData.onlineTime }}</span
        >分钟
      </p>
    </div>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="orderNo"
        label="订单号"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="gameAccountId" label="游戏账号ID">
      </el-table-column>
      <el-table-column align="center" prop="lastLoginTime" label="最近登录">
        <!-- <template slot-scope="scope">
          {{ $moment(scope.row.serviceTime).format("YYYY-MM-DD HH:mm:ss") }}
        </template> -->
      </el-table-column>
      <el-table-column align="center" prop="lastOfflineTime" label="下线时间">
      </el-table-column>
      <el-table-column align="center" prop="lastChargeStatus" label="支付方式">
        <template slot-scope="scope">
          {{
            typeof scope.row.payChannel === "number"
              ? +scope.row.payChannel === 0
                ? "微信"
                : "支付宝"
              : "无"
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="ip" label="充值IP">
        <template slot-scope="scope">
          <div>
            <div>{{ scope.row.ip }}</div>
            <div>{{ scope.row.ipAreaInfo }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="lastChargeAmount" label="最近充值">
      </el-table-column>
      <el-table-column align="center" prop="lastChargeTime" label="充值时间">
      </el-table-column>
      <el-table-column align="center" prop="capacity" label="充值状态">
        <template slot-scope="scope">
          {{ statusList[scope.row.lastChargeStatus] }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="inviterAccountName" label="推广员">
      </el-table-column>
      <el-table-column align="center" prop="headAccountName" label="团长">
      </el-table-column>

      <!-- <el-table-column align="center" prop="" label="详情" width="150">
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="openEditDialog(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next"
      :page-size="20"
      :current-page.sync="midForm.pageIndex"
      @current-change="handleCurrentChange"
      :total="midForm.totalElements"
    >
    </el-pagination>

    <p style="font-size: 20px; font-weight: bold; margin: 20px 10px">
      角色笔记
    </p>
    <!-- {{ commentData }} -->
    <div class="comment-area">
      <el-card
        class="box-card"
        v-for="item in commentData"
        :key="item.id"
        v-show="commentData.length"
      >
        <div slot="header" class="clearfix">
          <el-avatar
            style="vertical-align: middle; margin-right: 10px"
            shape="square"
            size="small"
            src="@/assets/img/comment-icon.png"
          ></el-avatar>
          <span style="margin-right: 10px">{{ item.accountName }}</span>
          <span>{{ item.createTime }}</span>
        </div>
        <div class="text item">
          {{ item.content }}
        </div>
      </el-card>
      <el-pagination
        v-show="commentData.length"
        style="float: right"
        layout="prev, pager, next"
        :page-size="bottomForm.pageSize"
        :current-page.sync="bottomForm.pageIndex"
        @current-change="handleCurrentChangeNew"
        :total="bottomForm.totalElements"
      >
      </el-pagination>
      <el-empty v-show="!commentData.length"></el-empty>

      <br />
      <el-divider></el-divider>
      <el-form
        :model="queryForm"
        style="margin-top: 30px"
        ref="queryForm"
        :inline="true"
      >
        <el-form-item>
          <el-input
            style="width: 370px"
            type="textarea"
            placeholder="请输入评论内容"
            v-model="commentText"
            rows="3"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="doComment"
            >发布</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      value1: "",
      activeName: "first",
      tabList: [],
      pickerOptions: {
        shortcuts,
      },
      rechargeStatusList: [
        { name: "创建中", id: 100 },
        { name: "支付中", id: 200 },
        { name: "发货中", id: 300 },
        { name: "已完成", id: 400 },
        { name: "已取消", id: 500 },
      ],
      statusList: {
        100: "创建中",
        200: "支付中",
        300: "发货中",
        400: "已完成",
        500: "已取消",
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      getGameListData: [],
      dialogFormVisible: false,
      editDialogShow: false,
      editUnionId: "",
      gameRoleId: this.gameRoleId,
      commentText: "",
      midForm: {
        pageIndex: 1,
        pageSize: 10,
        totalElements: 0,
      },
      bottomForm: {
        pageIndex: 1,
        pageSize: 5,
        totalElements: 0,
      },

      queryForm: {
        serviceTime: "",
        gameId: null,
        capacity: 1,
        serviceName: "",
      },
      tableData: [],
      commentData: [],
      topData: {},
    };
  },
  created() {
    // alert(this.$route.query.gameRoleId);
    const roleId = this.$route.query.gameRoleId
      ? [+this.$route.query.gameRoleId]
      : null;
    const accountIdList = this.$route.query.gameAccountId
      ? [+this.$route.query.gameAccountId]
      : null;
    this.getRoleList(roleId, accountIdList);
    // this.getTop();
  },
  mounted() {
    // this.getPermission();
    // this.getGameList();
    // this.getList();
  },
  methods: {
    getPage(e) {
      console.log(e);
      this.getTop();
      this.getMid();
      this.getBottom();
      this.commentText = "";
    },
    getRoleList(roleId, accountIdList) {
      this.$api.organizationApi
        .queryGameRole({
          // accountIdList: [0],
          firstChargeInfo: true,
          // roleName: query,
          gameAccountIdList: accountIdList,
          // gameIdList: [this.roleForm.gameId],
          // gameServerIdList: [parseInt(this.roleForm.gameArea)],
          needAccountInfo: true,
          roleIdList: roleId,
          // roleName: "string",
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data ? res.data : [];
            this.tabList = result;
            this.gameRoleId = result[0].roleId ? result[0].roleId : null;
            this.getPage();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    doComment() {
      this.$api.dataApi
        .taskRoleDetailComment({
          gameRoleId: +this.gameRoleId,
          content: this.commentText,
        })
        .then((res) => {
          console.log("changeUnionStatus", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.commentText = "";
          } else {
            this.$message.error(res.msg);
          }
          this.bottomForm.pageIndex = 1;
          this.getBottom();
        });
    },
    getTop() {
      // 获取公会列表
      this.$api.dataApi
        .taskRoleDetailSum({ gameRoleId: +this.gameRoleId })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.topData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getMid() {
      // 获取公会列表
      this.$api.dataApi
        .taskRoleDetailQuery({ ...this.midForm, gameRoleId: +this.gameRoleId })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.midForm.pageIndex = res.data.pageIndex;
            this.midForm.totalElements = res.data.totalElements;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getBottom() {
      // 获取公会列表
      this.$api.dataApi
        .taskRoleDetailCommentList({
          ...this.bottomForm,
          gameRoleId: +this.gameRoleId,
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.commentData = result;
            this.bottomForm.pageIndex = res.data.pageIndex;
            this.bottomForm.totalElements = res.data.totalElements;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      // 获取公会列表
      this.$api.organizationApi
        .queryAreaPlan({
          ...this.formInline,
          startTime: this.formInline.time ? this.formInline.time[0] : "",
          endTime: this.formInline.time ? this.formInline.time[1] : "",
          sort: "desc",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : {};
            this.tableData = result;
            this.unionMemberNum = result.unionMemberNum;
            this.unionNum = result.unionNum;
            this.formInline.totalElements = res.data.totalElements;
            this.formInline.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.gameApi
        .queryPackage({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    resetUnionPassword() {
      this.$api.organizationApi
        .resetPassword({
          unionId: this.editUnionId,
        })
        .then((res) => {
          console.log("resetPassword", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    deleteUnion(data) {
      this.$api.organizationApi
        .deleteUnion({
          unionId: data.id,
          isDelete: 1,
        })
        .then((res) => {
          console.log("deleteUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    changeUnionStatus(data) {
      this.$api.organizationApi
        .changeUnionStatus({
          unionId: data.id,
          status: data.status === 0 ? 1 : 0,
        })
        .then((res) => {
          console.log("changeUnionStatus", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    updateUnion() {
      this.$api.organizationApi
        .updateAreaPlan({ ...this.queryForm, id: this.editUnionId })
        .then((res) => {
          console.log("updateUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.dialogFormVisible = false;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    addUnion() {
      this.$api.organizationApi.createAreaPlan(this.queryForm).then((res) => {
        console.log("createUnion", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.dialogFormVisible = false;
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleCurrentChange(val) {
      this.midForm.pageIndex = val;
      this.getMid();
    },
    handleCurrentChangeNew(val) {
      this.bottomForm.pageIndex = val;
      this.getBottom();
    },
    onSubmit() {
      console.log("submit!");
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/organization/bm/staff/list",
        query: {
          unionId: e.id,
        },
      });
    },
    handleClick1(tab, event) {
      console.log(tab, event);
    },
    resetPassWord(pId) {
      this.$confirm("重置密码:默认密码123456", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 重置密码
        this.editUnionId = pId.id;
        this.resetUnionPassword();
      });
    },
    changeStatus(pId) {
      this.$confirm("确定要更改状态吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 改变状态
        this.changeUnionStatus(pId);
      });
    },
    deleteItem(pId) {
      this.$confirm("确定要删除数据吗？", "", {
        type: "error",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 删除
        this.deleteUnion(pId);
      });
    },
    openEditDialog(data) {
      this.dialogFormVisible = true;
      this.editDialogShow = true;
      this.editUnionId = data.id;
      setTimeout(() => {
        this.queryForm.serviceTime = this.$moment(data.serviceTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.queryForm.gameId = data.gameId;
        this.queryForm.capacity = data.capacity;
        this.queryForm.serviceName = data.serviceName;
      }, 1);
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-radio-button--mini .el-radio-button__inner {
  padding: 7px 15px;
  font-size: 12px;
  border-radius: 0;
  margin: 5px;
  border: 1px #dcdfe6 solid !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}
.unionList {
  .comment-area {
    overflow: hidden;
    border: 1px #999 dashed;
    padding: 30px 15px;
    width: fit-content;
    border-radius: 8px;
    .box-card {
      width: 450px;
      &:nth-of-type(odd) {
        // background: red;
      }
      &:nth-of-type(even) {
        margin-left: 500px;
      }
    }
  }
  .data_bar {
    margin-bottom: 20px;
    padding: 0 16px;
    background: #eff5ff;
    line-height: 40px;
    width: calc(100vw - 258px);
    margin-left: -16px;
    display: flex;
    justify-content: flex-start;
    gap: 24px;

    span {
      // padding-right: 1vw;
      color: #255ec0;
    }
  }
  .title-t {
    margin: 25px 0;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15%;
    padding-left: 15px;
    span {
    }
  }
  .mr15 {
    margin-right: 15px;
  }
}
</style>
