<template>
  <div class="yd_select_wrap">
    <el-form-item :prop="propId" :label="label">
      <treeselect
        v-model="backShow"
        :multiple="multiple"
        :disable-branch-nodes="disableBranchNodes"
        :placeholder="placeholder"
        :options="options"
        :style="sty"
        @input="changeTreeValues"
        :noOptionsText="noOptionsText"
        :noChildrenText="noChildrenText"
        :appendToBody="appendToBody"
      >
        <p
          style="padding-right: 20px; white-space: nowrap; width: 100%"
          slot="option-label"
          slot-scope="{ node }"
          :title="node.label"
        >
          <template> {{ node.label }}</template>
        </p>
      </treeselect>
    </el-form-item>
  </div>
</template>
<script>
import Treeselect from "@riophae/vue-treeselect";
import { collectAllAccountId } from "@/utils/util";

export default {
  name: "InviterSelect",
  components: { Treeselect },
  data() {
    return {
      inviters: null,
      backShow: null,
    };
  },
  props: {
    value: {
      type: [String, Number, Array, {}],
      default: null,
    },
    label: { type: String, default: "推广员" },
    propId: { type: String, default: "" },
    multiple: { type: Boolean, default: true },
    disableBranchNodes: { type: Boolean, default: false },
    appendToBody: { type: Boolean, default: true },
    placeholder: { type: String, default: "请选择推广员" },
    options: { type: Array, default: () => [] },
    sty: { type: String, default: "width: 198px" },
    noOptionsText: { type: String, default: "暂无选项" },
    noChildrenText: { type: String, default: "暂无人员" },
  },
  watch: {
    value(newVal) {
      console.log("固定", newVal, this.multiple);
      if (!newVal) {
        this.inviters = null;
        this.backShow = null;
      } else {
        this.inviters = newVal;
      }
    },
  },
  methods: {
    changeTreeValues(values) {
      if (this.multiple) {
        let accountIds = values.filter((val) => val.toString().length >= 8);
        let fromIds = [];
        const ids = values.filter((val) => val.length < 15);
        fromIds = ids.map((val) => {
          return collectAllAccountId(this.options, val);
        });

        this.inviters = [...accountIds, ...fromIds.flat(Infinity)];

        this.$emit("input", this.inviters);
      } else {
        this.inviters = values;
        console.log("this.inviters", this.inviters);
        this.$emit("input", this.inviters);
      }
      this.backShow = values;
    },
  },
};
</script>
<style lang="less" scoped>
.yd_select_wrap {
  display: inline-block;
  /deep/ .vue-treeselect__menu {
    width: 180px;
    overflow-x: scroll;
  }
  /deep/ .vue-treeselect__no-children-tip-text {
    white-space: nowrap;
    text-overflow: clip;
    width: 100px;
  }
}
</style>
