import base from "../base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例
const billingApi = {
  // ----------------   超管    ----------------

  //查询结算详情1
  queryDetailFirst(params) {
    return axios.post(`${base.sq}/settle/data/list/page`, params);
  },
  //编辑结算周期
  updatePeriod(params) {
    return axios.post(`${base.sq}/settle/data/update`, params);
  },
  // 查询结算详情1 上方详情
  queryDetailFirstTop(params) {
    return axios.post(`${base.sq}/settle/data/sum`, params);
  },
  //查询结算详情2
  queryDetailSecond(params) {
    return axios.post(`${base.sq}/settle/union/list/page`, params);
  },
  //查询结算详情2-待出账单
  queryDetailSecondSum(params) {
    return axios.post(
      `${base.sq}/settle/union/unsettled/data/sum?unionId=${params}`
    );
  },
  //查询结算详情3
  queryDetailThird(params) {
    return axios.post(`${base.sq}/settle/game/list/page`, params);
  },
  //查询结算详情4
  queryDetailFourth(params) {
    return axios.post(`${base.sq}/settle/order/list/page`, params);
  },
  //导出
  exportDetailSecond(params) {
    return axios.post(`${base.sq}/settle/union/list/export`, params, {
      responseType: "blob",
    });
  },

  // 充值申请模块
  // 公会充值申请详情
  queryRechargeList(params) {
    return axios.post(`${base.sq}/recharge/union/apply/all`, params);
  },
  queryRechargeDetail(params) {
    return axios.post(`${base.sq}/recharge/union/apply/detail`, params);
  },
  queryRechargeAccountSum(params) {
    return axios.post(`${base.sq}/recharge/account/sum`, params);
  },
  queryRechargeAccountDetail(params) {
    return axios.post(`${base.sq}/recharge/account/detail`, params);
  },
  queryRechargeAccountInfo(params) {
    return axios.post(`${base.sq}/recharge/account/info`, params);
  },

  // 调账记录1列表
  queryAdjustmentList(params) {
    return axios.post(`${base.sq}/adjust/union/count/page`, params);
  },
  // 调账记录1上方汇总信息
  queryAdjustmentSum(params) {
    return axios.post(`${base.sq}/adjust/union/all/count`, params);
  },
  // 记录1-调账
  addSubAdjustment(params) {
    return axios.post(`${base.sq}/adjust/asset/account`, params);
  },
  // 记录1-查询余额
  getAdjustmentBalance(params) {
    return axios.post(`${base.sq}/adjust/union/account/detail`, params);
  },
  // 记录2-列表
  queryAdjustmentListPage(params) {
    return axios.post(`${base.sq}/adjust/union/detail/page`, params);
  },
  // 记录2-导出
  queryAdjustmentListExport(params) {
    return axios.post(`${base.sq}/adjust/union/detail/export`, params, {
      responseType: "blob",
    });
  },
  // 记录2-上方汇总信息
  queryAdjustmentListSum(params) {
    return axios.post(`${base.sq}/adjust/union/sum`, params);
  },

  // 余额提现-平台和工会结算详情
  getWithdrawalSum(params) {
    return axios.post(`${base.sq}/settle/all/data/sum`, params);
  },
  // 余额提现-公会汇总
  getBalanceUnionSum(params) {
    return axios.post(`${base.sq}/balance/union/sum`, params);
  },
  // 余额提现-提现审批查询
  getWithdrawalList(params) {
    return axios.post(
      `${base.sq}/balance/union/withdrawal/progress/page`,
      params
    );
  },
  // 余额提现-提现审批list
  getWithdrawalApplyList(params) {
    return axios.post(`${base.sq}/balance/union/withdrawal/apply/page`, params);
  },
  // 余额提现-提现审批list
  getWithdrawalApprovalList(params) {
    return axios.post(
      `${base.sq}/balance/union/withdrawal/approval/page`,
      params
    );
  },
  // 余额提现-提现审批
  approvalWithdrawApply(params) {
    return axios.post(`${base.sq}/balance/union/withdrawal/approval`, params);
  },
  // 余额提现-转账执行结果录入
  withdrawTransfer(params) {
    return axios.post(`${base.sq}/balance/union/withdrawal/transfer`, params);
  },
  // 余额提现-结算账户信息
  getUnionAccountDetail(params) {
    return axios.post(`${base.sq}/balance/union/account/detail`, params);
  },
  // 余额提现-公会提现申请list
  getWithdrawApprovalList(params) {
    return axios.post(
      `${base.sq}/balance/union/withdrawal/progress/page`,
      params
    );
  },
  // 余额提现-导出公会申请数据
  exportApprovalList(params) {
    return axios.post(
      `${base.sq}/balance/union/withdrawal/progress/export`,
      params,
      {
        responseType: "blob",
      }
    );
  },

  // ----------------   公会    ----------------

  // 充值角色
  virRechargeQuery(params) {
    return axios.post(`${base.sq}/recharge/virtual/union/page`, params);
  },
  virRechargeQueryNew(params) {
    return axios.post(`${base.sq}/recharge/virtual/role/sum`, params);
  },
  virRechargeQueryDetail(params) {
    return axios.post(`${base.sq}/recharge/virtual/role/detail`, params);
  },

  //对账账单
  queryReconciliation(params) {
    return axios.post(`${base.sq}/reconciliation/list`, params);
  },
  //对账账单新街口
  queryReconciliationCount(params) {
    return axios.post(`${base.sq}/reconciliation/count`, params);
  },
  queryReconciliationExport(params) {
    return axios.post(`${base.sq}/reconciliation/list/export`, params, {
      responseType: "blob",
    });
  },

  // 余额提现-总资产等  balance/union/withdrawal/progress/export

  // 余额提现-列表  balance/union/withdrawal/progress/page

  // 余额提现-导出  balance/union/withdrawal/progress/export

  // 余额提现-提现申请
  withdrawalApply(params) {
    return axios.post(`${base.sq}/balance/union/withdrawal/apply`, params);
  },

  // 余额提现-支付列表
  queryPayTypes(params) {
    return axios.post(`${base.sq}/balance/card/list`, params);
  },
  // 余额提现-支付列表
  handleFixPayPassword(params) {
    return axios.post(`${base.sq}/balance/update/password`, params);
  },
  // 余额提现-查询手机号
  getMobileNum(params) {
    return axios.post(`${base.sq}/balance/account/mobile`, params);
  },
  // 余额提现-修改支付密码
  updatePassword(params) {
    return axios.post(`${base.sq}/balance/update/password`, params);
  },
  // 余额提现-绑定支付账号
  addPayAccount(params) {
    return axios.post(`${base.sq}/balance/card/add`, params);
  },
  // 余额提现-发送验证码
  unionSendSmsCode(params) {
    return axios.post(`${base.sq}/common/sendSmsCode`, params);
  },
  // 余额提现-检验验证码
  unionVerifySmsCode(params) {
    return axios.post(`${base.sq}/common/verifySmsCode`, params);
  },
  // 余额提现-删除支付账号
  delPayAccount(params) {
    return axios.post(`${base.sq}/balance/card/delete`, params);
  },
  // 虚拟额度-额度查询
  queryQuotaList(params) {
    return axios.post(`${base.sq}/recharge/account/page`, params);
  },
  // 虚拟额度-额度查询
  initUnionQuota(params) {
    return axios.post(`${base.sq}/recharge/account/init`, params);
  },
  // 虚拟额度-虚拟账户增加可用额度
  addVAAAAmount(params) {
    return axios.post(
      `${base.sq}/recharge/account/addVirtualAssetAccountAvailableAmount`,
      params
    );
  },
  // 角色标识
  markRoleColor(params) {
    return axios.post(`${base.sq}/data/mark/gameRole`, params);
  },
  // 账号标识
  markAccountColor(params) {
    return axios.post(`${base.sq}/data/mark/gameAccount`, params);
  },

  // 账号标识
  rechargeCountInfo(params) {
    return axios.post(`${base.sq}/recharge/account/sum`, params);
  },
  // 落地页-发送验证码
  sendSmsCode(params) {
    return axios.post(`${base.cq}/account/sendSmsCode`, params);
  },
  // 落地页-发送验证码/user/v1/account/create
  verifySmsCode(params) {
    return axios.post(`${base.cq}/account/verifySmsCode`, params);
  },
  // 落地页-创建账号
  createAccount(params) {
    return axios.post(`${base.cq}/account/create`, params, {
      headers: {
        Platform: "iOS",
      },
    });
  },
};

export default billingApi;
