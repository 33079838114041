import loginApi from "@/api/login";
import organizationApi from "@/api/organization";
import gameApi from "@/api/game";
import billingApi from "@/api/billing";
import dataApi from "@/api/data";
import taskApi from "@/api/task";

import supportApi from "@/api/support";

export default {
  loginApi,
  organizationApi,
  gameApi,
  billingApi,
  supportApi,
  dataApi,
  taskApi,
};
