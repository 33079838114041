<template>
  <div class="home">
    <el-container class="home-container">
      <el-aside class="slide-box" :width="leftMenuWidth">
        <Nav :isFold="isFold" />
      </el-aside>
      <el-container>
        <el-header class="top-header">
          <div class="ctrl_menu" @click="changeSideBar">
            <img v-if="!isFold" src="@/assets/img/to_left.png" alt="" />
            <img v-else src="@/assets/img/to_right.png" alt="" />
          </div>
          <div class="breadcrumb" v-if="breadcrumbList.length">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item
                v-for="item in breadcrumbList"
                :key="item.id"
                :to="item.path"
                :class="[
                  item.id !== breadcrumbList[breadcrumbList.length - 1].id &&
                    'bold',
                ]"
              >
                {{ item.name }}
              </el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="flex">
            <img style="height: 30px" src="@/assets/img/avator.png" alt="" />
            <span v-if="platform !== 1" slot="reference" style="margin: 0 10px">
              {{ accountName }}
            </span>
            <div v-if="platform === 1" slot="reference" style="margin: 0 10px">
              <span class="nav_wrap">
                <em>{{ unionName }}</em>
                <em>{{ accountName }}({{ accountId }})</em>
                <em class="pointer" @click="handleOpen">个人中心</em>
              </span>
            </div>
            <el-popconfirm title="确定要退出登录吗？" @confirm="logout">
              <el-link slot="reference" type="info">退出登录</el-link>
            </el-popconfirm>
          </div>
        </el-header>
        <el-main class="main-area">
          <!-- <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>活动管理</el-breadcrumb-item>
              <el-breadcrumb-item>活动列表</el-breadcrumb-item>
              <el-breadcrumb-item>活动详情</el-breadcrumb-item>
            </el-breadcrumb>
          </div> -->
          <router-view />
        </el-main>
      </el-container>
    </el-container>
    <el-dialog
      title="个人中心"
      :visible.sync="dialogVisible"
      width="420px"
      :before-close="handleClose"
    >
      <el-form :model="memberInfoForm" label-width="120px">
        <el-form-item label="用户名：">
          <div>{{ memberInfoForm.accountName }}</div>
        </el-form-item>
        <el-form-item label="密码：">
          <div v-if="!isPwdEdit">********</div>
          <el-button
            :disabled="isInfoEdit"
            type="text"
            @click="handleEditPwd"
            >{{ isPwdEdit ? "取消" : "修改密码" }}</el-button
          >
          <el-input
            v-if="isPwdEdit"
            v-model="memberInfoForm.accountPassword"
            placeholder="密码"
            type="password"
            show-password
            clearable
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机：">
          <div v-if="!isInfoEdit">{{ memberInfoForm.mobile }}</div>
          <el-button
            :disabled="isPwdEdit"
            type="text"
            @click="handleEditUserInfo"
            >{{ isInfoEdit ? "取消" : "修改用户信息" }}</el-button
          >
          <el-input
            v-if="isInfoEdit"
            v-model="memberInfoForm.mobile"
            placeholder="手机"
            clearable
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱：">
          <div v-if="!isInfoEdit">{{ memberInfoForm.mail }}</div>
          <el-input
            v-else
            v-model="memberInfoForm.mail"
            clearable
            placeholder="邮箱"
            style="width: 240px"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系QQ：">
          <div v-if="!isInfoEdit">{{ memberInfoForm.qq }}</div>
          <el-input
            v-else
            v-model="memberInfoForm.qq"
            placeholder="QQ"
            clearable
            style="width: 240px"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleEditPersonalInfo"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import Nav from "@/components/Nav";
import choosePlantorm from "@/utils/choosePlantorm";
import { mapState } from "vuex";
export default {
  name: "HomeView",
  data() {
    return {
      slideData: [],
      accountName: "",
      accountId: null,
      unionName: "",
      dialogVisible: false,
      platform: choosePlantorm().platformType,
      memberInfoForm: {
        accountName: "",
        accountPassword: "",
        mobile: "",
        mail: "",
        qq: "",
      },
      isPwdEdit: false,
      isInfoEdit: false,
      leftMenuWidth: "186px",
      isFold: false,
      // 收集路由数据
      breadcrumbList: [],
    };
  },
  components: { Nav },
  computed: {
    ...mapState(["menuTree"]),
  },
  watch: {
    $route: {
      handler: function () {
        this.getBreadcrumb();
      },
      immediate: true,
    },
    menuTree: function () {
      this.getBreadcrumb();
    },
  },
  mounted() {
    if (this.platform === 1) {
      this.getUserInfo();
    }
    // this.slideData = [
    //   {
    //     id: 1,
    //     name: "游戏资源",
    //     children: [
    //       { id: 12, name: "后管-游戏申请", path: "/game/bm/apply" },
    //       { id: 13, name: "后管-公会游戏", path: "/game/bm/list" },
    //       { id: 14, name: "公会-游戏申请", path: "/game/union/apply" },
    //       { id: 15, name: "公会-公会游戏", path: "/game/union/list" },
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "组织结构",
    //     children: [
    //       { id: 21, name: "创建公会", path: "/organization/bm/union/list" },
    //       {
    //         id: 22,
    //         name: "人员权限",
    //         path: "/organization/bm/staff/permission",
    //       },
    //       { id: 23, name: "组织信息", path: "/organization/bm/contract" },
    //       { id: 24, name: "玩家归属转移", path: "/organization/bm/transfer" },
    //       {
    //         id: 25,
    //         name: "游戏角色设置",
    //         path: "/organization/bm/role/setting",
    //       },
    //     ],
    //   },
    // ];

    // this.accountName = JSON.parse(localStorage.getItem("userInfo")).accountName;
    // this.accountId = JSON.parse(localStorage.getItem("userInfo")).accountId;
    const { accountName, accountId, unionName } = JSON.parse(
      localStorage.getItem("userInfo")
    );
    this.accountName = accountName;
    this.accountId = accountId;
    this.unionName = unionName;
  },
  methods: {
    // 遍历列表取数据
    getRouteList(path) {
      this.menuTree.some((item) => {
        if (item.path === path) {
          this.breadcrumbList.push(item);
          return true;
        } else if (item.children && item.children.length >= 1) {
          const flag = item.children.some((subItem) => {
            if (subItem.path === path) {
              this.breadcrumbList.push(item);
              this.breadcrumbList.push(subItem);
              return true;
            }
            return false;
          });
          if (flag) {
            return true;
          }
        }
        return false;
      });
    },
    // 获取面包屑
    getBreadcrumb() {
      this.breadcrumbList = [];
      if (!this.menuTree.length) {
        return;
      }
      const path = this.$route.path;
      this.getRouteList(path);
    },
    logout() {
      this.$api.loginApi.logout({}).then((res) => {
        console.log("logout", res);
        if (res.code === 0) {
          this.$message.success("请重新登录~");
          this.$router.push("/login");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleOpen() {
      this.dialogVisible = true;
    },
    resetPassword() {
      const { accountPassword } = this.memberInfoForm;
      this.$api.loginApi.updatePassword({ accountPassword }).then((res) => {
        console.log("resetPassword", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.getUserInfo();
          this.isPwdEdit = false;
          this.dialogVisible = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    resetUserInfo() {
      const { mobile, mail, qq } = this.memberInfoForm;
      this.$api.loginApi.updateAccountInfo({ mobile, mail, qq }).then((res) => {
        console.log("resetUserInfo", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.getUserInfo();
          this.isInfoEdit = false;
          this.dialogVisible = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getUserInfo() {
      this.$api.dataApi.queryAccount({}).then((res) => {
        // alert(11);
        if (res.code === 0) {
          const { accountName, accountId, unionName, mobile, qq, mail } =
            res.data;

          this.memberInfoForm = {
            accountName,
            accountId,
            unionName,
            mail,
            mobile,
            qq,
          };
          // URL.createObjectURL(
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleEditPwd() {
      const { isPwdEdit } = this;
      this.isPwdEdit = !isPwdEdit;
    },
    handleEditUserInfo() {
      const { isInfoEdit } = this;
      this.isInfoEdit = !isInfoEdit;
    },
    handleEditPersonalInfo() {
      if (this.isPwdEdit) {
        this.resetPassword();
      }
      if (this.isInfoEdit) {
        this.resetUserInfo();
      }
    },
    changeSideBar() {
      const { isFold } = this;
      this.isFold = !isFold;
      this.leftMenuWidth = this.isFold ? "64px" : "186px";
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  font-size: 14px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .home-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .top-header {
      position: relative;
      height: 64px;
      padding: 0;
      background: #fff;
      -webkit-box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
      box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 30px;

      .ctrl_menu {
        margin-left: 12px;
        width: 32px;
        height: 32px;
        cursor: pointer;

        img {
          width: 32px;
          height: 32px;
        }
      }
      .breadcrumb {
        flex: 1;
        padding-left: 20px;
      }
    }
    .main-area {
      background: #f3f3f3;
      .breadcrumb {
        position: relative;
        bottom: 3px;
        margin-bottom: 10px;
      }
    }
    .nav_wrap {
      display: flex;
      justify-content: flex-start;
      gap: 16px;

      em {
        border-right: 1px solid #ddd;
        padding-right: 10px;

        &:nth-of-type(3) {
          color: #1e80ff;
        }
      }
      em.pointer {
        cursor: pointer;
      }
    }
    .slide-box {
      .el-menu-vertical {
        border: none;
      }
      .title {
        width: 100%;
        // padding-left: 24px;
        height: 64px;
        color: #fff;
        font-size: 20px;
        // margin: 0 0 0 12px;
        font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
        font-weight: 600;
        vertical-align: middle;
      }
      img {
        width: 32px;
        height: 32px;
        margin-right: 12px;
      }
      color: rgba(255, 255, 255, 0.65);
      background: #001529;
    }
  }
}
::v-deep .bold .el-breadcrumb__inner {
  font-weight: bold;
}
</style>
