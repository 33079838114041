import moment from "moment";
/**
 * 时间处理工具类
 */
const dateUtil = {
  /**
   * @name dayToStartUnix
   * @description 将 yyyy-MM-DD 字符串格式的时间日期 Format成为 当天0点0分0秒的时间戳
   * @param {String} pDate
   * @returns {Number}
   */
  dayToStartUnix: (pDate) => moment(pDate).startOf("day").valueOf(),
  /**
   * @name dayToEndUnix
   * @description 将 yyyy-MM-DD 字符串格式的时间日期 Format成为 当天23点59分59秒的时间戳
   * @param {String} pDate
   * @returns {Number}
   */
  dayToEndUnix: (pDate) => moment(pDate).endOf("day").valueOf(),
};

export default dateUtil;
