<template>
  <div class="unionList ground-white">
    <el-page-header
      @back="$router.back()"
      v-if="$choosePlantorm().platformType === 0"
    >
    </el-page-header>
    <br />
    <!-- {{ formInline }} -->
    <el-form :inline="true" :model="formInline">
      <!-- <el-form-item>
        <el-select
          v-model="formInline.gameId"
          clearable
          placeholder="汇总维度"
          v-if="$choosePlantorm().platformType === 1"
        >
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item>
        <el-input
          v-model="formInline.gameAccountId"
          placeholder="游戏账号ID"
        ></el-input>
      </el-form-item>
      <InviterSelect
        label=""
        propId="inviterAdminAccountIdList"
        v-model="formInline.inviterAdminAccountIdList"
        :multiple="true"
        sty="width: 220px"
        :options="inviterOptions"
        :disable-branch-nodes="false"
      />
      <el-form-item>
        <el-select
          v-model="formInline.rechargeStatus"
          clearable
          placeholder="充值状态"
        >
          <el-option
            v-for="item in rechargeStatusList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item>
        <el-select v-model="formInline.gameId" clearable placeholder="角色标记">
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-date-picker
          v-model="formInline.time"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="账号创建开始日期"
          end-placeholder="账号创建结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="formInline.time1"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="充值开始日期"
          end-placeholder="充值结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="formInline.time2"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="最近登录开始日期"
          end-placeholder="最近登录结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.page.pageIndex = 1;
            getList();
          "
          >查询</el-button
        >
        <el-button type="primary" v-if="1" @click="exportList">导出</el-button>
      </el-form-item>
    </el-form>
    <p style="margin-bottom: 15px">
      <!-- 充值总额：{{ totalData.totalChargeAmount }} -->
    </p>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="gameAccountId"
        label="游戏账号ID"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="inviterAdminAccountName"
        label="推广员"
      >
      </el-table-column>
      <el-table-column align="center" prop="deviceId" label="设备信息">
      </el-table-column>
      <el-table-column align="center" prop="roleNum" label="游戏角色数">
        <template slot-scope="scope">
          <div class="badge">
            <span>{{ scope.row.roleNum }}</span>
            <span v-if="scope.row.newRole">新</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="firstLoginTime" label="首次登录">
      </el-table-column>
      <el-table-column align="center" prop="lastLoginTime" label="最近登录">
      </el-table-column>
      <el-table-column align="center" prop="lastOfflineTime" label="下线时间">
      </el-table-column>
      <el-table-column
        align="center"
        prop="lastChargeTime"
        label="最近充值时间"
      >
      </el-table-column>
      <el-table-column align="center" prop="lastChargeAmount" label="最近充值">
      </el-table-column>
      <el-table-column align="center" prop="chargeTotalAmount" label="充值总额">
      </el-table-column>
      <el-table-column
        align="center"
        prop="lastOrderStatusName"
        label="充值状态"
      >
        <template slot-scope="scope">
          <div>
            {{ statusList[scope.row.lastOrderStatus] || "未充值" }}
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="" label="详情" width="80">
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="handleClick(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.page.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.page.totalElements"
    >
    </el-pagination>

    <!-- dialog -->
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      value1: "",
      rechargeStatusList: [
        { name: "创建中", id: 100 },
        { name: "支付中", id: 200 },
        { name: "发货中", id: 300 },
        { name: "已完成", id: 400 },
        { name: "已取消", id: 500 },
      ],
      statusList: {
        100: "创建中",
        200: "支付中",
        300: "发货中",
        400: "已完成",
        500: "已取消",
      },
      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      getGameListData: [],
      staffListData: [],
      dialogFormVisible: false,
      editDialogShow: false,
      loading: false,
      editUnionId: "",
      formInline: {
        rechargeStatus: null,
        gameRoleId: null,
        gameAccountId: null,
        gameId: null,
        unionId: +this.$route.query.unionId,
        inviterAdminAccountIdList: [],
        opPlatform: null,
        startTime: "",
        endTime: "",
        page: { pageIndex: 1, pageSize: 20, totalElements: 0 },
        time: ["", ""],
        time1: ["", ""],
        time2: ["", ""],
      },
      queryForm: {
        serviceTime: "",
        gameId: null,
        capacity: 1,
        serviceName: "",
      },
      tableData: [],
      totalData: {},
    };
  },
  mounted() {
    // this.getPermission();
    // this.getGameList();
    this.getList();
    this.getListSum();
    this.getAllInviter();
  },
  methods: {
    exportList() {
      this.formInline.page.pageSize = 50000;
      // 获取公会列表
      this.$api.dataApi
        .gameAccountExport({
          ...this.formInline,
          accountCreateStartTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          accountCreateEndTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
          rechargeStartTime: this.formInline.time1
            ? this.$dateUtil.dayToStartUnix(this.formInline.time1[0])
            : "",
          rechargeEndTime: this.formInline.time1
            ? this.$dateUtil.dayToEndUnix(this.formInline.time1[1])
            : "",
          recentLoginStartTime: this.formInline.time2
            ? this.$dateUtil.dayToStartUnix(this.formInline.time2[0])
            : "",
          recentLoginEndTime: this.formInline.time2
            ? this.$dateUtil.dayToEndUnix(this.formInline.time2[1])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          this.formInline.pageSize = 20;
          // 导出Excel
          this.$exportExcel(
            res,
            `已出数据_${this.$moment().format(
              "YYYY-MM-DD"
            )}_${this.$moment().unix()}.xlsx`
          );
        });
    },
    getListSum() {
      // 获取公会列表
      this.$api.dataApi
        .inviterStatisTotal({
          ...this.formInline,
          accountCreateStartTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          accountCreateEndTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
          rechargeStartTime: this.formInline.time1
            ? this.$dateUtil.dayToStartUnix(this.formInline.time1[0])
            : "",
          rechargeEndTime: this.formInline.time1
            ? this.$dateUtil.dayToEndUnix(this.formInline.time1[1])
            : "",
          recentLoginStartTime: this.formInline.time2
            ? this.$dateUtil.dayToStartUnix(this.formInline.time2[0])
            : "",
          recentLoginEndTime: this.formInline.time2
            ? this.$dateUtil.dayToEndUnix(this.formInline.time2[1])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.totalData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      // 获取公会列表
      this.$api.dataApi
        .gameAccountQuery({
          ...this.formInline,
          accountCreateStartTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          accountCreateEndTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
          rechargeStartTime: this.formInline.time1
            ? this.$dateUtil.dayToStartUnix(this.formInline.time1[0])
            : "",
          rechargeEndTime: this.formInline.time1
            ? this.$dateUtil.dayToEndUnix(this.formInline.time1[1])
            : "",
          recentLoginStartTime: this.formInline.time2
            ? this.$dateUtil.dayToStartUnix(this.formInline.time2[0])
            : "",
          recentLoginEndTime: this.formInline.time2
            ? this.$dateUtil.dayToEndUnix(this.formInline.time2[1])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.page.totalElements = res.data.totalElements;
            this.formInline.page.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.gameApi
        .queryPackage({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryStaff({
            nickName: query,
          })
          .then((res) => {
            console.log("getGameList", res);
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.staffListData = result;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.staffListData = [];
      }
    },
    handleCurrentChange(val) {
      this.formInline.page.pageIndex = val;
      this.getList();
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/data/union/commander/detail",
        query: {
          gameAccountId: e.gameAccountId,
          gameRoleId: e.gameRoleId,
        },
      });
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .mr15 {
    margin-right: 15px;
  }
}
.role_mark {
  // position: relative;
  // z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .role_mark_icon {
    position: relative;
    margin-right: 6px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    border: 3px solid #fff;

    &::before {
      position: absolute;
      top: -4px;
      left: -4px;
      content: "";
      width: 12px;
      height: 12px;
      display: block;
      border: 1px solid #b0b0b0;
      border-radius: 12px;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 4px;
      height: 4px;
      display: block;
      border-radius: 6px;
      border: 1px solid #b0b0b0;
    }
  }

  .role_mark_icon.no_line {
    &::before {
      position: absolute;
      top: -4px;
      left: -4px;
      content: "";
      width: 12px;
      height: 12px;
      display: block;
      // border: 1px solid #b0b0b0;
      border: none;
      border-radius: 12px;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 4px;
      height: 4px;
      display: block;
      border-radius: 6px;
      border: none;
      // border: 1px solid #b0b0b0;
    }
  }
  .opt_panel {
    position: absolute;
    z-index: 200;
    top: -30px;
    border: 1px solid #ddd;
    width: 240px;
    padding: 20px;
  }
}
.role_bd {
  display: flex;
  justify-content: flex-start;

  span {
    position: relative;
    margin-right: 10px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    border: 3px solid #d1d1d1;
    cursor: pointer;

    &:nth-of-type(1) {
      border-color: #fb5353;
    }
    &:nth-of-type(2) {
      border-color: #f49207;
    }
    &:nth-of-type(3) {
      border-color: #888;
    }
    &:nth-of-type(4) {
      border-color: #fff;
      &::before {
        position: absolute;
        top: -4px;
        left: -4px;
        content: "";
        width: 12px;
        height: 12px;
        display: block;
        border: 1px solid #b0b0b0;
        border-radius: 12px;
      }
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 4px;
        height: 4px;
        display: block;
        border-radius: 6px;
        border: 1px solid #b0b0b0;
      }
    }
  }
}
.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;

  span {
    &:nth-of-type(2) {
      width: 28px;
      height: 28px;
      line-height: 28px;
      background: rgb(205, 253, 221);
      color: #0cd13a;
      border-radius: 28px;
    }
  }
}
</style>
