import gameConfig from "./game";
import organizationConfig from "./organization";
import billingConfig from "./billing";
import taskConfig from "./task";
import dataConfig from "./data";
import supportConfig from "./support";
import dashboardConfig from "./dashboard";
import permission from "./permission";
export default [
  //【数据统计】模块
  ...dashboardConfig,
  //【游戏资源】模块
  ...gameConfig,
  //【组织结构】模块
  ...organizationConfig,
  //【计费详情】模块
  ...billingConfig,
  //【任务管理】模块
  ...taskConfig,
  //【数据详情】模块
  ...dataConfig,
  // 客服平台
  ...supportConfig,
  // 无权限空白页
  ...permission,
];
