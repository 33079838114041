import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/index/home.vue";
import Config from "./config/routerConfig";
import shareConfig from "./config/share";
import protocol from "./config/protocol";

Vue.use(VueRouter);

// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    // redirect: "/game",
    children: Config,
  },
  {
    path: "/login",
    name: "登录",
    component: () => import("@/views/login"),
  },
  {
    path: "/share",
    // name: "分享落地",
    component: { render: (e) => e("router-view") },
    children: shareConfig,
  },
  {
    path: "/protocol",
    // name: "协议",
    component: { render: (e) => e("router-view") },
    children: protocol,
  },
  // {
  //   path: "*",
  //   redirect: "/404",
  //   component: () => import("@/components/test"),
  // },
];

const router = new VueRouter({
  routes,
});

// let _this = Vue.prototype;
router.beforeEach((to, from, next) => {
  // 这里写你的一些页面跳转控制逻辑
  // console.log(to, from, _this);
  next();
});
export default router;
