export default [
  // 后管端
  {
    path: "/organization/bm/union/list",
    name: "创建公会",
    component: () => import("@/views/organization/bm/unionList"),
  },
  {
    path: "/organization/bm/staff/list",
    name: "人员结构bm",
    component: () => import("@/views/organization/bm/staffList"),
  },
  {
    path: "/organization/bm/staff/permission",
    name: "人员权限bm",
    component: () => import("@/views/organization/bm/staffPermission"),
  },
  {
    path: "/organization/bm/unionrole/list",
    name: "公会角色数量详情",
    component: () => import("@/views/organization/bm/unionRoleList"),
  },
  {
    path: "/organization/bm/bmrole/list",
    name: "管理端角色数量详情",
    component: () => import("@/views/organization/bm/bmRoleList"),
  },
  {
    path: "/organization/bm/contract",
    name: "组织信息",
    component: () => import("@/views/organization/bm/unionContract"),
  },
  {
    path: "/organization/bm/transfer",
    name: "玩家归属转移",
    component: () => import("@/views/organization/bm/ownerTransfer"),
  },
  {
    path: "/organization/bm/role/setting",
    name: "有效角色设置",
    component: () => import("@/views/organization/bm/roleSetting"),
  },
  {
    path: "/organization/bm/union/setting",
    name: "公会有效设置",
    component: () => import("@/views/organization/bm/unionSetting"),
  },
  // 公会端
  {
    path: "/organization/union/staff/list",
    name: "人员结构union",
    component: () => import("@/views/organization/union/staffList"),
  },

  {
    path: "/organization/union/staff/permission",
    name: "人员权限union",
    component: () => import("@/views/organization/union/staffPermission"),
  },
  {
    path: "/organization/union/unionrole/list",
    name: "公会角色数量详情",
    component: () => import("@/views/organization/union/unionRoleList"),
  },
  {
    path: "/organization/union/contract",
    name: "组织信息",
    component: () => import("@/views/organization/union/unionContract"),
  },
  {
    path: "/organization/union/transfer",
    name: "玩家归属转移",
    component: () => import("@/views/organization/union/ownerTransfer"),
  },
  {
    path: "/organization/union/role/setting",
    name: "有效角色设置",
    component: () => import("@/views/organization/union/roleSetting"),
  },
  {
    path: "/organization/union/bmrole/list",
    name: "公会端角色数量详情",
    component: () => import("@/views/organization/union/bmRoleList"),
  },
  {
    path: "/organization/area/plan",
    name: "开服计划",
    component: () => import("@/views/organization/bm/areaPlan"),
  },
];
