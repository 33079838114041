<template>
  <div class="game-apply flex">
    <div class="left ground-white" v-loading="$store.state.loadingStatus">
      <div class="top flex">
        <el-page-header @back="$router.back()" content=""></el-page-header>
        <el-button
          type="primary"
          v-if="$route.query.show"
          size="small"
          @click="
            dialogFormVisible = true;
            detailDesc = detailData.gameDetail;
          "
          >编辑详情</el-button
        >
      </div>
      <div class="bottom">
        <div class="content flex">
          <img :src="detailData.gamePic" class="icon" />
          <div style="flex: 1">
            <p style="font-size: 24px; font-weight: bold; margin-bottom: 6px">
              <span>{{ detailData.gameName || " - " }}</span
              ><img
                src="@/assets/img/icon-s.png"
                style="
                  width: 30px;
                  height: 30px;

                  vertical-align: text-top;
                  margin-left: 6px;
                "
              />
            </p>
            <p class="desc">
              <b>{{ detailData.gameDesc || " - " }}</b>
              <span style="margin-left: 15px">|</span>
              <span style="margin: 0 15px; color: #fe7a00">{{
                detailData.gameType
              }}</span>
              <span
                >Android容量：<b
                  >{{
                    parseInt(detailData.packageSize / 1024 / 1024) || " - "
                  }}M</b
                ></span
              >
              <span style="margin: 0 15px">|</span>
              <span
                >IOS容量：<b
                  >{{
                    parseInt(detailData.packageSize / 1024 / 1024) || " - "
                  }}M</b
                ></span
              >
              <span style="margin: 0 15px"
                >核心玩法：<b>国战{{ detailData.playType || " - " }}</b></span
              >
              <span
                >题材：<b>{{ detailData.gameArea || " - " }}</b></span
              >
            </p>
          </div>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="介绍" name="first">{{
            detailData.gameDetail
          }}</el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="right ground-white" v-loading="$store.state.loadingStatus">
      <div class="flex ftitle">
        <span style="font-size: 20px; font-weight: bold">开服计划</span>
        <span style="cursor: pointer" @click="dialogFormVisibleNew = true"
          >全部></span
        >
      </div>
      <!--  -->
      <el-button type="text" @click="dialogFormVisibleNew = true"
        >查看以前计划</el-button
      >
      <!--  -->
      <el-timeline style="margin-top: 20px; margin-left: 10px">
        <el-timeline-item
          v-for="(activity, index) in areaList"
          :key="index"
          :timestamp="$moment(activity.serviceTime).format('MM-DD HH:mm')"
        >
          {{ activity.serviceName }}
        </el-timeline-item>
      </el-timeline>
    </div>
    <!-- dialog -->
    <el-dialog :visible.sync="dialogFormVisible">
      <el-form :model="detailData" status-icon ref="detailData" :inline="true">
        <el-form-item label="游戏介绍" prop="detailDesc">
          <el-input
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="detailDesc"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateDetailDesc">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogFormVisibleNew" width="360px">
      <div slot="title" class="open_server">
        <h3>开服计划</h3>
      </div>
      <el-form
        :model="detailData"
        size="small"
        status-icon
        ref="detailData"
        :inline="true"
      >
        <el-form-item>
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="areaForm.startTime"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getAreaListModal">查询</el-button>
        </el-form-item>
      </el-form>
      <!--  -->
      <div style="height: 420px" class="timeline_wrap">
        <el-timeline style="margin-left: 10px">
          <el-timeline-item
            v-for="(activity, index) in modalAreaList"
            :key="index"
            :timestamp="$moment(activity.serviceTime).format('MM-DD HH:mm')"
          >
            {{ activity.gameName }} - {{ activity.serviceName }}
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      areaList: [],
      modalAreaList: [],
      dialogFormVisible: false,
      dialogFormVisibleNew: false,
      areaForm: {
        startTime: "",
        endTime: "",
        pageIndex: 1,
        pageSize: 10000,
      },
      modalAreaForm: {
        gameId: null,
        startTime: "",
        endTime: "",
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      activeName: "first",
      detailData: {},
      detailDesc: "",
      activities: [
        {
          content: "活动按期开始",
          timestamp: "2018-04-15",
        },
        {
          content: "通过审核",
          timestamp: "2018-04-13",
        },
        {
          content: "创建成功",
          timestamp: "2018-04-11",
        },
      ],
    };
  },
  mounted() {
    this.getDetail();

    this.getAreaList();
    this.getAreaListModal();
  },
  methods: {
    getAreaListModal() {
      // 详情页内默认查看全部的计划
      this.$api.organizationApi
        .queryAreaPlan({
          gameId: this.$route.query.id,
          ...this.areaForm,
          endTime: this.areaForm.startTime,
          sort: "desc",
        })
        .then((res) => {
          console.log("getAreaList", res);
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : {};
            this.modalAreaList = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getAreaList() {
      // 详情页内默认查看未来一周的计划
      this.$api.organizationApi
        .queryAreaPlan({
          gameId: this.$route.query.id,
          startTime: this.$moment(new Date()).format("YYYY-MM-DD"),
          endTime: this.$moment().add(7, "d").format("YYYY-MM-DD"),
          pageIndex: 1,
          pageSize: 10000,
          sort: "desc",
        })
        .then((res) => {
          console.log("getAreaList", res);
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : {};
            this.areaList = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    updateDetailDesc() {
      this.$api.gameApi
        .updateGameDetail({
          detail: this.detailDesc,
          id: this.$route.query.id,
        })
        .then((res) => {
          console.log("updateGameDetail", res);
          if (res.code === 0) {
            this.dialogFormVisible = false;
            this.$message.success("操作成功!");
            this.getDetail();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getDetail() {
      this.$api.gameApi
        .queryGameDetail({
          id: this.$route.query.id,
        })
        .then((res) => {
          console.log("queryGameDetail", res);
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.detailData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  /deep/.el-tabs__active-bar,
  /deep/ .el-tabs__nav {
    padding: 0 20px;
  }
  align-items: flex-start;
  height: 100%;
  .left {
    .desc {
      color: #999;
      b {
        color: #444;
      }
    }
    flex: 1;
    margin-right: 12px;
    height: calc(100% - 45px);
    .top {
      justify-content: space-between;
    }
    .bottom {
      /deep/.el-tabs__item {
        // margin: 0 15px;
      }

      margin-top: 22px;
      .content {
        margin-bottom: 20px;
      }
      .icon {
        width: 70px;
        height: 70px;
        margin-right: 20px;
        border-radius: 6px;
      }
    }
  }
  .right {
    width: 280px;
    height: calc(100% - 45px);
    padding: 10px;
    .ftitle {
      justify-content: space-between;
      // margin-top: 200px;
      padding-right: 10px;
      border-bottom: 1px #999 solid;
      padding-bottom: 10px;
    }
  }
  .open_server {
    h3 {
      font-size: 16px;
      padding-bottom: 12px;
      border-bottom: 1px solid #ddd;
    }
  }
  /deep/ .el-dialog__body {
    padding-top: 10px;
  }
  .timeline_wrap {
    height: 420px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
