<template>
  <div class="game-apply">
    <!-- top -->
    <div class="top-container ground-white flex">
      <div class="title">今日开服</div>
      <div class="box">
        <div class="bar" ref="bar" v-if="areaList.length">
          <div class="item" v-for="item in areaList" :key="item.id">
            <span>{{
              $moment(item.serviceTime).format("YYYY-MM-DD HH:mm").split(" ")[1]
            }}</span>
            <span style="margin: 0 6px">{{ item.gameName }}</span>
            <span>{{ item.serviceName }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- bottom -->
    <div class="bottom-container ground-white">
      <el-form :inline="true" :model="formInline">
        <el-form-item label="区服类型">
          <el-input
            v-model="formInline.gameArea"
            placeholder="区服类型"
          ></el-input>
        </el-form-item>
        <el-form-item label="游戏名字">
          <el-input
            v-model="formInline.gameName"
            placeholder="游戏名字"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="
              formInline.pageIndex = 1;
              getList();
            "
            >查询</el-button
          >
          <el-button
            type="primary"
            @click="
              dialogFormVisible = true;
              editShow = false;
              resetUpload('upload');
              resetForm('gameForm');
            "
            >新增游戏</el-button
          >
        </el-form-item>
      </el-form>
      <div class="list">
        <div
          v-if="tableData.length === 0"
          style="text-align: center; font-size: 16px; margin-top: 20px"
        >
          暂无数据
        </div>
        <div class="item flex" v-for="item in tableData" :key="item.id">
          <img :src="item.gamePic" class="icon" alt="" />
          <div style="width: 20%">
            <p
              style="
                font-size: 16px;
                font-weight: bold;
                cursor: pointer;
                margin-bottom: 3px;
              "
              @click="goDetail(item.id)"
            >
              <span>{{ item.gameName }}</span
              ><img
                src="@/assets/img/icon-s.png"
                style="
                  width: 20px;
                  height: 20px;
                  vertical-align: text-top;
                  margin-left: 6px;
                "
              />
            </p>
            <p style="color: #999" class="elp">{{ item.gameDesc }}</p>
          </div>
          <div style="width: 20%">
            <p style="color: #999">
              <span style="color: #666" class="elp">
                游戏类型:{{ item.gameType }}</span
              >
            </p>
            <p style="color: #999">
              <span style="color: #666" class="elp"
                >核心玩法:{{ item.playType }}</span
              >
            </p>
          </div>
          <div style="width: 15%">
            <p style="color: #999">
              <span style="color: #666" class="elp"
                >游戏区服:{{ item.gameArea }}</span
              >
            </p>
            <p style="color: #999">
              <span style="color: #666" class="elp">
                游戏题材:{{ item.gameTheme }}</span
              >
            </p>
          </div>
          <div style="width: 12%">
            <p>
              <img
                src="@/assets/img/icon-apple.png"
                style="width: 23px; height: 23px; vertical-align: bottom"
              />
              分成:
              <span>{{ item.iosRatio }}%</span>
            </p>
            <p>
              <img
                src="@/assets/img/icon-android.png"
                style="width: 23px; height: 23px; vertical-align: bottom"
              />
              分成:
              <span>{{ item.androidRatio }}%</span>
            </p>
          </div>
          <div style="flex: 1; text-align: center">
            <el-button type="primary" size="mini" @click="openEdit(item)"
              >编辑</el-button
            >
            <el-button
              v-if="item.gameStatus === 0"
              type=""
              size="mini"
              @click="changeStatus(item)"
              >{{ "下架推广" }}</el-button
            >
            <el-button
              v-else
              type="primary"
              size="mini"
              @click="changeStatus(item)"
              >{{ "上架" }}</el-button
            >
            <el-button
              type="primary"
              v-if="!item.packageSize"
              size="mini"
              @click="$router.push('/game/bm/apply')"
              >绑定包</el-button
            >
            <el-button
              v-if="0"
              type="danger"
              @click="deleteItem(item.id)"
              size="mini"
              >删除</el-button
            >
          </div>
        </div>
      </div>
      <p style="width: 100; color: #999; text-align: right">
        注意：当未申请的游戏产生充值时，将以游戏的基础分成比例进行分成
      </p>
      <el-pagination
        style="float: right; margin-top: 8px"
        layout="prev, pager, next, jumper"
        :page-size="20"
        :current-page.sync="formInline.pageIndex"
        @current-change="handleCurrentChange"
        :total="formInline.totalElements"
      >
      </el-pagination>
    </div>

    <!-- 编辑包体弹窗dialog -->
    <el-dialog
      :title="editShow ? '编辑游戏' : '新增游戏'"
      :visible.sync="dialogFormVisible"
    >
      <!-- {{ gameForm }} -->
      <!-- {{ fileList }} -->
      <el-form :inline="true" ref="gameForm" :model="gameForm">
        <el-form-item prop="gameName" label="游戏名称">
          <el-input
            v-model="gameForm.gameName"
            placeholder="游戏名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="gameDesc" label="游戏介绍">
          <el-input
            v-model="gameForm.gameDesc"
            placeholder="游戏介绍"
          ></el-input>
        </el-form-item>
        <el-form-item prop="gameType" label="游戏类型">
          <el-input
            v-model="gameForm.gameType"
            placeholder="游戏类型"
          ></el-input>
        </el-form-item>
        <el-form-item prop="playType" label="核心玩法">
          <el-input
            v-model="gameForm.playType"
            placeholder="核心玩法"
          ></el-input>
        </el-form-item>
        <el-form-item prop="gameArea" label="区服类型">
          <el-input
            v-model="gameForm.gameArea"
            placeholder="区服类型"
          ></el-input>
        </el-form-item>
        <el-form-item prop="gameTheme" label="游戏题材">
          <el-input
            v-model="gameForm.gameTheme"
            placeholder="游戏题材"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item prop="" label="包版本号">
          <el-input
            v-model="gameForm.gameVersion"
            placeholder="包版本号"
          ></el-input>
        </el-form-item> -->
        <br />
        <el-form-item prop="androidRatio" label="默认分成：安卓">
          <el-input-number
            type="number"
            :min="0.01"
            :step="0.01"
            v-model="gameForm.androidRatio"
            placeholder="默认分成：安卓"
            ><template slot="append">%</template></el-input-number
          >%
        </el-form-item>
        <el-form-item prop="iosRatio" label="IOS">
          <el-input-number
            type="number"
            :min="0.01"
            :step="0.01"
            v-model="gameForm.iosRatio"
            placeholder="默认分成：IOS"
            ><template slot="append">%</template></el-input-number
          >%
        </el-form-item>
        <el-form-item prop="chargeNum" label="游戏扶持额度比例">
          <el-input-number
            type="number"
            :min="0.01"
            :step="0.01"
            v-model="gameForm.chargeNum"
            disabled
            placeholder="游戏扶持额度比例"
          ></el-input-number>
        </el-form-item>
        <el-form-item prop="transferNum" label=" ：">
          <el-input-number
            type="number"
            :min="0.01"
            :step="0.01"
            v-model="gameForm.transferNum"
            placeholder="游戏扶持额度比例"
          ></el-input-number>
        </el-form-item>
        <el-form-item prop="gamePic" label="游戏logo">
          <el-upload
            class="avatar-uploader"
            :action="$api.gameApi.uploadResourc()"
            :show-file-list="true"
            :on-success="handleAvatarSuccess"
            :headers="headersObj"
            :before-upload="beforeUpload"
            :limit="1"
            ref="upload"
            :file-list="fileList"
          >
            <img
              v-if="gameForm.gamePic"
              :src="gameForm.gamePic"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过10MB
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="editShow ? updateGame() : addGame()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "loginPage",
  data() {
    return {
      fileList: [],
      areaList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      headersObj: {
        Authorization: localStorage.getItem("token"),
      },
      dialogFormVisible: false,
      editShow: false,
      activeName: "first",
      editId: "",
      gameForm: {
        gameName: "",
        gameDesc: "",
        gameType: "",
        playType: "",
        gameArea: "",
        gameTheme: "",
        androidRatio: 50,
        iosRatio: 50,
        chargeNum: 1,
        transferNum: 1,
        gamePic: "",
        fileList: [],
      },
      formInline: {
        gameArea: "",
        gameName: "",
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      permissionForm: {},
      tableData: [],
      rules: {
        accountName: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        accountPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    dialogFormVisible(v) {
      if (!v) {
        this.gameForm = {
          gameName: "",
          gameDesc: "",
          gameType: "",
          playType: "",
          gameArea: "",
          gameTheme: "",
          androidRatio: 50,
          iosRatio: 50,
          chargeNum: 1,
          transferNum: 1,
          gamePic: "",
          fileList: [],
        };
      }
    },
  },
  mounted() {
    this.getList();
    // setTimeout(() => {
    //   this.$refs.bar.style = `transform:translateY(-42px);`;
    // }, 1000);
    this.getAreaList();
  },
  methods: {
    getAreaList() {
      // 获取公会列表
      this.$api.organizationApi
        .queryAreaPlan({
          gameId: null,
          startTime: this.$moment(new Date()).format("YYYY-MM-DD"),
          endTime: this.$moment(new Date()).format("YYYY-MM-DD"),
          pageIndex: 1,
          pageSize: 10000,
        })
        .then((res) => {
          console.log("getAreaList", res);
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : {};
            this.areaList = result;

            let times = Math.ceil(this.areaList.length / 4);
            if (times > 1) {
              this.ani(times);
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    ani(data) {
      data -= 1;
      setTimeout(() => {
        const a =
          data < 0 ? 0 : (Math.ceil(this.areaList.length / 4) - data) * 42;
        this.$refs.bar.style = ` transition: 2s; transform:translateY(-${a}px);`;
      }, 2000);
      setTimeout(() => {
        if (data === 1) {
          this.$refs.bar.style = `transtion:0;transform:translateY(0px);`;
          this.ani(Math.ceil(this.areaList.length / 4));
        } else {
          this.ani(data);
        }
      }, 6000);
    },
    deleteItem(pId) {
      this.$confirm("确定要删除数据吗？", "", {
        type: "error",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 删除
        this.deleteUnion(pId);
      });
    },
    deleteUnion(pId) {
      this.$api.gameApi
        .deletePackage({
          id: pId,
        })
        .then((res) => {
          console.log("updatePackage", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    beforeUpload(file) {
      console.log(file);
      const isJPG = file.type === "image/jpg";
      const isPng = file.type === "image/png";
      const isJpeg = file.type === "image/jpeg";

      //1MB=1024*1024(1MB=1024KB 1KB=1024MB)
      const is10M = file.size / 1024 / 1024 < 10;

      //限制文件上传类型
      if (!isJPG && !isPng && !isJpeg) {
        this.$message.error("游戏图片只能是 png,jpg,jpeg 格式!");
        return false;
      }

      //限制文件上传大小
      if (!is10M) {
        this.$message.error("游戏图片大小不能超过 10MB!");
        return false;
      }

      return true;
    },
    openEdit(data) {
      this.resetUpload("upload");
      this.editShow = true;
      this.dialogFormVisible = true;
      setTimeout(() => {
        this.gameForm = { ...data };
      }, 1);
      // setTimeout(function () {
      // }, 1);
    },
    changeStatus(item) {
      console.log(item);
      this.$api.gameApi
        .changeStatusPackage({
          id: item.id,
          status: item.gameStatus === 0 ? 1 : 0,
        })
        .then((res) => {
          console.log("updatePackage", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    updateGame() {
      this.$api.gameApi.updatePackage(this.gameForm).then((res) => {
        console.log("resetPassword", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.dialogFormVisible = false;
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    addGame() {
      this.$api.gameApi.addPackage(this.gameForm).then((res) => {
        console.log("resetPassword", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.dialogFormVisible = false;
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getList() {
      this.$api.gameApi.queryAdminGame(this.formInline).then((res) => {
        console.log("getGameList", res);
        if (res.code === 0) {
          const result = res.data.content ? res.data.content : [];
          this.tableData = result;
          this.formInline.totalElements = res.data.totalElements;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    goDetail(id) {
      this.$router.push({
        path: "/game/detail",
        query: {
          id: id,
          show: true,
        },
      });
    },
    handleCurrentChange(e) {
      this.formInline.pageIndex = e;
      this.getList();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.login();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetUpload(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].clearFiles();
        }
      });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
    handleAvatarSuccess(res, file) {
      console.log("res", res);
      console.log("file", file);
      console.log(this.gameForm);
      if (res.code === 0) {
        this.gameForm.gamePic =
          res.data.uploadUrl || URL.createObjectURL(file.raw);
      } else {
        this.$message.error(res.msg);
      }
    },
    onSubmit() {},
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  .elp {
    color: #666;
    width: 100%;
    color: rgb(102, 102, 102);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/.avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  /deep/.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  /deep/.avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .top-container {
    margin-bottom: 8px;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #255ec0;
      margin-left: 24px;
      margin-right: 40px;
    }
    .box {
      flex: 1;
      overflow: hidden;
      height: 42px;
      line-height: 42px;
      .bar {
        .item {
          // flex: 1;
          width: 25%;
          height: 100%;
          float: left;
          text-align: center;
          font-size: 15px;
        }
      }
    }
  }
  .bottom-container {
    .list {
      margin-bottom: 60px;
      .item {
        width: 100%;
        height: 71px;
        border-top: 1px #eee solid;
        align-items: center;
        &:last-child {
          border-bottom: 1px #eee solid;
        }
        .icon {
          width: 50px;
          height: 50px;
          margin: 0 20px;
          border-radius: 6px;
        }
      }
    }
  }
}
</style>
