<template>
  <div class="unionList ground-white">
    <el-form :inline="true" :model="formInline">
      <el-form-item label="公会ID">
        <el-input
          maxlength="8"
          type="number"
          min="0"
          v-model="formInline.unionId"
          placeholder="请输入公会ID"
        ></el-input>
      </el-form-item>
      <el-form-item label="公会名称">
        <el-input
          v-model="formInline.unionName"
          placeholder="请输入公会名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="formInline.status" placeholder="活动区域">
          <el-option label="全部" value=""></el-option>
          <el-option label="启用" value="0"></el-option>
          <el-option label="禁用" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="
            formInline.pageIndex = 1;
            getList();
          "
          >查询</el-button
        >
        <el-button
          type="primary"
          @click="
            dialogFormVisible = true;
            editDialogShow = false;
            resetForm('queryForm');
          "
          >新建公会</el-button
        >
      </el-form-item>
    </el-form>
    <div class="data_bar">
      <p>
        公会总数：<span>{{ unionNum || 0 }}</span>
      </p>
      <p>
        公会总人数：<span>{{ unionMemberNum || 0 }}</span>
      </p>
    </div>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column align="center" prop="id" label="公会ID" width="120">
      </el-table-column>
      <el-table-column
        align="center"
        prop="unionName"
        label="公会名称"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="status"
        label="公会状态"
        width="150"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" size="small" type="success"
            >启用</el-tag
          >
          <el-tag v-else size="small" type="danger">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="memberNum"
        label="公会人数"
        width="150"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="openTime"
        label="开通时间"
        width="150"
      >
        <template slot-scope="scope">
          {{ $moment(scope.row.openTime).format("YYYY-MM-DD") }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="" label="操作" width="230">
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="handleClick(scope.row)"
            >查看</el-button
          >
          <el-button type="text" size="small" @click="openEditDialog(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="changeStatus(scope.row)"
            >{{ scope.row.status === 0 ? "禁用" : "启用" }}</el-button
          >
          <el-button type="text" size="small" @click="resetPassWord(scope.row)"
            >重置密码</el-button
          >
          <el-button
            v-if="0"
            type="text"
            size="small"
            @click="deleteItem(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.totalElements"
    >
    </el-pagination>

    <!-- dialog -->
    <el-dialog
      :title="editDialogShow ? '编辑公会' : '新建公会'"
      :visible.sync="dialogFormVisible"
    >
      <!-- {{ queryForm }} -->

      <el-form :model="queryForm" status-icon ref="queryForm" :inline="true">
        <el-form-item label="公会名称" required prop="unionName">
          <el-input v-model="queryForm.unionName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="超管账号" required prop="accountName">
          <el-input
            :disabled="editDialogShow"
            v-model="queryForm.accountName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="!editDialogShow"
          label="超管密码"
          required
          prop="accountPassword"
        >
          <el-input
            v-model="queryForm.accountPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" required prop="realName">
          <el-input v-model="queryForm.realName"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" required prop="mobile">
          <el-input v-model="queryForm.mobile"></el-input> </el-form-item
        ><el-form-item label="电子邮箱" prop="mail">
          <el-input v-model="queryForm.mail"></el-input>
        </el-form-item>
        <el-form-item label="QQ号码" prop="qq">
          <el-input v-model="queryForm.qq"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="editDialogShow" type="primary" @click="updateUnion"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="addUnion">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      dialogFormVisible: false,
      editDialogShow: false,
      editUnionId: "",
      formInline: {
        unionId: "",
        unionName: "",
        status: "",
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      queryForm: {
        unionName: "",
        accountName: "",
        realName: "",
        accountPassword: "",
        mobile: "",
        mail: "",
        qq: "",
      },
      tableData: [],
    };
  },
  mounted() {
    // this.getPermission();
    this.getList();
  },
  methods: {
    resetUnionPassword() {
      this.$api.organizationApi
        .resetPassword({
          unionId: this.editUnionId,
        })
        .then((res) => {
          console.log("resetPassword", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    deleteUnion(data) {
      this.$api.organizationApi
        .deleteUnion({
          unionId: data.id,
          isDelete: 1,
        })
        .then((res) => {
          console.log("deleteUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    changeUnionStatus(data) {
      this.$api.organizationApi
        .changeUnionStatus({
          unionId: data.id,
          status: data.status === 0 ? 1 : 0,
        })
        .then((res) => {
          console.log("changeUnionStatus", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    updateUnion() {
      this.$api.organizationApi
        .updateUnion({ ...this.queryForm, unionId: this.editUnionId })
        .then((res) => {
          console.log("updateUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.dialogFormVisible = false;
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    addUnion() {
      this.$api.organizationApi.createUnion(this.queryForm).then((res) => {
        console.log("createUnion", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.dialogFormVisible = false;
        } else {
          this.$message.error(res.msg);
        }
        this.getList();
      });
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
    onSubmit() {
      console.log("submit!");
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/organization/bm/staff/list",
        query: {
          unionId: e.id,
        },
      });
    },
    resetPassWord(pId) {
      this.$confirm("重置密码:默认密码123456", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 重置密码
        this.editUnionId = pId.id;
        this.resetUnionPassword();
      });
    },
    changeStatus(pId) {
      this.$confirm("确定要更改状态吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 改变状态
        this.changeUnionStatus(pId);
      });
    },
    deleteItem(pId) {
      this.$confirm("确定要删除数据吗？", "", {
        type: "error",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 删除
        this.deleteUnion(pId);
      });
    },
    openEditDialog(data) {
      this.dialogFormVisible = true;
      this.editDialogShow = true;
      this.editUnionId = data.id;
      setTimeout(() => {
        this.queryForm.unionName = data.unionName;
        this.queryForm.accountName = data.accountName;
        this.queryForm.mobile = data.mobile;
        this.queryForm.qq = data.qq;
        this.queryForm.mail = data.mail;
        this.queryForm.realName = data.realName;
      }, 1);
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    getList() {
      // 获取公会列表
      this.$api.organizationApi.getUnionList(this.formInline).then((res) => {
        console.log("getUnionList", res);
        if (res.code === 0) {
          const result = res.data.content ? res.data.content : {};
          this.tableData = result.list;
          this.unionMemberNum = result.unionMemberNum;
          this.unionNum = result.unionNum;
          this.formInline.totalElements = res.data.totalElements;
          this.formInline.pageIndex = res.data.pageIndex;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .mr15 {
    margin-right: 15px;
  }
}
</style>
