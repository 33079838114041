<template>
  <div class="unionList ground-white">
    <!-- {{ formInline }} -->
    <el-form :inline="true" :model="formInline">
      <!-- <el-form-item label="下级公会名称">
        <el-input
          clearable
          v-model="formInline.unionName"
          placeholder=""
        ></el-input>
      </el-form-item> -->
      <el-form-item label="下级公会">
        <el-select
          v-model="formInline.unionId"
          clearable
          placeholder="请选择下级公会"
        >
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.unionName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="公会ID">
        <el-input
          clearable
          v-model="formInline.unionId"
          placeholder=""
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.pageIndex = 1;
            getList();
            getListSum();
          "
          >查询</el-button
        >
        <!-- <el-button type="primary" v-if="1" @click="exportList">导出</el-button> -->
      </el-form-item>
    </el-form>
    <div class="data_bar">
      <p>
        充值总额：<span>{{ totalData.totalChargeAmount }}</span>
      </p>
    </div>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <!-- <el-table-column
        align="center"
        prop="statisDate"
        label="时间"
        :show-overflow-tooltip="true"
      >
      </el-table-column> -->
      <el-table-column align="center" prop="unionName" label="下级公会名称">
        <!-- <template slot-scope="scope">
          {{ $moment(scope.row.serviceTime).format("YYYY-MM-DD HH:mm:ss") }}
        </template> -->
      </el-table-column>
      <el-table-column align="center" prop="unionId" label="公会ID">
      </el-table-column>
      <el-table-column align="center" prop="headAccountNum" label="团长数量">
      </el-table-column>
      <el-table-column align="center" prop="gameNum" label="游戏数量">
      </el-table-column>
      <!-- <el-table-column align="center" prop="newUserNum" label="注册数量">
      </el-table-column>
      <el-table-column align="center" prop="onlineNum" label="在线人数">
      </el-table-column> -->
      <el-table-column align="center" prop="chargeTotalAmount" label="充值总额">
      </el-table-column>
      <!-- <el-table-column align="center" prop="lastChargeAmount" label="最近充值">
      </el-table-column> -->
      <el-table-column align="center" prop="" label="详情" width="150">
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="handleClick(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.totalElements"
    >
    </el-pagination>

    <!-- dialog -->
    <el-dialog
      :title="editDialogShow ? '编辑计划' : '新建计划'"
      :visible.sync="dialogFormVisible"
    >
      <!-- {{ queryForm }} -->

      <el-form :model="queryForm" status-icon ref="queryForm" :inline="true">
        <el-form-item label="游戏名称" prop="gameId">
          <el-select v-model="queryForm.gameId" placeholder="请选择游戏名称">
            <el-option
              v-for="item in getGameListData"
              :key="item.id"
              :label="item.gameName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开服时间" prop="serviceTime">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="queryForm.serviceTime"
            type="datetime"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="服务器名称" prop="serviceName">
          <el-input v-model="queryForm.serviceName"></el-input> </el-form-item
        ><el-form-item label="峰值" prop="capacity">
          <el-input-number
            :min="1"
            v-model="queryForm.capacity"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="editDialogShow" type="primary" @click="updateUnion"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="addUnion">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      value1: "",
      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      getGameListData: [],
      staffListData: [],
      dialogFormVisible: false,
      editDialogShow: false,
      loading: false,
      editUnionId: "",
      formInline: {
        dateType: "1",
        unionId: null,
        inviterAccountIdList: [],
        opPlatform: null,
        startTime: "",
        endTime: "",
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
        time: ["", ""],
      },
      queryForm: {
        serviceTime: "",
        gameId: null,
        capacity: 1,
        serviceName: "",
      },
      tableData: [],
      totalData: {},
    };
  },
  mounted() {
    // this.getPermission();
    this.getGameList();
    this.getList();
    this.getListSum();
  },
  methods: {
    exportList() {
      this.formInline.pageSize = 50000;
      // 获取公会列表
      this.$api.dataApi
        .gameAccountExportA({
          ...this.formInline,
          startTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          endTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          this.formInline.pageSize = 20;
          // 导出Excel
          this.$exportExcel(
            res,
            `已出数据_${this.$moment().format(
              "YYYY-MM-DD"
            )}_${this.$moment().unix()}.xlsx`
          );
        });
    },
    getListSum() {
      // 获取公会列表
      this.$api.dataApi
        .inviterStatisTotal({
          ...this.formInline,
          startTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          endTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.totalData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      // 获取公会列表
      this.$api.dataApi
        .taskRoleQueryA({
          ...this.formInline,
          startTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          endTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.totalElements = res.data.totalElements;
            this.formInline.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.organizationApi
        .getUnionList({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content.list : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryStaff({
            nickName: query,
          })
          .then((res) => {
            console.log("getGameList", res);
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.staffListData = result;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.staffListData = [];
      }
    },
    resetUnionPassword() {
      this.$api.organizationApi
        .resetPassword({
          unionId: this.editUnionId,
        })
        .then((res) => {
          console.log("resetPassword", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    deleteUnion(data) {
      this.$api.organizationApi
        .deleteUnion({
          unionId: data.id,
          isDelete: 1,
        })
        .then((res) => {
          console.log("deleteUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    changeUnionStatus(data) {
      this.$api.organizationApi
        .changeUnionStatus({
          unionId: data.id,
          status: data.status === 0 ? 1 : 0,
        })
        .then((res) => {
          console.log("changeUnionStatus", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    updateUnion() {
      this.$api.organizationApi
        .updateAreaPlan({ ...this.queryForm, id: this.editUnionId })
        .then((res) => {
          console.log("updateUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.dialogFormVisible = false;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    addUnion() {
      this.$api.organizationApi.createAreaPlan(this.queryForm).then((res) => {
        console.log("createUnion", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.dialogFormVisible = false;
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
    onSubmit() {
      console.log("submit!");
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/data/bm/commander/detail",
        query: {
          unionId: e.unionId,
        },
      });
    },
    resetPassWord(pId) {
      this.$confirm("重置密码:默认密码123456", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 重置密码
        this.editUnionId = pId.id;
        this.resetUnionPassword();
      });
    },
    changeStatus(pId) {
      this.$confirm("确定要更改状态吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 改变状态
        this.changeUnionStatus(pId);
      });
    },
    deleteItem(pId) {
      this.$confirm("确定要删除数据吗？", "", {
        type: "error",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 删除
        this.deleteUnion(pId);
      });
    },
    openEditDialog(data) {
      this.dialogFormVisible = true;
      this.editDialogShow = true;
      this.editUnionId = data.id;
      setTimeout(() => {
        this.queryForm.serviceTime = this.$moment(data.serviceTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.queryForm.gameId = data.gameId;
        this.queryForm.capacity = data.capacity;
        this.queryForm.serviceName = data.serviceName;
      }, 1);
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .mr15 {
    margin-right: 15px;
  }
}
</style>
