<template>
  <div class="game-apply ground-white">
    <p>
      <br />
      当前接口请求前缀：<span>{{ currentUrl }}</span> &nbsp;&nbsp; （
      <b>按照此格式</b> ，自行在下方输入框修改前缀）
    </p>
    <p>
      设置前缀：{{ localUrl }}
      <br />
      <br />
      <el-input v-model="localUrl" style="width: 300px"></el-input>&nbsp;
      <el-button type="success" @click="doSet">生效</el-button>
    </p>
    <p>
      <el-button type="danger" @click="doCancel">恢复默认</el-button>
      （点此重置为测试环境接口前缀）
    </p>
  </div>
</template>
<script>
import base from "@/api/base";
export default {
  name: "loginPage",
  data() {
    return {
      currentUrl: "",
      originUrl: "",
      localUrl: "",
    };
  },
  mounted() {
    console.log(base);
    this.doPermission();
    this.originUrl = base.sq;
    this.currentUrl = sessionStorage.getItem("localip")
      ? JSON.parse(sessionStorage.getItem("localip"))
      : this.originUrl;
  },
  methods: {
    doCancel() {
      sessionStorage.clear();
      this.$message.success("已恢复默认请求前缀~");
      setTimeout(() => {
        window.location.reload();
      }, 800);
    },
    doSet() {
      if (!this.localUrl) {
        this.$message.error("设置不能为空！");
        return;
      }
      this.$confirm("请仔细核对设置的Url是否和你本地ip保持一致", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          sessionStorage.setItem("localip", JSON.stringify(this.localUrl));
          this.$message.success("请求前缀设置已生效~");
          setTimeout(() => {
            window.location.reload();
          }, 800);
        })
        .catch(() => {});
    },
    doPermission() {
      // 不在测试环境  &&  不在开发环境  不允许访问此界面
      if (
        window.location.href.indexOf("/test") === -1 &&
        window.location.href.indexOf("localhost") === -1
      ) {
        this.$router.push("/login");
        sessionStorage.clear();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  p {
    font-size: 16px;
    color: #555;
    padding: 15px 5px;
    span {
      font-size: 18px;
      color: #333;
    }
    b {
      color: palevioletred;
    }
  }
}
</style>
