<template>
  <div class="game-apply ground-white">
    <el-form :inline="true" :model="formThird">
      <el-form-item label="公会名称">
        <el-select
          v-model="formThird.unionId"
          clearable
          filterable
          placeholder="请选择公会名称"
        >
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.unionName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="打款状态">
        <el-select
          v-model="formThird.transferStatus"
          clearable
          placeholder="请选择审批状态"
        >
          <el-option
            v-for="item in approvalStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="
            // detailForm.pageIndex = 1;
            getList()
          "
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        v-for="column in transferConfig"
        :key="column.prop"
        align="center"
        :prop="column.prop"
        :label="column.label"
        :show-overflow-tooltip="true"
      />
      <el-table-column align="center" prop="" label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="gotoViewDetail(scope.row)"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="formThird.page.pageSize"
      :current-page.sync="formThird.page.pageIndex"
      @current-change="handleCurrentChange"
      :total="formThird.page.totalElements"
    >
    </el-pagination>
    <el-dialog
      :title="`${payDetailTitle}打款详情`"
      :visible.sync="isShowPayDetail"
      width="55%"
      :before-close="handleClose"
    >
      <div class="formWrap">
        <el-form
          :inline="true"
          ref="detailForm"
          :model="detailForm"
          class="formWrap"
        >
          <div class="line_wrap">
            <el-form-item label="公会名称：">
              <div>{{ detailForm.unionName || "-" }}</div>
            </el-form-item>
            <el-form-item label="公会ID：">
              <div>{{ detailForm.unionId || "-" }}</div>
            </el-form-item>
          </div>
          <div class="line_wrap">
            <el-form-item label="提现额度：">
              <div>{{ detailForm.withdrawAmount }}</div>
            </el-form-item>
            <el-form-item v-if="detailForm.withdrawType === 2" label="开户行：">
              <div>{{ detailForm.cardChannel }}</div>
            </el-form-item>

            <el-form-item
              v-if="[0, 1].includes(detailForm.withdrawType)"
              label="开户名："
            >
              <div>{{ detailForm.recipientName }}</div>
            </el-form-item>
          </div>
          <div class="line_wrap">
            <el-form-item v-if="detailForm.withdrawType === 2" label="开户名：">
              <div>{{ detailForm.recipientName }}</div>
            </el-form-item>
            <el-form-item v-if="detailForm.withdrawType === 2" label="卡号：">
              <div>{{ detailForm.cardNo }}</div>
            </el-form-item>
            <el-form-item
              v-if="[0, 1].includes(detailForm.withdrawType)"
              label="支付宝账号："
            >
              <div>{{ detailForm.cardNo }}</div>
            </el-form-item>
          </div>
          <div class="line_wrap">
            <el-form-item
              label="打款状态："
              prop="transferStatus"
              :rules="[
                {
                  required: true,
                  message: '请选择一个打款状态',
                  trigger: 'blur',
                },
              ]"
            >
              <el-radio
                :disabled="
                  [1, 2].includes(detailForm.transferStatus) &&
                  ['打款成功', '打款失败'].includes(
                    detailForm.transferStatusDesc
                  )
                "
                v-model="detailForm.transferStatus"
                :label="1"
                >打款成功</el-radio
              >
              <el-radio
                :disabled="
                  [1, 2].includes(detailForm.transferStatus) &&
                  ['打款成功', '打款失败'].includes(
                    detailForm.transferStatusDesc
                  )
                "
                v-model="detailForm.transferStatus"
                :label="2"
                >打款失败</el-radio
              >
            </el-form-item>
          </div>
          <el-form-item
            label="备注："
            prop="remarks"
            :rules="[
              {
                required: true,
                message: '请输入备注信息',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model.trim="detailForm.remarks"
              :rows="6"
              :disabled="
                [1, 2].includes(detailForm.transferStatus) &&
                ['打款成功', '打款失败'].includes(
                  detailForm.withdrawProgressStatus
                )
              "
              style="width: 400px"
              type="textarea"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelRemark">取 消</el-button>
        <el-button type="primary" @click="handleChangeStatus">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="isShowTip"
      width="25%"
      :before-close="handleCloseTip"
    >
      <span>请检查是否已完成打款状态修改！</span>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="isShowTip = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="handleFinishedTip"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formThird: {
        unionId: null,
        transferStatus: null,
        page: {
          pageIndex: 1,
          pageSize: 20,
          totalElements: 0,
        },
      },
      getGameListData: [],
      approvalStatus: [
        {
          label: "全部",
          value: null,
        },
        {
          label: "打款中",
          value: 0,
        },
        {
          label: "打款成功",
          value: 1,
        },
        {
          label: "打款失败",
          value: 2,
        },
      ],
      tableData: [],
      transferConfig: [
        {
          label: "申请时间",
          prop: "applyTime",
        },
        {
          label: "公会名称",
          prop: "unionName",
        },
        {
          label: "申请人ID",
          prop: "applyAccountId",
        },
        {
          label: "申请人",
          prop: "applyAccountName",
        },
        {
          label: "提现单审批人",
          prop: "auditAccountName",
        },
        {
          label: "打款时间",
          prop: "transferTime",
        },
        {
          label: "打款人",
          prop: "transferAccountName",
        },
        {
          label: "提现额度",
          prop: "withdrawAmount",
        },
        {
          label: "打款状态",
          prop: "transferStatusDesc",
        },
      ],
      isShowPayDetail: false,
      payDetailTitle: "微信",
      detailForm: {},
      isShowTip: false,
    };
  },
  watch: {
    isShowPayDetail(v) {
      if (!v) {
        this.$refs.detailForm.clearValidate();
      }
    },
  },
  mounted() {
    this.getGameList();
    this.getList();
  },
  methods: {
    getList() {
      this.$api.billingApi
        .getWithdrawalApprovalList({ ...this.formThird })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            console.log("UI", res.data, res.data.content);
            this.tableData = result.map((item) => {
              return {
                ...item,
                transferStatusDesc: ["打款中", "打款成功", "打款失败"][
                  item.transferStatus
                ],
              };
            });
            this.formThird.page.totalElements = res.data.totalElements;
            this.formThird.page.pageIndex = res.data.pageIndex;
          }
        });
    },
    handleCurrentChange(val) {
      this.formThird.page.pageIndex = val;
      this.getList();
    },
    getGameList() {
      this.$api.organizationApi
        .getUnionList({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content.list : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    gotoViewDetail(item) {
      console.log("item", item);
      const { withdrawType, transferStatus } = item;

      this.detailForm = {
        ...item,
        transferStatus: [1, 2].includes(transferStatus) ? transferStatus : null,
      };
      this.payDetailTitle = ["微信", "支付宝", "银行卡"][withdrawType] || "";
      this.isShowPayDetail = true;
    },
    handleClose() {
      console.log("this.detailForm", this.detailForm);
      const { transferStatus, withdrawProgressStatus } = this.detailForm;
      if (
        [1, 2].includes(transferStatus) &&
        ["打款成功", "打款失败"].includes(withdrawProgressStatus)
      ) {
        this.isShowPayDetail = false;
      } else {
        this.isShowTip = true;
      }
    },
    cancelRemark() {
      // this.isShowTip = true;
      this.handleClose();
    },
    handleCloseTip() {
      this.isShowTip = false;
    },
    handleFinishedTip() {
      this.isShowTip = false;
      this.isShowPayDetail = false;
    },
    handleChangeStatus() {
      const { transferStatus, withdrawProgressStatus } = this.detailForm;
      if (
        [1, 2].includes(transferStatus) &&
        ["打款成功", "打款失败"].includes(withdrawProgressStatus)
      ) {
        this.isShowPayDetail = false;
      } else {
        this.$refs.detailForm.validate((v) => {
          if (v) {
            // this.isShowPayDetail = false;
            // TODO: 资产账号ID还没拿到
            const { remarks, transferStatus, id } = this.detailForm;
            const params = {
              assetAccountId: 57,
              assetWithdrawId: id,
              remark: remarks,
              transferStatus,
            };
            this.$api.billingApi.withdrawTransfer({ ...params }).then((res) => {
              console.log("res", res);
              if (res.code === 0) {
                this.$message.success("操作成功!");
                this.isShowPayDetail = false;
                this.getList();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.line_wrap {
  display: flex;
  justify-content: flex-start;

  & /deep/ .el-form-item {
    flex: 1;
    // margin-bottom: 10px;
  }
}
</style>
