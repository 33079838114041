<template>
  <div class="game-apply">
    <div class="ground-white">
      <div class="header_title">账户信息修改</div>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>修改密码</span>
          <el-button
            v-if="!isModify"
            style="float: right; padding: 3px 0"
            type="text"
            @click="canEditPassword"
            >编辑</el-button
          >
          <el-button
            v-else
            style="float: right; padding: 3px 0"
            type="text"
            @click="cancelEditPassword"
            >取消</el-button
          >
        </div>
        <el-form :inline="false" :model="passWordFixForm" style="width: 300px">
          <el-form-item label="账号" label-width="100px">
            <!-- <el-input
              v-model="accountName"
              placeholder="账号"
              disabled
            ></el-input> -->
            <div>{{ accountName }}</div>
          </el-form-item>
          <el-form-item label="密码" label-width="100px">
            <div v-if="!isModify">************</div>
            <el-input
              v-else
              v-model="passWordFixForm.accountPassword"
              placeholder="密码"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="isModify" style="text-align: center">
            <el-button type="primary" @click="updatePassword">保存</el-button>
          </el-form-item>
        </el-form>
      </el-card>
      <br />
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>修改账户信息</span>
          <el-button
            v-if="!isModifyAccount"
            style="float: right; padding: 3px 0"
            type="text"
            @click="canModifyAccount"
            >编辑</el-button
          >
          <el-button
            v-else
            style="float: right; padding: 3px 0"
            type="text"
            @click="cancelModifyAccount"
            >取消</el-button
          >
        </div>
        <el-form :inline="false" :model="formInline" style="width: 300px">
          <el-form-item label="账号" label-width="100px">
            <!-- <el-input
              v-model="formInline.accountName"
              placeholder="账号"
              disabled
            ></el-input> -->
            <div>{{ formInline.accountName }}</div>
          </el-form-item>
          <el-form-item label="昵称" label-width="100px">
            <el-input
              v-if="isModifyAccount"
              v-model="formInline.nickName"
              placeholder="昵称"
            ></el-input>
            <div v-else>{{ formInline.nickName || "无" }}</div>
          </el-form-item>
          <el-form-item label="手机号" label-width="100px">
            <el-input
              v-if="isModifyAccount"
              v-model="formInline.mobile"
              placeholder="手机号"
            ></el-input>
            <div v-else>{{ formInline.mobile || "无" }}</div>
          </el-form-item>
          <el-form-item v-if="isModifyAccount" style="text-align: center">
            <el-button type="primary" v-if="1" @click="updateInfo"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <div class="ground-white">
      <div class="header_title">操作记录</div>
      <div
        class="block"
        style="
          height: calc(100vh - 172px);
          overflow-y: auto;
          overflow-x: hidden;
        "
      >
        <el-timeline>
          <el-timeline-item
            v-for="(item, i) in logData"
            :timestamp="item.createTime"
            :key="i"
            placement="top"
          >
            <el-card>
              <h4>{{ item.accountName }}</h4>
              <p>{{ item.createTime }}-{{ item.opDesc }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formInline: {
        accountName: "",
        accountId: "",
        nickName: "",
        mobile: "",
        accountPassword: "",
      },
      passWordFixForm: {
        accountPassword: "",
      },
      accountName: "",
      isModify: false,
      isModifyAccount: false,
      logData: [],
    };
  },
  mounted() {
    this.getList();
    this.getAccountInfo();
  },
  methods: {
    getList() {
      // 获取公会列表
      this.$api.supportApi
        .queryOpLog({ pageIndex: 1, pageSize: 3000 })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.logData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getAccountInfo() {
      this.$api.supportApi.queryAccountInfo().then((res) => {
        console.log("res", res);
        if (res.code === 0) {
          const accountInfo = res.data ? res.data : {};
          this.formInline = accountInfo;
          this.accountName = accountInfo.accountName;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    updateInfo() {
      const { accountId, mobile, accountPassword, nickName } = this.formInline;
      this.$api.supportApi
        .updateAccountInfo({ accountId, mobile, accountPassword, nickName })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.getAccountInfo();
            this.isModifyAccount = false;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    updatePassword() {
      this.$api.supportApi
        .updatePassword({
          accountPassword: this.passWordFixForm.accountPassword,
        })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.isModify = false;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    canEditPassword() {
      this.isModify = true;
    },
    cancelEditPassword() {
      this.isModify = false;
    },
    canModifyAccount() {
      this.isModifyAccount = true;
    },
    cancelModifyAccount() {
      this.isModifyAccount = false;
    },
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  & > div:nth-of-type(1) {
    width: 50%;
  }
  & > div:nth-of-type(2) {
    width: 45%;
  }
}
.header_title {
  padding: 20px 0 10px 20px;
  font-size: 16px;
  font-weight: 500;
}
</style>
