import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./api";

import "core-js/stable";
import "regenerator-runtime/runtime";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/reset.css";

import moment from "moment";
moment.locale("zh-cn");

import exportExcel from "./utils/exportExcel";
import choosePlantorm from "./utils/choosePlantorm";
import dateUtil from "./utils/dateFormate";

import Treeselect from "@riophae/vue-treeselect";
import InviterSelect from "@/components/inviterSelect";
import GamePlatform from "@/components/uiComponents/GamePlatform";
import MemberShow from "@/components/uiComponents/MemberShow";
import roleMark from "@/components/uiComponents/roleMark";

import Mixin from "./mixins";

Vue.use(ElementUI);
Vue.component("Treeselect", Treeselect);
Vue.component("InviterSelect", InviterSelect);
Vue.component("GamePlatform", GamePlatform);
Vue.component("MemberShow", MemberShow);
Vue.component("RoleMark", roleMark);

Vue.prototype.$api = api;
Vue.prototype.$moment = moment;
Vue.prototype.$exportExcel = exportExcel;
Vue.prototype.$choosePlantorm = choosePlantorm;
Vue.prototype.$dateUtil = dateUtil;

Vue.config.productionTip = false;

Vue.mixin(Mixin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
