<template>
  <div class="game-apply">
    <div class="box">
      <el-card class="box-card flex1">
        <div class="flex">
          <el-input
            style="width: 80%"
            v-model="formInline.gameName"
            size="small"
            placeholder="游戏名称"
          ></el-input>

          <el-button
            type="primary"
            style="margin-left: 8px"
            size="small"
            @click="getList"
            >查询</el-button
          >
        </div>
        <div
          class="add"
          @click="
            resetForm('form');
            form.gameId = null;
            form.roleLevel = null;
            form.chargeAmount = null;
            form.specialConfigList = [];
            dialogFormVisible = true;
            editDialogShow = false;
          "
        >
          + 添加
        </div>
      </el-card>
      <el-card
        class="box-card carditem"
        v-for="(item, index) in listData"
        :key="item.id + index"
      >
        <p class="title">{{ item.gameName + "" }}</p>
        <!-- <p>结算时间：开服后48小时</p> -->
        <p>角色等级：{{ item.roleLevel }}</p>
        <p>充值金额：{{ item.chargeAmount }}</p>
        <div class="btn" @click="openEdit(item)">修改</div>
      </el-card>
    </div>
    <el-dialog
      :title1="editDialogShow ? '编辑' : '新建'"
      :visible.sync="dialogFormVisible"
      width="700px"
    >
      <!-- {{ form }} -->

      <el-form :model="form" status-icon ref="form" :inline="true">
        <el-form-item prop="gameId" required label="游戏">
          <el-select
            v-model="form.gameId"
            clearable
            placeholder="请选择游戏名称"
            :disabled="editDialogShow"
          >
            <el-option
              v-for="item in gameListData"
              :key="item.id"
              :label="item.gameName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <p>基础条件</p>
        <el-form-item prop="roleLevel" required label="角色等级">
          <el-input
            type="number"
            v-model.number="form.roleLevel"
            :min="1"
            placeholder="角色等级"
          ></el-input>
        </el-form-item>
        <el-form-item><div>或</div></el-form-item>
        <el-form-item prop="chargeAmount" required label="充值金额">
          <el-input
            type="number"
            v-model.number="form.chargeAmount"
            :min="0"
            placeholder="充值金额"
          ></el-input>
        </el-form-item>
        <p>
          特殊条件
          <el-button @click="addDomain" type="text"
            >&nbsp;&nbsp;+添加</el-button
          >
        </p>
        <div v-for="(item, index) in form.specialConfigList" :key="index">
          <el-form-item label="限制类型">
            <el-select
              v-model.number="item.type"
              clearable
              placeholder="请选择"
              @change="item.content = null"
            >
              <el-option :key="1" label="IP" :value="1"> </el-option>
              <el-option :key="2" label="区域" :value="2"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="限制内容">
            <el-input
              v-if="item.type === 1"
              type="textarea"
              placeholder="请输入内容"
              v-model="item.content"
              @change="changeIp"
            >
            </el-input>
            <el-cascader
              v-else
              size="large"
              clearable
              :show-all-levels="false"
              filterable
              :props="{ multiple: true }"
              collapse-tags
              :options="options"
              v-model="item.content"
              @change="handleChange"
            >
            </el-cascader></el-form-item
          ><span
            ><el-button
              @click="removeDomain(index)"
              type="text"
              style="color: red"
              >删除</el-button
            > </span
          ><br />
          <el-form-item required label="角色等级">
            <el-input
              type="number"
              v-model.number="item.roleLevel"
              :min="1"
              placeholder="角色等级"
            ></el-input>
          </el-form-item>
          <el-form-item><span>且</span></el-form-item>
          <el-form-item type="number" required label="充值金额">
            <el-input
              type="number"
              v-model.number="item.chargeAmount"
              :min="0"
              placeholder="充值金额"
            ></el-input>
          </el-form-item>
          <el-divider v-if="form.specialConfigList.length !== 1"></el-divider>
        </div>
      </el-form>
      <p style="font-size: 13px">
        注意：判断规则，特殊条件>基础条件，仅对保存后的角色生效。
      </p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="editDialogShow" type="primary" @click="updateUnion"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="addUnion">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
import { traditionalized } from "@/utils/util";
export default {
  data() {
    return {
      options: [],
      editDialogShow: false,
      editId: null,
      dialogFormVisible: false,
      gameListData: [],
      listData: [],
      form: {
        gameId: null,
        roleLevel: null,
        chargeAmount: null,
        specialConfigList: [],
      },
      formInline: {
        gameName: null,
      },
      ipWarning: false,
    };
  },
  watch: {
    dialogFormVisible(v) {
      if (!v) {
        this.getList();
      }
    },
  },
  created() {
    const ac = traditionalized("東區");
    console.log("轉化", ac);
    this.options = regionData.map((item) => {
      console.log("姑且", item);
      let newChildren = [];
      if (item.children && item.children.length) {
        newChildren = item.children.map((chl) => {
          let newLabel = "";
          if (
            item.label === "香港特别行政区" ||
            item.label === "澳门特别行政区"
          ) {
            newLabel = traditionalized(chl.label);
          } else {
            newLabel = chl.label;
          }
          return {
            label: newLabel,
            value: chl.value,
          };
        });
      }
      return {
        ...item,
        children: newChildren,
      };
    });
    console.log("this.options", this.options);
  },
  mounted() {
    this.getList();
    this.getGameList();
    console.log("regionData", this.options);
  },
  methods: {
    resetForm(formName) {
      console.log(this.$refs[formName]);
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
    openEdit(data) {
      console.log(data);
      // 回显数据
      let showData = { ...data };
      if (data && data.specialConfigList && data.specialConfigList.length) {
        const specialConfigList = data.specialConfigList.map((list) => {
          let showContent = [];
          if (list.content) {
            if (list.type === 1) {
              showContent =
                typeof list.content === "object"
                  ? list.content.join("\n")
                  : list.content;
            } else {
              showContent = list.content.map((it) => {
                if (typeof it === "string") {
                  const [p, c] = it.split("|");
                  return [
                    TextToCode[p].code,
                    TextToCode[p][c].code,
                    // TextToCode[p][c][d].code,
                  ];
                } else {
                  return it;
                }
              });
            }
          }
          return {
            ...list,
            content: showContent,
          };
        });
        data.specialConfigList = specialConfigList;
        showData = { ...data };
      }

      this.editDialogShow = true;
      this.dialogFormVisible = true;
      this.form.gameId = showData.gameId;
      this.form.roleLevel = showData.roleLevel;
      this.form.chargeAmount = showData.chargeAmount;
      this.form.specialConfigList = showData.specialConfigList
        ? showData.specialConfigList
        : [];

      this.editId = showData.id;
    },
    getList() {
      // 获取公会列表
      this.$api.organizationApi.queryUsefulRole(this.formInline).then((res) => {
        console.log("queryUsefulRole", res);
        if (res.code === 0) {
          const result = res.data ? res.data : [];
          this.listData = result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleChange(value) {
      console.log(value, CodeToText);
    },
    removeDomain(index) {
      if (index !== -1) {
        this.form.specialConfigList.splice(index, 1);
      }
    },
    addDomain() {
      // this.editId = null;
      this.form.specialConfigList.push({
        type: 1,
        content: null,
        roleLevel: null,
        chargeAmount: null,
      });
    },
    updateUnion() {
      const { requestForm, isIPGoOn, isRegionGoOn } = this.renderParams();
      if (this.ipWarning) {
        this.$message.warning("IP地址格式有误, 请仔细检查！");
        return;
      }
      if (isIPGoOn && isRegionGoOn) {
        this.$api.organizationApi
          .updateUsefulRole({
            ...this.form,
            specialConfigList: requestForm,
            id: this.editId,
          })
          .then((res) => {
            console.log("addUsefulRole", res);
            if (res.code === 0) {
              this.dialogFormVisible = false;
              this.$message.success("操作成功!");
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
            this.getList();
          });
      } else {
        if (!isIPGoOn) {
          this.$message.warning("请检查所输入的IP地址，不能有重复IP地址！");
          return;
        }
        if (!isRegionGoOn) {
          this.$message.warning("请检查所选城市，不能有重复选项！");
          return;
        }
      }
    },
    addUnion() {
      const { requestForm, isIPGoOn, isRegionGoOn } = this.renderParams();
      if (this.ipWarning) {
        this.$message.warning("IP地址格式有误, 请仔细检查！");
        return;
      }
      if (isIPGoOn && isRegionGoOn) {
        this.$api.organizationApi
          .addUsefulRole({ ...this.form, specialConfigList: requestForm })
          .then((res) => {
            console.log("addUsefulRole", res);
            if (res.code === 0) {
              this.dialogFormVisible = false;
              this.$message.success("操作成功!");
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
            this.getList();
          });
      } else {
        if (!isIPGoOn) {
          this.$message.warning("请检查所输入的IP地址，不能有重复选项！");
          return;
        }
        if (!isRegionGoOn) {
          this.$message.warning("请检查所选城市，不能有重复选项！");
          return;
        }
      }
    },
    renderParams(type) {
      let isRegionGoOn = true;
      let isIPGoOn = true;
      const checkingRegionForm = this.form.specialConfigList.map((item) => {
        if (item.type !== 1) {
          let checkingContent = [];
          checkingContent = item.content.map((con) => {
            const [p, c] = con;
            console.log("查看", p, c);
            const address = `${p}-${c}`;
            if (address && address !== "undefined") {
              return address;
            }
          });
          console.log("呼啦", checkingContent);
          return [checkingContent];
        }
      });
      const checkingRegionContent = checkingRegionForm
        .flat(Infinity)
        .filter((item) => Boolean(item));
      const myRegionContent = new Set(
        checkingRegionContent.filter((item) => Boolean(item))
      );

      if (checkingRegionContent.length > myRegionContent.size) {
        isRegionGoOn = false;
      }

      const checkingIPForm = this.form.specialConfigList.map((item) => {
        if (item.type === 1) {
          let checkingContent = [];
          checkingContent = item.content.split("\n");
          return [checkingContent];
        }
      });

      const checkingIPContent = checkingIPForm
        .flat(Infinity)
        .filter((item) => Boolean(item));
      const myIPContent = new Set(
        checkingIPContent.filter((item) => Boolean(item))
      );

      if (checkingIPContent.length > myIPContent.size) {
        isIPGoOn = false;
      }

      console.log("haha ", checkingIPContent, myIPContent.size);

      let requestForm = this.form.specialConfigList.map((item) => {
        let content = [];
        if (item.content && item.type === 1) {
          content = item.content.split("\n");
        }
        if (item.content && item.type !== 1) {
          content = item.content.map((item) => {
            const [p, c, d] = item;
            return [CodeToText[p], CodeToText[c], CodeToText[d]].join("|");
          });
        }

        return {
          ...item,
          content,
        };
      });

      if (type && type === "edit") {
        requestForm = requestForm.map((form) => {
          return {
            ...form,
            id: this.editId,
          };
        });
      }

      return { requestForm, isIPGoOn, isRegionGoOn };
    },
    onSubmit() {
      console.log("submit!");
    },
    getGameList() {
      this.$api.gameApi
        .queryGameList({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.gameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeIp(v) {
      const reg =
        /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
      if (v) {
        const ips = v.split("\n");
        if (ips.some((ip) => !reg.test(ip))) {
          this.ipWarning = true;
          this.$message.warning("IP地址格式有误, 请仔细检查！");
        } else {
          this.ipWarning = false;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  .box {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    .flex1 {
      display: flex;
      flex-direction: column;
      .add {
        cursor: pointer;
        text-align: center;
        height: 100px;
        border: 1px #666 dashed;
        margin-top: 10px;
        font-size: 16px;
        line-height: 100px;
      }
    }

    .carditem {
      /deep/ .el-card__body {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        box-sizing: border-box;
      }
    }
    .box-card {
      p {
        font-size: 13px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        color: #666666;
      }
      .title {
        font-size: 16px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }

      .btn {
        width: 100%;
        height: 35px;
        line-height: 35px;
        text-align: center;
        color: #255ec0;
        font-size: 15px;
        background: #eff5ff;
        border-radius: 4px;
        cursor: pointer;
      }
      width: calc(25% - 12px);
      height: 180px;
      margin-right: 10px;
      margin-bottom: 12px;
      & > div {
        height: 100%;
      }
    }
  }
}
</style>
