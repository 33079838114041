<template>
  <div class="game-apply ground-white">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="角色管理" name="first">
        <el-form inline ref="staffForm" v-model="staffForm">
          <el-form-item label="昵称">
            <el-input
              placeholder="请输入"
              v-model="staffForm.nickName"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select
              v-model="staffForm.status"
              clearable
              filterable
              placeholder="请选择状态值"
              @change="getToUnion"
            >
              <el-option label="启用状态" :value="0" />
              <el-option label="禁用状态" :value="1" />
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" @click="getMemberList">查询</el-button>
            <el-button type="primary" @click="showAddMemberPop(1)"
              >新增人员</el-button
            >
          </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="nickName" label="昵称" />
          <el-table-column label="角色" prop="roleName" />
          <el-table-column prop="accountName" label="账号" />
          <el-table-column prop="mobile" label="手机号码" />
          <el-table-column prop="status" label="状态">
            <template slot-scope="scope">
              <span>{{ scope.row.status === 0 ? "启用" : "禁用" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作" width="220">
            <template slot-scope="scope">
              <el-button type="text" @click="showAddMemberPop(2, scope.row)"
                >编辑人员</el-button
              >
              <el-button type="text" @click="setStatus(scope.row)"
                >设置状态</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="float: right; margin-top: 20px"
          layout="prev, pager, next, jumper"
          :page-size="20"
          :current-page.sync="staffForm.pageIndex"
          @current-change="handleCurrentChange"
          :total="totalElements"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="资源账号设定" name="second">
        <el-form
          :inline="false"
          ref="resourceForm"
          :model="resourceForm"
          label-width="190px"
        >
          <el-form-item label="游戏扶持账号冻结时间" prop="days">
            <el-input
              v-model.number="resourceForm.content"
              placeholder="请输入冻结天数"
              clearable
              style="width: 240px"
            >
              <template slot="append">天</template></el-input
            >
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" @click="handleFrozenAccount"
              >提交</el-button
            >
            <!-- <el-button>取消</el-button> -->
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :title="popTitle"
      :visible.sync="isShowRolePop"
      width="800px"
      :before-close="handleCloseRole"
    >
      <el-form :inline="true" ref="rolesForm" :model="rolesForm">
        <el-form-item label="账号：" prop="accountName">
          <el-form-item>
            <el-input
              v-model="rolesForm.accountName"
              autocomplete="off"
              :disabled="
                Boolean(rolesForm.accountName) && popTitle === '编辑人员'
              "
            ></el-input>
          </el-form-item>
        </el-form-item>
        <el-form-item
          v-if="popTitle !== '编辑人员'"
          label="密码："
          prop="accountPassword"
        >
          <el-input
            v-model="rolesForm.accountPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="昵称：" prop="nickName">
          <el-input
            v-model="rolesForm.nickName"
            clearable
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码：" prop="mobile">
          <el-input
            v-model="rolesForm.mobile"
            maxlength="11"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色设置：" prop="roleType">
          <el-radio v-model="rolesForm.roleType" :label="4">管理员</el-radio>
          <el-radio v-model="rolesForm.roleType" :label="5">客服</el-radio>
        </el-form-item>
        <br />
        <el-form-item label="权限设置：" prop="resourceIdList">
          <el-checkbox-group v-model="rolesForm.resourceIdList">
            <el-checkbox
              v-for="item in permissions"
              :label="item.value"
              :key="item.value"
              >{{ item.label }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="isShowRolePop = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="handleMember"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      activeName: "first",
      staffForm: {
        userName: "",
        status: null,
        unionId: null,
        nickName: "",
        departmentId: "",
        roleId: "",
        pageIndex: 1,
        pageSize: 20,
      },
      totalElements: 0,
      resourceForm: {
        content: null,
      },
      isShowRolePop: false,
      rolesForm: {
        resourceIdList: [],
        accountName: "",
        accountPassword: "",
        nickName: "",
        mobile: "",
        roleType: null,
      },
      permissions: [
        {
          label: "账号管理",
          value: 218,
        },
        {
          label: "资源账号",
          value: 220,
        },
        {
          label: "人员管理",
          value: 221,
        },
        {
          label: "转移记录",
          value: 219,
        },
        {
          label: "资源管理",
          value: 222,
        },
        {
          label: "异常订单",
          value: 223,
        },
        {
          label: "个人记录",
          value: 224,
        },
      ],
      popTitle: "新增人员",
    };
  },
  watch: {
    isShowRolePop(v) {
      if (!v) {
        this.rolesForm = {
          resourceIdList: [],
          accountName: "",
          accountPassword: "",
          nickName: "",
          mobile: "",
          roleType: null,
        };
      }
    },
  },
  mounted() {
    this.getMemberList();
    this.queryFrozenAccount();
  },
  methods: {
    getMemberList() {
      this.$api.organizationApi
        .queryStaff({ ...this.staffForm })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data?.content ? res.data?.content : [];
            this.tableData = result;
            this.totalElements = res.data?.totalElements;
          }
        });
    },
    handleCurrentChange(val) {
      this.getMemberList();
      this.staffForm.pageIndex = val;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getToUnion() {
      console.log("12", 12);
    },
    handleCloseRole() {
      console.log("2", 2);
      this.isShowRolePop = false;
    },
    showAddMemberPop(type, item) {
      this.isShowRolePop = true;
      const ADD = 1,
        EDIT = 2;
      if (type === ADD) {
        this.popTitle = "新增人员";
        this.rolesForm.resourceIdList = [224];
      } else if (type === EDIT) {
        this.popTitle = "编辑人员";
        const { resourceIdList } = item;

        this.rolesForm = { ...item, resourceIdList: resourceIdList || [] };
      }
    },
    handleMember() {
      const method =
        this.popTitle === "新增人员" ? "createMember" : "editMember";
      this.$api.supportApi[method](this.rolesForm).then((res) => {
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.isShowRolePop = false;
          this.getMemberList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    setStatus(item) {
      const { memberId, status } = item;
      let newStatus = null;
      if (status === 0) {
        newStatus = 1;
      } else {
        newStatus = 0;
      }
      this.$confirm("确定要更改状态吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        this.$api.supportApi
          .setMemberStatus({ memberId, status: newStatus })
          .then((res) => {
            if (res.code === 0) {
              this.$message.success("操作成功!");
              this.isShowRolePop = false;
              this.getMemberList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
    queryFrozenAccount() {
      this.$api.supportApi
        .queryAccountConfig({ type: "gameRoleForzen" })
        .then((res) => {
          if (res.code === 0) {
            const content = res.data?.content ? res.data?.content : {};
            this.resourceForm.content = content;
          }
        });
    },
    handleFrozenAccount() {
      const { content } = this.resourceForm;
      const reg = /^[1-9]\d*$/;
      if (!reg.test(content) || content <= 0) {
        this.$message.warning("只能输入正整数！");
        return;
      }
      this.$api.supportApi
        .updateAccountConfig({
          type: "gameRoleForzen",
          content,
        })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success("操作成功！");
            this.queryFrozenAccount();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.game-apply {
}
</style>
