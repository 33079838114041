import choosePlantorm from "../utils/choosePlantorm";

let base = {
  // sq: "http://192.168.1.112:18080/admin/v1",
  // sq: "http://192.168.1.94:18080/union/v1",
  // sq: "http://192.168.1.159:18080/union/v1",

  // 测试环境后管端
  // sq: "https://testadmin.zyyf.vip/admin/v1",
  // 测试环境公会端
  // sq: "https://testapi.zhongyiyf.com/union/v1",
  // 测试环境客服端
  // sq: "https://testadmin.zyyf.vip/support/v1",

  dev: "",
  test: "",
  prod: "",
};
// 设置不同端的接口前缀
base.sq = choosePlantorm().requestUrl;
base.cq = choosePlantorm().tocRequestUrl;

if (
  window.location.href.indexOf("/test") !== -1 ||
  window.location.href.indexOf("localhost") !== -1 ||
  window.location.href.indexOf("192.168.1.111") !== -1
) {
  if (sessionStorage.getItem("localip")) {
    base.sq = JSON.parse(sessionStorage.getItem("localip"));
  }
}

export default base;
