<template>
  <div class="game-apply">
    <!-- top -->
    <div class="top-container ground-white flex">
      <div class="title">今日开服</div>
      <div class="box">
        <div class="bar" ref="bar" v-if="areaList.length">
          <div v-for="item in areaList" class="item" :key="item.id">
            <span>{{
              $moment(item.serviceTime).format("YYYY-MM-DD HH:mm").split(" ")[1]
            }}</span>
            <span style="margin: 0 6px">{{ item.gameName }}</span>
            <span>{{ item.serviceName }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- bottom -->
    <div class="bottom-container ground-white">
      <el-radio-group
        style="margin-bottom: 10px"
        size="small"
        v-model="formInline.status"
        @input="changeList"
      >
        <el-radio-button :label="0">正常推广</el-radio-button>
        <el-radio-button :label="1">游戏下架</el-radio-button>
      </el-radio-group>
      <el-form :inline="true" :model="formInline">
        <el-form-item label="区服类型">
          <el-input
            v-model="formInline.gameArea"
            placeholder="区服类型"
          ></el-input>
        </el-form-item>
        <el-form-item label="游戏名">
          <el-input
            v-model="formInline.gameName"
            placeholder="游戏名"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="游戏状态">
          
        </el-form-item> -->
        <el-form-item>
          <el-button
            type="primary"
            @click="
              formInline.pageIndex = 1;
              getList();
            "
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <div class="list">
        <!-- {{ formInline }} -->
        <div
          v-if="tableData.length === 0"
          style="text-align: center; font-size: 16px; margin-top: 20px"
        >
          暂无数据~
        </div>
        <section
          :class="{ item: 1, flex: 1, itemactive: activeItem === index }"
          v-for="(item, index) in tableData"
          :key="index"
          @mouseenter="
            creatQrCode(item);
            activeItem = index;
          "
          @mouseleave="activeItem = false"
        >
          <img :src="item.gamePic" class="icon" alt="游戏图标" />
          <div class="game-name">
            <p class="game-title activeTitle" @click="goDetail(item.id)">
              <span>{{ item.gameName }}</span>
              <span class="detail"
                ><el-button type="text">详情</el-button></span
              >
            </p>
          </div>
          <div style="width: 8%">
            <img
              src="@/assets/img/icon-s.png"
              style="width: 20px; height: 20px; vertical-align: text-top"
            />
          </div>
          <div style="width: 20%; padding-right: 1%">
            <p style="color: #999">
              <span style="color: #666">{{ item.gameType }}</span>
            </p>
          </div>
          <div style="width: 6%; padding-right: 1%">
            <p style="color: #999">
              <span style="color: #666" class="elp">{{ item.gameArea }}</span>
            </p>
          </div>
          <div v-if="formInline.status === 0" style="width: 12%">
            <p>
              <img
                src="@/assets/img/icon-apple.png"
                style="width: 23px; height: 23px; vertical-align: bottom"
              />
              {{ "-" }}
            </p>
          </div>
          <div style="width: 12%" v-if="formInline.status === 0">
            <p style="display: flex; justify-content: flex-start">
              <img
                src="@/assets/img/icon-android.png"
                style="width: 23px; height: 23px; vertical-align: bottom"
              />
              <span v-if="item.packStatus === PACKAGED"
                ><a style="color: #2d8cf0 !important" :href="item.packageUrl"
                  >下载游戏</a
                ></span
              >
              <span
                style="color: #f70"
                v-if="!item.packStatus && item.packStatus !== PACKAGE_ING"
              >
                未分包</span
              >
              <span style="color: #f70" v-if="item.packStatus === PACKAGE_ING">
                分包中</span
              >
              <span style="color: red" v-if="item.packStatus === PACKAGE_FAIL">
                分包失败，请重新分包</span
              >
            </p>
          </div>
          <div class="downbtn" v-if="formInline.status === 0">
            <div
              class="downdiv"
              v-if="item.packStatus === PACKAGED"
              size="small"
            >
              推广游戏
              <div class="modal">
                <img :src="QRImgUrl" alt="" />
                <div>
                  <el-button
                    size="mini"
                    class="tag-read"
                    style="background: rgba(187, 200, 220, 0.3)"
                    :data-clipboard-text="getShareUrl(item)"
                    @click="copy"
                    >复制推广链接</el-button
                  >
                  <br />
                  <el-button
                    size="mini"
                    style="background: rgba(187, 200, 220, 0.3)"
                    @click="downloadIamge(item.gameName)"
                    >保存二维码</el-button
                  >
                </div>
                <b>当游戏为下架或其他异常状态时</b>
                <b>用户无法下载该游戏</b>
              </div>
            </div>
            <!-- <div
              class="downdiv1"
              v-else
              v-loading="item.packStatus === 0 || item.loading"
              element-loading-spinner="el-icon-loading"
              @click="subContract(item.id, index)"
            >
              {{ item.packStatus === 0 ? "分包中" : "分包" }}
            </div> -->
            <el-button
              class="downdiv1"
              v-else
              v-loading="item.packStatus === 0 || item.loading"
              element-loading-spinner="el-icon-loading"
              @click="subContract(item.id, index)"
              plain
              :disabled="item.packStatus === 0 || item.loading"
              >{{
                item.packStatus === 0 || item.loading ? "分包中" : "分包"
              }}</el-button
            >
          </div>
        </section>
      </div>
      <p style="width: 100; color: #999; text-align: right">
        注意：当未申请的游戏产生充值时，将以游戏的基础分成比例进行分成
      </p>
      <el-pagination
        style="float: right; margin-top: 8px"
        layout="prev, pager, next, jumper"
        :page-size="20"
        :current-page.sync="formInline.pageIndex"
        @current-change="handleCurrentChange"
        :total="formInline.totalElements"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcode";
import Clipboard from "clipboard";

export default {
  name: "loginPage",
  data() {
    return {
      areaList: [],
      QRImgUrl: "",
      QRlink: "www.xxx.com",
      activeItem: false,
      imageUrl: "",
      dialogFormVisible: false,
      activeName: "first",
      uploadForm: {},
      formInline: {
        gameName: "",
        gameArea: null,
        status: 0,
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      permissionForm: {},
      tableData: [],
      rules: {
        accountName: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        accountPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
      },
      PACKAGE_ING: 0,
      PACKAGED: 1,
      PACKAGE_FAIL: 2,
      timer: null,
    };
  },
  watch: {
    tableData: {
      handler(v) {
        console.log("监听分包", v);

        v.forEach((ele) => {
          const now = this.$moment();
          const isFiveMin = now.diff(this.$moment(ele.timePoint), "minute") > 5;
          if (
            [this.PACKAGED, this.PACKAGE_FAIL].includes(ele.packStatus) ||
            isFiveMin
          ) {
            window.clearInterval(ele.timer);
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();

    this.getAreaList();
  },
  methods: {
    getShareUrl(data) {
      return `${window.location.href.split("#")[0]}#/share/gsws?gameId=${
        data.id
      }&inviterAdminAccountId=${
        JSON.parse(localStorage.getItem("userInfo")).accountId
      }`;
    },
    getAreaList() {
      // 获取公会列表
      this.$api.organizationApi
        .queryUnionAreaPlan({
          gameId: null,
          startTime: this.$moment().format("YYYY-MM-DD"),
          endTime: this.$moment().format("YYYY-MM-DD"),
          pageIndex: 1,
          pageSize: 10000,
        })
        .then((res) => {
          console.log("getAreaList", res);
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : {};
            this.areaList = result;
            let times = Math.ceil(this.areaList.length / 4);
            console.log("@@@", times);
            if (times > 1) {
              this.ani(times);
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    ani(data) {
      data -= 1;
      setTimeout(() => {
        const a =
          data < 0 ? 0 : (Math.ceil(this.areaList.length / 4) - data) * 42;
        this.$refs.bar.style = ` transition: 2s; transform:translateY(-${a}px);`;
      }, 2000);
      setTimeout(() => {
        if (data === 1) {
          this.$refs.bar.style = `transtion:0;transform:translateY(0px);`;
          this.ani(Math.ceil(this.areaList.length / 4));
        } else {
          this.ani(data);
        }
      }, 6000);
    },
    changeList(e) {
      this.formInline.status = e;
      this.formInline.pageIndex = 1;
      this.getList();
    },
    downloadIamge(name) {
      var image = new Image();
      //跨域会导致a标签在部分浏览器中会直接打开新标签页 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;

        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png");

        // 生成一个a元素
        var a = document.createElement("a");
        // 创建一个单击事件
        var event = new MouseEvent("click");

        // 将a的download属性设置为我们想要下载的图片名称，若name不存在则使用‘下载图片名称’作为默认名称
        a.download = name || "下载图片名称";
        // 将生成的URL设置为a.href属性
        a.href = url;

        // 触发a的单击事件
        a.dispatchEvent(event);
      };

      image.src = this.QRImgUrl;
    },
    copy() {
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        console.log("复制成功", e);
        this.$message.success("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        console.log("该浏览器不支持自动复制", e);
        this.$message.error("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    creatQrCode(data) {
      // this.$api.gameApi
      //   .getShareUrl({
      //     gameId: data,
      //   })
      //   .then((res) => {
      //     console.log("getShareUrl", res);
      //     if (res.code === 0) {
      //       console.log(res);
      //     } else {
      //       this.$message.error(res.msg);
      //     }
      //   });
      data = this.getShareUrl(data);
      // console.log(data);
      const that = this;
      QRCode.toDataURL(data, function (err, url) {
        // console.log(url);
        that.QRImgUrl = url;
      });
    },
    getList() {
      this.$api.gameApi
        .queryGameListMap({
          ...this.formInline,
        })
        .then((res) => {
          console.log("querySubPackage", res);
          if (res.code === 0) {
            console.log();
            const result = res.data.content ? res.data.content : [];
            this.tableData = result.map((item) => {
              return {
                ...item,
                loading: false,
                timer: null,
                timePoint: null,
                count: 0,
              };
            });
            this.formInline.totalElements = res.data.totalElements;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    goDetail(id) {
      this.$router.push({
        path: "/game/detail",
        query: {
          id: id,
        },
      });
    },
    handleCurrentChange(page) {
      this.formInline.pageIndex = page;
      this.getList();
    },
    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.login();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    subContract(gameId, i) {
      const currentGame = this.tableData[i];

      currentGame.timePoint = this.$moment();
      currentGame.loading = true; // 开启loading，展示分包中状态
      currentGame.count = 0;
      currentGame.packStatus = this.PACKAGE_ING; // 点击分包后返回成功，状态没变之前初始化为分包中

      // 清除当前game的定时器
      if (currentGame.timer) {
        window.clearInterval(currentGame.timer);
        currentGame.timer = null;
      }

      console.log("现在的game", currentGame);

      this.$api.gameApi.unionGamePack({ gameId }).then((res) => {
        if (res.code === 0) {
          this.checkPackStatus(gameId, i); // 第一次获取分包状态
          // 启动该分包活动的定时器
          currentGame.timer = window.setInterval(() => {
            this.checkPackStatus(gameId, i);
          }, 5000);
          console.log("变动的game", currentGame);
        } else {
          this.$message.error(res.msg);
          currentGame.loading = false;
        }
      });
    },
    checkPackStatus(gameId, i) {
      const currentGame = this.tableData[i];
      this.$api.gameApi.queryPackStatus({ gameId }).then((res) => {
        if (res.code === 0) {
          const result = res.data || {};
          currentGame.count += 1;
          if ([this.PACKAGED, this.PACKAGE_FAIL].includes(result.packStatus)) {
            // 返回的分包状态成功或者失败，变成分包按钮
            currentGame.loading = false;
            window.clearInterval(currentGame.timer);
            this.getList();
          } else if (result.packStatus === this.PACKAGE_ING) {
            // 如果返回的分包状态是分包中，保持loading状态
            currentGame.loading = true;
          }
        } else {
          this.$message.error(res.msg);
          currentGame.loading = false; // 失败，显示分包按钮
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  .elp {
    color: #666;
    width: 100%;
    color: rgb(102, 102, 102);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  .top-container {
    margin-bottom: 8px;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #255ec0;
      margin-left: 24px;
      margin-right: 40px;
    }
    .box {
      flex: 1;
      overflow: hidden;
      height: 42px;
      line-height: 42px;
      .bar {
        white-space: nowrap;
        .item {
          // flex: 1;
          width: 25%;
          height: 100%;
          float: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: center;
          font-size: 15px;
        }
      }
    }
  }
  .bottom-container {
    .list {
      margin-bottom: 60px;
      .itemactive {
        .activeTitle {
          font-weight: bold;
          font-size: 15px !important;
        }
        .detail {
          visibility: visible !important;
        }
      }
      .item {
        .game-name {
          width: 15%;
          line-height: 18px;

          .game-title {
            font-size: 14px;
            text-align: center;
            cursor: pointer;

            /deep/ .el-button {
              padding: 0;
            }
          }
        }
        .downbtn {
          position: relative;
          display: flex;
          justify-content: center;
          // flex-direction: column;
          .fail_tip {
            color: red;
            position: absolute;
            top: -20px;
            font-size: 12px;
          }
          .downdiv1 {
            position: relative;
            width: 80px;
            height: 32px;
            border: 1px #999 solid;
            border-radius: 4px;
            line-height: 9px;
            background: #fff;
            color: #333;
            cursor: pointer;

            /deep/ .el-loading-spinner {
              top: 9px;
              margin-top: 0;
              left: 4px;
              width: 0px;
            }

            /deep/ .el-loading-mask {
              background-color: rgba(255, 255, 255, 0);
            }
          }
          .downdiv {
            position: relative;
            width: 80px;
            height: 32px;
            border: 1px #255ec0 solid;
            border-radius: 4px;
            line-height: 32px;
            background: #255ec0;
            color: white;
            &:hover {
              .modal {
                visibility: visible !important;
              }
            }
          }
          flex: 1;
          text-align: center;

          .modal {
            z-index: 3;
            visibility: hidden;
            width: 429px;
            position: absolute;
            background: #fff url("@/assets/img/subcontract_bg.jpg") no-repeat
              top right;
            background-size: cover;
            box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
            height: 252px;
            left: -420px;
            bottom: -100px;
            display: flex;
            flex-direction: column;
            // align-items: center;
            // justify-content: space-between;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            img {
              width: 110px;
              height: 110px;
              margin: 44px 0 0 106px;
            }
            & > div {
              text-align: left;
              margin-left: 106px;
              margin-top: 6px;
            }
            b {
              &:nth-of-type(1) {
                margin-top: -213px;
                margin-left: 36px;
                color: #34436b;
                line-height: 1.3;
                text-align: left;
                padding-left: 4px;
                font-size: 12px;
                word-break: break-all;
                width: 14px;
              }
              &:nth-of-type(2) {
                margin-top: -179px;
                margin-left: 57px;
                color: #34436b;
                line-height: 1.3;
                text-align: left;
                padding-left: 4px;
                font-size: 12px;
                word-break: break-all;
                width: 14px;
              }
            }
          }
        }
        .detail {
          visibility: hidden;
          margin-left: 3px;
        }
        &:hover {
          background: #f5f7fa;
        }
        width: 100%;
        height: 71px;
        border-top: 1px #eee solid;
        align-items: center;
        &:last-child {
          border-bottom: 1px #eee solid;
        }
        .icon {
          width: 40px;
          height: 40px;
          margin: 0 8px 0 12px;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
