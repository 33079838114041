<template>
  <div class="unionList ground-white">
    <!-- {{ formInline }} -->
    <el-page-header @back="$router.back()" style="margin-bottom: 20px">
    </el-page-header>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column align="center" prop="gameAccountId" label="游戏账号ID">
      </el-table-column>
      <el-table-column
        align="center"
        prop="inviterAdminAccountName"
        label="游戏信息"
      >
        <template slot-scope="scope"
          >{{ scope.row.gameName }}/{{ scope.row.gameServerName }}</template
        >
      </el-table-column>
      <el-table-column align="center" prop="gameRoleName" label="角色">
      </el-table-column>
      <el-table-column align="center" prop="roleStatus" label="账号状态">
        <template slot-scope="scope">
          <el-tag
            :key="scope.row.roleStatus"
            :type="scope.row.roleStatus === 0 ? 'success' : 'danger'"
          >
            {{ scope.row.roleStatus === 0 ? "正常" : "冻结" }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="headAdminAccountName" label="团长">
      </el-table-column>
      <el-table-column
        align="center"
        prop="inviterAdminAccountName"
        label="推广员"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="applyAdminAccountName"
        label="首次申请人"
      >
      </el-table-column>
      <el-table-column align="center" prop="applyAdminAccountId" label="申请ID">
      </el-table-column>
      <el-table-column align="center" prop="applyTime" label="申请时间">
      </el-table-column>
      <el-table-column align="center" prop="status" label="申请状态">
        <template slot-scope="scope">{{
          statusList.find((item) => item.id === scope.row.status).name
        }}</template>
      </el-table-column>

      <el-table-column align="center" prop="rechargeAmount" label="充值金额">
      </el-table-column>
      <!-- <el-table-column
        align="center"
        prop="sumRechargeAmount"
        label="角色充值金额"
      >
      </el-table-column> -->
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.page.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.page.totalElements"
    >
    </el-pagination>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      statusList: [
        { id: 0, name: "待发放" },
        { id: 1, name: "发放成功" },
        { id: 2, name: "发放失败" },
        { id: 3, name: "待付款" },
        { id: 4, name: "待审核" },
        { id: 5, name: "取消申请" },
        { id: 6, name: "驳回" },
      ],
      value1: "",
      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      getGameListData: [],
      dialogFormVisible: false,
      editDialogShow: false,
      editUnionId: "",
      formInline: {
        unionId: +this.$route.query.unionId,
        gameRoleId: +this.$route.query.gameRoleId,
        gameAccoundId: +this.$route.query.gameAccoundId,
        page: {
          pageIndex: 1,
          pageSize: 20,
          totalElements: 0,
        },
        time: ["", ""],
      },
      queryForm: {
        serviceTime: "",
        gameId: null,
        capacity: 1,
        serviceName: "",
      },
      tableData: [],
    };
  },
  mounted() {
    // this.getPermission();
    this.getList();
  },
  methods: {
    getList() {
      // 获取公会列表
      this.$api.billingApi
        .virRechargeQueryDetail(this.formInline)
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.page.totalElements = res.data.totalElements;
            this.formInline.page.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.gameApi
        .queryPackage({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    resetUnionPassword() {
      this.$api.organizationApi
        .resetPassword({
          unionId: this.editUnionId,
        })
        .then((res) => {
          console.log("resetPassword", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    deleteUnion(data) {
      this.$api.organizationApi
        .deleteUnion({
          unionId: data.id,
          isDelete: 1,
        })
        .then((res) => {
          console.log("deleteUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    changeUnionStatus(data) {
      this.$api.organizationApi
        .changeUnionStatus({
          unionId: data.id,
          status: data.status === 0 ? 1 : 0,
        })
        .then((res) => {
          console.log("changeUnionStatus", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    updateUnion() {
      this.$api.organizationApi
        .updateAreaPlan({ ...this.queryForm, id: this.editUnionId })
        .then((res) => {
          console.log("updateUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.dialogFormVisible = false;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    addUnion() {
      this.$api.organizationApi.createAreaPlan(this.queryForm).then((res) => {
        console.log("createUnion", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.dialogFormVisible = false;
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleCurrentChange(val) {
      this.formInline.page.pageIndex = val;
      this.getList();
    },
    onSubmit() {
      console.log("submit!");
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/organization/bm/staff/list",
        query: {
          unionId: e.id,
        },
      });
    },
    resetPassWord(pId) {
      this.$confirm("重置密码:默认密码123456", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 重置密码
        this.editUnionId = pId.id;
        this.resetUnionPassword();
      });
    },
    changeStatus(pId) {
      this.$confirm("确定要更改状态吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 改变状态
        this.changeUnionStatus(pId);
      });
    },
    deleteItem(pId) {
      this.$confirm("确定要删除数据吗？", "", {
        type: "error",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 删除
        this.deleteUnion(pId);
      });
    },
    openEditDialog(data) {
      this.dialogFormVisible = true;
      this.editDialogShow = true;
      this.editUnionId = data.id;
      setTimeout(() => {
        this.queryForm.serviceTime = this.$moment(data.serviceTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.queryForm.gameId = data.gameId;
        this.queryForm.capacity = data.capacity;
        this.queryForm.serviceName = data.serviceName;
      }, 1);
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .mr15 {
    margin-right: 15px;
  }
}
</style>
