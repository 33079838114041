<template>
  <div class="unionList ground-white">
    <el-page-header
      @back="$router.back()"
      content=""
      style="margin-bottom: 15px"
    >
    </el-page-header>
    <ul class="box">
      <li>
        <p>入账</p>
        <b>{{ totalData.inAmount }}<span>元</span></b>
      </li>
      <li>
        <p>出账</p>
        <b>{{ totalData.outAmount }} <span>元</span></b>
      </li>
      <li>
        <p>调账次数</p>
        <b>{{ totalData.unionAdjustCount }} <span>次</span></b>
      </li>
    </ul>
    <p
      style="
        font-weight: bold;
        color: #666666;
        line-height: 40px;
        font-size: 24px;
        margin-left: 6px;
      "
    >
      记录
    </p>
    <!-- {{ formInline }} -->
    <el-form :inline="true" :model="formInline">
      <el-form-item>
        <el-date-picker
          v-model="formInline.time"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="formInline.amountDirectionType"
          clearable
          placeholder="请选择类型"
        >
          <el-option label="入账" :value="0"> </el-option>
          <el-option label="出账" :value="1"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="formInline.id" clearable placeholder="记录ID">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.page.pageIndex = 1;
            getList();
          "
          >查询</el-button
        >
        <el-button type="primary" v-if="1" @click="exportList">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column align="center" prop="id" width="130" label="记录ID">
      </el-table-column>
      <el-table-column
        align="center"
        prop="createTime"
        width="180"
        label="调账时间"
      >
      </el-table-column>
      <el-table-column align="center" prop="amount" width="200" label="金额">
        <!-- <template slot-scope="scope">
          {{ scope.row. }}
        </template> -->
      </el-table-column>
      <el-table-column
        align="center"
        prop="balanceAmount"
        width="200"
        label="余额"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="adjustDesc"
        label="调账原因"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="orderNo"
        label="关联账单"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.page.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.page.totalElements"
    >
    </el-pagination>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      value1: "",
      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      getGameListData: [],
      dialogFormVisible: false,
      editDialogShow: false,
      editUnionId: "",
      formInline: {
        gameId: null,
        unionId: +this.$route.query.unionId,
        amountDirectionType: null,
        startTime: "",
        endTime: "",
        page: {
          pageIndex: 1,
          pageSize: 20,
          totalElements: 0,
        },
        time: ["", ""],
      },
      queryForm: {
        serviceTime: "",
        gameId: null,
        capacity: 1,
        serviceName: "",
      },
      tableData: [],
      totalData: {},
    };
  },
  mounted() {
    // this.getPermission();
    this.getGameList();
    this.getList();
    this.getListSum();
  },
  methods: {
    exportList() {
      // 获取公会列表
      this.formInline.page.pageSize = 50000;
      this.$api.billingApi
        .queryAdjustmentListExport({
          ...this.formInline,
          startTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          endTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          this.formInline.page.pageSize = 20;
          // 导出Excel
          this.$exportExcel(
            res,
            `已出数据_${this.$moment().format(
              "YYYY-MM-DD"
            )}_${this.$moment().unix()}.xlsx`
          );
        });
    },
    getListSum() {
      // 获取公会列表
      this.$api.billingApi
        .queryAdjustmentListSum({
          ...this.formInline,
          startTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          endTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
        })
        .then((res) => {
          console.log("inviterStatisExportA", res);
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.totalData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      // 获取公会列表
      this.$api.billingApi
        .queryAdjustmentListPage({
          ...this.formInline,
          startTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          endTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.page.totalElements = res.data.totalElements;
            this.formInline.page.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.gameApi
        .queryPackage({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    resetUnionPassword() {
      this.$api.organizationApi
        .resetPassword({
          unionId: this.editUnionId,
        })
        .then((res) => {
          console.log("resetPassword", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    deleteUnion(data) {
      this.$api.organizationApi
        .deleteUnion({
          unionId: data.id,
          isDelete: 1,
        })
        .then((res) => {
          console.log("deleteUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    changeUnionStatus(data) {
      this.$api.organizationApi
        .changeUnionStatus({
          unionId: data.id,
          status: data.status === 0 ? 1 : 0,
        })
        .then((res) => {
          console.log("changeUnionStatus", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    updateUnion() {
      this.$api.organizationApi
        .updateAreaPlan({ ...this.queryForm, id: this.editUnionId })
        .then((res) => {
          console.log("updateUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.dialogFormVisible = false;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    addUnion() {
      this.$api.organizationApi.createAreaPlan(this.queryForm).then((res) => {
        console.log("createUnion", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.dialogFormVisible = false;
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleCurrentChange(val) {
      this.formInline.page.pageIndex = val;
      this.getList();
    },
    onSubmit() {
      console.log("submit!");
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/organization/bm/staff/list",
        query: {
          unionId: e.id,
        },
      });
    },
    resetPassWord(pId) {
      this.$confirm("重置密码:默认密码123456", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 重置密码
        this.editUnionId = pId.id;
        this.resetUnionPassword();
      });
    },
    changeStatus(pId) {
      this.$confirm("确定要更改状态吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 改变状态
        this.changeUnionStatus(pId);
      });
    },
    deleteItem(pId) {
      this.$confirm("确定要删除数据吗？", "", {
        type: "error",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 删除
        this.deleteUnion(pId);
      });
    },
    openEditDialog(data) {
      this.dialogFormVisible = true;
      this.editDialogShow = true;
      this.editUnionId = data.id;
      setTimeout(() => {
        this.queryForm.serviceTime = this.$moment(data.serviceTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.queryForm.gameId = data.gameId;
        this.queryForm.capacity = data.capacity;
        this.queryForm.serviceName = data.serviceName;
      }, 1);
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .box {
    display: flex;
    justify-content: space-around;
    margin-bottom: 12px;
    li {
      border-radius: 6px;
      width: calc(33% - 6px);
      height: 100px;
      background: #f5f5f5;
      color: #646e80;
      font-size: 16px;
      padding: 23px 0 0 35px;
      box-sizing: border-box;
      b {
        color: #3a4a65;
        font-size: 22px;
        font-weight: bold;
      }
      span {
        color: #646e80;
        font-size: 16px;
        font-weight: normal;
      }
    }
  }
  .mr15 {
    margin-right: 15px;
  }
}
</style>
