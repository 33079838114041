export default [
  // 后管【结算详情】模块
  {
    path: "/share/ztx",
    name: "游戏-争天下",
    component: () => import("@/views/share/zhengtianxiaPage"),
  },
  {
    path: "/share/zhwc",
    name: "客服-账号管理",
    component: () => import("@/views/share/zhaohuanwangchaoPage"),
  },
  {
    path: "/share/gsws",
    name: "远征手游",
    component: () => import("@/views/share/gaishiwushuangPage"),
  },
  {
    path: "/hdxHome",
    name: "游戏-悍刀行-首页",
    component: () => import("@/views/share/gameHomePage.vue"),
  },
  {
    path: "/ztxHome",
    name: "游戏-征天下-首页",
    component: () => import("@/views/share/gameHomePage.vue"),
  },
  {
    path: "/qqtxHome",
    name: "游戏-权倾天下-首页",
    component: () => import("@/views/share/gameHomePage.vue"),
  },
  {
    path: "/share/sslc",
    name: "游戏-盛世龙城",
    component: () => import("@/views/share/shengshilongchengPage"),
  },
];
