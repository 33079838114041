export default [
  // 后管【游戏资源】模块
  {
    path: "/game/bm/apply",
    name: "游戏申请bm",
    component: () => import("@/views/game/bmGameApply"),
  },
  {
    path: "/game/bm/list",
    name: "公会游戏bm",
    component: () => import("@/views/game/bmGameList"),
  },
  // 公会【游戏资源】模块
  {
    path: "/game/union/apply",
    name: "游戏申请union",
    component: () => import("@/views/game/unionGameApply"),
  },
  {
    path: "/game/union/list",
    name: "公会游戏union",
    component: () => import("@/views/game/unionGameList"),
  },
  {
    path: "/game/detail",
    name: "公会游戏union",
    component: () => import("@/views/game/gameDetail"),
  },
  {
    path: "/setting/ip",
    name: "切换后端路由",
    component: () => import("@/views/game/ipConfig"),
  },
];
