import fileDownLoad from "js-file-download";
import { Message } from "element-ui";

/**
 * 
 * 所有导出相关的接口Api，要加上 responseType: "blob"，否则调用该方法导出的文件会乱码。
 * 
 * example：
 * 
  exportDetailSecond(params) {
    return axios.post(`${base.sq}/settle/union/list/export`, params, {
      responseType: "blob",
    });
  },
 * 
 */

/**
 * @name 接收blob流生成Excel文件并下载到本地
 * @param {*} data 流信息（后端返回）
 * @param {*} fileName 文件名（导出后，下载至本地的文件名）
 * @returns void
 */
function exportExcel(data, fileName) {
  if (!data) {
    Message({
      message: "文件导出异常",
      type: "error",
      duration: 1000,
    });
    return;
  }
  // do something

  fileDownLoad(data, fileName);
}

export default exportExcel;
