<template>
  <div class="unionList ground-white">
    <!-- {{ formInline }} -->
    <el-form :inline="true" :model="formInline">
      <el-form-item label="选择游戏">
        <el-radio-group
          v-model="formInline.gameId"
          size="mini"
          @input="changeGame"
        >
          <el-radio-button
            v-for="(item, index) in getGameListData"
            :key="index"
            :label="item.id"
            >{{ item.gameName }}</el-radio-button
          >
        </el-radio-group>
      </el-form-item>
      <br />
      <el-divider></el-divider>
      <el-form-item label="选择区服">
        <el-radio-group
          v-model="formInline.gameServiceId"
          size="mini"
          @input="changeArea"
        >
          <el-tooltip
            v-for="(item, index) in getAreaListData"
            :key="index"
            class="item"
            effect="dark"
            :disabled="!item.content"
            :content="item.content"
            placement="top"
          >
            <el-radio-button :label="item.id">{{
              item.serviceName
            }}</el-radio-button>
          </el-tooltip>
        </el-radio-group>
      </el-form-item>
      <el-divider></el-divider>
      <el-row
        :gutter="20"
        class="recharge-area"
        v-show="formInline.gameServiceId"
      >
        <el-col :span="6">
          <div class="card">
            <p>可用额度</p>
            <p>
              <span>{{ accountInfo.availableAmount }}元&nbsp;&nbsp;</span>
              <el-button
                type="success"
                size="mini"
                v-if="accountInfo.availableAmount"
                @click="
                  $router.push({
                    path: '/billing/union/recharge/detail',
                    query: {
                      gameId: formInline.gameId,
                      gameServiceId: formInline.gameServiceId,
                    },
                  })
                "
                >明细</el-button
              >
            </p>
            <p>
              初始充值额度{{
                accountInfo.initFirstRechargeAmount
              }}，后续额度以充值比例1:{{ accountInfo.supportRatio }}实时发放
              <el-tooltip class="item" placement="right">
                <div slot="content">
                  领取方式：<br />直接发放，扶持必须在线状态 下线领取不了
                </div>
                <el-button type="text" size="mini">领取方式></el-button>
              </el-tooltip>
            </p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="card">
            <p>冻结额度</p>
            <p>{{ accountInfo.frozenAmount }}元</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="card">
            <p>首冲剩余个数</p>
            <p>{{ accountInfo.firstChargeRemainNum }}</p>
            <p>
              共{{ accountInfo.initFirstChargeNum }}个首充，以每次328面额发放
            </p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="card">
            <p>已用额度</p>
            <p>{{ accountInfo.usedAmount }}元</p>
          </div>
        </el-col>
      </el-row>
      <!-- <el-form-item label="公会名称">
        <el-select
          v-model="formInline.unionId"
          clearable
          filterable
          placeholder="请选择公会名称"
        >
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.unionName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item label="申请状态">
        <el-select
          v-model="formInline.applyStatus"
          clearable
          filterable
          placeholder="请选择申请状态"
        >
          <el-option
            v-for="item in statusList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="游戏账号ID">
        <el-input
          v-model="formInline.gameAccountId"
          placeholder="游戏账号ID"
        ></el-input>
      </el-form-item>
      <el-form-item label="申请ID">
        <el-input v-model="formInline.applyId" placeholder="申请ID"></el-input>
      </el-form-item>
      <el-form-item label="申请角色名">
        <el-select
          v-model="formInline.gameRoleId"
          clearable
          placeholder="申请角色名"
          filterable
          remote
          :remote-method="remoteMethodNew"
          :loading="loadingNew"
          @change="changeRole"
        >
          <el-option
            v-for="item in gameRoleListData"
            :key="item.id"
            :label="item.roleName"
            :value="item.roleId"
          >
          </el-option>
        </el-select> </el-form-item
      ><br />
      <el-form-item label="申请时间范围">
        <el-date-picker
          v-model="formInline.time"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="完成时间范围">
        <el-date-picker
          v-model="formInline.time1"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            // formInline.pageIndex = 1;
            getList()
          "
          >查询</el-button
        >
        <el-button type="primary" v-if="1" @click="showRechargePop"
          >充值申请
        </el-button>
      </el-form-item>
    </el-form>
    <p style="margin-top: 10px; margin-bottom: 20px" v-if="false">
      总计：申请笔数：73680笔，成功笔数：71702笔，实际付款笔数：0笔，充值金额：0元。
    </p>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="applyId"
        label="申请ID"
        width="100"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="applyTime"
        label="申请时间"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="gameAccountId" label="游戏账号ID">
        <!-- <template slot-scope="scope">
          {{ $moment(scope.row.serviceTime).format("YYYY-MM-DD") }}
        </template> -->
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameName"
        label="游戏名称"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameServiceName"
        label="游戏区服名称"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameRoleName"
        label="角色名"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="chargeAmount" label="充值金额">
      </el-table-column>
      <el-table-column align="center" prop="applyStatus" label="申请状态">
        <template slot-scope="scope">
          {{
            statusList.find((item) => item.id === scope.row.applyStatus).name ||
            "-"
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="completeTime" label="完成时间">
      </el-table-column>
      <el-table-column align="center" prop="applyAccountName" label="申请人">
      </el-table-column>
      <!-- <el-table-column align="center" prop="" label="详情" width="150">
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="goSecond(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="formInline.page.pageSize"
      :current-page.sync="formInline.page.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.page.totalElements"
    >
    </el-pagination>

    <!-- dialog -->
    <el-dialog
      :title="editDialogShow ? '编辑结算周期' : '新建计划'"
      :visible.sync="dialogFormVisible"
    >
      <!-- {{ queryForm }} -->

      <el-form :model="queryForm" status-icon ref="queryForm" :inline="true">
        <el-form-item label="游戏名称" prop="gameId">
          <el-input-number :min="1" v-model="queryForm.gameId">
          </el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="editDialogShow" type="primary" @click="updateUnion"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="addUnion">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="isShowRechargePop"
      width="606px"
      :before-close="handleCloseRechargePop"
    >
      <div slot="title" class="dialog-title">
        <div>申请充值</div>
        <span>严禁现金充值，自助充值账号一旦申请，不得主动变更手机号</span>
      </div>
      <div class="dialog-wrap">
        <h4 class="recharge_title">后续充值</h4>
        <div class="game_info">
          <div>
            <span>游戏：</span><span>{{ currentGame.gameName }}</span>
          </div>
          <div>
            <span>区服：</span><span>{{ currentGameService.serviceName }}</span>
          </div>
        </div>
        <el-form
          :inline="false"
          ref="rechargeForm"
          :model="rechargeForm"
          label-width="120px"
        >
          <el-form-item
            label="充值角色："
            :rules="[
              {
                required: true,
                message: '请输入充值角色',
                trigger: ['blur', 'change'],
              },
            ]"
            prop="roleId"
          >
            <el-select
              v-model="rechargeForm.roleId"
              clearable
              placeholder="请选择游戏角色"
              filterable
              remote
              :remote-method="remoteMethodNew"
              :loading="loadingNew"
              @change="changeRechargeRole"
            >
              <el-option
                v-for="item in gameRoleListData"
                :key="item.id"
                :label="item.roleName"
                :value="item.roleId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="user_info" v-if="rechargeUserInfo.gameId">
            <div class="game_info">
              <div>
                <span>游戏账号ID：</span
                ><span>{{ rechargeUserInfo.gameId }}</span>
              </div>
              <div>
                <span>载体：</span><span>{{ rechargeUserInfo.platform }}</span>
              </div>
            </div>
            <div class="game_info">
              <div>
                <span>推广员：</span
                ><span>{{ rechargeUserInfo.inviterAdminAccountName }}</span>
              </div>
              <div>
                <span>团长：</span
                ><span>{{ rechargeUserInfo.headAdminAccountName }}</span>
              </div>
            </div>
          </div>
          <el-form-item
            label="充值金额："
            :rules="[
              {
                required: true,
                message: '请选择充值金额',
                trigger: 'change',
              },
            ]"
            prop="amount"
          >
            <span>{{ rechargeForm.amount || 0 }}元</span>
            <div class="advice_wrap">
              <span
                v-for="(item, i) in amountList"
                :key="i"
                :class="item.isTrue ? 'active' : ''"
                @click="selectedAmount(item, i)"
                >{{ item.payTypeName }}</span
              >
            </div>
            <div class="reset_wrap">
              可用余额<span>{{ accountInfo.availableAmount }} </span>元
            </div>
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" @click="handleSubmitRecharge"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      loadingNew: false,
      value1: "",
      radio2: "",
      statusList: [
        { id: 0, name: "待发放" },
        { id: 1, name: "发放成功" },
        { id: 2, name: "发放失败" },
        // { id: 3, name: "待付款" },
        // { id: 4, name: "待审核" },
        // { id: 5, name: "取消申请" },
        // { id: 6, name: "驳回" },
      ],
      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      getGameListData: [],
      gameRoleListData: [],
      getAreaListData: [{ id: null, serviceName: "全部" }],
      dialogFormVisible: false,
      editDialogShow: false,
      editUnionId: "",
      accountInfo: {},
      formInline: {
        gameId: null,
        gameServiceId: null,
        page: {
          pageIndex: 1,
          pageSize: 20,
          totalElements: 0,
        },
      },
      queryForm: {
        serviceTime: "",
        gameId: null,
        capacity: 1,
        serviceName: "",
      },
      tableData: [],
      isShowRechargePop: false,
      rechargeForm: {
        amount: null,
        roleId: null,
      },
      gameRoles: [],
      amountList: [],
      currentGame: {},
      currentGameService: {},
      rechargeUserInfo: {},
    };
  },
  watch: {
    isShowRechargePop(v) {
      if (!v) {
        this.rechargeForm.roleId = null;
        this.gameRoleListData = [];
        this.rechargeUserInfo.gameId = null;
        this.rechargeForm.payTypeId = "";
        this.rechargeForm.amount = 0;
        this.$refs.rechargeForm.resetFields();
      }
    },
  },
  mounted() {
    // this.getPermission();
    this.getGameList();
    this.getList();
  },
  methods: {
    changeRole(e) {
      console.log(e);
      if (!e) {
        return;
      }
    },
    remoteMethodNew(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryGameRole({
            roleName: query,
            // accountIdList: [0],
            firstChargeInfo: true,
            // gameAccountIdList: [0],
            gameIdList: this.formInline.gameId
              ? [this.formInline.gameId]
              : null,
            gameServerIdList: this.formInline.gameServiceId
              ? [parseInt(this.formInline.gameServiceId)]
              : null,
            needAccountInfo: true,
            // roleIdList: ["string"],
            // roleName: "string",
          })
          .then((res) => {
            console.log("getGameList", res);
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.gameRoleListData = result;
              // this.gameRoles =
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.gameRoleListData = [];
      }
    },
    getAreaList(id) {
      this.$api.organizationApi
        .queryAreaPlan({
          gameId: id,
          startTime: null,
          endTime: null,
          pageIndex: 1,
          pageSize: 10000,
        })
        .then((res) => {
          console.log("getAreaList", res);
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : {};
            this.getAreaListData = result.map((item) => {
              return {
                ...item,
                content: `开服时间：${this.$moment(item.serviceTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}`,
              };
            });
            this.getAreaListData.unshift({
              id: null,
              serviceName: "全部",
              content: "",
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.gameApi
        .queryGameList({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("querySubPackage", res);
          if (res.code === 0) {
            console.log();
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
            this.getGameListData.unshift({
              id: null,
              gameName: "全部",
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeArea(id) {
      this.currentGameService = this.getAreaListData.find(
        (item) => id === item.id
      );

      if (id === null) {
        this.getList();
        return;
      }
      this.getList();
      this.getRechargeAccountInfo();
      //
    },
    getRechargeAccountInfo() {
      const item = this.getAreaListData.find(
        (item) => item.id === this.formInline.gameServiceId
      );
      // 获取公会列表
      this.$api.billingApi
        .queryRechargeAccountInfo({
          unionId: this.formInline.unionId,
          gameId: this.formInline.gameId,
          gameServiceId: +item.serviceId,
          // applyStartTime: this.formInline.time ? this.formInline.time[0] : "",
          // applyEndTime: this.formInline.time ? this.formInline.time[1] : "",
          // completeStartTime: this.formInline.time1
          //   ? this.formInline.time1[0]
          //   : "",
          // completeEndTime: this.formInline.time1
          //   ? this.formInline.time1[1]
          //   : "",
        })
        .then((res) => {
          console.log("queryRechargeAccountInfo", res);
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.accountInfo = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeGame(id) {
      console.log(id);
      this.currentGame = this.getGameListData.find((item) => id === item.id);
      if (id === null) {
        this.getAreaListData = [
          {
            id: null,
            serviceName: "全部",
          },
        ];
        this.formInline.gameServiceId = null;
        this.getList();

        return;
      }
      this.formInline.gameServiceId = null;
      this.getAreaList(id);
      this.getList();
    },
    goSecond(data) {
      this.$router.push({
        path: "/billing/bm/settle/union",
        query: {
          unionId: data.unionId,
        },
      });
    },
    getList() {
      // 获取公会列表
      this.$api.billingApi
        .queryRechargeDetail({
          ...this.formInline,
          applyStartTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          applyEndTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[0])
            : "",
          completeStartTime: this.formInline.time1
            ? this.$dateUtil.dayToStartUnix(this.formInline.time1[0])
            : "",
          completeEndTime: this.formInline.time1
            ? this.$dateUtil.dayToEndUnix(this.formInline.time1[0])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.page.totalElements = res.data.totalElements;
            this.formInline.page.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    resetUnionPassword() {
      this.$api.organizationApi
        .resetPassword({
          unionId: this.editUnionId,
        })
        .then((res) => {
          console.log("resetPassword", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    deleteUnion(data) {
      this.$api.organizationApi
        .deleteUnion({
          unionId: data.id,
          isDelete: 1,
        })
        .then((res) => {
          console.log("deleteUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    changeUnionStatus(data) {
      this.$api.organizationApi
        .changeUnionStatus({
          unionId: data.id,
          status: data.status === 0 ? 1 : 0,
        })
        .then((res) => {
          console.log("changeUnionStatus", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    updateUnion() {
      this.$api.organizationApi
        .updateAreaPlan({ ...this.queryForm, id: this.editUnionId })
        .then((res) => {
          console.log("updateUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.dialogFormVisible = false;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    addUnion() {
      this.$api.organizationApi.createAreaPlan(this.queryForm).then((res) => {
        console.log("createUnion", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.dialogFormVisible = false;
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleCurrentChange(val) {
      this.formInline.page.pageIndex = val;
      this.getList();
    },
    onSubmit() {
      console.log("submit!");
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/organization/bm/staff/list",
        query: {
          unionId: e.id,
        },
      });
    },
    resetPassWord(pId) {
      this.$confirm("重置密码:默认密码123456", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 重置密码
        this.editUnionId = pId.id;
        this.resetUnionPassword();
      });
    },
    changeStatus(pId) {
      this.$confirm("确定要更改状态吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 改变状态
        this.changeUnionStatus(pId);
      });
    },
    deleteItem(pId) {
      this.$confirm("确定要删除数据吗？", "", {
        type: "error",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 删除
        this.deleteUnion(pId);
      });
    },
    openEditDialog(data) {
      this.dialogFormVisible = true;
      this.editDialogShow = true;
      this.editUnionId = data.id;
      setTimeout(() => {
        this.queryForm.serviceTime = this.$moment(data.serviceTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.queryForm.gameId = data.gameId;
        this.queryForm.capacity = data.capacity;
        this.queryForm.serviceName = data.serviceName;
      }, 1);
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
    handleCloseRechargePop() {
      this.isShowRechargePop = false;
    },
    showRechargePop() {
      if (!this.formInline.gameId) {
        this.$message.warning("请选择游戏！");
        return;
      }
      if (!this.formInline.gameServiceId) {
        this.$message.warning("请选择游戏区服！");
        return;
      }
      this.isShowRechargePop = true;
      this.getAmountConfigList();
    },
    getAmountConfigList() {
      this.$api.organizationApi
        .getAmountList({
          gameId: this.currentGame.id,
          // gameServiceId: this.currentGameService.serviceId,
          gameServiceId: null,
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data ? res.data : [];
            this.amountList = result.map((item) => {
              return {
                ...item,
                isTrue: false,
              };
            });
          }
        });
    },
    selectedAmount(item, i) {
      console.log("i", item, i);
      this.rechargeForm.payTypeId = item.payTypeId;
      this.rechargeForm.amount = item.amount;
      this.amountList.forEach((item) => (item.isTrue = false));
      this.amountList[i].isTrue = true;
    },
    changeRechargeRole(val) {
      if (val && this.gameRoleListData) {
        this.rechargeUserInfo = this.gameRoleListData.find(
          (item) => val === item.roleId
        );
      } else {
        this.gameRoleListData = [];
        this.rechargeUserInfo.gameId = null;
      }
    },
    handleSubmitRecharge() {
      console.log("查看", this.rechargeForm);
      this.$refs.rechargeForm.validate((valid) => {
        if (valid) {
          const params = {
            applyAccountId: JSON.parse(localStorage.getItem("userInfo"))
              .accountId,
            gameAccountId: this.rechargeUserInfo.gameAccountId,
            gameId: this.currentGame.id,
            gameServiceId: this.currentGameService.serviceId,
            gameRoleId: this.rechargeUserInfo.roleId,
            payTypeId: this.rechargeForm.payTypeId || "",
            amount: this.rechargeForm.amount,
            type: 1,
          };
          this.$api.organizationApi.rechargeHandle(params).then((res) => {
            if (res.code === 0) {
              this.$message.success("操作成功!");
              this.isShowRechargePop = false;
              this.getList();
              this.getRechargeAccountInfo();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          console.log("UI", this.rechargeForm);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.card {
  height: 130px;
  background: #eee;
  border-radius: 6px;
  padding: 5px 16px;
  box-sizing: border-box;
  & > p:nth-of-type(1) {
    font-size: 16px;
    line-height: 2.2;
  }
  & > p:nth-of-type(2) {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.8;
    /deep/.el-button--mini {
      vertical-align: middle;
    }
  }
}
.unionList {
  /deep/ .el-radio-button--mini .el-radio-button__inner {
    padding: 7px 15px;
    font-size: 12px;
    border-radius: 0;
    margin: 5px;
    border: 1px #dcdfe6 solid !important;
    border-radius: 4px !important;
    box-shadow: none !important;
  }
  .recharge-area {
    margin-bottom: 20px;
  }
  .mr15 {
    margin-right: 15px;
  }

  .dialog-title {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;

    & > div {
      font-size: 22px;
      padding-right: 24px;
    }
    & > span {
      font-size: 12px;
      color: red;
    }
  }

  .dialog-wrap {
    .recharge_title {
      padding-left: 42px;
      font-size: 20px;
    }
    .game_info {
      padding: 10px 0 10px 42px;
      display: flex;
      justify-content: flex-start;

      & > div {
        flex: 1;
      }
    }
    .user_info {
      margin-top: -10px;
      margin-bottom: 10px;
      .game_info {
        margin-left: 9px;
        padding-left: 0;

        & > div {
          display: flex;
          justify-content: flex-start;
          height: 8px;

          &:nth-of-type(2) {
            margin-left: -80px;
          }
          & > span {
            &:nth-of-type(1) {
              width: 100px;
              text-align: right;
            }
          }
        }
      }
    }
    .advice_wrap {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 10px;

      & > span {
        padding: 0px 10px;
        height: 30px;
        line-height: 30px;
        background: #efefef;
        border: 1px solid #efefef;
        border-radius: 4px;
        cursor: pointer;

        &:hover,
        &.active {
          background: #fdfefd;
          border: 1px solid #00a046;
          color: #00a046;
        }
      }
    }
    .reset_wrap {
      & > span {
        padding: 0 4px;
        color: #0b8a2f;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
}
</style>
