<template>
  <div class="log">
    <div class="top flex">
      <img
        src="@/assets/img/avator.png"
        style="
          height: 72px;
          display: block;
          margin-left: 5%;
          margin-right: 15px;
        "
      />
      <p>
        <span
          style="
            font-size: 16px;
            color: #666;
            font-style: normal;
            margin-bottom: 1px;
            display: inline-block;
            margin-top: 5px;
            margin-left: 9px;
          "
          >Game最强王者联盟</span
        ><br />
        <span
          style="
            color: #999;
            font-size: 12px;
            font-family: simsun;
            letter-spacing: 1px;
            display: block;
            text-align: center;
          "
          >-beta测试版本-</span
        >
      </p>
    </div>
    <div class="login">
      <img class="cloud" src="@/assets/img/login-cloud.gif" />
      <img class="person" src="../../assets/img/login-person.gif" />
      <div class="login-bg">
        <img
          src="@/assets/img/avator.png"
          style="
            width: 30%;
            margin: 10px auto;
            display: block;
            margin-bottom: 30px;
          "
        />
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="70px"
          label-position="top"
        >
          <el-form-item prop="accountName">
            <el-input
              prefix-icon="el-icon-user"
              v-model="ruleForm.accountName"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="accountPassword">
            <el-input
              prefix-icon="el-icon-lock"
              type="password"
              show-password
              v-model="ruleForm.accountPassword"
              placeholder="请输入密码"
              @keyup.enter.native="submitForm('ruleForm')"
            ></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 3px">
            <el-button
              style="background: #444; width: 100%; background-color: #27282c"
              type="info"
              @click="submitForm('ruleForm')"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div>
            <el-checkbox
              v-model="checked"
              style="margin-bottom: 2.5px; margin-right: 5px"
            >
            </el-checkbox>
            <span>请先同意</span>
            <el-button
              style="font-weight: normal; color: #ba4d4f"
              type="text"
              @click="dialogVisible = true"
              >《平台协议》</el-button
            >
          </div>
          <el-button
            @click="open"
            style="font-weight: normal; color: #999"
            type="text"
            >忘记密码?</el-button
          >
        </div>
      </div>
      <el-dialog :visible.sync="centerDialogVisible" width="30%" center>
        <span
          style="
            font-size: 16px;
            color: #666;
            text-align: center;
            width: 100%;
            display: block;
            margin-bottom: -20px;
          "
          >{{ contact[platformType] }}</span
        >
        <span slot="footer" class="dialog-footer">
          <div
            style="
              background: #27282c;
              cursor: pointer;
              width: 90%;
              margin: 10px auto;
              height: 42px;
              text-align: center;
              color: white;
              line-height: 42px;
              border-radius: 5px;
            "
            @click="centerDialogVisible = false"
          >
            确认
          </div>
        </span>
      </el-dialog>
      <el-dialog title="平台协议" :visible.sync="dialogVisible" width="80%">
        <div class="protocol">
          <Protocol></Protocol>
          <!-- <article class="protocol_bd">
            <p>
              欢迎您注册/登陆使用本平台。请您在注册/登陆本平台前仔细阅读《推广员合作协议》（以下简称“本协议”）的所有内容，特别是有关推广员的义务的条款以及免除、限制本平台责任的条款。本协议是平台与推广员（以下称“您”或“推广员”、“你方”、“合作方”）所约定的规范双方权利、义务关系的电子合同。如您对本协议任何内容有异议，
              请不要使用本平台；当您点击确定使用本平台或者相关服务，即视为您已仔细阅读本协议所有内容，
              同意接受本协议的所有规定，成为受本协议约束的推广员。
            </p>
            <p>
              本协议下推广员指注册并登陆本平台的：（1）如您为自然人，则推广员指您本人；（2）如您为公司、个体工商户、合伙企业等组织，则推广员指该等组织。
            </p>
            <p>
              您应对您雇佣、指派的受您管理的自然人或其他组织的行为承担全部责任，请确保您雇佣、指派的受您管理的自然人或其他组织知晓并履行本协议约定的义务，并合法推广。
            </p>
          </article> -->
        </div>

        <span slot="footer" class="dialog-footer"> </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import choosePlantorm from "@/utils/choosePlantorm";
import Protocol from "./Protocol.vue";
export default {
  name: "loginPage",
  components: {
    Protocol,
  },
  data() {
    return {
      checked: false,
      centerDialogVisible: false,
      dialogVisible: false,
      ruleForm: {
        accountName: "",
        accountPassword: "",
      },
      rules: {
        accountName: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        accountPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
      },
      platformType: choosePlantorm().platformType,
      contact: ["请联系管理员", "请联系客服", "请联系管理员"],
    };
  },
  mounted() {
    if (this.$route.query.timeout === "403") {
      this.$message.error("登录过期，请重新登录");
    }
  },
  methods: {
    open() {
      this.centerDialogVisible = true;
    },
    submitForm(formName) {
      let that = this;
      if (!this.checked) {
        this.$message.error("请先同意平台协议");
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.login();
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    login() {
      this.$api.loginApi
        .login({
          ...this.ruleForm,
          platformType: choosePlantorm().platformType,
        })
        .then((res) => {
          console.log(res);
          if (res.code === 0) {
            this.$message.success("登录成功!");
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            // this.$router.push("/home");
          } else {
            this.$message.error(res.msg);
          }
        })
        .then(() => {
          this.$api.loginApi.tree({}).then((res) => {
            if (res.code === 0) {
              console.log("treeData", res);
              const { data } = res;
              if (data.length <= 0) {
                // 没有权限的账号跳转到无权限页面
                this.$router.push("/noPermission").catch((err) => {
                  console.log("err", err);
                });
              } else {
                // 需求要求进入系统后打开第一个有权限的页面
                const firstMenu = data.shift();
                const { children, path } = firstMenu;
                if (children && children.length !== 0) {
                  const { path, id } = children.shift();
                  this.$router.push({ path, query: { pageId: id } });
                } else {
                  this.$router.push(path);
                }
              }
              this.slideData = res.data;
              // sessionStorage.setItem("menuTree", JSON.stringify(res.data));
            } else {
              this.$message.error(res.msg);
            }
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.log {
  width: 100%;
  height: 100%;
  background-image: url("@/assets/img/repeat-bg.png");
  .top {
    height: 90px;
    position: relative;
    top: 5%;
  }
  .login {
    width: 100%;
    // left: calc((100vw - 1280px) / 2);
    top: calc((100vh - 600px) / 4);
    height: 702px;
    background-image: url("@/assets/img/login-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .login-bg {
      z-index: 13;
      position: absolute;
      background: #fff;
      border-radius: 10px;
      padding: 20px 36px 20px 36px;
      width: 320px;
      right: 15%;
      top: 160px;
      -webkit-box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
      background: rgba(249, 249, 249, 0.9);
      box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 20%);
      /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
      .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #27282c;
        border-color: #27282c;
      }
    }
    .cloud {
      z-index: 2;
      position: absolute;
      height: 100%;
      // width: 100%;
    }
    .person {
      height: 640px;
      left: 11%;
      bottom: 0;
      z-index: 3;
      position: absolute;
    }
  }
}
.protocol {
  h3 {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .protocol_bd {
    padding-top: 20px;
    max-height: calc(100vh - 420px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  h4 {
    margin-top: 24px;
    font-size: 15px;
    font-size: 400;
    text-indent: 30px;
  }
  p {
    font-size: 14px;
    text-indent: 30px;
    line-height: 28px;
  }
}
</style>
