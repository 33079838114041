<template>
  <div class="game-apply">
    <div class="flex ground-white">
      <el-page-header
        @back="$router.back()"
        content=""
        v-if="$choosePlantorm().platformType === 0"
      >
      </el-page-header>
      <br />
      <el-form>
        <el-select
          v-model="formInline.gameId"
          clearable
          placeholder="请选择游戏"
        >
          <el-option
            v-for="item in gameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>

        <el-button type="primary" style="margin-left: 8px" @click="getList"
          >查询</el-button
        >
      </el-form>
    </div>
    <div class="box">
      <div
        v-if="listData.length > 0"
        style="width: 100%; display: flex; justify-content: flex-start"
      >
        <el-card
          class="box-card carditem"
          v-for="(item, index) in listData"
          :key="item.id + index"
        >
          <p class="title">{{ item.gameName + "" }}</p>
          <!-- <p>结算时间：开服后48小时</p> -->
          <p>角色等级：{{ item.roleLevel }}</p>
          <p>充值金额：{{ item.chargeAmount }}</p>
          <!-- <div class="btn" @click="openEdit(item)">修改</div> -->
        </el-card>
      </div>
      <div v-else>
        <img class="el-empty" src="@/assets/img/noData.png" alt="" />
        <div class="text-no-data">暂无数据</div>
      </div>

      <!-- <el-empty v-else image="img/noData.png" description="暂无数据"></el-empty> -->
    </div>
  </div>
</template>
<script>
import { regionData } from "element-china-area-data";
export default {
  data() {
    return {
      options: regionData,
      editDialogShow: false,
      dialogFormVisible: false,
      gameListData: [],
      listData: [],
      form: {
        unionId: this.$route.query.unionId,
        roleLevel: null,
        chargeAmount: null,
        specialConfigList: [
          {
            type: 1,
            content: null,
            roleLevel: null,
            chargeAmount: null,
          },
        ],
      },
      formInline: {
        gameId: "",
        unionId: +this.$route.query.unionId,
      },
    };
  },
  mounted() {
    this.getList();
    this.getGameList();
  },
  methods: {
    openEdit(item) {
      console.log(item);
      this.$message.error("编辑功能暂不可用");
      // this.dialogFormVisible = true;
      // 回显
    },
    getList() {
      // 获取公会列表
      this.$api.organizationApi.queryUsefulRole(this.formInline).then((res) => {
        console.log("queryUsefulRole", res);
        if (res.code === 0) {
          const result = res.data ? res.data : [];
          this.listData = result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleChange(value) {
      console.log(value);
    },
    removeDomain(index) {
      if (index !== -1) {
        this.form.specialConfigList.splice(index, 1);
      }
    },
    addDomain() {
      this.form.specialConfigList.push({
        type: 1,
        content: null,
        roleLevel: null,
        chargeAmount: null,
      });
    },
    addUnion() {
      const requestForm = this.form.specialConfigList.map((item) => {
        const content = item.content.split("\n");
        return {
          ...item,
          content: content,
        };
      });
      this.$api.organizationApi
        .addUsefulRole({ ...this.form, specialConfigList: requestForm })
        .then((res) => {
          console.log("addUsefulRole", res);
          if (res.code === 0) {
            this.dialogFormVisible = false;
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getDepartment();
        });
    },
    onSubmit() {
      console.log("submit!");
    },
    getGameList() {
      this.$api.gameApi
        .queryPackage({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.gameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  .box {
    margin-top: 12px;
    & > div {
      display: flex;
      flex-wrap: wrap;
    }
    // justify-content: space-around;
    .flex1 {
      display: flex;
      flex-direction: column;
      .add {
        cursor: pointer;
        text-align: center;
        height: 100px;
        border: 1px #666 dashed;
        margin-top: 10px;
        font-size: 16px;
        line-height: 100px;
      }
    }

    /deep/ .el-empty {
      width: 112px;
      margin: 30vh auto 0 36vw;
      transform: translateY(-10vh);
    }
    .text-no-data {
      margin-top: -12vh;
      padding-right: 18px;
      text-align: right;
      font-size: 18px;
      color: #aaa;
    }

    .carditem {
      /deep/ .el-card__body {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        box-sizing: border-box;
      }
    }
    .box-card {
      p {
        font-size: 13px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        color: #666666;
      }
      .title {
        font-size: 16px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }

      .btn {
        width: 100%;
        height: 35px;
        line-height: 35px;
        text-align: center;
        color: #255ec0;
        font-size: 15px;
        background: #eff5ff;
        border-radius: 4px;
        cursor: pointer;
      }
      width: calc(25% - 12px);
      height: 180px;
      margin-right: 10px;
      margin-bottom: 12px;
      & > div {
        height: 100%;
      }
    }
  }
}
</style>
