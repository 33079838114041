<template>
  <div class="game-apply ground-white">
    <el-page-header
      @back="$router.back()"
      content=""
      style="margin-bottom: 15px"
      v-if="$choosePlantorm().platformType === 0"
    />
    <div>
      <div class="topBar">
        <div class="bar">
          <div class="icon_wrap">
            <img src="@/assets/img/totalAssets.png" alt="总资产" />
          </div>
          <div class="asset_bd">
            <span>总资产</span>
            <div>
              <strong>{{ assetInfo.totalAmount }}</strong
              >元
            </div>
            <em>当前组织所有资金综合</em>
          </div>
        </div>
        <div class="bar">
          <div class="icon_wrap">
            <img src="@/assets/img/overage.png" alt="账户余额" />
          </div>
          <div class="asset_bd">
            <span>账户余额</span>
            <div>
              <strong>{{ assetInfo.balanceAmount }}</strong
              ><span>元</span>
            </div>
            <em>可用于提现等操作的余额</em>
          </div>
        </div>
        <div class="bar">
          <div class="icon_wrap">
            <img src="@/assets/img/toSettle.png" alt="待结算资金" />
          </div>
          <div class="asset_bd">
            <span>待结算资金</span>
            <div>
              <strong>{{ assetInfo.unsettledAmount }}</strong
              >元
            </div>
            <em>即将结算至可用余额的资金</em>
          </div>
        </div>
        <div class="bar">
          <div class="icon_wrap">
            <img src="@/assets/img/frozenMoney.png" alt="冻结资金" />
          </div>
          <div class="asset_bd">
            <span>冻结资金</span>
            <div>
              <strong>{{ assetInfo.frozenAmount }}</strong
              >元
            </div>
            <em>暂不可用资金，一般为提现中的资金</em>
          </div>
        </div>
      </div>
      <el-form :inline="true" :model="formInline" label-width="80px">
        <el-form-item label="">
          <el-date-picker
            v-model="formInline.time"
            type="daterange"
            align="right"
            clearable
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="">
          <el-select
            v-model="formInline.withdrawProgressStatus"
            clearable
            filterable
            placeholder="提现进度"
            @change="changeWithdrawStatus"
          >
            <el-option
              v-for="item in withdrawProcess"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            v-if="1"
            @click="
              formInline.page.pageIndex = 1;
              getList();
            "
            >查询</el-button
          >
          <el-button type="primary" @click="exportData">导出</el-button>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="$store.state.loadingStatus"
        :data="tableData"
        :border="true"
        style="width: 100%"
        :header-cell-style="
          () =>
            'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
        "
      >
        <el-table-column
          v-for="column in transferConfig"
          :key="column.prop"
          align="center"
          :prop="column.prop"
          :label="column.label"
          :show-overflow-tooltip="true"
        />
      </el-table>
      <div class="page_footer">
        <el-pagination
          style="float: right; margin-top: 20px"
          layout="prev, pager, next, jumper"
          :page-size="formInline.page.pageSize"
          :current-page.sync="formInline.page.pageIndex"
          @current-change="handleCurrentChange"
          :total="totalElements"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      activeName: "first",
      formInline: {
        unionId: +this.$route.query.unionId,
        endTime: null,
        startTime: null,
        time: ["", ""],
        page: {
          pageIndex: 1,
          pageSize: 20,
        },
        withdrawProgressStatus: null,
      },
      totalElements: 0,
      assetInfo: {},
      daterange: ["", ""],
      unionId: null,
      tableData: [],
      withdrawProcess: [
        {
          label: "全部",
          value: null,
        },
        {
          label: "审核中",
          value: 0,
        },
        {
          label: "审核拒绝",
          value: 1,
        },
        {
          label: "提现中",
          value: 2,
        },
        {
          label: "打款成功",
          value: 3,
        },
        {
          label: "打款失败",
          value: 4,
        },
      ],
      pickerOptions: {
        shortcuts,
      },
      paymentStatus: [
        {
          label: "全部",
          value: 0,
        },
        {
          label: "待打款",
          value: 1,
        },
        {
          label: "打款完成",
          value: 2,
        },
        {
          label: "打款失败",
          value: 3,
        },
      ],
      transferConfig: [
        {
          label: "创建时间",
          prop: "applyTime",
        },
        {
          label: "流水ID",
          prop: "id",
        },
        {
          label: "提现方式",
          prop: "withdrawTypeStr",
        },
        {
          label: "收款信息",
          prop: "recipientInfo",
        },
        {
          label: "提现金额",
          prop: "withdrawAmount",
        },
        {
          label: "服务费",
          prop: "feeAmount",
        },
        {
          label: "提现进度",
          prop: "withdrawProgressStatus",
        },
        {
          label: "申请人",
          prop: "applyAccountName",
        },
      ],
    };
  },
  mounted() {
    this.getAssetInfo(+this.$route.query.unionId);
    this.getList();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getList() {
      const [startTime, endTime] = this.formInline.time || ["", ""];
      this.$api.billingApi
        .getWithdrawApprovalList({
          ...this.formInline,
          startTime: startTime ? this.$dateUtil.dayToStartUnix(startTime) : "",
          endTime: endTime ? this.$dateUtil.dayToEndUnix(endTime) : "",
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data?.content ? res.data?.content : [];
            this.tableData = result;
            this.totalElements = res.data.totalElements;
            this.formInline.page.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    exportData() {
      this.formInline.page.pageSize = 50000;
      const [startTime, endTime] = this.formInline.time || ["", ""];
      this.$api.billingApi
        .exportApprovalList({
          ...this.formInline,
          startTime: startTime ? this.$dateUtil.dayToStartUnix(startTime) : "",
          endTime: endTime ? this.$dateUtil.dayToEndUnix(endTime) : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          this.formInline.page.pageSize = 20;
          // 导出Excel
          this.$exportExcel(
            res,
            `已出数据_${this.$moment().format(
              "YYYY-MM-DD"
            )}_${this.$moment().unix()}.xlsx`
          );
        });
    },
    handleCurrentChange(p) {
      this.formInline.page.pageIndex = p;
      this.getList();
    },
    getAssetInfo(unionId) {
      this.$api.billingApi.getUnionAccountDetail({ unionId }).then((res) => {
        if (res.code === 0) {
          this.assetInfo = res.data ? res.data : {};
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    changeWithdrawStatus(val) {
      if (val === "") {
        this.formInline.withdrawProgressStatus = null;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  .overage {
    // padding: 10px 20px;
    // background: #f5f5f5;
    // border-radius: 8px;
  }
  .topBar {
    background: #f9f9f9;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;

    .bar {
      flex: 1;
      display: flex;
      justify-content: center;
      align-content: center;
      padding: 20px 0px;
      border-right: 1px solid #efefef;
      &:last-of-type {
        border-right: none;
      }
      &:nth-of-type(2) {
        & > .asset_bd {
          & > div {
            color: #17c988;
          }
        }
      }
      .icon_wrap {
        width: 68px;
        height: 68px;
        text-align: center;
        line-height: 68px;
        background: #fff;
        border-radius: 100%;

        img {
          width: 68px;
          height: 68px;
        }
      }
      .asset_bd {
        padding-left: 20px;
        // flex: 1;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        & > span {
          font-size: 14px;
          color: #888;
        }
        & > em {
          font-size: 12px;
          color: #aaa;
        }
        & > div {
          font-size: 22px;
          color: #333;
        }
      }
    }
  }
  .page_footer {
    padding-top: 10px;
    // display: flex;
    // justify-content: space-between;
    // align-content: center;
  }
  .dataWrap {
    padding-right: 40px;
    width: 50vw;

    .dataItem {
      // padding-bottom: 12px;
      line-height: 30px;
      display: flex;
      justify-content: flex-start;

      & > span {
        flex: 1;
      }
    }
  }
}
</style>
