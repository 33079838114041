<template>
  <div class="unionList ground-white">
    <el-page-header
      @back="$router.back()"
      v-if="$choosePlantorm().platformType === 0"
    >
    </el-page-header>
    <br />
    <!-- {{ formInline }} -->
    <el-form :inline="true" :model="formInline">
      <!-- <el-form-item>
        <el-select
          v-model="formInline.gameId"
          clearable
          placeholder="汇总维度"
          v-if="$choosePlantorm().platformType === 1"
        >
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-input
          v-model="formInline.roleName"
          cleartable
          placeholder="游戏角色名称"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="formInline.gameAccountId"
          cleartable
          placeholder="游戏账号ID"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="formInline.orderNo"
          cleartable
          placeholder="订单号"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item>
        <el-select v-model="formInline.gameId" clearable placeholder="发货状态">
          <el-option label="发货成功" :value="0"> </el-option>
          <el-option label="发货失败" :value="1"> </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.pageIndex = 1;
            getList();
          "
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <!-- <p style="margin-bottom: 15px">
      充值总额：{{ totalData.totalChargeAmount }}
    </p> -->
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="orderNo"
        label="订单号"
        width="230"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameAccountId"
        label="游戏账号ID"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="gameName" label="游戏名称">
      </el-table-column>
      <el-table-column align="center" prop="gameServerName" label="区服">
      </el-table-column>
      <el-table-column align="center" prop="gameRoleName" label="角色名字">
      </el-table-column>
      <el-table-column
        align="center"
        prop="orderCreateTime"
        label="订单创建时间"
      >
      </el-table-column>
      <el-table-column align="center" prop="payFinishTime" label="支付成功时间">
      </el-table-column>
      <el-table-column align="center" prop="opIp" label="充值IP">
        <template slot-scope="scope">
          <div>
            <div>{{ scope.row.opIp }}</div>
            <div>{{ scope.row.ipArea }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="totalPayAmount" label="充值金额">
      </el-table-column>
      <el-table-column
        align="center"
        prop="inviterAdminAccountName"
        label="推广员"
      >
      </el-table-column>
      <el-table-column align="center" prop="deliveryStatus" label="发货状态">
        <template>
          <span>发送失败</span>
        </template>
      </el-table-column>
      <!--  -->
      <el-table-column align="center" prop="" label="操作" width="80">
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="handleClick(scope.row)"
            >重新发货</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.pageIndex"
      @current-change="handleCurrentChange"
      :total="totalElements"
    >
    </el-pagination>
  </div>
</template>
<script>
import moment from "moment";
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      value1: "",
      rechargeStatusList: [
        { name: "支付失败", id: 200 },
        { name: "交货中", id: 300 },
        { name: "已完成", id: 400 },
        { name: "已取消", id: 500 },
      ],
      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      getGameListData: [],
      staffListData: [],
      dialogFormVisible: false,
      editDialogShow: false,
      loading: false,
      editUnionId: "",
      formInline: {
        roleName: "",
        gameAccountId: null,
        inviterAccountName: "",
        orderNo: "",
        deliveryStatus: null,
        pageIndex: 1,
        pageSize: 20,
      },
      totalElements: 0,
      queryForm: {
        serviceTime: "",
        gameId: null,
        capacity: 1,
        serviceName: "",
      },
      tableData: [],
      totalData: {},
    };
  },
  mounted() {
    // this.getPermission();
    // this.getGameList();
    this.getList();
    // this.getListSum();
  },
  methods: {
    exportList() {
      this.formInline.pageSize = 50000;
      // 获取公会列表
      this.$api.organizationApi
        .queryAreaPlan({
          ...this.formInline,
          recentLoginStartTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          recentLoginEndTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
          rechargeStartTime: this.formInline.time1
            ? this.$dateUtil.dayToStartUnix(this.formInline.time1[0])
            : "",
          rechargeEndTime: this.formInline.time1
            ? this.$dateUtil.dayToEndUnix(this.formInline.time1[1])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          this.formInline.pageSize = 20;
          // 导出Excel
          this.$exportExcel(
            res,
            `已出数据_${this.$moment().format(
              "YYYY-MM-DD"
            )}_${this.$moment().unix()}.xlsx`
          );
        });
    },
    getListSum() {
      // 获取公会列表
      this.$api.support
        .queryAbnormalOrder({
          ...this.formInline,
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.totalData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      // 获取公会列表
      this.$api.supportApi
        .queryAbnormalOrder({
          ...this.formInline,
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result.map((item) => {
              return {
                ...item,
                orderCreateTime: moment(item.orderCreateTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                payFinishTime: moment(item.payFinishTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
              };
            });
            this.totalElements = res.data.totalElements;
            this.formInline.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.gameApi
        .querySubPackage({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryStaff({
            nickName: query,
          })
          .then((res) => {
            console.log("getGameList", res);
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.staffListData = result;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.staffListData = [];
      }
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
    handleClick(val) {
      this.$confirm("确定要重新发货吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        const { orderNo = "" } = val;
        this.$api.supportApi.redeliveryOrder({ orderNo }).then((res) => {
          if (res.code === 0) {
            this.$message.success("操作成功！");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  /deep/ .el-radio-button--mini .el-radio-button__inner {
    padding: 7px 15px;
    font-size: 12px;
    border-radius: 0;
    margin: 5px;
    border: 1px #dcdfe6 solid !important;
    border-radius: 4px !important;
    box-shadow: none !important;
  }
  .mr15 {
    margin-right: 15px;
  }
}
</style>
