export default [
  // 【结算详情】模块
  {
    path: "/billing/bm/settle",
    name: "后管结算详情1",
    component: () => import("@/views/billing/bm/settleFirst"),
  },
  {
    path: "/billing/bm/settle/union",
    name: "后管结算详情2",
    component: () => import("@/views/billing/bm/settleSecond"),
  },
  {
    path: "/billing/bm/settle/detail",
    name: "后管结算详情3",
    component: () => import("@/views/billing/bm/settleThird"),
  },
  {
    path: "/billing/bm/settle/bill",
    name: "后管结算详情4",
    component: () => import("@/views/billing/bm/settleFourth"),
  },
  {
    path: "/billing/union/settle/union",
    name: "后管结算详情2",
    component: () => import("@/views/billing/union/settleSecond"),
  },
  {
    path: "/billing/union/settle/detail",
    name: "后管结算详情3",
    component: () => import("@/views/billing/union/settleThird"),
  },
  {
    path: "/billing/union/settle/bill",
    name: "后管结算详情4",
    component: () => import("@/views/billing/union/settleFourth"),
  },
  // 【充值申请】模块
  {
    path: "/billing/bm/recharge/apply",
    name: "后管充值申请",
    component: () => import("@/views/billing/bm/rechargeFirst"),
  },
  {
    path: "/billing/bm/recharge/apply/union",
    name: "后管充值申请",
    component: () => import("@/views/billing/bm/rechargeSecond"),
  },
  {
    path: "/billing/bm/recharge/detail",
    name: "公会充值详情",
    component: () => import("@/views/billing/bm/rechargeThird"),
  },
  // 调账记录模块
  {
    path: "/billing/bm/checking/record",
    name: "调账记录",
    component: () => import("@/views/billing/bm/adjustmentFirst"),
  },
  {
    path: "/billing/bm/checking/record/detail",
    name: "调账记录",
    component: () => import("@/views/billing/bm/adjustmentSecond"),
  },
  // 公会【结算详情】模块
  {
    path: "/billing/union/settle/union",
    name: "公会结算详情1",
    component: () => import("@/views/billing/union/settleSecond"),
  },
  {
    path: "/billing/union/settle/detail",
    name: "公会结算详情2",
    component: () => import("@/views/billing/union/settleThird"),
  },
  {
    path: "/billing/union/settle/bill",
    name: "公会结算详情3",
    component: () => import("@/views/billing/union/settleFourth"),
  },
  // 【充值申请】模块
  {
    path: "/billing/union/recharge/apply",
    name: "公会充值申请",
    component: () => import("@/views/billing/union/rechargeSecond"),
  },
  {
    path: "/billing/union/recharge/detail",
    name: "公会充值详情",
    component: () => import("@/views/billing/union/rechargeThird"),
  },
  // 调账记录
  {
    path: "/billing/union/checking/record",
    name: "调账记录",
    component: () => import("@/views/billing/union/adjustmentSecond"),
  },
  // 【余额提现】模块
  {
    path: "/billing/bm/cash",
    name: "余额提现",
    component: () => import("@/views/billing/bm/balanceCash"),
  },
  {
    path: "/billing/bm/cash/detail",
    name: "余额提现详情",
    component: () => import("@/views/billing/bm/balanceCashSecond"),
  },
  {
    path: "/billing/union/withDrawApproval",
    name: "公会提现审批",
    component: () => import("@/views/billing/bm/withDrawApproval"),
  },
  {
    path: "/billing/union/withDrawHandle",
    name: "提现转账操作",
    component: () => import("@/views/billing/bm/withDrawHandle"),
  },
  // 【对账账单】模块
  {
    path: "/billing/bm/checking/detail",
    name: "对账账单",
    component: () => import("@/views/billing/bm/checkingDetail"),
  },
  // 【充值角色】模块
  {
    path: "/billing/bm/recharge/role",
    name: "充值角色",
    component: () => import("@/views/billing/bm/cashRoleFirst"),
  },
  {
    path: "/billing/bm/recharge/role/detail",
    name: "充值角色",
    component: () => import("@/views/billing/bm/cashRoleSecond"),
  },
  {
    path: "/billing/bm/recharge/role/details",
    name: "充值角色",
    component: () => import("@/views/billing/bm/cashRoleThird"),
  },
  // 虚拟额度
  {
    path: "/billing/bm/virtualQuota",
    name: "虚拟额度",
    component: () => import("@/views/billing/bm/virtualQuota"),
  },
  {
    path: "/billing/union/recharge/role",
    name: "充值角色",
    component: () => import("@/views/billing/union/cashRoleSecond"),
  },
  {
    path: "/billing/union/recharge/role/detail",
    name: "充值角色",
    component: () => import("@/views/billing/union/cashRoleThird"),
  },
  // 公会端余额提现
  {
    path: "/billing/union/cash",
    name: "充值角色",
    component: () => import("@/views/billing/union/balanceCash"),
  },
];
