<template>
  <el-dialog
    title="记录查询"
    :visible="true"
    class="Record"
    width="1200px"
    :before-close="() => $emit('close')"
  >
    <!-- 查询条件 -->
    <el-form ref="formdata" :model="formdata" inline size="small">
      <el-form-item label="账号ID:" prop="accountId">
        <el-input
          v-model="formdata.accountId"
          placeholder="请输入账号ID"
          @input="(val) => (formdata.accountId = val.replace(/[^\d]/, ''))"
          clearable
          @change="search"
        />
      </el-form-item>
      <el-form-item label="IP:" prop="ip">
        <el-input
          v-model="formdata.ip"
          placeholder="请输入ip"
          clearable
          @change="search"
        />
      </el-form-item>
      <el-form-item label="设备号:" prop="deviceId">
        <el-input
          v-model="formdata.deviceId"
          placeholder="请输入设备号"
          clearable
          @change="search"
        />
      </el-form-item>
      <el-form-item>
        <el-button plain icon="el-icon-refresh-left" @click="reset"
          >重置
        </el-button>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >查询
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-copy-document"
          :data-clipboard-text="copyInfo"
          class="btn1"
          @click="copy"
        >
          复制
        </el-button>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table border v-loading="loading" :data="tabledata" height="480px">
      <el-table-column prop="accountId" label="账户ID" />
      <el-table-column prop="opDevice" label="设备号" />
      <el-table-column prop="ip" label="ip" />
      <el-table-column prop="loginTime" label="记录时间" />
    </el-table>

    <!-- 分页器 -->
    <el-pagination
      :page-size="pagination.pageSize"
      :current-page="pagination.pageIndex"
      :total="pagination.total"
      @current-change="handleCurrentChange"
    />
  </el-dialog>
</template>

<script>
import Clipboard from "clipboard";

export default {
  data() {
    return {
      formdata: {
        accountId: "",
        ip: "",
        deviceId: "",
      },
      tabledata: [],
      pagination: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
    };
  },
  mounted() {
    this.search();
  },
  computed: {
    copyInfo: function () {
      let text = "";
      this.tabledata.forEach((item) => {
        let arr = [];
        for (let key in item) {
          arr.push(key + "=" + item[key]);
        }
        text += arr.join(",") + "\n";
      });
      return text;
    },
  },
  methods: {
    handleCurrentChange(pageIndex) {
      this.pagination.pageIndex = pageIndex;
      this.getData();
    },
    getData() {
      if (this.loading) return;
      this.loading = true;
      const params = {
        ...this.formdata,
        pageIndex: this.pagination.pageIndex,
        pageSize: this.pagination.pageSize,
      };
      this.$api.supportApi
        .getAccountLoginRecord(params)
        .then((res = {}) => {
          const { code, data = {}, msg } = res;
          if (code === 0) {
            const { content = [], totalElements = 0 } = data;
            this.tabledata = content || [];
            this.pagination.total = totalElements;
          } else {
            msg && this.$message.error(msg);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.pagination.pageIndex = 1;
      this.getData();
    },
    reset() {
      this.$refs.formdata.resetFields();
      this.search();
    },
    copy() {
      // const aLink = document.createElement("a");
      // aLink.setAttribute("href", this.appUrl);
      // document.body.appendChild(aLink);
      // aLink.click();
      // document.body.removeChild(aLink);

      var clipboard = new Clipboard(".btn1");
      clipboard.on("success", (e) => {
        console.log("复制成功", e);
        // 释放内存
        clipboard.destroy();
        // 复制成功

        this.$message.success("复制成功");
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        console.log("该浏览器不支持自动复制", e);
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-dialog__body {
  padding: 20px;
}

::v-deep .el-table__header th {
  background: #f7f9fd;
  font-weight: bold;
  height: 40px;
  padding: 0;
  color: #95a3bb;
}

.el-pagination {
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
}
</style>
