export default [
  // 后管端
  {
    path: "/task/bm/config",
    name: "任务配置",
    component: () => import("@/views/task/bmConfig"),
  },
  {
    path: "/task/bm/config/detail",
    name: "任务配置详情",
    component: () => import("@/views/task/bmConfigDetail"),
  },
  // 公会端
  {
    path: "/task/union/config",
    name: "人员结构union",
    component: () => import("@/views/task/unionConfigDetail"),
  },
];
