export default [
  // 后管【结算详情】模块
  {
    path: "/protocol/privacy",
    name: "用户隐私协议",
    component: () => import("@/views/protocol/privacy"),
  },
  {
    path: "/protocol/service",
    name: "用户服务协议",
    component: () => import("@/views/protocol/service"),
  },
];
