var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav"},[_c('div',{staticClass:"title flex"}),_c('el-menu',{staticClass:"el-menu-vertical",attrs:{"default-active":_vm.$route.path,"background-color":"#001529","text-color":"#fff","active-text-color":"#ffd04b","unique-opened":true,"router":true,"collapse":_vm.isFold},on:{"open":_vm.handleOpen,"close":_vm.handleClose}},_vm._l((_vm.slideData),function(item){return _c('div',{key:item.id},[(!item.children || item.children.length === 0)?_c('el-menu-item',{attrs:{"index":item.path,"route":{
          path: item.path,
          query: {
            pageId: item.id,
          },
        }}},[_c('i',{staticClass:"el-icon-setting"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(item.name))])]):_c('el-submenu',{attrs:{"index":`${item.id}`}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-setting"}),_c('span',[_vm._v(_vm._s(item.name))])]),_c('el-menu-item-group',_vm._l((item.children),function(i){return _c('el-menu-item',{key:i.id,attrs:{"index":i.path,"route":{
              path: i.path,
              query: {
                pageId: i.id,
              },
            }}},[_vm._v(_vm._s(i.name))])}),1)],2)],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }