export default [
  // ----------------   超管    ----------------
  // 【数据详情】模块
  {
    path: "/data/bm/summary",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/dataSummaryFirst"),
  },
  {
    path: "/data/bm/summary/detail",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/dataSummarySecond"),
  },
  {
    path: "/data/union/summary",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/union/dataSummarySecond"),
  },
  // 【角色区服统计】模块
  {
    path: "/data/bm/area/role",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/areaRoleFirst"),
  },
  {
    path: "/data/bm/area/role/union",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/areaRoleSecond"),
  },
  {
    path: "/data/bm/area/role/union/detail",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/areaRoleThird"),
  },
  {
    path: "/data/union/area/role",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/union/areaRoleThird"),
  },
  // 游戏角色
  {
    path: "/data/bm/game/role",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/gameRoleFirst"),
  },
  {
    path: "/data/bm/game/role/detail",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/gameRoleSecond"),
  },
  {
    path: "/data/union/game/role",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/union/gameRoleSecond"),
  },
  // 有效角色
  {
    path: "/data/bm/role/rule",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/ruleRoleFirst"),
  },
  {
    path: "/data/bm/role/rule/detail",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/ruleRoleSecond"),
  },
  {
    path: "/data/union/role/rule",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/union/ruleRoleSecond"),
  },
  // 游戏账号
  {
    path: "/data/bm/game/account",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/gameAccountFirst"),
  },
  {
    path: "/data/bm/game/account/detail",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/gameAccountSecond"),
  },
  {
    path: "/data/union/game/account",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/union/gameAccountSecond"),
  },
  // 充值明细
  {
    path: "/data/bm/recharge",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/unionRechargeFirst"),
  },
  {
    path: "/data/bm/recharge/detail",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/unionRechargeSecond"),
  },
  {
    path: "/data/union/recharge",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/union/unionRechargeSecond"),
  },
  // 任务汇总
  {
    path: "/data/bm/task",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/unionTaskFirst"),
  },
  {
    path: "/data/bm/task/detail",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/unionTaskSecond"),
  },
  {
    path: "/data/union/task",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/union/unionTaskSecond"),
  },
  // 带团角色
  {
    path: "/data/bm/commander",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/unionCommanderFirst"),
  },
  {
    path: "/data/bm/commander/detail",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/unionCommanderSecond"),
  },
  {
    path: "/data/bm/commander/detail/role",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/bm/unionCommanderThird"),
  },
  {
    path: "/data/union/commander",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/union/unionCommanderSecond"),
  },
  {
    path: "/data/union/commander/detail",
    name: "后管数据汇总bm",
    component: () => import("@/views/data/union/unionCommanderThird"),
  },
];
