<template>
  <div class="unionList ground-white">
    <el-form :inline="true" :model="formInline">
      <el-form-item label="角色名称">
        <el-input
          v-model="formInline.roleName"
          placeholder="请输入角色名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="分端角色筛选" v-if="false">
        <el-select v-model="formInline.roleType" placeholder="请选择">
          <el-option label="全部" :value="null"></el-option>
          <el-option label="管理端角色" :value="1"></el-option>
          <el-option label="公会端角色" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.pageIndex = 1;
            getList();
          "
          >查询</el-button
        >
        <el-button
          type="primary"
          v-if="1"
          @click="
            dialogFormVisible = true;
            editDialogShow = false;
            resetForm('queryForm');
            queryForm.roleType = 2;
            clearSelected();
          "
          >新建角色</el-button
        >
      </el-form-item>
    </el-form>

    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column align="center" prop="roleName" label="角色名称">
      </el-table-column>
      <!-- <el-table-column
        align="center"
        prop="roleType"
        label="分端角色"
        width="150"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{ scope.row.roleType == 1 ? "管理端" : "公会端" }}
        </template>
      </el-table-column> -->
      <el-table-column align="center" prop="status" label="状态" width="70">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" size="small" type="success"
            >启用</el-tag
          >
          <el-tag v-else size="small" type="danger">禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="memberNum"
        label="成员数量"
        width="90"
      >
      </el-table-column>
      <!-- <el-table-column
        align="center"
        prop="openTime"
        label="角色权限"
        width="200"
      >
      </el-table-column> -->
      <el-table-column align="center" prop="roleDesc" label="角色描述">
      </el-table-column>
      <el-table-column align="center" prop="" label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="handleClick(scope.row)"
            >人员列表</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="batchFrozenMember(scope.row, 1)"
            >批量冻结人员</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="batchFrozenMember(scope.row, 0)"
            >批量解冻人员</el-button
          >
          <el-button
            v-if="permissionCode.includes('update')"
            type="text"
            size="small"
            @click="openEditDialog(scope.row)"
            :disabled="scope.row.roleType !== 3"
            >编辑</el-button
          >
          <el-button
            v-if="permissionCode.includes('status')"
            type="text"
            size="small"
            :disabled="scope.row.roleType !== 3"
            @click="changeStatus(scope.row)"
            >{{ scope.row.status === 0 ? "禁用" : "启用" }}</el-button
          >
          <el-button
            v-if="permissionCode.includes('password')"
            type="text"
            size="small"
            @click="resetPassWord(scope.row)"
            >重置密码</el-button
          >
          <el-button
            v-if="0"
            type="text"
            size="small"
            @click="deleteItem(scope.row)"
            :disabled="scope.row.roleType !== 3"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.totalElements"
    >
    </el-pagination>

    <!-- dialog -->
    <el-dialog
      :title="editDialogShow ? '编辑角色' : '新建角色'"
      :visible.sync="dialogFormVisible"
      width="420px"
    >
      <!-- {{ queryForm }} -->
      <!-- <button @click="resetForm('queryForm')">reset</button> -->
      <el-form :model="queryForm" status-icon ref="queryForm" :inline="true">
        <el-form-item
          label="角色名称"
          message="hahahahah "
          required
          prop="roleName"
        >
          <el-input v-model="queryForm.roleName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" required prop="roleDesc">
          <el-input
            v-model="queryForm.roleDesc"
            type="textarea"
            maxlength="100"
            show-word-limit
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <br />
        <el-form-item label="角色位置" v-if="false" required prop="roleType">
          <el-radio v-model="queryForm.roleType" :label="1">管理后台</el-radio>
          <el-radio v-model="queryForm.roleType" :label="2">公会</el-radio>
        </el-form-item>
        <br />
        <div class="tree_wrap">
          <el-form-item label="权限列表" required1 prop="resourceIdList">
            <div style="display: flex; flex-wrap: wrap">
              <el-tree
                :ref="`tree`"
                :data="treeData"
                :props="defaultProps"
                node-key="id"
                :check-strictly="true"
                default-expand-all
                :default-checked-keys="queryForm.resourceIdList"
                @check="handleCheck"
                show-checkbox
                @node-click="handleNodeClick"
              ></el-tree>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="editDialogShow" type="primary" @click="updateUnion"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="addUnion">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      permissionCode: ["update", "status", "delete"],
      cusChecked: [],
      dialogFormVisible: false,
      editDialogShow: false,
      editUnionId: "",
      formInlineRule: {
        // roleName: [{ required: true, message: "不能为空", trigger: "blur" }],
      },
      formInline: {
        // unionId: "",
        unionName: "",
        status: "",
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      queryForm: {
        roleName: "",
        roleDesc: "",
        roleType: 2,
        resourceIdList: [],
        disabledList: [],
      },
      tableData: [],
      unionTreeData: [
        {
          label: "我是云鼎（11）",
          id: "1",
          children: [
            {
              label: "云鼎部门1（2）",
              id: 11,
              children: [
                {
                  id: 111,
                  label: " 1-1-1",
                  disabled: true,
                },
                {
                  id: 12211,
                  label: " 1-1-1",
                  disabled: true,
                },
              ],
            },
            {
              label: "云鼎部门2（4）",
              id: 12,
              children: [
                {
                  label: "1-2-2",
                  id: 22222,
                  disabled: true,
                },
                {
                  label: "1-2-2",
                  id: 22255522,
                },
              ],
            },
          ],
        },
      ],
      adminTreeData: [
        {
          label: "我是云鼎（11）",
          id: "1312321321321",
        },
      ],
      // treeData: [],

      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  mounted() {
    this.getPermissionTree(1);
    this.getPermissionTree(2);
    this.getList();
    this.queryForm = {
      roleName: "",
      roleDesc: "",
      roleType: 2,
      resourceIdList: [],
    };
  },
  computed: {
    treeData: function () {
      return this.queryForm.roleType === 1
        ? this.adminTreeData
        : this.unionTreeData;
    },
  },
  methods: {
    getList() {
      // 获取公会列表
      this.$api.organizationApi.queryRole(this.formInline).then((res) => {
        console.log("queryRole", res);

        const result = res.data.content ? res.data.content : [];

        this.tableData = result;
        this.formInline.totalElements = res.data.totalElements;
        this.formInline.pageIndex = res.data.pageIndex;
      });
    },
    handleNodeClick(data, node) {
      console.log(data);
      console.log(node);
    },
    getPermissionTree(pType) {
      console.log(pType);
      this.$api.organizationApi
        .getPermissionTree({
          resourceOwner: pType,
        })
        .then((res) => {
          console.log("getPermissionTree", res);
          if (pType === 1) {
            this.adminTreeData = res.data;
          } else {
            this.unionTreeData = res.data;
          }
        });
    },
    /* 清空选中样式 */
    clearSelected() {
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys([]);
      });
    },
    resetUnionPassword() {
      this.$api.organizationApi
        .resetPassword({
          id: this.editUnionId,
        })
        .then((res) => {
          console.log("resetPassword", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    deleteUnion(data) {
      this.$api.organizationApi
        .deleteRole({
          id: data.id,
          isDelete: 1,
        })
        .then((res) => {
          console.log("deleteUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    changeUnionStatus(data) {
      this.$api.organizationApi
        .changeRoleStatus({
          id: data.id,
          status: data.status === 0 ? 1 : 0,
        })
        .then((res) => {
          console.log("changeUnionStatus", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    updateUnion() {
      this.$api.organizationApi
        .updateRole({ ...this.queryForm, id: this.editUnionId })
        .then((res) => {
          console.log("updateRole", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.dialogFormVisible = false;
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    addUnion() {
      this.$api.organizationApi.createRole(this.queryForm).then((res) => {
        console.log("createUnion", res);
        if (res.code === 0) {
          this.dialogFormVisible = false;
          this.$message.success("操作成功!");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
    onSubmit() {
      console.log("submit!");
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/organization/union/bmrole/list",
        query: {
          roleId: e.id,
          roleType: e.roleType,
        },
      });
    },
    resetPassWord(pId) {
      this.$confirm("重置密码:默认密码123456", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 重置密码
        this.editUnionId = pId.id;
        this.resetUnionPassword();
      });
    },
    changeStatus(pId) {
      this.$confirm("确定要更改状态吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 改变状态
        this.changeUnionStatus(pId);
      });
    },
    deleteItem(pId) {
      this.$confirm("确定要删除数据吗？", "", {
        type: "error",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 删除
        this.deleteUnion(pId);
      });
    },
    openEditDialog(e) {
      this.editDialogShow = true;
      this.dialogFormVisible = true;
      this.editUnionId = e.id;
      setTimeout(() => {
        this.queryForm.roleType = e.roleType;
        this.queryForm.resourceIdList = e.resourceIdList;
        this.queryForm.roleDesc = e.roleDesc;
        this.queryForm.roleName = e.roleName;
        this.$refs.tree.setCheckedKeys(
          this.queryForm.resourceIdList ? this.queryForm.resourceIdList : []
        );
      }, 1);
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },

    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
    handleCheck(currentNode, treeStatus) {
      /**
       * @des 根据父元素的勾选或取消勾选，将所有子级处理为选择或非选中状态
       * @param { node: Object }  当前节点
       * @param { status: Boolean } （true ： 处理为勾选状态 ； false： 处理非选中）
       */
      const setChildStatus = (node, status) => {
        /* 这里的 id children 也可以是其它字段，根据实际的业务更改 */
        this.$refs.tree.setChecked(node.id, status);
        if (node.children) {
          /* 循环递归处理子节点 */
          console.log(node.children);
          for (let i = 0; i < node.children.length; i++) {
            // if (node.children[i].disabled === false) {
            setChildStatus(node.children[i], status);
            // }
          }
        }
      };
      /* 设置父节点为选中状态 */
      const setParentStatus = (nodeObj) => {
        /* 拿到tree组件中的node,使用该方法的原因是第一次传入的 node 没有 parent */
        const node = this.$refs.tree.getNode(nodeObj);
        if (node.parent.key) {
          this.$refs.tree.setChecked(node.parent, true);
          setParentStatus(node.parent);
        }
      };
      /*设置父节点为取消状态*/
      const offParentStatus = (nodeObj) => {
        const node = this.$refs.tree.getNode(nodeObj);
        if (node.parent.key) {
          if (
            node.parent.childNodes.every((item) => {
              return item.checked == false;
            }) == true
          ) {
            this.$refs.tree.setChecked(node.parent, false);
            offParentStatus(node.parent);
          }
        }
      };

      /* 判断当前点击是选中还是取消选中操作 */
      if (treeStatus.checkedKeys.includes(currentNode.id)) {
        /*当前节点选中,*/
        setParentStatus(currentNode);
        /*当前节点选中，子级全部选中*/
        // setChildStatus(currentNode, true);
      } else {
        /* 当前节点取消选中, 判断父级的所有子级是否全部取消,如果全部取消，则父级也取消*/
        offParentStatus(currentNode);
        if (currentNode.children) {
          /*当前节点取消选中时，当前节点的子级全部取消*/
          setChildStatus(currentNode, false);
        }
      }
      this.queryForm.resourceIdList = [...this.$refs.tree.getCheckedKeys()];
    },
    batchFrozenMember(val, status) {
      const typeDesc = ["解冻", "冻结"];
      this.$confirm(`确定要批量${typeDesc[status]}人员吗？`, "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        const { id: roleId } = val;
        this.$api.organizationApi
          .batchFrozenMember({ roleId, status })
          .then((res) => {
            if (res.code === 0) {
              this.$message.success("操作成功!");
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .mr15 {
    margin-right: 15px;
  }
  .tree_wrap {
    height: 380px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
