<template>
  <div class="game-apply">
    <!-- top -->
    <!-- <div class="top-container ground-white flex">
      <div class="title">今日开服</div>
      <div class="box flex">
        <div class="item" v-for="item in 4" :key="item">
          <span>15:00</span>
          <span style="margin: 0 6px">小鱼传奇</span>
          <span>权纵36服</span>
        </div>
      </div>
    </div> -->
    <!-- bottom -->
    <div class="bottom-container ground-white">
      <el-form :inline="true" :model="formInline">
        <el-form-item label="区服类型">
          <el-input
            v-model="formInline.gameArea"
            placeholder="区服类型"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="formInline.applyStatus" placeholder="状态">
            <el-option :key="null" :label="'全部'" :value="null"> </el-option>
            <el-option :key="'1'" :label="'未申请'" :value="4"> </el-option>
            <el-option :key="'2'" :label="'待审核'" :value="0"> </el-option>
            <el-option :key="'3'" :label="'已申请'" :value="1"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="gameName" label="游戏名称">
          <el-input
            v-model="formInline.gameName"
            placeholder="游戏名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="
              formInline.pageIndex = 1;
              getList();
            "
            >查询</el-button
          >
        </el-form-item>
      </el-form>

      <div class="list" v-loading="$store.state.loadingStatus">
        <div
          v-if="tableData.length === 0"
          style="text-align: center; font-size: 16px; margin-top: 20px"
        >
          暂无数据
        </div>
        <div
          :class="{ item: 1, flex: 1, itemactive: activeItem === index }"
          v-for="(item, index) in tableData"
          :key="item.id"
          @mouseenter="activeItem = index"
          @mouseleave="activeItem = false"
        >
          <img :src="item.gamePic" class="icon" alt="" />
          <div style="width: 20%">
            <p style="font-size: 16px; font-weight: bold">
              <span
                :style="{
                  fontSize: '14px',
                  marginBottom: '3px',
                  textAlign: 'center',
                  cursor: 'pointer',
                }"
                class="activeTitle"
                @click="goDetail(item.id)"
                >{{ item.gameName }}</span
              ><span class="detail"
                ><el-button type="text" @click="goDetail(item.id)"
                  >详情</el-button
                ></span
              ><img src="@/assets/img/icon-s.png" class="iconicon" />
            </p>
            <p style="color: #999" class="elp">{{ item.gameDesc }}</p>
          </div>
          <div style="width: 20%">
            <p style="color: #999">
              <span style="color: #999" class="elp">
                游戏类型: <em style="color: #666">{{ item.gameType }}</em></span
              >
            </p>
            <p style="color: #999">
              <span style="color: #999" class="elp"
                >核心玩法:
                <em style="color: #666">{{ item.playType }}</em></span
              >
            </p>
          </div>
          <div style="width: 15%">
            <p style="color: #999">
              <span style="color: #999" class="elp"
                >游戏区服:
                <em style="color: #666">{{ item.gameArea }}</em></span
              >
            </p>
            <p style="color: #999">
              <span style="color: #999" class="elp">
                游戏题材:
                <em style="color: #666">{{ item.gameTheme }}</em></span
              >
            </p>
          </div>
          <div style="width: 12%">
            <p v-if="item.iosRatio">
              <img
                src="@/assets/img/icon-apple.png"
                style="width: 23px; height: 23px; vertical-align: bottom"
              />
              分成:
              <span>{{ item.iosRatio + "%" }}</span>
            </p>
            <p v-if="item.androidRatio">
              <img
                src="@/assets/img/icon-android.png"
                style="width: 23px; height: 23px; vertical-align: bottom"
              />
              分成:
              <span>{{ item.androidRatio + "%" }}</span>
            </p>
          </div>
          <div style="flex: 1; text-align: center">
            <el-button
              type="info"
              size="small"
              disabled
              v-if="item.applyStatus === 0"
              style="width: 85px"
              >待审核</el-button
            >
            <el-button
              type="success"
              size="small"
              disabled
              v-else-if="item.applyStatus === 1"
              style="width: 85px"
              >已申请</el-button
            >
            <el-button
              type="normal"
              size="small"
              v-else
              @click.stop="applyGame(item.id)"
              style="width: 85px"
              >申请</el-button
            >
          </div>
        </div>
      </div>
      <p style="width: 100; color: #999; text-align: right">
        注意：当未申请的游戏产生充值时，将以游戏的基础分成比例进行分成
      </p>
      <!-- {{ formInline }} -->
      <el-pagination
        style="float: right; margin-top: 8px"
        layout="prev, pager, next, jumper"
        :page-size="formInline.pageSize"
        :current-page.sync="formInline.pageIndex"
        @current-change="handleCurrentChange"
        :total="formInline.totalElements"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
// null 未申请  0申请中 1申请成功 2申请失败
export default {
  name: "loginPage",
  data() {
    return {
      activeItem: false,
      imageUrl: "",
      dialogFormVisible: false,
      activeName: "first",
      uploadForm: {},
      formInline: {
        gameName: "",
        applyStatus: null,
        gameArea: null,
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      permissionForm: {},
      tableData: [],
      rules: {
        accountName: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        accountPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
      },
      gameListData: [],
    };
  },
  mounted() {
    this.getList();
    this.getGameList();
  },
  methods: {
    applyGame(id) {
      this.$api.gameApi
        .applyUnionGame({
          gameId: id,
        })
        .then((res) => {
          console.log("applyUnionGame", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.gameApi
        .queryGameList({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.gameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      this.$api.gameApi
        .queryPackage({
          ...this.formInline,
        })
        .then((res) => {
          console.log("queryPackage", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.totalElements = res.data.totalElements;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    goDetail(id) {
      this.$router.push({
        path: "/game/detail",
        query: {
          id: id,
        },
      });
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.login();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    onSubmit() {},
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  .elp {
    color: #666;
    width: 100%;
    color: rgb(102, 102, 102);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  .top-container {
    margin-bottom: 8px;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #255ec0;
      margin-left: 24px;
      margin-right: 40px;
    }
    .box {
      flex: 1;

      .item {
        flex: 1;
        text-align: center;
        font-size: 15px;
      }
    }
  }
  .bottom-container {
    .list {
      .iconicon {
        width: 20px;
        height: 20px;
        vertical-align: text-top;
        margin-left: 6px;
      }
      margin-bottom: 60px;
      .itemactive {
        .activeTitle {
          font-weight: bold;
          font-size: 16px !important;
        }
        .detail {
          visibility: visible !important;
        }
      }
      .item {
        .detail {
          visibility: hidden;
          margin-left: 3px;
        }
        &:hover {
          background: #f5f7fa;
        }
        width: 100%;
        height: 71px;
        border-top: 1px #eee solid;
        align-items: center;
        &:last-child {
          border-bottom: 1px #eee solid;
        }
        .icon {
          width: 50px;
          height: 50px;
          margin: 0 20px 0 12px;
          border-radius: 6px;
        }
      }
    }
  }
}
</style>
