<template>
  <el-popover
    placement="top-start"
    title="选择标记"
    width="100"
    trigger="hover"
    :disabled="!hasPermission"
  >
    <div class="role_bd">
      <span
        v-for="(item, i) in markColor"
        :key="item"
        @click="selectMarkColor(rowData, i, type)"
      ></span>
    </div>
    <div class="role_mark" slot="reference">
      <i
        :class="
          !!colorMark && colorMark !== 4
            ? 'role_mark_icon  no_line'
            : 'role_mark_icon'
        "
        :style="colorMark ? `borderColor: ${markColor[colorMark - 1]}` : ''"
      ></i>
      <span>{{ showData }}</span>
    </div>
  </el-popover>
</template>
<script>
export default {
  props: [
    "rowData",
    "colorMark",
    "showData",
    "type",
    "hasPermission",
    "getList",
  ],
  data() {
    return {
      markColor: ["#fb5353", "#f49207", "#888", "#fff"],
    };
  },
  mounted() {
    console.log("isPermission", this.hasPermission);
  },
  methods: {
    selectMarkColor(val, i, type) {
      const methods = {
        role: "markRoleColor",
        account: "markAccountColor",
      };
      const params = {
        role: { gameRoleId: val.gameRoleId, colorMark: i + 1 },
        account: { gameAccountId: val.gameAccountId, colorMark: i + 1 },
      };

      this.$api.billingApi[methods[type]]({ ...params[type] }).then((res) => {
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.role_mark {
  // position: relative;
  // z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .role_mark_icon {
    position: relative;
    margin-right: 6px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    border: 3px solid #fff;

    &::before {
      position: absolute;
      top: -4px;
      left: -4px;
      content: "";
      width: 12px;
      height: 12px;
      display: block;
      border: 1px solid #b0b0b0;
      border-radius: 12px;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 4px;
      height: 4px;
      display: block;
      border-radius: 6px;
      border: 1px solid #b0b0b0;
    }
  }

  .role_mark_icon.no_line {
    &::before {
      position: absolute;
      top: -4px;
      left: -4px;
      content: "";
      width: 12px;
      height: 12px;
      display: block;
      // border: 1px solid #b0b0b0;
      border: none;
      border-radius: 12px;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 4px;
      height: 4px;
      display: block;
      border-radius: 6px;
      border: none;
      // border: 1px solid #b0b0b0;
    }
  }
  .opt_panel {
    position: absolute;
    z-index: 200;
    top: -30px;
    border: 1px solid #ddd;
    width: 240px;
    padding: 20px;
  }
}
.role_bd {
  display: flex;
  justify-content: flex-start;

  span {
    position: relative;
    margin-right: 10px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    border: 3px solid #d1d1d1;
    cursor: pointer;

    &:nth-of-type(1) {
      border-color: #fb5353;
    }
    &:nth-of-type(2) {
      border-color: #f49207;
    }
    &:nth-of-type(3) {
      border-color: #888;
    }
    &:nth-of-type(4) {
      border-color: #fff;
      &::before {
        position: absolute;
        top: -4px;
        left: -4px;
        content: "";
        width: 12px;
        height: 12px;
        display: block;
        border: 1px solid #b0b0b0;
        border-radius: 12px;
      }
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 4px;
        height: 4px;
        display: block;
        border-radius: 6px;
        border: 1px solid #b0b0b0;
      }
    }
  }
}
</style>
