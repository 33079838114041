import base from "../base"; // 导入接口域名列表
import axios from "@/utils/http"; // 导入http中创建的axios实例

const loginApi = {
  // 登录
  login(params) {
    return axios.post(`${base.sq}/account/login`, params);
  },
  // 退出登录
  logout(params) {
    return axios.post(`${base.sq}/account/loginOut`, params);
  },
  //获取导航
  tree(params) {
    return axios.post(`${base.sq}/resource/queryMenu`, params);
  },
  //获取页面基础配置
  treeDetail(params) {
    return axios.post(`${base.sq}/resource/queryMenuInterface`, params);
  },
  //修改账户信息
  updateAccountInfo(params) {
    return axios.post(`${base.sq}/account/updateInfo`, params);
  },
  //查询账户信息
  queryAccountInfo(params) {
    return axios.post(`${base.sq}/account/query`, params);
  },
  //修改密码
  updatePassword(params) {
    return axios.post(`${base.sq}/account/updatePassword`, params);
  },
};

export default loginApi;
