<template>
  <div class="unionList ground-white">
    <el-page-header
      @back="$router.back()"
      v-if="$choosePlantorm().platformType === 0"
    >
    </el-page-header>
    <br />
    <!-- {{ formInline }} -->
    <el-form :inline="true" :model="formInline">
      <el-form-item>
        <el-input
          v-model="formInline.roleName"
          placeholder="游戏角色名称"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="formInline.gameAccountId"
          placeholder="游戏账号ID"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="formInline.roleId"
          placeholder="游戏角色ID"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.pageIndex = 1;
            getList();
          "
          >查询</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="showPromotion = true"
          >推广查询
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="showRecord = true"
          >记录查询
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="accountId"
        label="账号ID"
        :show-overflow-tooltip="true"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameAccountId"
        label="游戏账号ID"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="gameName" label="游戏名称">
      </el-table-column>
      <el-table-column align="center" prop="gameServerName" label="区服">
      </el-table-column>
      <el-table-column align="center" prop="roleId" label="游戏角色ID">
      </el-table-column>
      <el-table-column align="center" prop="roleName" label="角色名字">
      </el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间">
      </el-table-column>
      <el-table-column align="center" prop="mobile" label="手机号码">
      </el-table-column>
      <el-table-column align="center" prop="idCard" label="身份证号码">
      </el-table-column>
      <el-table-column align="center" prop="opIp" label="创建IP">
        <template slot-scope="scope">
          <div>
            <div>{{ scope.row.opIp }}</div>
            <div>{{ scope.row.ipArea }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="inviterAdminAccountName"
        label="推广员"
      >
      </el-table-column>
      <el-table-column align="center" prop="frozenStatus" label="角色状态">
        <template slot-scope="scope">
          <span>{{ scope.row.frozenStatus === 1 ? "冻结" : "正常" }}</span>
        </template>
      </el-table-column>
      <!--  -->
      <el-table-column align="center" prop="" label="详情" width="180">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleClick(scope.row, 1)"
            >账号转移</el-button
          >
          <el-button
            v-if="scope.row.roleId"
            type="text"
            size="small"
            @click="handleClick(scope.row, 2)"
            >角色转移</el-button
          >
          <el-button
            v-if="scope.row.roleId && scope.row.frozenStatus === 0"
            type="text"
            size="small"
            @click="handleClickFrozen(scope.row, 1)"
            >冻结</el-button
          >
          <el-button
            v-if="scope.row.roleId && scope.row.frozenStatus === 1"
            type="text"
            size="small"
            @click="handleClickFrozen(scope.row, 0)"
            >解冻</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.pageIndex"
      @current-change="handleCurrentChange"
      :total="totalElements"
    >
    </el-pagination>
    <el-dialog
      title="账号转移"
      :visible.sync="isShowAccountTransform"
      width="600px"
      :before-close="handleCloseAccountTransform"
    >
      <el-form
        :inline="false"
        ref="transferForm"
        :model="transferForm"
        label-width="140px"
      >
        <el-form-item label="游戏账号ID：" prop="gameAccountId">
          <el-input
            v-model="transferForm.gameAccountId"
            clearable
            style="width: 198px"
            autocomplete="off"
            :disabled="Boolean(transferForm.gameAccountId)"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item
          label="转移推广人员："
          required
          prop="afterInviterAdminAccountId"
        >
          <el-select
            v-model="transferForm.afterInviterAdminAccountId"
            clearable
            placeholder="转移推广人员"
            filterable
          >
            <el-option
              v-for="item in accountListData"
              :key="item.accountId"
              :label="item.nickName"
              :value="item.accountId"
            />
          </el-select>
        </el-form-item> -->
        <InviterSelect
          propId="afterInviterAdminAccountId"
          v-model="transferForm.afterInviterAdminAccountId"
          :multiple="false"
          :options="inviterOptions"
          :disable-branch-nodes="true"
          :appendToBody="false"
        />
        <el-form-item label="转移原因：" prop="opDesc">
          <el-input
            type="textarea"
            v-model="transferForm.opDesc"
            autocomplete="off"
            style="width: 420px"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="isShowAccountTransform = false"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="handTransfer"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="角色转移"
      :visible.sync="isShowRoleTransform"
      width="800px"
      :before-close="handleCloseRoleTransform"
    >
      <el-form
        inline
        ref="transferForm"
        :model="transferForm"
        label-width="150px"
      >
        <el-form-item label="游戏：" prop="gameId">
          <el-form-item>
            <el-select
              v-model="transferForm.gameId"
              clearable
              filterable
              placeholder="请选择游戏"
              :disabled="Boolean(transferForm.gameId)"
              @change="changeGame"
            >
              <el-option
                v-for="item in getGameListData"
                :key="item.id"
                :label="item.gameName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item label="区服：" prop="gameServerId">
          <el-select
            v-model="transferForm.gameServerId"
            clearable
            placeholder="请选择区服"
            :disabled="Boolean(transferForm.gameServerId)"
          >
            <el-option
              v-for="item in subGameListData"
              :key="item.serviceId"
              :label="item.serviceName"
              :value="item.serviceId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色：" prop="roleName">
          <el-input
            v-model="transferForm.roleName"
            clearable
            style="width: 198px"
            autocomplete="off"
            :disabled="Boolean(transferForm.roleName)"
          ></el-input>
        </el-form-item>
        <InviterSelect
          propId="afterInviterAdminAccountId"
          v-model="transferForm.afterInviterAdminAccountId"
          :multiple="false"
          :options="inviterOptions"
          :disable-branch-nodes="true"
          :appendToBody="false"
        />
        <el-form-item label="转移原因：" prop="opDesc">
          <el-input
            type="textarea"
            v-model="transferForm.opDesc"
            autocomplete="off"
            style="width: 420px"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="isShowRoleTransform = false"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="handTransfer"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <Promotion v-if="showPromotion" @close="showPromotion = false" />
    <Record v-if="showRecord" @close="showRecord = false" />
  </div>
</template>
<script>
import { transferDataForTreeSelect } from "@/utils/util";
import Promotion from "./Promotion.vue";
import Record from "./Record.vue";

export default {
  data() {
    return {
      permissionCode: [],
      loading: false,
      formInline: {
        roleName: null,
        gameAccountId: null,
        pageIndex: 1,
        pageSize: 20,
      },
      totalElements: 0,
      tableData: [],
      currentAccount: {},
      isShowAccountTransform: false,
      transferForm: {
        type: null,
        gameId: null,
        gameRoleId: null,
        gameAccountId: null,
        gameArea: "",
        opDesc: "",
        beforeInviterAdminAccountId: null,
        afterInviterAdminAccountId: null,
        opAccountId: null,
      },
      accountListData: [],
      isShowRoleTransform: false,
      subGameListData: [],
      getGameListData: [],
      showPromotion: false,
      showRecord: false,
    };
  },
  watch: {
    isShowAccountTransform(v) {
      if (!v) {
        this.initForm();
      }
    },
    isShowRoleTransform(v) {
      if (!v) {
        this.initForm();
      }
    },
  },
  components: { Promotion, Record },
  mounted() {
    // this.getPermission();
    this.getGameList();
    this.getList();
    this.getInviterList();
    this.queryInviter();
  },
  methods: {
    initForm() {
      this.transferForm = {
        type: null,
        gameId: null,
        gameRoleId: null,
        gameAccountId: null,
        gameArea: "",
        opDesc: "",
        roleName: "",
        beforeInviterAdminAccountId: null,
        afterInviterAdminAccountId: null,
        opAccountId: null,
      };
    },
    getList() {
      // 获取公会列表
      this.$api.supportApi
        .queryAccountList({
          ...this.formInline,
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.totalElements = res.data.totalElements;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.supportApi
        .getGameList({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data ? res.data : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeGame(val) {
      console.log("val", val);

      this.$api.supportApi
        .getGameServiceList({ pageIndex: 1, pageSize: 1000, gameId: val })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data?.data ? res.data?.data : [];
            this.subGameListData = result;
          }
        });
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
    handleClick(item, type) {
      this.currentAccount = {};
      const { gameAccountId, gameId, gameServerName, roleName } = item;
      this.transferForm.gameAccountId = gameAccountId;
      this.transferForm.gameId = gameId;
      this.transferForm.roleName = roleName;
      if (this.transferForm.gameId) {
        this.transferForm.gameServerId = gameServerName;
      }
      if (type === 1) {
        this.isShowAccountTransform = true;
      }
      if (type === 2) {
        this.isShowRoleTransform = true;
      }
      this.currentAccount = { ...item, type };
    },
    handTransfer() {
      const {
        type,
        gameId,
        roleId,
        gameAccountId,
        serverId,
        inviterAdminAccountId,
      } = this.currentAccount;

      const params =
        type == 1
          ? {
              type,
              gameId: gameId,
              gameAccountId: gameAccountId,
              opDesc: this.transferForm.opDesc,
              beforeInviterAdminAccountId: inviterAdminAccountId,
              afterInviterAdminAccountId:
                this.transferForm.afterInviterAdminAccountId,
              opAccountId: JSON.parse(localStorage.getItem("userInfo"))
                .accountId,
            }
          : {
              type,
              gameId: gameId,
              gameRoleId: roleId,
              gameAccountId: gameAccountId,
              gameArea: serverId,
              opDesc: this.transferForm.opDesc,
              beforeInviterAdminAccountId: inviterAdminAccountId,
              afterInviterAdminAccountId:
                this.transferForm.afterInviterAdminAccountId,
              opAccountId: JSON.parse(localStorage.getItem("userInfo"))
                .accountId,
            };

      this.$api.supportApi.createGameTransfer({ ...params }).then((res) => {
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.isShowAccountTransform = false;
          this.isShowRoleTransform = false;

          this.$refs.transferForm.resetFields();
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleCloseAccountTransform() {
      console.log("12", 12);
      this.isShowAccountTransform = false;
    },
    handleCloseRoleTransform() {
      this.isShowRoleTransform = false;
    },
    getInviterList() {
      this.$api.supportApi.getGameInviter({ nickName: "" }).then((res) => {
        if (res.code === 0) {
          const result = res.data ? res.data : [];
          this.accountListData = result;
        }
      });
    },
    handleClickFrozen(val, type) {
      const { roleId } = val;
      const params = {
        roleId: roleId,
        frozenStatus: type,
      };
      this.$api.supportApi.frozenMember({ ...params }).then((res) => {
        if (res.code === 0) {
          this.$message.success("操作成功！");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    queryInviter() {
      const unionId = this.$route.query.unionId;
      this.inviterParams = unionId ? { unionId } : {};
      this.$api.supportApi.queryInviter(this.inviterParams).then((res) => {
        if (res.code === 0) {
          const returnData = res.data;
          const result = returnData
            ? Array.isArray(returnData)
              ? returnData
              : [returnData]
            : [];
          this.inviterOptions = transferDataForTreeSelect(result);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  /deep/ .el-radio-button--mini .el-radio-button__inner {
    padding: 7px 15px;
    font-size: 12px;
    border-radius: 0;
    margin: 5px;
    border: 1px #dcdfe6 solid !important;
    border-radius: 4px !important;
    box-shadow: none !important;
  }
  .mr15 {
    margin-right: 15px;
  }
}
</style>
