<template>
  <div class="game-apply">
    <div class="top">
      <div>
        数据指标&nbsp;&nbsp;<el-select
          v-model="queryForm.unionId"
          placeholder="请选择"
          @change="chooseUnion"
          size="mini"
          v-show="$choosePlantorm().platformType === 0"
        >
          <el-option
            v-for="item in unionList"
            :key="item.id"
            :label="item.unionName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div class="right-text">数据更新时间：今天 10:00</div> -->
    </div>
    <ul class="card-box flex" v-loading="$store.state.loadingStatus">
      <li>
        <div class="li-top flex">
          <p>
            <span>新用户</span> <br /><b>{{ topData.newUser.newUserNum }}</b>
          </p>
          <img src="@/assets/img/frame-icon2x.png" alt="" />
        </div>
        <div class="li-bottom flex">
          <div>
            <p>
              上个月
              <img
                src="@/assets/img/rise-icon2x.png"
                v-if="topData.newUser.lastMonthIncreaseNum >= 0"
              />
              <img v-else src="@/assets/img/decline-icon2x.png" />
              <span
                :class="
                  topData.newUser.lastMonthIncreaseNum >= 0
                    ? 'up_green'
                    : 'down_red'
                "
                >{{ Math.abs(topData.newUser.lastMonthIncreaseNum) }}</span
              >
            </p>
            <p>昨日：{{ topData.newUser.yesterdayNum }}</p>
          </div>
          <div>
            <p>本月：{{ topData.newUser.currentMonthNum }}</p>
            <p>上月：{{ topData.newUser.lastMonthNum }}</p>
          </div>
        </div>
      </li>
      <li>
        <div class="li-top flex">
          <p>
            <span>活跃数</span> <br /><b>{{ topData.active.newUserNum }}</b>
          </p>
          <img src="@/assets/img/slice2-iocn2x.png" alt="" />
        </div>
        <div class="li-bottom flex">
          <div>
            <p>
              上个月
              <img
                src="@/assets/img/rise-icon2x.png"
                v-if="topData.active.lastMonthIncreaseNum >= 0"
              />
              <img v-else src="@/assets/img/decline-icon2x.png" />
              <span
                :class="
                  topData.active.lastMonthIncreaseNum >= 0
                    ? 'up_green'
                    : 'down_red'
                "
                >{{ Math.abs(topData.active.lastMonthIncreaseNum) }}</span
              >
            </p>
            <p>昨日：{{ topData.active.yesterdayNum }}</p>
          </div>
          <div>
            <p>本月：{{ topData.active.currentMonthNum }}</p>
            <p>上月：{{ topData.active.lastMonthNum }}</p>
          </div>
        </div>
      </li>
      <li>
        <div class="li-top flex">
          <p>
            <span>充值用户</span> <br /><b>{{
              topData.rechargeUser.newUserNum
            }}</b>
          </p>
          <img src="@/assets/img/slice1-iocn.png" alt="" />
        </div>
        <div class="li-bottom flex">
          <div>
            <p>
              上个月
              <img
                src="@/assets/img/rise-icon2x.png"
                v-if="topData.rechargeUser.lastMonthIncreaseNum >= 0"
              />
              <img v-else src="@/assets/img/decline-icon2x.png" />
              <span
                :class="
                  topData.rechargeUser.lastMonthIncreaseNum >= 0
                    ? 'up_green'
                    : 'down_red'
                "
                >{{ Math.abs(topData.rechargeUser.lastMonthIncreaseNum) }}</span
              >
            </p>
            <p>昨日：{{ topData.rechargeUser.yesterdayNum }}</p>
          </div>
          <div>
            <p>本月：{{ topData.rechargeUser.currentMonthNum }}</p>
            <p>上月：{{ topData.rechargeUser.lastMonthNum }}</p>
          </div>
        </div>
      </li>
      <li>
        <div class="li-top flex">
          <p>
            <span>充值总额</span> <br /><b>{{
              topData.rechargeMoney.newUserNum
            }}</b>
          </p>
          <img src="@/assets/img/slice3-iocn.png" alt="" />
        </div>
        <div class="li-bottom flex">
          <div>
            <p>
              上个月
              <img
                src="@/assets/img/rise-icon2x.png"
                v-if="topData.rechargeMoney.lastMonthIncreaseNum >= 0"
              />
              <img v-else src="@/assets/img/decline-icon2x.png" />
              <span
                :class="
                  topData.rechargeMoney.lastMonthIncreaseNum >= 0
                    ? 'up_green'
                    : 'down_red'
                "
                >{{
                  Math.abs(topData.rechargeMoney.lastMonthIncreaseNum)
                }}</span
              >
            </p>
            <p>昨日：{{ topData.rechargeMoney.yesterdayNum }}</p>
          </div>
          <div>
            <p>本月：{{ topData.rechargeMoney.currentMonthNum }}</p>
            <p>上月：{{ topData.rechargeMoney.lastMonthNum }}</p>
          </div>
        </div>
      </li>
    </ul>
    <ul class="chart-box flex" v-loading="$store.state.loadingStatus">
      <li>
        <p>新用户（注册）</p>
        <div id="c1"></div>
      </li>
      <li>
        <p>活跃数（用户）</p>
        <div id="c2"></div>
      </li>
      <li>
        <p>用户充值（用户充值个数）</p>
        <div id="c3"></div>
      </li>
      <li>
        <p>充值总额</p>
        <div id="c4"></div>
      </li>
    </ul>
    <div class="top-container ground-white flex">
      <div class="title">今日开服</div>
      <div class="box">
        <div class="bar" ref="bar" v-if="areaList.length">
          <div class="item" v-for="item in areaList" :key="item.id">
            <span>{{
              $moment(item.serviceTime).format("YYYY-MM-DD HH:mm").split(" ")[1]
            }}</span>
            <span style="margin: 0 6px">{{ item.gameName }}</span>
            <span>{{ item.serviceName }}</span>
          </div>
        </div>
      </div>
    </div>
    <el-form
      :inline="true"
      ref="gameForm"
      v-loading="$store.state.loadingStatus"
    >
      <!-- {{ queryFormNew }} -->
      <el-form-item label="TOP推广">
        <el-radio-group
          v-model="queryFormNew.dateType"
          @change="dataTypeChange"
          size="small"
        >
          <el-radio-button :label="1">日</el-radio-button>
          <el-radio-button :label="3">月</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="queryFormNew.startTime"
          :type="queryFormNew.dateType === 1 ? 'date' : 'month'"
          size="small"
          :format="queryFormNew.dateType === 1 ? 'yyyy-MM-dd' : 'yyyy-MM'"
          :value-format="queryFormNew.dateType === 1 ? 'yyyy-MM-dd' : 'yyyy-MM'"
          @change="monthChange"
          style="position: absolute"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <ul class="listbox flex" v-loading="$store.state.loadingStatus">
      <li>
        <div class="item-top flex">
          <b>注册数</b>
          <span
            @click="
              dialogFormVisible = true;
              dialogListData = bottomData.newUser;
            "
            >全部></span
          >
        </div>
        <div class="item-box">
          <div
            class="item"
            v-for="(item, index) in bottomData.newUser12"
            :key="item.accoundId"
          >
            <span class="index">{{ index + 1 }}</span>
            <span class="content"
              >{{ item.accountName }}
              <el-tooltip placement="top">
                <div slot="content">
                  ID：{{ item.accountId }} <br />职务：职务-{{ item.roleName }}
                  <br />部门：部门-{{ item.departmentName }}
                </div>
                <i
                  style="margin-left: 12px; cursor: pointer"
                  class="el-icon-tickets"
                ></i>
              </el-tooltip>
            </span>
            <span class="num">{{ item.num }}</span>
          </div>
        </div>
      </li>
      <li>
        <div class="item-top flex">
          <b>活跃数</b>
          <span
            @click="
              dialogFormVisible = true;
              dialogListData = bottomData.active;
            "
            >全部></span
          >
        </div>
        <div class="item-box">
          <div
            class="item"
            v-for="(item, index) in bottomData.active12"
            :key="item.accoundId"
          >
            <span class="index">{{ index + 1 }}</span>
            <span class="content"
              >{{ item.accountName }}
              <el-tooltip placement="top">
                <div slot="content">
                  ID：{{ item.accountId }} <br />职务：职务-{{ item.roleName }}
                  <br />部门：部门-{{ item.departmentName }}
                </div>
                <i
                  style="margin-left: 12px; cursor: pointer"
                  class="el-icon-tickets"
                ></i>
              </el-tooltip>
            </span>
            <span class="num">{{ item.num }}</span>
          </div>
        </div>
      </li>
      <li>
        <div class="item-top flex">
          <b>充值用户数</b>
          <span
            @click="
              dialogFormVisible = true;
              dialogListData = bottomData.rechargeUser;
            "
            >全部></span
          >
        </div>
        <div class="item-box">
          <div
            class="item"
            v-for="(item, index) in bottomData.rechargeUser12"
            :key="item.accoundId"
          >
            <span class="index">{{ index + 1 }}</span>
            <span class="content"
              >{{ item.accountName }}
              <el-tooltip placement="top">
                <div slot="content">
                  ID：{{ item.accountId }} <br />职务：职务-{{ item.roleName }}
                  <br />部门：部门-{{ item.departmentName }}
                </div>
                <i
                  style="margin-left: 12px; cursor: pointer"
                  class="el-icon-tickets"
                ></i>
              </el-tooltip>
            </span>
            <span class="num">{{ item.num }}</span>
          </div>
        </div>
      </li>
      <li>
        <div class="item-top flex">
          <b>充值总额</b>
          <span
            @click="
              dialogFormVisible = true;
              dialogListData = bottomData.rechargeMoney;
            "
            >全部></span
          >
        </div>
        <div class="item-box">
          <div
            class="item"
            v-for="(item, index) in bottomData.rechargeMoney12"
            :key="item.accoundId"
          >
            <span class="index">{{ index + 1 }}</span>
            <span class="content"
              >{{ item.accountName }}
              <el-tooltip placement="top">
                <div slot="content">
                  ID：{{ item.accountId }} <br />职务：职务-{{ item.roleName }}
                  <br />部门：部门-{{ item.departmentName }}
                </div>
                <i
                  style="margin-left: 12px; cursor: pointer"
                  class="el-icon-tickets"
                ></i>
              </el-tooltip>
            </span>
            <span class="num">{{ item.num }}</span>
          </div>
        </div>
      </li>
    </ul>
    <!-- 编辑包体弹窗dialog -->
    <el-dialog :title="'人员列表'" :visible.sync="dialogFormVisible">
      <ul class="listboxnew flex">
        <li>
          <div class="item-box">
            <div
              class="item"
              v-for="(item, index) in dialogListData"
              :key="item.accoundId"
            >
              <span class="index">{{ index + 1 }}</span>
              <span class="content"
                >{{ item.accountName }}
                <el-tooltip placement="top">
                  <div slot="content">
                    ID：{{ item.accountId }} <br />职务：职务-{{
                      item.roleName
                    }}
                    <br />部门：部门-{{ item.departmentName }}
                  </div>
                  <i
                    style="margin-left: 12px; cursor: pointer"
                    class="el-icon-tickets"
                  ></i>
                </el-tooltip>
              </span>
              <span class="num">{{ item.num }}</span>
            </div>
          </div>
        </li>
      </ul>
    </el-dialog>
  </div>
</template>
<script>
import { Chart } from "@antv/g2";
export default {
  data() {
    return {
      dialogFormVisible: false,
      radio4: "TOP推广",
      value1: "",
      queryForm: {
        unionId: null,
      },
      queryFormNew: {
        dateType: 1,
        startTime: this.$moment().format("YYYY-MM-DD"),
      },
      dialogListData: [],
      areaList: [],
      unionList: [],
      topData: {
        newUser: {},
        newUserList: [],
        active: {},
        activeList: [],
        rechargeMoney: {},
        rechargeMoneyList: [],
        rechargeUser: {},
        rechargeUserList: [],
      },
      bottomData: {
        active: [],
        newUser: [],
        rechargeMoney: [],
        rechargeUser: [],
        active12: [],
        newUser12: [],
        rechargeMoney12: [],
        rechargeUser12: [],
      },
      value: "",
      oChart: [],
    };
  },
  methods: {
    getAreaList() {
      // 获取公会列表
      this.$api.organizationApi
        .queryAreaPlan({
          gameId: null,
          startTime: this.$moment(new Date()).format("YYYY-MM-DD"),
          endTime: this.$moment(new Date()).format("YYYY-MM-DD"),
          pageIndex: 1,
          pageSize: 10000,
        })
        .then((res) => {
          console.log("getAreaList", res);
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : {};
            this.areaList = result;

            let times = Math.ceil(this.areaList.length / 4);
            if (times > 1) {
              this.ani(times);
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    ani(data) {
      data -= 1;
      setTimeout(() => {
        const a =
          data < 0 ? 0 : (Math.ceil(this.areaList.length / 4) - data) * 42;
        this.$refs.bar.style = ` transition: 2s; transform:translateY(-${a}px);`;
      }, 2000);
      setTimeout(() => {
        if (data === 1) {
          this.$refs.bar.style = `transtion:0;transform:translateY(0px);`;
          this.ani(Math.ceil(this.areaList.length / 4));
        } else {
          this.ani(data);
        }
      }, 6000);
    },
    monthChange() {
      this.getListLData();
    },
    dataTypeChange(e) {
      if (e === 1) {
        this.queryFormNew.startTime = this.$moment().format("YYYY-MM-DD");
      } else {
        this.queryFormNew.startTime = this.$moment().format("YYYY-MM-DD");
      }
      this.getListLData();
    },
    chooseUnion(e) {
      this.queryForm.unionId = e;
      this.getUserListData();
      this.getAreaList();
      this.getListLData(e);
      // this.getUserListData();
    },
    getUnionList() {
      // 获取公会列表
      this.$api.organizationApi
        .getUnionList({
          pageIndex: 1,
          pageSize: 10000,
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : {};
            this.unionList = result.list;
            this.unionList.unshift({
              unionName: `全部`,
              id: null,
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    initChart() {
      for (let index = 0; index < 4; index++) {
        this.oChart[index] = new Chart({
          container: `c${index + 1}`,
          autoFit: true,
          padding: [20, 45, 20, 55],
        });
      }
      console.log(this.chart);
    },
    renderChart(index, data) {
      const chart = this.oChart[index];
      console.log(data);
      chart.data(data);
      // chart.data([
      //   { statisDate: "2023-01-01", num: 20 },
      //   { statisDate: "2023-01-02", num: 30 },
      //   { statisDate: "2023-01-03", num: 40 },
      //   { statisDate: "2023-01-04", num: 70 },
      //   { statisDate: "2023-01-05", num: 10 },
      // ]);
      chart.scale({
        statisDate: {
          range: [0, 1],
          type: "time",
        },
        num: {
          min: 0,
          nice: true,
        },
      });
      chart.source(data, {
        statisDate: {
          type: "timeCat",
        },
      });
      chart.axis("num", {
        label: {
          formatter: function formatter(val) {
            return val;
          },
        },
      });
      chart.tooltip({
        showCrosshairs: true, // 展示 Tooltip 辅助线
        shared: true,
      });

      chart.line().position("statisDate*num").shape("smooth");
      chart.point().position("statisDate*num").shape("circle");

      chart.render();
    },
    getUserListData() {
      this.$api.gameApi.getUserStatis(this.queryForm).then((res) => {
        console.log("getUserStatis", res);
        if (res.code === 0) {
          const result = res.data ? res.data : {};
          this.topData = result;
          this.renderChart(0, this.topData.newUserList);
          this.renderChart(1, this.topData.activeList);
          this.renderChart(2, this.topData.rechargeUserList);
          this.renderChart(3, this.topData.rechargeMoneyList);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getTop12List(pType) {
      for (let index = 0; index < 12; index++) {
        this.bottomData[`${pType}12`] =
          this.bottomData[pType].length > 12
            ? this.bottomData[pType].slice(0, 12)
            : [
                ...this.bottomData[pType],
                ...Array(12 - this.bottomData[pType].length).fill({}),
              ];
      }
    },
    getListLData() {
      this.$api.gameApi
        .getUserStatisList({
          ...this.queryFormNew,
          unionId: this.queryForm.unionId,
          dateType:
            this.queryFormNew.dateType === 3 ? 2 : this.queryFormNew.dateType,
        })
        .then((res) => {
          console.log("getUserStatisList", res);
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.bottomData = result;
            this.getTop12List("active");
            this.getTop12List("newUser");
            this.getTop12List("rechargeMoney");
            this.getTop12List("rechargeUser");
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
  mounted() {
    this.getAreaList();
    this.getListLData();
    this.getUserListData();
    if (this.$choosePlantorm().platformType === 0) {
      this.getUnionList();
    }
    this.initChart();
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  width: 100%;
  .listboxnew {
    justify-content: space-between;
    overflow: hidden;
    li {
      .item-box {
        overflow: hidden;
        .item {
          width: 100%;
          height: 44px;
          display: flex;
          color: #666666;
          font-size: 15px;
          align-items: center;
          border-bottom: 1px solid #f0f0f0;
          &:nth-of-type(1) {
            .index {
              color: #c90000;
            }
          }
          &:nth-of-type(2) {
            .index {
              color: #fe8900;
            }
          }
          &:nth-of-type(3) {
            .index {
              color: #ffc700;
            }
          }
          .content {
            flex: 1;
          }
          .num {
            text-align: center;
            // width: 10%;
          }
          .index {
            box-sizing: border-box;
            width: 15%;
            text-align: left;
            font-weight: bold;
            padding-left: 4%;
            font-size: 15px;
            color: #666666;
            margin-right: 15px;
          }
        }
      }
      .item-top {
        b {
          font-weight: bold;
          color: #666666;
          font-size: 18px;
        }
        span {
          cursor: pointer;
          letter-spacing: 1px;
        }
        justify-content: space-between;
        border-bottom: 1px solid #f0f0f0;
        height: 30px;
        align-items: center;
        padding-bottom: 10px;
      }
      box-sizing: border-box;
      padding: 12px 16px;
      width: calc(85% - 8px);
      // height: 148px;
      background: #ffffff;
      border-radius: 8px;
    }
  }
  .listbox {
    justify-content: space-between;
    overflow: hidden;
    li {
      .item-box {
        overflow: hidden;
        .item {
          width: 100%;
          height: 44px;
          display: flex;
          color: #666666;
          font-size: 15px;
          align-items: center;
          border-bottom: 1px solid #f0f0f0;
          &:nth-of-type(1) {
            .index {
              color: #c90000;
            }
          }
          &:nth-of-type(2) {
            .index {
              color: #fe8900;
            }
          }
          &:nth-of-type(3) {
            .index {
              color: #ffc700;
            }
          }
          .content {
            flex: 1;
          }
          .num {
            text-align: center;
            // width: 10%;
          }
          .index {
            box-sizing: border-box;
            width: 15%;
            text-align: left;
            font-weight: bold;
            padding-left: 4%;
            font-size: 15px;
            color: #666666;
            margin-right: 15px;
          }
        }
      }
      .item-top {
        b {
          font-weight: bold;
          color: #666666;
          font-size: 18px;
        }
        span {
          cursor: pointer;
          letter-spacing: 1px;
        }
        justify-content: space-between;
        border-bottom: 1px solid #f0f0f0;
        height: 30px;
        align-items: center;
        padding-bottom: 10px;
      }
      box-sizing: border-box;
      padding: 12px 16px;
      width: calc(25% - 8px);
      // height: 148px;
      background: #ffffff;
      border-radius: 8px;
    }
  }
  .top-container {
    margin-bottom: 8px;
    .title {
      font-size: 18px;
      font-weight: bold;
      color: #255ec0;
      margin-left: 24px;
      margin-right: 40px;
    }
    .box {
      flex: 1;
      overflow: hidden;
      height: 42px;
      line-height: 42px;
      .bar {
        .item {
          // flex: 1;
          width: 25%;
          height: 100%;
          float: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: center;
          font-size: 15px;
        }
      }
    }
  }
  .chart-box {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 12px auto;
    li {
      padding: 12px;
      box-sizing: border-box;
      width: calc(50% - 4px);
      height: 320px;
      background: #ffffff;
      border-radius: 8px;
      margin-bottom: 8px;
      display: flex;
      flex-direction: column;
      p {
        font-weight: bold;
        color: #666666;
        font-size: 18px;
      }
      & > div {
        flex: 1;
      }
    }
  }
  .card-box {
    justify-content: space-between;
    li {
      box-sizing: border-box;
      padding: 12px 16px;
      width: calc(25% - 8px);
      height: 148px;
      background: #ffffff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .li-bottom {
        color: #646e80;
        font-size: 14px;
        & > div {
          &:nth-of-type(1) {
            width: 50%;
          }
          &:nth-of-type(2) {
            flex: 1;
          }
          &:nth-of-type(2) {
            // border: 1px #000 solid;
            margin-left: 16px;
          }
          img {
            width: 20px;
            vertical-align: middle;
            margin-right: 5px;
          }
        }
      }
      .li-top {
        justify-content: space-between;
        b {
          color: #3a4a65;
          font-size: 28px;
          font-weight: bold;
        }
        span {
          color: #646e80;
          font-size: 14px;
        }
        img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  .top {
    width: 100%;
    height: 40px;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    .right-text {
      color: #255ec0;
    }
  }
}
</style>
<style lang="less" scoped>
.up_green {
  color: #0ab13c;
}
.down_red {
  color: #f42c2c;
}
</style>
