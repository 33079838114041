<template>
  <div class="game-apply ground-white">
    <div class="groupTree">
      <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
          <span>账号密码：</span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
            @click="
              dialogFormVisible = true;
              resetForm('accountForm');
            "
            >编辑</el-button
          >
        </div>
        <div class="text item">用户名：{{ accountName }}</div>
        <div class="text item">密码：********</div>
      </el-card>

      <el-card class="box-card" shadow="hover">
        <div slot="header" class="clearfix">
          <span>用户信息：</span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
            @click="openEdit"
            >编辑</el-button
          >
        </div>
        <div class="text item">手机号码：{{ userForm.mobile }}</div>
        <div class="text item">电子邮箱：{{ userForm.mail }}</div>
        <div class="text item">联系QQ：{{ userForm.qq }}</div>
      </el-card>
    </div>
    <div class="staffList">
      <el-form :inline="true">
        <el-form-item label="合同下载(pdf)：">
          <el-button
            size="small"
            icon="el-icon-download"
            type="primary"
            @click="downloadContract(contractUrl)"
            >下载</el-button
          >
        </el-form-item>
      </el-form>
      <div>合同预览：</div>
      <iframe
        v-if="contractUrl"
        :src="contractUrl"
        style="height: calc(100vh - 252px); width: calc(100vw - 650px)"
        frameborder="0"
      ></iframe>
      <div v-else class="no_contract">
        <img src="@/assets/img/noData.png" alt="" />
        <span>暂无合同</span>
      </div>
    </div>
    <el-dialog title="更改密码" :visible.sync="dialogFormVisible" width="420px">
      <el-form :model="accountForm" ref="accountForm" label-width="120px">
        <el-form-item label="账号">
          <el-input
            style="width: 198px"
            disabled
            v-model="accountName"
          ></el-input>
        </el-form-item>
        <el-form-item required label="密码" prop="accountPassword">
          <el-input
            v-model="accountForm.accountPassword"
            style="width: 198px"
            type="password"
            show-password
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="resetPassword">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="修改信息"
      :visible.sync="dialogFormVisibleNew"
      width="420px"
    >
      <el-form :model="userFormNew" label-width="120px">
        <el-form-item label="手机号">
          <el-input
            style="width: 198px"
            v-model="userFormNew.mobile"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input
            style="width: 198px"
            v-model="userFormNew.mail"
            placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系QQ">
          <el-input
            style="width: 198px"
            v-model="userFormNew.qq"
            placeholder="请输入联系QQ"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="dialogFormVisibleNew = false">取消</el-button>
          <el-button type="primary" @click="resetUserInfo">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  computed: {
    userInfo: function () {
      return null;
      // return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  watch: {},
  data() {
    return {
      dialogFormVisible: false,
      dialogFormVisibleNew: false,
      accountName: "",
      accountForm: {
        accountPassword: "",
      },
      userFormNew: {
        mobile: "",
        mail: "",
        qq: "",
      },
      userForm: {
        mobile: "",
        mail: "",
        qq: "",
      },
      contractUrl: "",
    };
  },
  mounted() {
    console.log(localStorage.getItem("userInfo"));
    this.getUserInfo();
  },
  methods: {
    openEdit() {
      this.dialogFormVisibleNew = true;
      this.userFormNew = { ...this.userForm };
    },
    getUserInfo() {
      this.$api.loginApi.queryAccountInfo({}).then((res) => {
        console.log("queryAccountInfo", res);
        if (res.code === 0) {
          this.accountName = res.data.accountName;
          this.userForm = { ...res.data };
          this.contractUrl = res.data.contractUrl;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    resetPassword() {
      this.$api.loginApi.updatePassword(this.accountForm).then((res) => {
        console.log("resetPassword", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.dialogFormVisible = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    resetUserInfo() {
      this.$api.loginApi.updateAccountInfo(this.userFormNew).then((res) => {
        console.log("resetUserInfo", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.getUserInfo();
          this.dialogFormVisibleNew = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
    downloadContract(url) {
      if (!url) {
        return;
      }
      const list = url.split("/");
      const a = document.createElement("a");
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          a.href = URL.createObjectURL(blob);
          a.download = list[list.length - 1] || "";
          document.body.appendChild(a);
          a.click();
        })
        .then(() => {
          this.$message.success("合同下载成功！");
        });
    },
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  display: flex;
  background: #f3f3f3;
  .groupTree {
    width: 262px;
    max-width: 700px;
    background: #fff;
    border-radius: 8px;
    padding: 16px;
    margin-right: 1%;
    overflow: hidden;
    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }

    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
    }
    .clearfix:after {
      clear: both;
    }

    .box-card {
      width: 90%;
      margin: 20px auto;
    }
    .btn {
      display: flex;
      .ant-btn {
        width: auto;
      }
      .ant-btn:last-child {
        margin-left: 9px;
      }
      margin-bottom: 10px;
    }
  }
  .grouplist {
    strong {
      margin: 10px 0;
      display: block;
      font-size: 16px;
    }
  }
  .staffList {
    flex: 1;
    background: #fff;
    border-radius: 8px;
    padding: 16px;
    overflow-x: auto;
  }
}
.no_contract {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-size: 12px;
  color: #aaa;

  img {
    margin-top: 5vh;
    width: 120px;
  }
}
/deep/.pd20 {
  // padding: 50px !important;
}
</style>
