<template>
  <div>
    <ZhengTx v-if="+gameId === 10013" />
    <div v-else class="unionList">
      <div v-if="+gameId === 10005" class="type_gwws">
        <div v-show="isShow">
          <div class="top">
            <img src="@/assets/img/share/gsws_logo.png" class="icon" />
            <div>
              <p style="color: #ffd56a; font-size: 0.2rem">上古情歌</p>
              <p style="color: #ffffff; font-size: 0.12rem">
                上古情歌之盖世无双，万人同屏
              </p>
            </div>
            <span :data-clipboard-text="copyInfo" class="btn" @click="copy">
              下载游戏
            </span>
          </div>
          <div class="video">
            <img
              src="@/assets/img/share/gsws_vide_img.png"
              @click="playVideo()"
            />
            <p
              style="
                position: absolute;
                font-size: 0.14rem;
                border-radius: 5px;
                overflow: hidden;
                bottom: 0.08rem;
              "
            >
              <span
                style="
                  background: rgba(20, 25, 54, 0.8);
                  color: #fff4da;
                  padding: 2px 4px;
                  border-radius: 3px 0px 0px 3px;
                "
                >新服推荐</span
              >
              <span
                style="
                  background: rgba(255, 255, 255, 0.9);
                  color: #141936;
                  padding: 2px 7px 2px 0;
                  border-radius: 0px 3px 3px 0px;
                "
                >【{{ showServerInfo.serviceName }}】{{ openDay }}({{
                  openTime
                }})开启</span
              >
            </p>
          </div>
          <div class="banner1">
            <div class="title">游戏特色</div>
            <p class="desc">于仙侠世界脱凡俗 感受修仙得道体验</p>
            <div class="swiper mySwiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img src="@/assets/img/share/banner1-1.jpg" />
                </div>
                <div class="swiper-slide">
                  <img src="@/assets/img/share/banner1-2.jpg" />
                </div>
                <div class="swiper-slide">
                  <img src="@/assets/img/share/banner1-3.jpg" />
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
          <div class="banner2">
            <div class="title" style="margin-bottom: 0">职业介绍</div>
            <div class="swiper mySwiper2">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img src="@/assets/img/share/banner2-1.png" />
                </div>
                <div class="swiper-slide">
                  <img src="@/assets/img/share/banner2-2.png" />
                </div>
                <div class="swiper-slide">
                  <img src="@/assets/img/share/banner2-3.png" />
                </div>
              </div>
              <div class="swiper-pagination2"></div>
            </div>
            <div class="title">游戏开服表</div>
            <!-- {{ areaList }} -->
            <div class="area">
              <ul class="a-top">
                <li>游戏名称</li>
                <li>开服时间</li>
                <li>服务器</li>
              </ul>
              <div class="bar">
                <div class="baritem" ref="bar">
                  <ul
                    class="a-bottom"
                    v-for="(item, index) in areaList"
                    :key="index"
                  >
                    <li class="tel">{{ item.gameName }}</li>
                    <li class="tel">
                      {{ $moment(item.serviceTime).format("MM-DD") }}({{
                        $moment(item.serviceTime).format("HH:mm")
                      }})
                    </li>
                    <li class="tel">{{ item.serviceName }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="footer">
            <img class="icon" src="@/assets/img/share/gsws_logo.png" />
            <span :data-clipboard-text="copyInfo" class="btn" @click="copy">
              下载游戏
            </span>
          </div>
          <div class="bottom">
            <span
              >抵制不良游戏，拒绝盗版游戏。 注意自我保护，谨防受骗上当。
              适度游戏益脑，沉迷游戏伤身。 合理安排时间，享受健康生活。</span
            >
          </div>
        </div>
        <div v-show="!isShow">请到移动端访问该页面</div>
        <div class="modal" v-show="modalShow" ref="mask"></div>
        <div v-if="isShowSign" class="sign_wrap">
          <i class="sign_close" @click="closeSign"></i>
          <div v-if="!isSign" class="sign_hd">
            <img src="@/assets/img/sign_logo.png" alt="" />
          </div>
          <div class="sign_bd">
            <div v-if="!isSign">
              <div class="ipt_bg" v-if="!isTrueCode">
                <input
                  v-model="mobileInfo.mobile"
                  placeholder="请输入手机号码"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  maxlength="11"
                  type="text"
                />
                <div class="verify_wrap">
                  <span v-if="accountSec" class="verify_txt"
                    >{{ accountSec }}秒后再次点击</span
                  >
                  <span v-else class="btn_txt" @click="sendSMSCode"
                    >获取验证码</span
                  >
                </div>
              </div>
              <div class="ipt_bg" v-if="!isTrueCode">
                <input
                  v-model="mobileInfo.code"
                  placeholder="请输入短信验证码"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  maxlength="6"
                  type="text"
                />
              </div>
              <div class="ipt_bg" v-if="isTrueCode">
                <input
                  v-model="mobileInfo.password"
                  placeholder="请设置6-12位密码"
                  type="password"
                />
              </div>
              <div class="info_wrap">
                <span>下载前，请先注册</span>
              </div>
              <button v-if="!isTrueCode" class="next_btn" @click="gotoVerify">
                下一步
              </button>
              <button
                v-if="isTrueCode"
                class="next_btn pre_btn"
                @click="goBackToGetVerify"
              >
                上一步
              </button>
              <button v-if="isTrueCode" class="next_btn" @click="createAccount">
                完成注册
              </button>
            </div>
            <div v-if="isSign" class="sign_text">已经注册，请下载游戏</div>
            <button v-if="isSign" class="next_btn" @click="gotoDownload">
              普通安装
            </button>
            <!-- <a v-if="isSign" class="next_btn" href="http://f.zhongyiyf.com/17game"
            >普通安装</a
          > -->
          </div>
          <div class="sign_fd">
            <!-- <div class="radio_wrap">
            <input type="radio" name="pay-type" id="protocol" />
            <label for="protocol" class="radios_type">
              <span class="radio_info"
                >我已详细阅读并同意《用户协议》《隐私协议》《网络游戏行业防沉迷自律公约》</span
              >
            </label>
          </div> -->
          </div>
        </div>
      </div>
      <div v-if="+gameId === 10012" class="type_sslc">
        <div v-show="isShow">
          <div class="top">
            <div
              style="width: 60vw; display: flex; justify-content: flex-start"
            >
              <img src="@/assets/img/share/qqtx_log.png" class="icon" />
              <div style="padding-left: 10px">
                <p style="color: #ffd56a; font-size: 0.2rem">权倾天下</p>
                <p style="color: #ffffff; font-size: 0.12rem">
                  盛世龙城之权倾天下
                </p>
              </div>
            </div>
            <span :data-clipboard-text="copyInfo" class="btn" @click="copy">
              下载游戏
            </span>
          </div>
          <div class="video">
            <img
              src="https://web.zhongyiyf.com/img/qqtx.1b5ca4a4.jpg"
              @click="playVideo()"
            />
            <p
              style="
                position: absolute;
                font-size: 0.14rem;
                border-radius: 5px;
                overflow: hidden;
                bottom: 0.08rem;
              "
            >
              <span
                style="
                  background: rgba(20, 25, 54, 0.8);
                  color: #fff4da;
                  padding: 2px 4px;
                  border-radius: 3px 0px 0px 3px;
                "
                >新服推荐</span
              >
              <span
                style="
                  background: rgba(255, 255, 255, 0.9);
                  color: #141936;
                  padding: 2px 7px 2px 0;
                  border-radius: 0px 3px 3px 0px;
                "
                >【{{ showServerInfo.serviceName }}】{{ openDay }}({{
                  openTime
                }})开启</span
              >
            </p>
          </div>
          <!-- <div class="swiper mySwiper2" style="height: 2.18rem">
        <div class="swiper-wrapper" style="height: 2.18rem">
          <div class="swiper-slide">
            <img src="@/assets/img/share/slider/01.jpg" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/img/share/slider/02.jpg" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/img/share/slider/03.jpg" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/img/share/slider/04.jpg" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/img/share/slider/05.jpg" />
          </div>
        </div>
        <div class="swiper-pagination2"></div>
      </div> -->
          <div class="banner1">
            <div class="title">游戏特色</div>
            <p class="desc">权倾天下--激情攻沙，万人同屏</p>
            <div class="swiper mySwiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img src="https://pic.zhongyiyf.com/img/01.fe1b8d71.jpg" />
                </div>
                <div class="swiper-slide">
                  <img src="https://pic.zhongyiyf.com/img/02.5b619dba.jpg" />
                </div>
                <div class="swiper-slide">
                  <img src="https://pic.zhongyiyf.com/img/03.0f226f85.jpg" />
                </div>
                <div class="swiper-slide">
                  <img src="https://pic.zhongyiyf.com/img/04.f83b95b8.jpg" />
                </div>
                <div class="swiper-slide">
                  <img src="https://pic.zhongyiyf.com/img/05.646e24ba.jpg" />
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
          <div class="banner2">
            <!-- <div class="title" style="margin-bottom: 0">职业介绍</div>
        <div class="swiper mySwiper2">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="@/assets/img/share/banner2-1.png" />
            </div>
            <div class="swiper-slide">
              <img src="@/assets/img/share/banner2-2.png" />
            </div>
            <div class="swiper-slide">
              <img src="@/assets/img/share/banner2-3.png" />
            </div>
          </div>
          <div class="swiper-pagination2"></div>
        </div> -->
            <div class="title">游戏开服表</div>
            <!-- {{ areaList }} -->
            <div class="area">
              <ul class="a-top">
                <li>游戏名称</li>
                <li>开服时间</li>
                <li>服务器</li>
              </ul>
              <div class="bar">
                <div class="baritem" ref="bar">
                  <ul
                    class="a-bottom"
                    v-for="(item, index) in areaList"
                    :key="index"
                  >
                    <li class="tel">{{ item.gameName }}</li>
                    <li class="tel">
                      {{ $moment(item.serviceTime).format("MM-DD") }}({{
                        $moment(item.serviceTime).format("HH:mm")
                      }})
                    </li>
                    <li class="tel">{{ item.serviceName }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="footer">
            <img class="icon" src="@/assets/img/share/qqtx_log.png" />
            <span :data-clipboard-text="copyInfo" class="btn" @click="copy">
              下载游戏
            </span>
          </div>
          <div class="bottom">
            <span
              >抵制不良游戏，拒绝盗版游戏。 注意自我保护，谨防受骗上当。
              适度游戏益脑，沉迷游戏伤身。 合理安排时间，享受健康生活。</span
            >
          </div>
        </div>
        <div v-show="!isShow">请到移动端访问该页面</div>
        <div class="modal" v-show="modalShow" ref="mask"></div>
        <div v-if="isShowSign" class="sign_wrap">
          <i class="sign_close" @click="closeSign"></i>
          <div v-if="!isSign" class="sign_hd">
            <img src="@/assets/img/sign_logo.png" alt="" />
          </div>
          <div class="sign_bd">
            <div v-if="!isSign">
              <div class="ipt_bg" v-if="!isTrueCode">
                <input
                  v-model="mobileInfo.mobile"
                  placeholder="请输入手机号码"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  maxlength="11"
                  type="text"
                />
                <div class="verify_wrap">
                  <span v-if="accountSec" class="verify_txt"
                    >{{ accountSec }}秒后再次点击</span
                  >
                  <span v-else class="btn_txt" @click="sendSMSCode"
                    >获取验证码</span
                  >
                </div>
              </div>
              <div class="ipt_bg" v-if="!isTrueCode">
                <input
                  v-model="mobileInfo.code"
                  placeholder="请输入短信验证码"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  maxlength="6"
                  type="text"
                />
              </div>
              <div class="ipt_bg" v-if="isTrueCode">
                <input
                  v-model="mobileInfo.password"
                  placeholder="请设置6-12位密码"
                  type="password"
                />
              </div>
              <div class="info_wrap">
                <span>下载前，请先注册</span>
              </div>
              <button v-if="!isTrueCode" class="next_btn" @click="gotoVerify">
                下一步
              </button>
              <button
                v-if="isTrueCode"
                class="next_btn pre_btn"
                @click="goBackToGetVerify"
              >
                上一步
              </button>
              <button v-if="isTrueCode" class="next_btn" @click="createAccount">
                完成注册
              </button>
            </div>
            <div v-if="isSign" class="sign_text">已经注册，请下载游戏</div>
            <button v-if="isSign" class="next_btn" @click="gotoDownload">
              普通安装
            </button>
            <!-- <a v-if="isSign" class="next_btn" href="http://f.zhongyiyf.com/17game"
            >普通安装</a
          > -->
          </div>
          <div class="sign_fd">
            <!-- <div class="radio_wrap">
          <input type="radio" name="pay-type" id="protocol" />
          <label for="protocol" class="radios_type">
            <span class="radio_info"
              >我已详细阅读并同意《用户协议》《隐私协议》《网络游戏行业防沉迷自律公约》</span
            >
          </label>
        </div> -->
          </div>
        </div>
      </div>
      <div v-if="gameIdList.includes(+gameId)" class="type_sslc">
        <div v-show="isShow">
          <div class="top">
            <div
              style="width: 60vw; display: flex; justify-content: flex-start"
            >
              <img :src="gameConfig.logo" class="icon" />
              <div style="padding-left: 10px">
                <p style="color: #ffd56a; font-size: 0.2rem">
                  {{ gameConfig.tit }}
                </p>
                <p style="color: #ffffff; font-size: 0.12rem">
                  {{ gameConfig.title }}
                </p>
              </div>
            </div>
            <span :data-clipboard-text="copyInfo" class="btn" @click="copy">
              下载游戏
            </span>
          </div>
          <div class="video">
            <img :src="gameConfig.bigBg" class="video_img" />
            <img
              class="video_play"
              src="@/assets/img/share/play-icon.png"
              @click="playVideo(gameConfig.video)"
              v-if="!!gameConfig.video"
            />
            <p
              style="
                position: absolute;
                font-size: 0.14rem;
                border-radius: 5px;
                overflow: hidden;
                bottom: 0.08rem;
              "
              v-if="showServerInfo.serviceName"
            >
              <span
                style="
                  background: rgba(20, 25, 54, 0.8);
                  color: #fff4da;
                  padding: 2px 4px;
                  border-radius: 3px 0px 0px 3px;
                "
                >新服推荐</span
              >
              <span
                style="
                  background: rgba(255, 255, 255, 0.9);
                  color: #141936;
                  padding: 2px 7px 2px 0;
                  border-radius: 0px 3px 3px 0px;
                "
                >【{{ showServerInfo.serviceName }}】{{ openDay }}({{
                  openTime
                }})开启</span
              >
            </p>
          </div>
          <!-- <div class="swiper mySwiper2" style="height: 2.18rem">
        <div class="swiper-wrapper" style="height: 2.18rem">
          <div class="swiper-slide">
            <img src="@/assets/img/share/slider/01.jpg" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/img/share/slider/02.jpg" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/img/share/slider/03.jpg" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/img/share/slider/04.jpg" />
          </div>
          <div class="swiper-slide">
            <img src="@/assets/img/share/slider/05.jpg" />
          </div>
        </div>
        <div class="swiper-pagination2"></div>
      </div> -->
          <div class="banner1">
            <div class="title">游戏特色</div>
            <p class="desc">{{ gameConfig.content }}</p>
            <div class="swiper mySwiper">
              <div
                class="swiper-wrapper"
                v-if="gameConfig.swiperList && gameConfig.swiperList.length"
              >
                <div
                  class="swiper-slide"
                  v-for="(item, index) in gameConfig.swiperList"
                  :key="index"
                >
                  <img :src="item" />
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
          <div class="banner2">
            <!-- <div class="title" style="margin-bottom: 0">职业介绍</div>
        <div class="swiper mySwiper2">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="@/assets/img/share/banner2-1.png" />
            </div>
            <div class="swiper-slide">
              <img src="@/assets/img/share/banner2-2.png" />
            </div>
            <div class="swiper-slide">
              <img src="@/assets/img/share/banner2-3.png" />
            </div>
          </div>
          <div class="swiper-pagination2"></div>
        </div> -->
            <div class="title">游戏开服表</div>
            <!-- {{ areaList }} -->
            <div class="area">
              <ul class="a-top">
                <li>游戏名称</li>
                <li>开服时间</li>
                <li>服务器</li>
              </ul>
              <div class="bar">
                <div class="baritem" ref="bar">
                  <ul
                    class="a-bottom"
                    v-for="(item, index) in areaList"
                    :key="index"
                  >
                    <li class="tel">{{ item.gameName }}</li>
                    <li class="tel">
                      {{ $moment(item.serviceTime).format("MM-DD") }}({{
                        $moment(item.serviceTime).format("HH:mm")
                      }})
                    </li>
                    <li class="tel">{{ item.serviceName }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="footer">
            <img class="icon" :src="gameConfig.logo" />
            <span :data-clipboard-text="copyInfo" class="btn" @click="copy">
              下载游戏
            </span>
          </div>
          <div class="bottom">
            <span
              >抵制不良游戏，拒绝盗版游戏。 注意自我保护，谨防受骗上当。
              适度游戏益脑，沉迷游戏伤身。 合理安排时间，享受健康生活。</span
            >
          </div>
        </div>
        <div v-show="!isShow">请到移动端访问该页面</div>
        <div class="modal" v-show="modalShow" ref="mask"></div>
        <div v-if="isShowSign" class="sign_wrap">
          <i class="sign_close" @click="closeSign"></i>
          <div v-if="!isSign" class="sign_hd">
            <img src="@/assets/img/sign_logo.png" alt="" />
          </div>
          <div class="sign_bd">
            <div v-if="!isSign">
              <div class="ipt_bg" v-if="!isTrueCode">
                <input
                  v-model="mobileInfo.mobile"
                  placeholder="请输入手机号码"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  maxlength="11"
                  type="text"
                />
                <div class="verify_wrap">
                  <span v-if="accountSec" class="verify_txt"
                    >{{ accountSec }}秒后再次点击</span
                  >
                  <span v-else class="btn_txt" @click="sendSMSCode"
                    >获取验证码</span
                  >
                </div>
              </div>
              <div class="ipt_bg" v-if="!isTrueCode">
                <input
                  v-model="mobileInfo.code"
                  placeholder="请输入短信验证码"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  maxlength="6"
                  type="text"
                />
              </div>
              <div class="ipt_bg" v-if="isTrueCode">
                <input
                  v-model="mobileInfo.password"
                  placeholder="请设置6-12位密码"
                  type="password"
                />
              </div>
              <div class="info_wrap">
                <span>下载前，请先注册</span>
              </div>
              <button v-if="!isTrueCode" class="next_btn" @click="gotoVerify">
                下一步
              </button>
              <button
                v-if="isTrueCode"
                class="next_btn pre_btn"
                @click="goBackToGetVerify"
              >
                上一步
              </button>
              <button v-if="isTrueCode" class="next_btn" @click="createAccount">
                完成注册
              </button>
            </div>
            <div v-if="isSign" class="sign_text">已经注册，请下载游戏</div>
            <button v-if="isSign" class="next_btn" @click="gotoDownload">
              普通安装
            </button>
            <!-- <a v-if="isSign" class="next_btn" href="http://f.zhongyiyf.com/17game"
            >普通安装</a
          > -->
          </div>
          <div class="sign_fd">
            <!-- <div class="radio_wrap">
          <input type="radio" name="pay-type" id="protocol" />
          <label for="protocol" class="radios_type">
            <span class="radio_info"
              >我已详细阅读并同意《用户协议》《隐私协议》《网络游戏行业防沉迷自律公约》</span
            >
          </label>
        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import Clipboard from "clipboard";
import ZhengTx from "./zhengtianxiaPage.vue";
let u = navigator.userAgent;

import { GAME_CONFIG, gameIdList } from "./config";

export default {
  components: {
    ZhengTx,
  },
  data() {
    const gameId = this.$route.query.gameId;
    const gameConfig = GAME_CONFIG[gameId] || GAME_CONFIG["10014"];
    return {
      isShow: true,
      modalShow: false,
      areaList: [],
      title: "上古情歌之盖世无双，万人同屏 ",
      copyInfo: null,
      appUrl: `/#${this.$route.fullPath}`,
      showServerInfo: {},
      openDay: "",
      openTime: "",
      isIOS: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      accountSec: null,
      mobileInfo: {
        mobile: "",
        code: "",
      },
      isTrueCode: false,
      isShowSign: false,
      isSign: false,
      gameId: this.$route.query.gameId,
      gameIdList,
      gameConfig: gameConfig,
    };
  },
  watch: {
    isShowSign(v) {
      if (!v) {
        this.mobileInfo = {
          mobile: "",
          code: "",
          password: "",
        };
        this.accountSec = null;
      }
    },
  },
  created() {
    this.isShow = this.isMobile() ? true : false;
    // this.doRem(document, window);
    console.log(this.$route);
    if (this.gameId === 10005) {
      this.title = "上古情歌之盖世无双，万人同屏";
    } else if (+this.gameId === 10012) {
      this.title = "盛世龙城之权倾天下";
    } else if (this.gameIdList.includes(+this.gameId)) {
      this.title = this.gameConfig.title;
    }

    document.title = this.title;
    if (this.isIOS) {
      // alert("哈哈");π
    }
  },
  mounted() {
    this.getRem();
    this.initPage();
  },
  methods: {
    getRem() {
      const html = document.getElementsByTagName("html")[0];
      const oWidth =
        document.body.clientWidth || document.documentElement.clientWidth;

      html.style.fontSize = (oWidth / 375) * 100 + "px";
    },
    initPage() {
      this.copyInfo = JSON.stringify({
        inviterAdminAccountId: this.$route.query.inviterAdminAccountId,
      });
      this.initSwiper();
      this.getAreaList();
      this.getPackage();
    },
    getPackage() {
      // 获取公会列表
      this.$api.gameApi
        .getSharePackage({
          gameId: this.$route.query.gameId,
          inviterAccountId: this.$route.query.inviterAdminAccountId,
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            if (res.data && !this.isIOS) {
              this.appUrl = res.data;
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getAreaList() {
      // 获取公会列表
      this.$api.gameApi
        .getShareArea({
          gameId: this.$route.query.gameId,
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data ? res.data : [];
            this.areaList = [...result].reverse();
            const times = Math.ceil(this.areaList.length / 3);
            this.showServerInfo = [...result].pop() || {};
            this.openDay = this.$moment(this.showServerInfo.serviceTime).format(
              "MM-DD"
            );
            this.openTime = this.$moment(
              this.showServerInfo.serviceTime
            ).format("HH:mm");
            console.log(this.areaList);
            console.log(this.areaList.length);
            if (times > 1) {
              this.ani(times);
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    ani(data) {
      data -= 1;
      setTimeout(() => {
        const a =
          data < 0 ? 0 : (Math.ceil(this.areaList.length / 3) - data) * 32 * 3;
        this.$refs.bar.style = `-webkit-transition: 2s; transform:translateY(-${
          0.01 * a
        }rem);`;
      }, 2000);
      setTimeout(() => {
        if (data === 1) {
          this.$refs.bar.style = `-webkit-transtion:0;transform:translateY(0px);`;
          this.ani(Math.ceil(this.areaList.length / 3));
        } else {
          this.ani(data);
        }
      }, 6000);
    },
    playVideo(video = "###") {
      this.modalShow = true;
      const html = ` <div class="btn-close" @click="modalShow = false"><i class="el-icon-close"></i></div>
      <video
        class="video-view"
        src="${video}"
        ref="vi"
        playsinline
        autoplay
        preload="metadata"
        controls
      ></video>`;
      console.log(this.$refs.mask);
      this.$refs.mask.innerHTML = html;
      console.log(document.querySelector(".btn-close"));
      document.querySelector(".btn-close").addEventListener("click", () => {
        this.$refs.mask.innerHTML = "";
        this.modalShow = false;
      });
    },
    copy() {
      if (this.isIOS) {
        this.isShowSign = true;
        this.modalShow = true;
        return;
      }

      const aLink = document.createElement("a");
      // aLink.setAttribute("download", "");
      aLink.setAttribute("href", this.appUrl);
      document.body.appendChild(aLink);
      aLink.click();
      document.body.removeChild(aLink);

      var clipboard = new Clipboard(".btn");
      clipboard.on("success", (e) => {
        console.log("复制成功", e);
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        console.log("该浏览器不支持自动复制", e);
        // 释放内存
        clipboard.destroy();
      });
    },
    isMobile() {
      return true;
    },
    initSwiper() {
      var swiper = new Swiper(".mySwiper", {
        loop: true,
        loopAdditionalSlides: 1,
        initialSlide: 2,
        autoplay: 2200,
        autoplayDisableOnInteraction: false,
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 2,
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 10,
          modifier: 1,
          slideShadows: true,
        },
      });
      console.log(swiper);
      var swiper1 = new Swiper(".mySwiper2", {
        loop: true,
        autoplay: 2200,
        autoplayDisableOnInteraction: false,
        pagination: ".swiper-pagination2",
      });
      console.log(swiper1);
    },
    countDown(counts, type) {
      this[type] = counts;
      this.timer = setTimeout(() => {
        counts--;
        this.countDown(counts, type);
      }, 1000);

      if (counts === 0) {
        clearTimeout(this.timer);
        this.counts = 0;
      }
    },
    sendSMSCode() {
      if (!this.mobileInfo.mobile) {
        this.$message.warning("请输入手机号码！");
        return;
      }
      this.$api.billingApi
        .sendSmsCode({
          mobile: this.mobileInfo.mobile,
          smsCodeType: 0,
        })
        .then((res) => {
          if (res.code === 0) {
            this.countDown(60, "accountSec");
            this.$message.success("验证码发送成功!");
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    closeSign() {
      this.isShowSign = false;
      this.modalShow = false;
      this.goBackToGetVerify();
      this.mobileInfo = {
        mobile: "",
        code: "",
      };
    },
    gotoVerify() {
      if (!this.mobileInfo.mobile) {
        this.$message.warning("请输入手机号码！");
        return;
      }
      if (!this.mobileInfo.code) {
        this.$message.warning("请输入验证码！");
        return;
      }
      this.$api.billingApi
        .verifySmsCode({
          mobile: this.mobileInfo.mobile,
          smsCode: this.mobileInfo.code,
          smsCodeType: 1,
        })
        .then((res) => {
          if (res.code === 0) {
            this.isTrueCode = true;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    goBackToGetVerify() {
      this.isTrueCode = false;
    },
    createAccount() {
      if (!this.mobileInfo.password) {
        this.$message.warning("请输入密码！");
        return;
      }
      this.$api.billingApi
        .createAccount({
          mobile: this.mobileInfo.mobile,
          password: this.mobileInfo.password,
          inviterAdminAccountId: this.$route.query.inviterAdminAccountId,
          gameId: this.$route.query.gameId,
        })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success("注册成功！");
            this.isTrueCode = false;
            this.isSign = true;
            this.mobileInfo = {
              mobile: "",
              code: "",
              password: "",
            };
          } else {
            if (+res.code === 100002001) {
              this.isTrueCode = false;
              this.isSign = true;
              this.mobileInfo = {
                mobile: "",
                code: "",
                password: "",
              };
            }
            this.$message.error(res.msg);
          }
        });
    },
    gotoDownload() {
      this.isShowSign = false;
      this.modalShow = false;
      this.isSign = false;
      // this.$router.replace("login");
      if (this.gameId === 10005) {
        window.open("https://www.pgyer.com/a4Jq", "_self");
      } else if (+this.gameId === 10012) {
        const url = "http://f.zhongyiyf.com/17game";
        let a = document.createElement("a");
        a.setAttribute("href", url);
        document.body.appendChild(a);
        a.click();
        a.remove();
        // window.location.href = "http://f.zhongyiyf.com/17game";
        // window.open("http://f.zhongyiyf.com/17game", "_self");
        // https://jzyx.ink/oV5vq3
      } else if (this.gameIdList.includes(+this.gameId)) {
        const url = this.gameConfig.iosDownloadUrl;
        let a = document.createElement("a");
        a.setAttribute("href", url);
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.tel {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bottom {
  z-index: 9990;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  span {
    display: inline-block;
    color: #fff;
    font-size: 12px;
    white-space: nowrap;
    transform: translateX(686px);
    animation: 14.0933s linear 1s infinite normal none running textRunning;
  }
}
@-webkit-keyframes textRunning {
  100% {
    transform: translateX(-686px);
  }
}
@keyframes textRunning {
  100% {
    transform: translateX(-682px);
  }
}
.bar {
  height: 1rem;
  overflow: hidden;
}
/deep/ .btn-close {
  width: 0.25rem;
  height: 0.25rem;
  background-color: #828282;
  color: whitesmoke;
  font-size: 0.24rem;
  position: absolute;
  right: 0.02rem;
  top: 1.65rem;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .video-view {
  width: 100%;
  height: 2.18rem;
  margin-top: 1.6rem;
}
.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  pointer-events: auto;
  top: 0;
  z-index: 3;
}
.mySwiper2 {
}
@import url("../../../node_modules/swiper/dist/css/swiper.css");
/deep/ .swiper-pagination2 {
  text-align: center;
}
/deep/ .swiper-pagination-bullet {
  width: 0.18rem;
  height: 0.02rem;
  border-radius: 0;
  background: #eee;
}
.btn {
  width: 1.22rem;
  height: 0.44rem;
  color: #fff9b8;
  line-height: 0.44rem;
  text-align: center;
  font-size: 0.18rem;
  background: inherit;
  background-image: url("@/assets/img/share/button.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.title {
  width: 2rem;
  height: 0.4rem;
  line-height: 0.3rem;
  text-align: center;
  font-weight: normal;
  color: #fff4da;
  font-size: 0.2rem;
  background-image: url("@/assets/img/share/ztx-title.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0.2rem auto;
  margin-bottom: 0.1rem;
}
.desc {
  width: 100%;
  text-align: center;
  background: linear-gradient(180deg, #fff4da 0%, #ffce75 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.3rem;
}
.swiper {
  width: 100%;
  overflow: hidden;
  // padding-top: 50px;
  // padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 2rem;
  height: 3.56rem;
}

.swiper-slide img {
  display: block;
  width: 100%;
  border-radius: 4px;
}
.unionList {
  box-sizing: border-box;
  overflow: auto;
  .footer {
    width: 100%;
    height: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #1d2741;
    box-sizing: border-box;
    padding-left: 0.12rem;
    padding-right: 0.18rem;
    .icon {
      height: 0.6rem;
      width: 0.6rem;
    }
  }
  .banner2 {
    overflow: hidden;
    width: 100%;
    // height: 6.86rem;
    background-image: url("https://pic.zhongyiyf.com/img/image_bg3.30afa74c.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .area {
      .a-top,
      .a-bottom {
        color: #141936;
        display: flex;
        align-items: center;
        width: 82%;
        height: 0.4rem;
        margin: 0 auto;

        li {
          &:nth-of-type(2) {
            width: 36%;
          }
          &:nth-of-type(3) {
            width: 28%;
          }
          width: 36%;
          text-align: center;
          font-size: 0.14rem;
        }
      }
      .a-bottom {
        height: 0.32rem;
      }
      box-sizing: border-box;
      padding-top: 0.186rem;
      width: 3.5rem;
      height: 1.9rem;
      margin: 0 auto;
      background-image: url("https://pic.zhongyiyf.com/img/area-bg.cfd4d76a.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-bottom: 0.3rem;
    }
  }
  .banner1 {
    width: 100%;
    height: 5.02rem;
    background-image: url("https://pic.zhongyiyf.com/img/image_bg2.f0faf215.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
  }
  .video {
    width: 100%;
    height: 100%;
    background: #999;
    text-align: center;
    line-height: 4;
    color: #eee;
    font-size: 0.26rem;
    // background-image: url("@/assets/img/share/video-pre.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .video_img {
      width: 100%;
      max-height: 2.18rem;
    }
    .video_play {
      width: 0.52rem;
      height: 0.52rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .top {
    box-sizing: border-box;
    padding: 0 0.16rem;
    padding-right: 0.1rem;
    height: 0.8rem;
    background-image: url("@/assets/img/share/image_bg1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    .icon {
      width: 0.44rem;
      height: 0.44rem;
    }
  }
  .mr15 {
    margin-right: 15px;
  }
}
</style>
<style lang="less" scoped>
.sign_wrap {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%;);
  padding: 0 36px;
  width: 300px;
  min-height: 80px;
  border-radius: 4px;
  background: #0d1621;

  .sign_close {
    position: absolute;
    top: 4px;
    right: 4px;
    display: block;
    width: 32px;
    height: 32px;
    background: url("@/assets/img/close_sign.png") no-repeat;
    background-size: cover;
  }

  .sign_hd {
    position: relative;
    height: 76px;
    border-bottom: 1px solid #efefef;
    text-align: center;

    &::after {
      content: "";
      // position: absolute;
      // right: 60px;
      // bottom: 0;
      // display: block;
      // width: 0;
      // height: 0;
      // border-width: 0 6px 8px;
      // border-style: solid;
      // border-color: transparent transparent #969292;
    }

    &::before {
      // content: "注册";
      // position: absolute;
      // right: 37px;
      // bottom: 9px;
      // display: block;
      // width: 60px;
      // color: #fff;
      // font-size: 14px;
    }

    img {
      margin-top: 14px;
      width: 48px;
      height: 48px;
    }
  }

  .sign_bd {
    padding-top: 16px;
    .info_wrap {
      padding-top: 9px;
      padding-left: 16px;
      span {
        font-size: 14px;
        color: #969696;
      }
    }
    .ipt_bg {
      margin-top: 12px;
      display: flex;
      justify-content: flex-start;
      height: 40px;
      background: rgba(255, 255, 255, 0.1);

      input {
        padding-left: 12px;
        flex: 1;
        background: rgba(255, 255, 255, 0);
        outline: none;
        border: none;
        color: #fff;

        &::placeholder {
          color: #888;
        }
      }

      .verify_wrap {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #449fc6;

        span {
          display: inline-block;
        }
        .btn_txt {
          width: 100px;
        }
        .verify_txt {
          width: 120px;
          color: #5c5c5c;
        }
      }
    }
    .sign_text {
      margin-top: 20px;
      font-size: 24px;
      text-align: center;
      color: #fff;
    }
    .next_btn {
      margin-top: 28px;
      height: 40px;
      width: 100%;
      outline: none;
      border: none;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.33) 0%,
        rgba(255, 255, 255, 0.13) 100%
      );
      border-radius: 4px 4px 4px 4px;
      color: #fff;
      // border: 1px solid;
      border-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.51)
        )
        1 1;

      // display: block;
      // text-align: center;
      // font-size: 17px;
      // line-height: 40px;

      &.pre_btn {
        background: none;

        border: 1px solid rgba(255, 255, 255, 0.3);
      }
    }
  }

  .sign_fd {
    height: 24px;
  }
}
</style>
