import { transferDataForTreeSelect, collectAllAccountId } from "@/utils/util";
export default {
  data() {
    return {
      inviterOptions: [],
      selectInviter: [],
      inviterParams: {},
      permissionCode: [],
      withdrawal: "withdrawal",
      markRole: "markRole",
      markAccount: "markAccount",
    };
  },
  methods: {
    getAllInviter(obj = {}) {
      const { api = "getAllInviter" } = obj;
      const unionId = this.$route.query.unionId;
      this.inviterParams = unionId ? { unionId } : {};
      this.$api.dataApi[api](this.inviterParams).then((res) => {
        if (res.code === 0) {
          const returnData = res.data;
          const result = returnData
            ? Array.isArray(returnData)
              ? returnData
              : [returnData]
            : [];
          this.inviterOptions = transferDataForTreeSelect(result);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    changeTreeValues(values) {
      let accountIds = values.filter((val) => val.toString().length === 15);
      let fromIds = [];
      const ids = values.filter((val) => val.length < 15);
      fromIds = ids.map((val) => {
        return collectAllAccountId(this.inviterOptions, val);
      });

      this.selectInviter = [...accountIds, ...fromIds.flat(Infinity)];
    },

    getBtnPermission(id) {
      this.$api.dataApi.getBtnPermission({ id }).then((res) => {
        if (res.code === 0) {
          console.log("res", res);
          const codes = res.data || [];
          this.permissionCode = codes;
        }
      });
    },
    isPermission(codes, code) {
      return codes && codes.includes(code);
    },
  },
};
