<template>
  <div class="unionList ground-white">
    <el-page-header
      @back="$router.back()"
      content=""
      style="margin-bottom: 15px"
      v-if="$choosePlantorm().platformType === 0"
    >
    </el-page-header>
    <!-- {{ formInline }} -->
    <el-form :inline="true" :model="formInline" label-width="70px">
      <el-form-item label="任务ID">
        <el-input
          v-model="formInline.taskId"
          clearable
          placeholder="任务ID"
        ></el-input>
      </el-form-item>
      <el-form-item prop="gameId" label="游戏">
        <el-select
          v-model="formInline.gameId"
          clearable
          placeholder="请选择游戏名称"
          @change="changeGame"
        >
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="gameArea" label="区服">
        <!-- <el-input v-model="formInline.gameArea"></el-input> -->
        <el-select
          v-model="formInline.gameServiceId"
          clearable
          placeholder="请选择区服"
        >
          <el-option
            v-for="item in areaListData"
            :key="item.id"
            :label="item.serviceName"
            :value="item.id"
          >
          </el-option>
        </el-select> </el-form-item
      ><br />
      <el-form-item prop="gameRoleId" label="状态">
        <el-select v-model="formInline.status" placeholder="请选择状态">
          <el-option :label="`全部`" :value="null"> </el-option>
          <el-option :label="`启用`" :value="0"> </el-option>
          <el-option :label="`关闭`" :value="1"> </el-option>
        </el-select>
      </el-form-item>

      <!-- <el-form-item prop="inviterAccountId" label="推广员">
        <el-select
          v-model="formInline.inviterAccountId"
          clearable
          placeholder="请输入推广员"
          filterable
          remote
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in staffListData"
            :key="item.id"
            :label="item.nickName"
            :value="item.accountId"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <InviterSelect
        propId="inviterAccountIdList"
        v-model="formInline.inviterAccountIdList"
        :multiple="true"
        :options="inviterOptions"
        :disable-branch-nodes="false"
      />
      <InviterSelect
        propId="headAccountId"
        label="团长"
        v-model="formInline.headAccountId"
        :multiple="false"
        placeholder="请输入团长"
        :options="inviterOptions"
        :disable-branch-nodes="true"
      />
      <!-- <el-form-item prop="headAccountId" label="团长">
        <el-select
          v-model="formInline.headAccountId"
          clearable
          placeholder="请输入团长"
          filterable
          remote
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in staffListData"
            :key="item.id"
            :label="item.nickName"
            :value="item.accountId"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="查询时间">
        <el-date-picker
          v-model="formInline.time"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.pageIndex = 1;
            getList();
          "
          >查询</el-button
        >
        <el-button
          type="primary"
          v-if="0"
          @click="
            dialogFormVisible = true;
            editDialogShow = false;
            resetForm('queryForm');
          "
          >新建计划
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="id"
        label="任务ID"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameName"
        label="游戏"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameName"
        label="状态"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{ scope.row.status === 0 ? "启用" : "关闭" }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameServiceName"
        label="区服"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="headAccountName"
        label="团长"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="createTime"
        label="创建时间"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="" label="推广员" width="150">
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="openEditDialog(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.totalElements"
    >
    </el-pagination>

    <!-- dialog -->
    <el-dialog title="推广员" :visible.sync="dialogFormVisible">
      <!-- {{ queryForm }} -->
      <ul class="persion-box">
        <li class="persion-item" v-for="item in dialogData" :key="item.id">
          {{ item.nickName }}
        </li>
      </ul>
    </el-dialog>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      value1: "",
      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      getGameListData: [],
      areaListData: [],
      staffListData: [],
      headListData: [],
      dialogFormVisible: false,
      editDialogShow: false,
      loading: false,
      editUnionId: "",
      formInline: {
        gameId: null,
        gameServiceId: null,
        taskId: null,
        status: null,
        inviterAccountId: null,
        headAccountId: null,
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
        time: ["", ""],
      },
      queryForm: {
        serviceTime: "",
        gameId: null,
        capacity: 1,
        serviceName: "",
      },
      tableData: [],
      taskId: null,
      dialogData: [],
    };
  },
  mounted() {
    // this.getPermission();
    this.getGameList();
    this.getList();
    this.getAllInviter();
  },
  methods: {
    remoteMethod(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryStaff({
            nickName: query,
          })
          .then((res) => {
            console.log("getGameList", res);
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.staffListData = result;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.staffListData = [];
      }
    },
    changeGame(e) {
      console.log(e);
      this.formInline.gameServiceId = null;
      this.getArea(e);
    },
    getArea(id) {
      this.$api.organizationApi
        .queryAreaPlan({
          gameId: id,
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : [];
            this.areaListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getListNew() {
      // 获取公会列表
      this.$api.taskApi
        .queryMember({
          taskId: this.taskId,
          pageIndex: 1,
          pageSize: 2000,
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.dialogData = result;
            this.dialogFormVisible = true;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      // 获取公会列表
      const [startTime, endTime] = this.formInline.time || ["", ""];
      this.$api.taskApi
        .queryTask({
          ...this.formInline,
          unionId: this.$route.query.unionId,
          startTime: startTime ? startTime + " 00:00:00" : null,
          endTime: endTime ? endTime + " 23:59:59" : null,
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.totalElements = res.data.totalElements;
            this.formInline.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.gameApi
        .queryPackage({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    resetUnionPassword() {
      this.$api.organizationApi
        .resetPassword({
          unionId: this.editUnionId,
        })
        .then((res) => {
          console.log("resetPassword", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    deleteUnion(data) {
      this.$api.organizationApi
        .deleteUnion({
          unionId: data.id,
          isDelete: 1,
        })
        .then((res) => {
          console.log("deleteUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    changeUnionStatus(data) {
      this.$api.organizationApi
        .changeUnionStatus({
          unionId: data.id,
          status: data.status === 0 ? 1 : 0,
        })
        .then((res) => {
          console.log("changeUnionStatus", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    updateUnion() {
      this.$api.organizationApi
        .updateAreaPlan({ ...this.queryForm, id: this.editUnionId })
        .then((res) => {
          console.log("updateUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.dialogFormVisible = false;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    addUnion() {
      this.$api.organizationApi.createAreaPlan(this.queryForm).then((res) => {
        console.log("createUnion", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.dialogFormVisible = false;
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
    onSubmit() {
      console.log("submit!");
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/organization/bm/staff/list",
        query: {
          unionId: e.id,
        },
      });
    },
    resetPassWord(pId) {
      this.$confirm("重置密码:默认密码123456", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 重置密码
        this.editUnionId = pId.id;
        this.resetUnionPassword();
      });
    },
    changeStatus(pId) {
      this.$confirm("确定要更改状态吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 改变状态
        this.changeUnionStatus(pId);
      });
    },
    deleteItem(pId) {
      this.$confirm("确定要删除数据吗？", "", {
        type: "error",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 删除
        this.deleteUnion(pId);
      });
    },
    openEditDialog(data) {
      this.taskId = data.id;
      this.getListNew();
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .persion-box {
    overflow: hidden;
    .persion-item {
      float: left;
      height: 35px;
      padding: 0 10px;
    }
  }
  .mr15 {
    margin-right: 15px;
  }
}
</style>
