<template>
  <div class="unionList ground-white">
    <el-page-header
      @back="$router.back()"
      v-if="$choosePlantorm().platformType === 0"
    >
    </el-page-header>
    <br />
    <!-- {{ formInline }} -->
    <el-form :inline="true" :model="formInline">
      <el-form-item prop="gameId" label="">
        <el-select
          v-model="formInline.gameId"
          clearable
          placeholder="请选择游戏名称"
          @change="changeGame"
        >
          <el-option
            v-for="item in gameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="gameArea" label="">
        <!-- <el-input v-model="roleForm.gameArea"></el-input> -->
        <el-select
          v-model="formInline.gameArea"
          clearable
          placeholder="请选择区服"
          @change="changeArea"
        >
          <el-option
            v-for="item in areaListData"
            :key="item.id"
            :label="item.serviceName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="gameRoleId" label="">
        <el-select
          v-model="formInline.gameRoleId"
          clearable
          placeholder="请输入角色"
          filterable
          remote
          :remote-method="remoteMethodNew"
          :loading="loadingNew"
        >
          <el-option
            v-for="item in gameRoleListData"
            :key="item.id"
            :label="item.roleName"
            :value="item.roleId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="formInline.gameAccountId"
          placeholder="游戏账号ID"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="formInline.opPlatform"
          clearable
          placeholder="客户端"
          @change="changeOpPlatform"
        >
          <el-option label="Android" value="Android"> </el-option>
          <el-option label="Ios" value="iOS"> </el-option>
        </el-select>
      </el-form-item>
      <InviterSelect
        label=""
        propId="headAccountIdList"
        v-model="formInline.headAccountIdList"
        :multiple="true"
        placeholder="请选择团长"
        :options="inviterOptions"
        :disable-branch-nodes="false"
      />
      <el-form-item>
        <el-select
          v-model="formInline.lastChargeStatus"
          clearable
          placeholder="支付状态"
        >
          <el-option
            v-for="item in rechargeStatusList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item>
        <el-input
          v-model="formInline.gameRoleId"
          placeholder="精确搜索订单号"
        ></el-input>
      </el-form-item> -->
      <el-form-item>
        <el-date-picker
          v-model="formInline.time"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="登录开始日期"
          end-placeholder="登录结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="formInline.time1"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="充值开始日期"
          end-placeholder="充值结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.pageIndex = 1;
            getList();
            getListSum();
          "
          >查询</el-button
        >
        <el-button type="primary" v-if="1" @click="exportList">导出</el-button>
      </el-form-item>
    </el-form>
    <div class="data_bar">
      <p>
        充值总额：<span>{{ totalData }}</span>
      </p>
    </div>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="gameName"
        label="游戏"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <GamePlatform
            :gameName="scope.row.gameName"
            :opPlatform="scope.row.opPlatform"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="gameServiceName" label="区服名">
      </el-table-column>
      <el-table-column
        align="center"
        prop="originGameServiceName"
        label="原区服"
      >
      </el-table-column>
      <el-table-column align="center" prop="gameRoleName" label="角色名">
        <template slot-scope="scope">
          <RoleMark
            type="role"
            :rowData="scope.row"
            :colorMark="scope.row.roleColorMark"
            :showData="scope.row.gameRoleName"
            :hasPermission="isPermission(permissionCode, markRole)"
            :getList="getList"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="roleLevel" label="等级">
      </el-table-column>
      <el-table-column align="center" prop="gameAccountId" label="游戏账号ID">
        <template slot-scope="scope">
          <RoleMark
            type="account"
            :rowData="scope.row"
            :colorMark="scope.row.accountColorMark"
            :showData="scope.row.gameAccountId"
            :hasPermission="isPermission(permissionCode, markAccount)"
            :getList="getList"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="inviterAccountName" label="推广员">
        <template slot-scope="scope">
          <MemberShow
            :accountName="scope.row.inviterAccountName"
            :inviterAccountId="scope.row.inviterAccountId"
            :departmentName="scope.row.departmentName"
            :roleName="scope.row.roleName"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="headAccountName" label="团长">
        <template slot-scope="scope">
          <MemberShow
            :accountName="scope.row.headAccountName"
            :inviterAccountId="scope.row.headAccountId"
            :departmentName="scope.row.departmentName"
            :roleName="scope.row.roleName"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="ipAreaInfo" label="创建IP">
        <template slot-scope="scope">
          <div>
            <div>{{ scope.row.registerIp }}</div>
            <div>{{ scope.row.ipAreaInfo }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="firstLoginTime" label="首次登录">
      </el-table-column>
      <el-table-column align="center" prop="lastLoginTime" label="最近登录">
      </el-table-column>
      <el-table-column align="center" prop="lastOfflineTime" label="下线时间">
      </el-table-column>

      <el-table-column align="center" prop="chargeTotalAmount" label="充值总额">
      </el-table-column>
      <el-table-column align="center" prop="lastChargeAmount" label="最近充值">
      </el-table-column>
      <el-table-column align="center" prop="lastChargeTime" label="充值时间">
        <!-- <template slot-scope="scope">
          {{ $moment(scope.row.serviceTime).format("YYYY-MM-DD HH:mm:ss") }}
        </template> -->
      </el-table-column>
      <el-table-column align="center" prop="lastChargeStatus" label="充值状态">
        <template slot-scope="scope">
          <div>
            <el-tag
              :key="tagList[scope.row.lastChargeStatus]"
              :type="tagList[scope.row.lastChargeStatus]"
            >
              {{ statusList[scope.row.lastChargeStatus] || "未充值" }}
            </el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="" label="详情" width="80">
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="handleClick(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.totalElements"
    >
    </el-pagination>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      loadingNew: false,
      rechargeStatusList: [
        { name: "创建中", id: 100 },
        { name: "支付中", id: 200 },
        { name: "发货中", id: 300 },
        { name: "已完成", id: 400 },
        { name: "已取消", id: 500 },
      ],
      statusList: {
        100: "创建中",
        200: "支付中",
        300: "发货中",
        400: "已完成",
        500: "已取消",
      },
      tagList: {
        100: "",
        200: "warning",
        300: "info",
        400: "success",
        500: "danger",
      },

      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      getGameListData: [],
      gameListData: [],
      areaListData: [],
      gameRoleListData: [],
      staffListData: [],
      dialogFormVisible: false,
      editDialogShow: false,
      loading: false,
      editUnionId: "",
      formInline: {
        lastChargeStatus: null,
        gameRoleId: null,
        gameAccountId: null,
        gameId: null,
        gameArea: null,
        inviterAccountIdList: [],
        opPlatform: null,
        startTime: "",
        endTime: "",
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
        time: ["", ""],
        time1: ["", ""],
      },
      queryForm: {
        serviceTime: "",
        gameId: null,
        capacity: 1,
        serviceName: "",
      },
      tableData: [],
      totalData: 0,
      inviterOptions: [],
      selectInviter: [],
    };
  },
  mounted() {
    // this.getPermission();
    this.getGameList();
    this.getList();
    this.getListSum();
    this.getAllInviter();
    this.getBtnPermission(this.$route.query.pageId); // 获取页面权限
  },
  methods: {
    exportList() {
      this.formInline.pageSize = 50000;
      // 获取公会列表
      this.$api.dataApi
        .taskRoleExport({
          ...this.formInline,
          recentLoginStartTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          recentLoginEndTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
          rechargeStartTime: this.formInline.time1
            ? this.$dateUtil.dayToStartUnix(this.formInline.time1[0])
            : "",
          rechargeEndTime: this.formInline.time1
            ? this.$dateUtil.dayToEndUnix(this.formInline.time1[1])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          this.formInline.pageSize = 20;
          // 导出Excel
          this.$exportExcel(
            res,
            `已出数据_${this.$moment().format(
              "YYYY-MM-DD"
            )}_${this.$moment().unix()}.xlsx`
          );
        });
    },
    getListSum() {
      // 获取公会列表
      this.$api.dataApi
        .taskRoleTotal({
          ...this.formInline,
          recentLoginStartTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          recentLoginEndTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
          rechargeStartTime: this.formInline.time1
            ? this.$dateUtil.dayToStartUnix(this.formInline.time1[0])
            : "",
          rechargeEndTime: this.formInline.time1
            ? this.$dateUtil.dayToEndUnix(this.formInline.time1[1])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data ? res.data : 0;
            this.totalData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      // 获取公会列表
      this.$api.dataApi
        .taskRoleQuery({
          ...this.formInline,
          recentLoginStartTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          recentLoginEndTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[1])
            : "",
          rechargeStartTime: this.formInline.time1
            ? this.$dateUtil.dayToStartUnix(this.formInline.time1[0])
            : "",
          rechargeEndTime: this.formInline.time1
            ? this.$dateUtil.dayToEndUnix(this.formInline.time1[1])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.totalElements = res.data.totalElements;
            this.formInline.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.gameApi
        .querySubPackage({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
            this.gameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryStaff({
            nickName: query,
          })
          .then((res) => {
            console.log("getGameList", res);
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.staffListData = result;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.staffListData = [];
      }
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/data/union/commander/detail",
        query: {
          gameRoleId: e.gameRoleId,
        },
      });
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
    changeOpPlatform(val) {
      if (!val) {
        this.formInline.opPlatform = null;
      }
    },
    changeGame(e) {
      console.log(e);
      this.formInline.gameArea = null;
      this.getArea(e);
    },
    getArea(id) {
      this.$api.organizationApi
        .queryAreaPlan({
          gameId: id,
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : [];
            this.areaListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    remoteMethodNew(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryGameRole({
            // accountIdList: [0],
            firstChargeInfo: true,
            roleName: query,
            gameIdList: this.formInline.gameId
              ? [this.formInline.gameId]
              : null,
            gameServerIdList: this.formInline.gameArea
              ? [parseInt(this.formInline.gameArea)]
              : null,
            needAccountInfo: true,
            // roleIdList: ["string"],
            // roleName: "string",
          })
          .then((res) => {
            console.log("getGameList", res);
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.gameRoleListData = result;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.gameRoleListData = [];
      }
    },
    changeArea(e) {
      console.log(e);
    },
    changeRole(e) {
      console.log(e);
      if (!e) {
        return;
      }
      this.$api.organizationApi
        .queryGameRole({
          // accountIdList: [0],
          firstChargeInfo: true,
          // gameAccountIdList: [0],
          gameIdList: this.formInline.gameId ? [this.formInline.gameId] : null,
          gameServerIdList: this.formInline.gameArea
            ? [parseInt(this.formInline.gameArea)]
            : null,
          needAccountInfo: true,
          roleIdList: [`${e}`],
          // roleName: "string",
        })
        .then((res) => {
          if (res.code === 0) {
            console.log(res);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  /deep/ .el-radio-button--mini .el-radio-button__inner {
    padding: 7px 15px;
    font-size: 12px;
    border-radius: 0;
    margin: 5px;
    border: 1px #dcdfe6 solid !important;
    border-radius: 4px !important;
    box-shadow: none !important;
  }
  .mr15 {
    margin-right: 15px;
  }
  .data_bar {
    margin-bottom: 20px;
    padding: 0 16px;
    background: #eff5ff;
    line-height: 40px;
    width: calc(100vw - 258px);
    margin-left: -16px;
    display: flex;
    justify-content: flex-start;
    gap: 24px;

    span {
      // padding-right: 1vw;
      color: #255ec0;
    }
  }
}
.role_mark {
  // position: relative;
  // z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .role_mark_icon {
    position: relative;
    margin-right: 6px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    border: 3px solid #fff;

    &::before {
      position: absolute;
      top: -4px;
      left: -4px;
      content: "";
      width: 12px;
      height: 12px;
      display: block;
      border: 1px solid #b0b0b0;
      border-radius: 12px;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 4px;
      height: 4px;
      display: block;
      border-radius: 6px;
      border: 1px solid #b0b0b0;
    }
  }

  .role_mark_icon.no_line {
    &::before {
      position: absolute;
      top: -4px;
      left: -4px;
      content: "";
      width: 12px;
      height: 12px;
      display: block;
      // border: 1px solid #b0b0b0;
      border: none;
      border-radius: 12px;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 4px;
      height: 4px;
      display: block;
      border-radius: 6px;
      border: none;
      // border: 1px solid #b0b0b0;
    }
  }
  .opt_panel {
    position: absolute;
    z-index: 200;
    top: -30px;
    border: 1px solid #ddd;
    width: 240px;
    padding: 20px;
  }
}
.role_bd {
  display: flex;
  justify-content: flex-start;

  span {
    position: relative;
    margin-right: 10px;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    border: 3px solid #d1d1d1;
    cursor: pointer;

    &:nth-of-type(1) {
      border-color: #fb5353;
    }
    &:nth-of-type(2) {
      border-color: #f49207;
    }
    &:nth-of-type(3) {
      border-color: #888;
    }
    &:nth-of-type(4) {
      border-color: #fff;
      &::before {
        position: absolute;
        top: -4px;
        left: -4px;
        content: "";
        width: 12px;
        height: 12px;
        display: block;
        border: 1px solid #b0b0b0;
        border-radius: 12px;
      }
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 4px;
        height: 4px;
        display: block;
        border-radius: 6px;
        border: 1px solid #b0b0b0;
      }
    }
  }
}
</style>
