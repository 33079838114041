<template>
  <div class="game-apply ground-white">
    <el-form :inline="true" :model="formSecond">
      <el-form-item label="公会名称">
        <el-select
          v-model="formSecond.unionId"
          clearable
          filterable
          placeholder="请选择公会名称"
        >
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.unionName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="审批状态">
        <el-select
          v-model="formSecond.auditStatus"
          clearable
          placeholder="请选择审批状态"
        >
          <el-option
            v-for="item in auditStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="getList">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        v-for="column in applicationConfig"
        :key="column.prop"
        align="center"
        :prop="column.prop"
        :label="column.label"
        :show-overflow-tooltip="true"
      />
      <el-table-column align="center" prop="" label="操作按钮" width="150">
        <template slot-scope="scope">
          <el-button type="text" size="small">{{
            approvalStatus[scope.row.approvalStatus]
          }}</el-button>
          <div v-if="scope.row.approvalStatus === 0">
            <el-button
              type="text"
              size="small"
              @click="gotoApproval(scope.row, 1)"
              >同意</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="gotoApproval(scope.row, 2)"
              >拒绝</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="formSecond.page.pageSize"
      :current-page.sync="formSecond.page.pageIndex"
      @current-change="handleCurrentChange"
      :total="formSecond.page.totalElements"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formSecond: {
        unionId: null,
        auditStatus: null,
        page: {
          pageIndex: 1,
          pageSize: 20,
          totalElements: 0,
        },
      },
      getGameListData: [],
      auditStatus: [
        {
          label: "全部",
          value: null,
        },
        {
          label: "审核中",
          value: 0,
        },
        {
          label: "审核通过",
          value: 1,
        },
        {
          label: "审核拒绝",
          value: 2,
        },
      ],
      approvalStatus: ["", "已同意", "已拒绝"],
      tableData: [],
      applicationConfig: [
        {
          label: "公会名称",
          prop: "unionName",
        },
        {
          label: "申请人ID",
          prop: "applyAccountId",
        },
        {
          label: "申请人",
          prop: "applyAccountName",
        },
        {
          label: "申请时间",
          prop: "applyTime",
        },
        {
          label: "提现额度",
          prop: "withdrawAmount",
        },
        {
          label: "审批状态",
          prop: "approvalStatusDesc",
        },
        {
          label: "审批时间",
          prop: "approvalTime",
        },
        {
          label: "审批人",
          prop: "auditAccountName",
        },
      ],
      approvalStatusList: ["审核中", "审核通过", "审核拒绝"],
    };
  },
  mounted() {
    this.getGameList();
    this.getList();
  },
  methods: {
    getList() {
      console.log("1", 1);
      this.$api.billingApi
        .getWithdrawalApplyList({ ...this.formSecond })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            console.log("UI", res.data, res.data.content);
            this.tableData = result.map((item) => {
              return {
                ...item,
                approvalStatusDesc:
                  this.approvalStatusList[item.approvalStatus],
              };
            });
            this.formSecond.page.totalElements = res.data.totalElements;
            this.formSecond.page.pageIndex = res.data.pageIndex;
          }
        });
    },
    handleCurrentChange(val) {
      this.formSecond.page.pageIndex = val;
      this.getList();
    },
    getGameList() {
      this.$api.organizationApi
        .getUnionList({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content.list : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    gotoApproval(item, type) {
      console.log("item", item);
      const REFUSE = 2;
      // const AGREE = 1;
      const { id: assetWithdrawId, auditAccountId } = item;
      const showText = type === REFUSE ? "拒绝" : "同意";

      this.$confirm(`此操作将${showText}该申请, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.billingApi
            .approvalWithdrawApply({
              assetWithdrawId,
              auditAccountId,
              auditStatus: type,
            })
            .then((res) => {
              if (res.code === 0) {
                this.$message.success(res.msg);
                this.getList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消${showText}操作`,
          });
        });
    },
  },
};
</script>
