<template>
  <div class="unionList ground-white">
    <el-form :inline="true" :model="formInline" label-width="70px">
      <el-form-item label="任务ID">
        <el-input
          v-model="formInline.taskId"
          clearable
          placeholder="任务ID"
        ></el-input>
      </el-form-item>
      <el-form-item prop="gameId" label="游戏">
        <el-select
          v-model="formInline.gameId"
          clearable
          placeholder="请选择游戏名称"
          @change="changeGame"
        >
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="gameArea" label="区服">
        <!-- <el-input v-model="formInline.gameArea"></el-input> -->
        <el-select
          v-model.number="formInline.gameServiceId"
          clearable
          placeholder="请选择区服"
        >
          <el-option
            v-for="item in areaListData"
            :key="item.id"
            :label="item.serviceName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="gameRoleId" label="状态">
        <el-select v-model="formInline.status" placeholder="请选择状态">
          <el-option :label="`全部`" :value="null"> </el-option>
          <el-option :label="`启用`" :value="0"> </el-option>
          <el-option :label="`关闭`" :value="1"> </el-option>
        </el-select>
      </el-form-item>
      <InviterSelect
        propId="inviterAccountIdList"
        v-model="formInline.inviterAccountIdList"
        :multiple="true"
        :options="inviterOptions"
        :disable-branch-nodes="false"
      />
      <InviterSelect
        label="团长"
        propId="headAccountId"
        v-model="formInline.headAccountId"
        :multiple="false"
        placeholder="请选择团长"
        :options="inviterOptions"
        :disable-branch-nodes="true"
      />
      <el-form-item label="查询时间">
        <el-date-picker
          v-model="formInline.time"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.pageIndex = 1;
            getList();
          "
          >查询</el-button
        >
        <el-button
          type="primary"
          v-if="1"
          @click="
            dialogFormVisibleNew = true;
            resetForm('queryForm');
          "
          >新建任务
        </el-button>
      </el-form-item>
    </el-form>
    <!-- {{ memberList }}
    {{ staffListData }} -->
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:36px;padding:0;color:#95A3BB'
      "
    >
      <el-table-column
        align="center"
        prop="id"
        label="任务ID"
        width="100"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameName"
        label="游戏"
        width="150"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameName"
        label="状态"
        width="150"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-tag :type="scope.row.status === 0 ? 'success' : 'danger'">{{
            scope.row.status === 0 ? "启用" : "关闭"
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameServiceName"
        label="区服"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="headAccountName"
        label="团长"
        width="150"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="startTime"
        label="创建时间"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="endTime"
        label="结束时间"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="" label="推广员" width="80">
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="openEditDialog(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column align="center" prop="" label="操作" width="250">
        <template slot-scope="scope" v-if="scope.row.status === 0">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="openEditHeadId(scope.row)"
            >编辑团长</el-button
          >
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="openEditDialog2(scope.row)"
            >编辑推广员</el-button
          >
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="changeStatus(scope.row)"
            >{{ scope.row.status === 0 ? "关闭任务" : "启用任务" }}</el-button
          >
          <!-- <el-link
            type="primary"
            :underline="false"
            @click="openEditHeadId(scope.row)"
            >编辑团长</el-link
          >&nbsp;&nbsp;
          <el-link
            type="primary"
            :underline="false"
            @click="openEditDialog2(scope.row)"
            >编辑推广员</el-link
          >&nbsp;&nbsp;
          <el-link
            type="primary"
            :underline="false"
            @click="changeStatus(scope.row)"
            >{{ scope.row.status === 0 ? "关闭任务" : "启用任务" }}</el-link
          >&nbsp;&nbsp;
          <el-link
            type="danger"
            :underline="false"
            @click="deleteItem(scope.row)"
            >删除</el-link
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.totalElements"
    >
    </el-pagination>

    <!-- dialog -->
    <el-dialog title="推广员" :visible.sync="dialogFormVisible">
      <!-- {{ queryForm }} -->
      <ul class="persion-box">
        <li class="persion-item" v-for="item in dialogData" :key="item.id">
          {{ item.nickName }}
        </li>
      </ul>
    </el-dialog>

    <!-- dialogNew-->
    <el-dialog
      title="创建任务"
      :visible.sync="dialogFormVisibleNew"
      width="420px"
    >
      <el-form :model="queryForm" ref="queryForm" label-width="120px">
        <el-form-item prop="gameId" label="游戏">
          <el-select
            v-model="queryForm.gameId"
            clearable
            placeholder="请选择游戏名称"
            @change="changeGame"
          >
            <el-option
              v-for="item in getGameListData"
              :key="item.id"
              :label="item.gameName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="gameServiceId" label="区服" v-if="queryForm.gameId">
          <!-- <el-input v-model="queryForm.gameArea"></el-input> -->
          <el-select
            v-model="queryForm.gameServiceId"
            clearable
            placeholder="请选择区服"
          >
            <el-option
              v-for="item in areaListData"
              :key="item.id"
              :label="item.serviceName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <InviterSelect
          label="团长"
          key="团长2"
          propId="headAccountId"
          placeholder="请输入团长"
          v-model="queryForm.headAccountId"
          :multiple="false"
          :options="inviterOptions"
          :disable-branch-nodes="true"
          :appendToBody="false"
        />
        <InviterSelect
          propId="memberList"
          key="推广员2"
          v-model="queryForm.memberList"
          :multiple="true"
          :options="inviterOptions"
          :disable-branch-nodes="false"
          :appendToBody="false"
        />
        <el-form-item label=""> 注意：保存后任务即刻生效 </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleNew = false">取 消</el-button>
        <el-button :loading="isShowLoading" type="primary" @click="addUnion">{{
          createText
        }}</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="编辑团长"
      :visible.sync="dialogFormVisibleNew1"
      width="420px"
    >
      <el-form ref="queryForm1" label-width="100px">
        <!-- <InviterSelect
          label="团长"
          key="团长3"
          placeholder="请输入团长"
          propId="headAccountId"
          v-model="headAccountId"
          :multiple="false"
          :options="inviterOptions"
          :disable-branch-nodes="true"
          :appendToBody="false"
        /> -->
        <el-form-item prop="headAccountId" label="团长">
          <treeselect
            v-model="headAccountId"
            :multiple="false"
            :disable-branch-nodes="true"
            placeholder="请输入团长"
            :options="inviterOptions"
            style="width: 180px"
            @input="changeTreeValues"
            noOptionsText="暂无选项"
            noChildrenText="暂无人员"
            :appendToBody="false"
          >
            <p
              style="padding-right: 20px; white-space: nowrap; width: 100%"
              slot="option-label"
              slot-scope="{ node }"
              :title="node.label"
            >
              <template> {{ node.label }}</template>
            </p>
          </treeselect>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleNew1 = false">取 消</el-button>
        <el-button type="primary" @click="updateHead">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="编辑推广员"
      :visible.sync="dialogFormVisibleNew2"
      width="420px"
    >
      <el-form label-width="100px">
        <!-- <InviterSelect
          propId="memberList"
          v-model="memberList"
          :multiple="true"
          :options="inviterOptions"
          :disable-branch-nodes="false"
          :appendToBody="false"
        /> -->
        <el-form-item prop="memberList" label="推广员">
          <treeselect
            v-model="memberList"
            :multiple="true"
            :disable-branch-nodes="false"
            placeholder="请输入推广员"
            :options="inviterOptions"
            style="width: 180px"
            @input="changeTreeValues"
            noOptionsText="暂无选项"
            noChildrenText="暂无人员"
            :appendToBody="false"
          >
            <p
              style="padding-right: 20px; white-space: nowrap; width: 100%"
              slot="option-label"
              slot-scope="{ node }"
              :title="node.label"
            >
              <template> {{ node.label }}</template>
            </p>
          </treeselect>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleNew2 = false">取 消</el-button>
        <el-button type="primary" @click="updateInviters">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import Treeselect from "@riophae/vue-treeselect";
import throttle from "lodash/throttle";
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      value1: "",
      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      getGameListData: [],
      areaListData: [],
      staffListData: [],
      headListData: [],
      dialogFormVisible: false,
      dialogFormVisibleNew: false,
      dialogFormVisibleNew1: false,
      dialogFormVisibleNew2: false,
      editDialogShow: false,
      loading: false,
      editUnionId: "",
      formInline: {
        gameId: null,
        gameServiceId: null,
        taskId: null,
        status: null,
        inviterAccountIdList: null,
        headAccountId: null,
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
        time: ["", ""],
      },
      queryForm: {
        gameId: null,
        gameServiceId: null,
        memberList: [],
        headAccountId: null,
      },
      tableData: [],
      taskId: null,
      memberList: [],
      headAccountId: null,
      dialogData: [],
      testArray: [],
      isShowLoading: false,
      createText: "确 定",
    };
  },
  watch: {
    dialogFormVisibleNew(v) {
      if (!v) {
        this.queryForm = {
          gameId: null,
          gameServiceId: null,
          memberList: null,
          headAccountId: null,
        };
        this.isShowLoading = false;
        this.createText = "确 定";
        this.$refs.queryForm.resetFields();
      }
    },
  },
  mounted() {
    // this.getPermission();
    this.getGameList();
    this.getList();
    this.getAllInviter({ api: "getAllInviterV2" });
  },
  methods: {
    remoteMethod(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryStaff({
            nickName: query,
          })
          .then((res) => {
            console.log("getGameList", res);
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.staffListData = result;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.staffListData = [];
      }
    },
    remoteMethodNew(query) {
      if (query !== "") {
        this.$api.organizationApi.queryStaff({}).then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data ? res.data : [];
            this.staffListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        this.staffListData = [];
      }
    },
    changeGame(e) {
      console.log(e);
      this.queryForm.gameServiceId = null;
      this.getArea(e);
    },
    getArea(id) {
      this.$api.organizationApi
        .queryAreaPlan({
          gameId: id,
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : [];
            this.areaListData = result.map((item) => {
              return {
                ...item,
                id: +item.id,
              };
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getListNew() {
      // 获取公会列表
      this.$api.taskApi
        .queryMember({
          taskId: this.taskId,
          pageIndex: 1,
          pageSize: 2000,
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.dialogData = result;
            this.dialogFormVisible = true;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      // 获取公会列表
      this.$api.taskApi
        .queryTask({
          ...this.formInline,
          startTime: this.formInline.time[0]
            ? this.formInline.time[0] + " 00:00:00"
            : "",
          endTime: this.formInline.time[1]
            ? this.formInline.time[1] + " 23:59:59"
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.totalElements = res.data.totalElements;
            this.formInline.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.gameApi
        .queryGameList({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    resetUnionPassword() {
      this.$api.organizationApi
        .resetPassword({
          unionId: this.editUnionId,
        })
        .then((res) => {
          console.log("resetPassword", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    deleteUnion(data) {
      this.$api.taskApi
        .deleteTask({
          id: data.id,
          isDelete: 1,
        })
        .then((res) => {
          console.log("deleteUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    updateHead(data) {
      console.log(data);
      this.$api.taskApi
        .updateHead({
          id: this.taskId,
          headAccountId: this.headAccountId,
        })
        .then((res) => {
          console.log("deleteUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.dialogFormVisibleNew1 = false;
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    updateInviters() {
      this.$api.taskApi
        .updateInviters({
          id: this.taskId,
          memberList: this.memberList,
        })
        .then((res) => {
          console.log("deleteUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.dialogFormVisibleNew2 = false;
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    changeUnionStatus(data) {
      this.$api.taskApi
        .changeTaskStatus({
          id: data.id,
          status: data.status === 0 ? 1 : 0,
        })
        .then((res) => {
          console.log("changeUnionStatus", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    updateUnion() {
      this.$api.organizationApi
        .updateAreaPlan({ ...this.queryForm, id: this.editUnionId })
        .then((res) => {
          console.log("updateUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.dialogFormVisible = false;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    addUnion: throttle(function () {
      this.isShowLoading = true;
      this.createText = "创建中";
      this.$api.taskApi.createTask(this.queryForm).then((res) => {
        if (res.code === 0) {
          this.$message.success("操作成功!");

          this.isShowLoading = false;
          this.createText = "确 定";
          this.dialogFormVisibleNew = false;
          this.getList();
        } else {
          this.$message.error(res.msg);
          this.isShowLoading = false;
          this.createText = "确 定";
        }
      });
    }, 1000),
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
    onSubmit() {
      console.log("submit!");
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/organization/bm/staff/list",
        query: {
          unionId: e.id,
        },
      });
    },
    resetPassWord(pId) {
      this.$confirm("重置密码:默认密码123456", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 重置密码
        this.editUnionId = pId.id;
        this.resetUnionPassword();
      });
    },
    changeStatus(pId) {
      this.$confirm("确定要更改状态吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 改变状态
        this.changeUnionStatus(pId);
      });
    },
    deleteItem(pId) {
      this.$confirm("确定要删除任务吗？", "", {
        type: "error",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 删除
        this.deleteUnion(pId);
      });
    },
    openEditDialog(data) {
      this.taskId = data.id;
      this.getListNew();
    },
    openEditHeadId(data) {
      this.taskId = data.id;
      this.headAccountId = data.headAccountId;
      // this.remoteMethodNew(null);
      this.dialogFormVisibleNew1 = true;
    },
    openEditDialog2(data) {
      this.taskId = data.id;
      // debugger;
      this.$api.taskApi
        .queryMember({
          taskId: data.id,
          pageIndex: 1,
          pageSize: 2000,
        })
        .then((res) => {
          console.log("memberList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];

            this.memberList = result.map((item) => {
              return +item.accountId;
            });
            // this.memberList = [];
            // // this.remoteMethodNew(null);
            // for (const iterator of result) {
            //   this.memberList.push(+iterator.accountId);
            // }

            console.log("呼啦", this.memberList);

            this.dialogFormVisibleNew2 = true;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .persion-box {
    overflow: hidden;
    .persion-item {
      float: left;
      height: 35px;
      padding: 0 10px;
    }
  }
  .mr15 {
    margin-right: 15px;
  }
}
</style>
