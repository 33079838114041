<template>
  <div class="unionList ground-white">
    <el-form :inline="true" :model="formInline">
      <el-form-item label="公会名称">
        <el-select
          v-model="formInline.unionId"
          clearable
          placeholder="请选择公会"
        >
          <el-option
            v-for="item in unionListData"
            :key="item.id"
            :label="item.unionName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="游戏：">
        <el-select
          v-model="formInline.gameId"
          clearable
          placeholder="请选择游戏"
        >
          <el-option
            v-for="item in gameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click="
            formInline.page.pageIndex = 1;
            getList();
          "
          >查询</el-button
        >
        <el-button type="primary" @click="handleShowPlanPop"
          >新建计划
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column align="center" prop="unionName" label="公会名称">
      </el-table-column>
      <el-table-column align="center" prop="gameName" label="游戏名称">
      </el-table-column>
      <el-table-column align="center" prop="gameServiceName" label="游戏区服" />
      <el-table-column
        align="center"
        prop="firstRechargeAmount"
        label="初始额度"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="availableAmount"
        width="200"
        label="可用额度"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="recharge"
        width="100"
        label="充值额度"
      >
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="goRecharge(scope.row)"
            >充值</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.page.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.page.totalElements"
    >
    </el-pagination>
    <el-dialog
      title="新建计划"
      :visible.sync="isShowPlanPop"
      width="75%"
      :before-close="handleClosePlanPop"
    >
      <el-form
        :inline="false"
        ref="planForm"
        :model="planForm"
        label-width="140px"
      >
        <el-form-item label="游戏：">
          <el-select
            v-model="planForm.gameId"
            clearable
            placeholder="请选择游戏"
            @change="changeGame"
          >
            <el-option
              v-for="item in gameListData"
              :key="item.id"
              :label="item.gameName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择区服：" prop="content">
          <el-checkbox
            :indeterminate="isGameServiceIndeterminate"
            v-model="gameServiceCheckAll"
            @change="handleCheckAllGameServiceChange"
            :disabled="!Boolean(subGameListData.length)"
            >全选</el-checkbox
          >
          <div class="list_item" style="">
            <el-checkbox-group
              v-model="planForm.gameServiceIdList"
              @change="handleCheckedGameServiceChange"
            >
              <el-checkbox
                v-for="list in subGameListData"
                :label="list.serviceId"
                :key="list.serviceId"
              >
                {{ list.serviceName }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-form-item>
        <el-form-item label="选择公会：" prop="content">
          <el-checkbox
            :indeterminate="isUnionIndeterminate"
            v-model="unionCheckAll"
            @change="handleCheckAllUnionChange"
            >全选</el-checkbox
          >
          <div class="list_item" style="">
            <el-checkbox-group
              v-model="planForm.unionIdList"
              @change="handleCheckedUnionChange"
            >
              <el-checkbox
                v-for="list in unionListData"
                :label="list.id"
                :key="list.id"
              >
                {{ list.unionName }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-form-item>
        <el-form-item label="初始额度：" prop="content">
          <el-input
            v-model.number="planForm.amount"
            style="width: 180px"
            autocomplete="off"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="isShowPlanPop = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="handleCreatePlan"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 充值 -->
    <el-dialog
      title="充值"
      :visible.sync="isShowRechargePop"
      width="400px"
      :before-close="rechargeClose"
      align="center"
    >
      <el-form
        :inline="false"
        ref="rechargeForm"
        :model="rechargeForm"
        label-width="140px"
      >
        <el-form-item label="可用额度：" prop="content">
          <el-input
            v-model.number="rechargeForm.amount"
            style="width: 200px"
            autocomplete="off"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-button size="mini" @click="isShowRechargePop = false">
          取 消
        </el-button>
        <el-button size="mini" type="primary" @click="handleRecharg"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      unionListData: [],
      gameListData: [],
      formInline: {
        unionId: null,
        gameId: null,
        page: { pageIndex: 1, pageSize: 20, totalElements: 0 },
      },
      select: "1",
      tableData: [{}],
      topData: {},
      planForm: {
        unionIdList: [],
        amount: null,
        gameId: null,
        gameIdList: [],
        gameServiceIdList: [],
      },
      isShowPlanPop: false,
      isUnionIndeterminate: false,
      unionCheckAll: false,
      isGameServiceIndeterminate: false,
      gameServiceCheckAll: false,
      subGameListData: [],
      // 充值弹窗
      rowData: {},
      isShowRechargePop: false,
      rechargeForm: {
        amount: null,
      },
    };
  },
  watch: {
    isShowPlanPop(v) {
      if (!v) {
        // this.$refs.planForm.resetFields();
        this.planForm = {
          unionIdList: [],
          initAmount: null,
          gameId: null,
          gameIdList: [],
          gameServiceIdList: [],
        };
        this.unionCheckAll = false;
        this.gameServiceCheckAll = false;
        this.isUnionIndeterminate = false;
        this.isGameServiceIndeterminate = false;
        this.subGameListData = [];
      }
    },
  },
  created() {
    this.getGameList();
    this.getUnionList();
    this.getList();
    // this.getTop();
  },
  methods: {
    getList() {
      // 获取公会列表
      this.$api.billingApi
        .queryQuotaList({
          ...this.formInline,
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.page.totalElements = res.data.totalElements;
            this.formInline.page.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.gameApi
        .queryPackage({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.gameListData = result;
            // this.getGameList.unshift({
            //   id: null,
            //   gameName: "全部",
            // });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // getTop() {
    //   // 获取公会列表
    //   this.$api.billingApi.queryAdjustmentSum({}).then((res) => {
    //     console.log("queryAdjustmentSum", res);
    //     if (res.code === 0) {
    //       const result = res.data ? res.data : {};
    //       this.topData = result;
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    // },
    getUnionList() {
      this.$api.organizationApi
        .getUnionList({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data?.content?.list
              ? res.data?.content?.list
              : [];
            this.unionListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeGame(val) {
      console.log("val", val);

      if (!val) {
        this.subGameListData = [];
        return;
      }

      this.$api.organizationApi
        .queryAreaPlan({ pageIndex: 1, pageSize: 1000, gameId: val })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data?.data ? res.data?.data : [];
            this.subGameListData = result;
          }
        });
    },
    handleCurrentChange(val) {
      this.formInline.page.pageIndex = val;
      this.getList();
    },
    handleClosePlanPop() {
      this.isShowPlanPop = false;
    },
    handleCreatePlan() {
      const { gameId } = this.planForm;
      this.$api.billingApi
        .initUnionQuota({ ...this.planForm, gameIdList: [gameId] })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.isShowPlanPop = false;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleCheckAllUnionChange(val) {
      this.planForm.unionIdList = val
        ? this.unionListData.map((item) => item.id)
        : [];
      this.isUnionIndeterminate = false;
    },
    handleCheckedUnionChange(val) {
      let checkedCount = val.length;
      this.unionCheckAll = checkedCount === this.unionListData.length;
      this.isUnionIndeterminate =
        checkedCount > 0 && checkedCount < this.unionListData.length;
    },
    handleCheckAllGameServiceChange(val) {
      this.planForm.gameServiceIdList = val
        ? this.subGameListData.map((item) => item.serviceId)
        : [];
      this.isGameServiceIndeterminate = false;
    },
    handleCheckedGameServiceChange(val) {
      let checkedCount = val.length;
      this.gameServiceCheckAll = checkedCount === this.subGameListData.length;
      this.isGameServiceIndeterminate =
        checkedCount > 0 && checkedCount < this.subGameListData.length;
    },
    handleShowPlanPop() {
      if (this.unionListData.length) {
        console.log("this.uni", this.unionListData);
        this.isShowPlanPop = true;
      }
    },
    // 打开充值弹窗
    goRecharge(row) {
      this.rowData = row;
      this.isShowRechargePop = true;
      this.rechargeForm.amount = null;
    },
    // 关闭充值弹窗
    rechargeClose() {
      this.isShowRechargePop = false;
    },
    // 充值
    handleRecharg() {
      const { unionId, gameId, gameServiceId } = this.rowData;
      const { amount } = this.rechargeForm;
      const params = { unionId, gameId, gameServiceId, amount };
      this.$api.billingApi.addVAAAAmount(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.rechargeClose();
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-radio-button--mini .el-radio-button__inner {
  padding: 7px 15px;
  font-size: 12px;
  border-radius: 0;
  margin: 5px;
  border: 1px #dcdfe6 solid !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}
.ss /deep/ .el-select .el-input {
  width: 80px;
}
/deep/ .input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.unionList {
  .mr15 {
    margin-right: 15px;
  }
}
.list_item {
  max-height: 190px;
  overflow-y: auto;
  overflow-x: hidden;
  line-height: 24px;

  /deep/ .el-checkbox {
    width: 140px;
    line-height: 24px;

    // /deep/ .el-form-item__content {
    //   line-height: 24px;
    // }
  }
}
</style>
