<template>
  <div class="unionList ground-white">
    <el-page-header
      @back="$router.back()"
      content=""
      style="margin-bottom: 15px"
    >
    </el-page-header>
    <!-- {{ formInline }} -->
    <el-form :inline="true" :model="formInline">
      <el-form-item label="选择游戏">
        <el-radio-group
          v-model="formInline.gameId"
          size="mini"
          @input="changeGame"
        >
          <el-radio-button
            v-for="(item, index) in getGameListData"
            :key="index"
            :label="item.id"
            >{{ item.gameName }}</el-radio-button
          >
        </el-radio-group>
      </el-form-item>
      <!-- <br /> -->
      <el-divider></el-divider>
      <el-form-item label="选择区服">
        <el-radio-group
          v-model="formInline.gameServiceId"
          size="mini"
          @input="changeArea"
        >
          <el-radio-button
            v-for="(item, index) in getAreaListData"
            :key="index"
            :label="item.id"
            >{{ item.serviceName }}</el-radio-button
          >
        </el-radio-group>
      </el-form-item>
      <el-divider></el-divider>
      <el-row
        :gutter="20"
        class="recharge-area"
        v-show="formInline.gameServiceId"
      >
        <el-col :span="6">
          <div class="card">
            <p>可用额度</p>
            <p>
              <span>{{ accountInfo.availableAmount }}元&nbsp;&nbsp;</span>
              <el-button
                type="success"
                size="mini"
                @click="
                  $router.push({
                    path: '/billing/bm/recharge/detail',
                    query: {
                      gameId: formInline.gameId,
                      gameServiceId: formInline.gameServiceId,
                      unionId: $route.query.unionId,
                    },
                  })
                "
                >明细</el-button
              >
            </p>
            <p>
              初始充值额度{{
                accountInfo.initFirstRechargeAmount
              }}，后续额度以充值比例1:{{ accountInfo.supportRatio }}实时发放
              <el-button type="text" size="mini">领取方式></el-button>
            </p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="card">
            <p>冻结额度</p>
            <p>{{ accountInfo.frozenAmount }}元</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="card">
            <p>首冲剩余个数</p>
            <p>{{ accountInfo.firstChargeRemainNum }}</p>
            <p>
              共{{ accountInfo.initFirstChargeNum }}个首充，以每次328面额发放
            </p>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="card">
            <p>已用额度</p>
            <p>{{ accountInfo.usedAmount }}元</p>
          </div>
        </el-col>
      </el-row>
      <!-- <el-form-item label="公会名称">
        <el-select
          v-model="formInline.unionId"
          clearable
          filterable
          placeholder="请选择公会名称"
        >
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.unionName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item label="申请状态">
        <el-select
          v-model="formInline.applyStatus"
          clearable
          filterable
          placeholder="请选择申请状态"
        >
          <el-option
            v-for="item in statusList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="游戏账号ID">
        <el-input
          v-model="formInline.gameAccountId"
          placeholder="游戏账号ID"
        ></el-input>
      </el-form-item>
      <el-form-item label="申请ID">
        <el-input v-model="formInline.applyId" placeholder="申请ID"></el-input>
      </el-form-item>
      <el-form-item label="申请角色名">
        <el-select
          v-model="formInline.gameRoleId"
          clearable
          placeholder="申请角色名"
          filterable
          remote
          :remote-method="remoteMethodNew"
          :loading="loadingNew"
          @change="changeRole"
        >
          <el-option
            v-for="item in gameRoleListData"
            :key="item.id"
            :label="item.roleName"
            :value="item.roleId"
          >
          </el-option>
        </el-select> </el-form-item
      ><br />
      <el-form-item label="申请时间范围">
        <el-date-picker
          v-model="formInline.time"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="完成时间范围">
        <el-date-picker
          v-model="formInline.time1"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            // formInline.pageIndex = 1;
            getList()
          "
          >查询</el-button
        >
        <!-- <el-button type="primary" v-if="1" @click="$message.info('暂未开放')"
          >充值申请
        </el-button> -->
      </el-form-item>
    </el-form>
    <p style="margin-top: 10px; margin-bottom: 20px" v-if="false">
      总计：申请笔数：73680笔，成功笔数：71702笔，实际付款笔数：0笔，充值金额：0元。
    </p>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="applyId"
        label="申请ID"
        width="100"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="applyTime"
        label="申请时间"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="gameAccountId" label="游戏账号ID">
        <!-- <template slot-scope="scope">
          {{ $moment(scope.row.serviceTime).format("YYYY-MM-DD") }}
        </template> -->
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameName"
        label="游戏信息"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameServiceName"
        label="游戏区服名称"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameRoleName"
        label="角色名"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="chargeAmount" label="充值金额">
      </el-table-column>
      <el-table-column align="center" prop="applyStatus" label="申请状态">
        <template slot-scope="scope">
          {{
            statusList.find((item) => item.id === scope.row.applyStatus).name ||
            "-"
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="completeTime" label="完成时间">
      </el-table-column>
      <el-table-column align="center" prop="applyAccountName" label="申请人">
      </el-table-column>
      <el-table-column v-if="0" align="center" prop="" label="详情" width="150">
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="goSecond(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="formInline.page.pageSize"
      :current-page.sync="formInline.page.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.page.totalElements"
    >
    </el-pagination>

    <!-- dialog -->
    <el-dialog
      :title="editDialogShow ? '编辑结算周期' : '新建计划'"
      :visible.sync="dialogFormVisible"
    >
      <!-- {{ queryForm }} -->

      <el-form :model="queryForm" status-icon ref="queryForm" :inline="true">
        <el-form-item label="游戏名称" prop="gameId">
          <el-input-number :min="1" v-model="queryForm.gameId">
          </el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="editDialogShow" type="primary" @click="updateUnion"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="addUnion">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      loadingNew: false,
      value1: "",
      radio2: "",
      statusList: [
        { id: 0, name: "待发放" },
        { id: 1, name: "发放成功" },
        { id: 2, name: "发放失败" },
        // { id: 3, name: "待付款" },
        // { id: 4, name: "待审核" },
        // { id: 5, name: "取消申请" },
        // { id: 6, name: "驳回" },
      ],
      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      getGameListData: [],
      gameRoleListData: [],
      getAreaListData: [{ id: null, serviceName: "全部" }],
      dialogFormVisible: false,
      editDialogShow: false,
      editUnionId: "",
      accountInfo: {},
      formInline: {
        gameId: null,
        gameServiceId: null,
        page: {
          pageIndex: 1,
          pageSize: 20,
          totalElements: 0,
        },
      },
      queryForm: {
        serviceTime: "",
        gameId: null,
        capacity: 1,
        serviceName: "",
      },
      tableData: [],
    };
  },
  mounted() {
    // this.getPermission();
    this.getGameList();
    this.getList();
  },
  methods: {
    changeRole(e) {
      console.log(e);
      if (!e) {
        return;
      }
    },
    remoteMethodNew(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryGameRole({
            roleName: query,
            // accountIdList: [0],
            firstChargeInfo: true,
            // gameAccountIdList: [0],
            gameIdList: this.formInline.gameId
              ? [this.formInline.gameId]
              : null,
            gameServerIdList: this.formInline.gameServiceId
              ? [parseInt(this.formInline.gameServiceId)]
              : null,
            needAccountInfo: true,
            // roleIdList: ["string"],
            // roleName: "string",
          })
          .then((res) => {
            console.log("getGameList", res);
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.gameRoleListData = result;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.gameRoleListData = [];
      }
    },
    getAreaList(id) {
      this.$api.organizationApi
        .queryAreaPlan({
          gameId: id,
          startTime: null,
          endTime: null,
          pageIndex: 1,
          pageSize: 10000,
        })
        .then((res) => {
          console.log("getAreaList", res);
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : {};
            this.getAreaListData = result;
            this.getAreaListData.unshift({
              id: null,
              serviceName: "全部",
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.gameApi
        .queryGameList({
          unionId: this.$route.query.unionId,
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("querySubPackage", res);
          if (res.code === 0) {
            console.log();
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
            this.getGameListData.unshift({
              id: null,
              gameName: "全部",
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeArea(id) {
      console.log(id);

      if (id === null) {
        this.getList();
        return;
      }
      this.getRechargeAccountInfo();
      this.getList();
      //
    },
    getRechargeAccountInfo() {
      const item = this.getAreaListData.find(
        (item) => item.id === this.formInline.gameServiceId
      );
      // 获取公会列表
      this.$api.billingApi
        .queryRechargeAccountInfo({
          unionId: this.$route.query.unionId,
          gameId: this.formInline.gameId,
          gameServiceId: +item.serviceId,
          // applyStartTime: this.formInline.time ? this.formInline.time[0] : "",
          // applyEndTime: this.formInline.time ? this.formInline.time[1] : "",
          // completeStartTime: this.formInline.time1
          //   ? this.formInline.time1[0]
          //   : "",
          // completeEndTime: this.formInline.time1
          //   ? this.formInline.time1[1]
          //   : "",
        })
        .then((res) => {
          console.log("queryRechargeAccountInfo", res);
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.accountInfo = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeGame(id) {
      console.log(id);
      if (id === null) {
        this.getAreaListData = [
          {
            id: null,
            serviceName: "全部",
          },
        ];
        this.formInline.gameServiceId = null;
        this.getList();
        return;
      }
      this.formInline.gameServiceId = null;
      this.getAreaList(id);
      this.getList();
    },
    goSecond(data) {
      this.$router.push({
        path: "/billing/bm/settle/union",
        query: {
          unionId: data.unionId,
        },
      });
    },
    getList() {
      // 获取公会列表
      this.$api.billingApi
        .queryRechargeDetail({
          ...this.formInline,
          unionId: this.$route.query.unionId || null,
          applyStartTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          applyEndTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[0])
            : "",
          completeStartTime: this.formInline.time1
            ? this.$dateUtil.dayToStartUnix(this.formInline.time1[0])
            : "",
          completeEndTime: this.formInline.time1
            ? this.$dateUtil.dayToEndUnix(this.formInline.time1[0])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.page.totalElements = res.data.totalElements;
            this.formInline.page.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    resetUnionPassword() {
      this.$api.organizationApi
        .resetPassword({
          unionId: this.editUnionId,
        })
        .then((res) => {
          console.log("resetPassword", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    deleteUnion(data) {
      this.$api.organizationApi
        .deleteUnion({
          unionId: data.id,
          isDelete: 1,
        })
        .then((res) => {
          console.log("deleteUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    changeUnionStatus(data) {
      this.$api.organizationApi
        .changeUnionStatus({
          unionId: data.id,
          status: data.status === 0 ? 1 : 0,
        })
        .then((res) => {
          console.log("changeUnionStatus", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    updateUnion() {
      this.$api.organizationApi
        .updateAreaPlan({ ...this.queryForm, id: this.editUnionId })
        .then((res) => {
          console.log("updateUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.dialogFormVisible = false;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    addUnion() {
      this.$api.organizationApi.createAreaPlan(this.queryForm).then((res) => {
        console.log("createUnion", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.dialogFormVisible = false;
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleCurrentChange(val) {
      this.formInline.page.pageIndex = val;
      this.getList();
    },
    onSubmit() {
      console.log("submit!");
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/organization/bm/staff/list",
        query: {
          unionId: e.id,
        },
      });
    },
    resetPassWord(pId) {
      this.$confirm("重置密码:默认密码123456", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 重置密码
        this.editUnionId = pId.id;
        this.resetUnionPassword();
      });
    },
    changeStatus(pId) {
      this.$confirm("确定要更改状态吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 改变状态
        this.changeUnionStatus(pId);
      });
    },
    deleteItem(pId) {
      this.$confirm("确定要删除数据吗？", "", {
        type: "error",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 删除
        this.deleteUnion(pId);
      });
    },
    openEditDialog(data) {
      this.dialogFormVisible = true;
      this.editDialogShow = true;
      this.editUnionId = data.id;
      setTimeout(() => {
        this.queryForm.serviceTime = this.$moment(data.serviceTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.queryForm.gameId = data.gameId;
        this.queryForm.capacity = data.capacity;
        this.queryForm.serviceName = data.serviceName;
      }, 1);
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.card {
  height: 130px;
  background: #eee;
  border-radius: 6px;
  padding: 5px 16px;
  box-sizing: border-box;
  & > p:nth-of-type(1) {
    font-size: 16px;
    line-height: 2.2;
  }
  & > p:nth-of-type(2) {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.8;
    /deep/.el-button--mini {
      vertical-align: middle;
    }
  }
}
.unionList {
  /deep/ .el-radio-button--mini .el-radio-button__inner {
    padding: 7px 15px;
    font-size: 12px;
    border-radius: 0;
    margin: 5px;
    border: 1px #dcdfe6 solid !important;
    border-radius: 4px !important;
    box-shadow: none !important;
  }
  .recharge-area {
    margin-bottom: 20px;
  }
  .mr15 {
    margin-right: 15px;
  }
}
</style>
