export default [
  // ----------------   超管    ----------------
  // 后管【数据统计】模块
  {
    path: "/dashboard/bm/statistics",
    name: "后管数据统计",
    component: () => import("@/views/dashboard/bm/indexPage"),
  },

  // ----------------   公会   ----------------
  // 公会【数据统计】模块
  {
    path: "/dashboard/union/statistics",
    name: "后管数据统计",
    component: () => import("@/views/dashboard/union/indexPage"),
  },
];
