<template>
  <div class="unionList ground-white">
    <!-- {{ formInline }} -->
    <el-form :inline="true" :model="formInline">
      <el-form-item label="公会名称">
        <el-select
          v-model="formInline.unionId"
          clearable
          filterable
          placeholder="请选择公会名称"
        >
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.unionName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click="
            formInline.page.pageIndex = 1;
            getList();
            getListSum();
          "
          >查询</el-button
        >
        <!-- <el-button
          type="primary"
          
          @click="
            dialogFormVisible = true;
            editDialogShow = false;
            resetForm('queryForm');
          "
          >新建计划
        </el-button> -->
      </el-form-item>
    </el-form>
    <div class="data_bar">
      <p>
        下级公会总和：<span>{{ topData.unionSumCount }}个</span>
      </p>
      <p>
        入账：<span>{{ topData.inAmount }}</span>
      </p>
      <p>
        出账：<span>{{ topData.outAmount }}</span>
      </p>
      <p>
        调账次数：<span>{{ topData.unionAdjustCount }}</span>
      </p>
    </div>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="unionId"
        label="公会ID"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="unionName" label="公会名称">
      </el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间">
        <template slot-scope="scope">
          {{ $moment(scope.row.createTime).format("YYYY-MM-DD HH:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="adjustCount" label="调账次数">
      </el-table-column>
      <el-table-column align="center" prop="" label="详情" width="150">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            style="color: #666"
            @click="
              $router.push({
                path: '/billing/bm/checking/record/detail',
                query: {
                  unionId: scope.row.unionId,
                },
              })
            "
            >查看</el-button
          >
          <el-button type="text" size="small" @click="openEditDialog(scope.row)"
            >调账</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.page.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.page.totalElements"
    >
    </el-pagination>

    <!-- dialog -->
    <el-dialog title="调账" :visible.sync="dialogFormVisible">
      <!-- {{ queryForm }} -->

      <el-form :model="queryForm" status-icon ref="queryForm" :inline="true">
        <el-form-item prop="adjustType" label="" label-width="100px">
          <el-radio-group
            v-model="queryForm.accountType"
            size="mini"
            @change="changeType"
          >
            <el-radio-button :label="1">下级公会调额</el-radio-button>
            <el-radio-button :label="2">同级级公会调额</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <br />

        <el-form-item style="border-rignt: 3px #666 solid">
          <p style="margin-bottom: 20px; margin-right: 30px">
            <span> 本公会名称：{{ queryForm.fromUnionName }} </span>
            <br />
            <span> 本公会ID：{{ queryForm.fromUnionId }} </span>
            <br />
            <span>账户余额：{{ queryForm.fromUnionBalance }}</span>
          </p>
        </el-form-item>
        <el-form-item v-if="queryForm.accountType === 1">
          <div>
            <span>后管账户余额：{{ adminBalance.balanceAmount }}</span>
            <br />
            <br />
          </div>
        </el-form-item>
        <el-form-item
          v-show="queryForm.accountType === 2"
          label=""
          prop="rightUnionIdeId"
        >
          <span>目标公会名称：</span>
          <el-select
            v-model="queryForm.toUnionId"
            clearable
            filterable
            placeholder="请选择公会名称"
            @change="getToUnion"
          >
            <el-option
              v-for="item in getGameListData"
              :key="item.id"
              :label="item.unionName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <br />
          <span>目标企业ID：{{ queryForm.toUnionId }}</span>
          <br />
          <span>账户余额：{{ queryForm.toUnionBalance }}</span>
        </el-form-item>
        <el-form-item> </el-form-item>
        <br />
        <el-form-item label="调额" class="ss" prop="toMoney">
          <el-input
            placeholder="请输入金额"
            class="input-with-select"
            oninput="value=value.replace(/[^0-9.]/g,'')"
            v-model="queryForm.adjustAmount"
          >
            <el-select
              v-model="queryForm.amountDirectionType"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="入账" :value="0"></el-option>
              <el-option label="出账" :value="1"></el-option>
            </el-select>
          </el-input>
          <span style="color: darkred; font-size: 13px"
            >入账出账都是相对于本公会。(入账:本公会+，出账:本公会-)</span
          >
        </el-form-item>
        <br />
        <el-form-item label="关联账单" prop="orderNo">
          <el-input
            style="width: 240px"
            placeholder="请输入账单"
            v-model="queryForm.orderNo"
          >
          </el-input>
        </el-form-item>
        <br />
        <el-form-item label="备注" prop="adjustDesc">
          <el-input
            style="width: 300px"
            type="textarea"
            placeholder="请输入内容"
            v-model="queryForm.adjustRemark"
            maxlength="30"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>

        <el-button type="primary" @click="addUnion">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";

export default {
  data() {
    return {
      value1: "",
      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      getGameListData: [],
      dialogFormVisible: false,
      editDialogShow: false,
      editUnionId: "",
      adminBalance: {
        assetAccountId: "",
        balanceAmount: "",
      },
      formInline: {
        unionName: "",
        page: { pageIndex: 1, pageSize: 20, totalElements: 0 },
      },
      select: "1",
      queryForm: {
        fromUnionName: "",
        adjustAmount: null,
        adjustRemark: "",
        amountDirectionType: 0,
        fromAssetAccountId: null,
        fromUnionId: null,
        toAssetAccountId: null,
        toUnionId: null,
        accountType: 1,
        fromUnionBalance: "",
        toUnionBalance: "",
        orderNo: "",
      },
      tableData: [{}],
      topData: {},
    };
  },
  mounted() {
    // this.getPermission();
    this.getGameList();
    this.getList();
    this.getTop();
  },
  methods: {
    addUnion() {
      const requestForm = {};
      requestForm.fromAssetAccountId = this.queryForm.fromAssetAccountId;
      requestForm.fromUnionId = this.queryForm.fromUnionId;

      requestForm.toAssetAccountId =
        this.queryForm.accountType === 1
          ? this.adminBalance.assetAccountId
          : this.queryForm.toAssetAccountId;

      requestForm.toUnionId =
        this.queryForm.accountType === 1 ? null : this.queryForm.toUnionId;

      requestForm.adjustAmount = this.queryForm.adjustAmount;
      requestForm.adjustRemark = this.queryForm.adjustRemark;
      requestForm.amountDirectionType = this.queryForm.amountDirectionType;
      requestForm.orderNo = this.queryForm.orderNo;

      this.$api.billingApi.addSubAdjustment(requestForm).then((res) => {
        console.log("createUnion", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.dialogFormVisible = false;
          this.getList();
          this.getTop();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    openEditDialog(data) {
      this.queryForm.fromUnionName = data.unionName;
      this.queryForm.accountType = 1;
      this.queryForm.adjustAmount = null;
      this.queryForm.adjustRemark = null;
      this.queryForm.orderNo = "";
      this.getAdminBalanceAmount();

      // fromAssetAccountId;
      this.getBalanceAmount(this.queryForm.accountType, data.unionId);

      this.dialogFormVisible = true;
    },
    getToUnion(id) {
      console.log(id);
      this.getBalanceAmount(this.queryForm.accountType, id);
    },
    changeType(e) {
      console.log(e);

      if (e === 2) {
        // this.queryForm.fromUnionId = this.queryForm.toUnionId;
        // this.queryForm.fromAssetAccountId = this.queryForm.toAssetAccountId;
        this.queryForm.toUnionId = null;
        this.queryForm.toAssetAccountId = null;
        this.queryForm.toUnionBalance = null;
      }
    },

    getBalanceAmount(from, id) {
      console.log(id);
      this.$api.billingApi
        .getAdjustmentBalance({
          unionId: id,
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            // const result = res.data ? res.data : {};
            if (from === 1) {
              this.queryForm.fromUnionBalance = res.data.balanceAmount;
              this.queryForm.fromAssetAccountId = res.data.assetAccountId;
              this.queryForm.fromUnionId = res.data.unionId;
            } else {
              this.queryForm.toAssetAccountId = res.data.assetAccountId;
              this.queryForm.toUnionId = res.data.unionId;
              this.queryForm.toUnionBalance = res.data.balanceAmount;
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getAdminBalanceAmount() {
      this.$api.billingApi.getAdjustmentBalance({}).then((res) => {
        console.log("getUnionList", res);
        if (res.code === 0) {
          // const result = res.data ? res.data : {};
          this.adminBalance.assetAccountId = res.data.assetAccountId;
          this.adminBalance.balanceAmount = res.data.balanceAmount;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getList() {
      // 获取公会列表
      this.$api.billingApi
        .queryAdjustmentList({
          ...this.formInline,
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.page.totalElements = res.data.totalElements;
            this.formInline.page.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getTop() {
      // 获取公会列表
      this.$api.billingApi.queryAdjustmentSum({}).then((res) => {
        console.log("queryAdjustmentSum", res);
        if (res.code === 0) {
          const result = res.data ? res.data : {};
          this.topData = result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getGameList() {
      this.$api.organizationApi
        .getUnionList({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content.list : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    resetUnionPassword() {
      this.$api.organizationApi
        .resetPassword({
          unionId: this.editUnionId,
        })
        .then((res) => {
          console.log("resetPassword", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    deleteUnion(data) {
      this.$api.organizationApi
        .deleteUnion({
          unionId: data.id,
          isDelete: 1,
        })
        .then((res) => {
          console.log("deleteUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    changeUnionStatus(data) {
      this.$api.organizationApi
        .changeUnionStatus({
          unionId: data.id,
          status: data.status === 0 ? 1 : 0,
        })
        .then((res) => {
          console.log("changeUnionStatus", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
          } else {
            this.$message.error(res.msg);
          }
          this.getList();
        });
    },
    updateUnion() {
      this.$api.organizationApi
        .updateAreaPlan({ ...this.queryForm, id: this.editUnionId })
        .then((res) => {
          console.log("updateUnion", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.dialogFormVisible = false;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    handleCurrentChange(val) {
      this.formInline.page.pageIndex = val;
      this.getList();
    },
    onSubmit() {
      console.log("submit!");
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/organization/bm/staff/list",
        query: {
          unionId: e.id,
        },
      });
    },
    resetPassWord(pId) {
      this.$confirm("重置密码:默认密码123456", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 重置密码
        this.editUnionId = pId.id;
        this.resetUnionPassword();
      });
    },
    changeStatus(pId) {
      this.$confirm("确定要更改状态吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 改变状态
        this.changeUnionStatus(pId);
      });
    },
    deleteItem(pId) {
      this.$confirm("确定要删除数据吗？", "", {
        type: "error",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 删除
        this.deleteUnion(pId);
      });
    },

    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-radio-button--mini .el-radio-button__inner {
  padding: 7px 15px;
  font-size: 12px;
  border-radius: 0;
  margin: 5px;
  border: 1px #dcdfe6 solid !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}
.ss /deep/ .el-select .el-input {
  width: 80px;
}
/deep/ .input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.unionList {
  .mr15 {
    margin-right: 15px;
  }
}
</style>
