<template>
  <div class="unionList ground-white">
    <!-- {{ formInline }} -->
    <p
      style="
        color: #3a4a65;
        font-size: 22px;
        font-weight: bold;
        margin-right: 60px;
        margin-bottom: 20px;
      "
    >
      迁移记录
    </p>
    <el-form :inline="true" :model="formInline">
      <el-form-item label="">
        <el-date-picker
          v-model="formInline.time"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.pageIndex = 1;
            getList();
          "
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="id"
        width="80"
        label="记录ID"
      ></el-table-column>
      <el-table-column align="center" prop="type" label="转移方式" width="120">
        <template slot-scope="scope">
          {{ scope.row.type === 1 ? "账户转移" : "角色转移" }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="opAccountName"
        label="玩家信息"
        width="300"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{
            scope.row.type === 1
              ? "-"
              : `${scope.row.gameName}/${scope.row.gameArea}/${scope.row.gameRoleName}`
          }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameAccountId"
        label="游戏账号ID"
        width="120"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="beforeInviterAdminName"
        label="转移前"
        :show-overflow-tooltip="true"
      >
      </el-table-column
      ><el-table-column
        align="center"
        prop="afterInviterAdminName"
        label="转移后"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="createTime" label="操作时间">
        <!-- <template slot-scope="scope">
          {{ $moment(scope.row.serviceTime).format("YYYY-MM-DD HH:mm:ss") }}
        </template> -->
      </el-table-column>
      <el-table-column align="center" prop="opAccountName" label="操作人">
      </el-table-column>
      <el-table-column
        align="center"
        prop="opDesc"
        :show-overflow-tooltip="true"
        label="转移原因"
      >
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.totalElements"
    >
    </el-pagination>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      value1: "",
      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      getGameListData: [],
      dialogFormVisible: false,
      editDialogShow: false,
      editUnionId: "",
      formInline: {
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
        time: ["", ""],
      },
      queryForm: {
        serviceTime: "",
        gameId: null,
        capacity: 1,
        serviceName: "",
      },
      tableData: [],
    };
  },
  mounted() {
    // this.getPermission();
    // this.getGameList();
    this.getList();
  },
  methods: {
    getList() {
      // 获取公会列表
      this.$api.supportApi
        .gameTransfer({
          ...this.formInline,
          startTime: this.formInline.time ? this.formInline.time[0] : "",
          endTime: this.formInline.time ? this.formInline.time[1] : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.totalElements = res.data.totalElements;
            this.formInline.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.gameApi
        .queryPackage({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
    onSubmit() {
      console.log("submit!");
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/organization/bm/staff/list",
        query: {
          unionId: e.id,
        },
      });
    },
    openEditDialog(data) {
      this.dialogFormVisible = true;
      this.editDialogShow = true;
      this.editUnionId = data.id;
      setTimeout(() => {
        this.queryForm.serviceTime = this.$moment(data.serviceTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.queryForm.gameId = data.gameId;
        this.queryForm.capacity = data.capacity;
        this.queryForm.serviceName = data.serviceName;
      }, 1);
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .mr15 {
    margin-right: 15px;
  }
}
</style>
