export default [
  // 后管【结算详情】模块
  // 转移记录
  {
    path: "/support/transfer/record",
    name: "客服-转移记录",
    component: () => import("@/views/support/transferRecord"),
  },
  // 资源管理
  {
    path: "/support/resources/manage",
    name: "客服-资源管理",
    component: () => import("@/views/support/resourceManager"),
  },
  // 账号管理
  {
    path: "/support/accout/manage",
    name: "客服-账号管理",
    component: () => import("@/views/support/accountManage"),
  },
  // 资源账号
  {
    path: "/support/resources/account",
    name: "客服-资源账号",
    component: () => import("@/views/support/resourceAccount"),
  },
  // 人员管理
  {
    path: "/support/staff/manage",
    name: "客服-人员管理",
    component: () => import("@/views/support/staffManage"),
  },
  // 异常订单
  {
    path: "/support/wrong/order",
    name: "客服-异常订单",
    component: () => import("@/views/support/wrongOrder"),
  },
  // 个人记录
  {
    path: "/support/staff/record",
    name: "客服-个人记录",
    component: () => import("@/views/support/staffRecord"),
  },
];
