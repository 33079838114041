<template>
  <div class="game-apply ground-white">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <!-- 公会余额 开始 -->
      <el-tab-pane label="公会余额" name="first">
        <div class="overage">
          <div class="topBar">
            <div class="dataWrap">
              <div class="dataItem">
                <span
                  >平台总额:
                  <span>{{ adminSettleData.sumAdminTotalAmount }}</span></span
                >
                <span
                  >余额:
                  <span>{{ adminSettleData.sumAdminBalanceAmount }}</span></span
                >
                <span
                  >公会余额:
                  <span>{{ adminSettleData.sumUnionBalanceAmount }}</span></span
                >
                <span
                  >待结算:
                  <span>{{
                    adminSettleData.sumAdminUnsettledAmount
                  }}</span></span
                >
                <span
                  >冻结:
                  <span>{{ adminSettleData.sumAdminFrozenAmount }}</span></span
                >
              </div>
              <div class="dataItem">
                <span
                  >下级公会总和:
                  <span>{{ unionSettleData.sumUnionCount }}</span></span
                >
                <span
                  >总金额:
                  <span>{{ unionSettleData.sumUnionTotalAmount }}</span></span
                >
                <span
                  >余额:
                  <span>{{ unionSettleData.sumUnionBalanceAmount }}</span></span
                >
                <span
                  >待结算:
                  <span>{{
                    unionSettleData.sumUnionUnsettledAmount
                  }}</span></span
                >
                <span
                  >冻结:
                  <span>{{ unionSettleData.sumUnionFrozenAmount }}</span></span
                >
              </div>
            </div>
            <!-- <div>
              <el-button type="primary">提现详情</el-button>
            </div> -->
          </div>
          <div class="btBar">
            <el-form :inline="true" :model="formInline">
              <el-form-item label="公会名称">
                <el-select
                  v-model="formInline.unionId"
                  clearable
                  filterable
                  placeholder="请选择公会名称"
                >
                  <el-option
                    v-for="item in getGameListData"
                    :key="item.id"
                    :label="item.unionName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item>
                <el-button
                  type="primary"
                  @click="
                    // formInline.pageIndex = 1;
                    getList()
                  "
                  >查询</el-button
                >
              </el-form-item>
            </el-form>
            <el-table
              v-loading="$store.state.loadingStatus"
              :data="tableData"
              :border="true"
              style="width: 100%"
              :header-cell-style="
                () =>
                  'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
              "
            >
              <el-table-column
                v-for="column in overageConfig"
                :key="column.prop"
                align="center"
                :prop="column.prop"
                :label="column.label"
                :show-overflow-tooltip="true"
              />
              <el-table-column align="center" prop="" label="详情" width="150">
                <template slot-scope="scope">
                  <el-button
                    v-if="1"
                    type="text"
                    size="small"
                    @click="goSecond(scope.row)"
                    >查看</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              style="float: right; margin-top: 20px"
              layout="prev, pager, next, jumper"
              :page-size="formInline.page.pageSize"
              :current-page.sync="formInline.page.pageIndex"
              @current-change="handleCurrentChange"
              :total="formInline.page.totalElements"
            >
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
      <!-- 公会余额 结束 -->
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      adminSettleData: {},
      unionSettleData: {},
      formInline: {
        unionId: null,
        page: {
          pageIndex: 1,
          pageSize: 20,
          totalElements: 0,
        },
      },
      tableData: [],
      getGameListData: [],
      auditStatus: [
        {
          label: "全部",
          value: 0,
        },
        {
          label: "待审批",
          value: 1,
        },
        {
          label: "审批完成",
          value: 2,
        },
      ],
      paymentStatus: [
        {
          label: "全部",
          value: 0,
        },
        {
          label: "待打款",
          value: 1,
        },
        {
          label: "打款完成",
          value: 2,
        },
        {
          label: "打款失败",
          value: 3,
        },
      ],
      overageConfig: [
        {
          label: "公会ID",
          prop: "unionId",
        },
        {
          label: "公会名称",
          prop: "unionName",
        },
        {
          label: "创建时间",
          prop: "createTime",
        },
        {
          label: "总资产",
          prop: "totalAmount",
        },
      ],
    };
  },
  mounted() {
    this.getPlatformSum();
    this.getGameList();
    this.getList();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleCurrentChange(val) {
      this.formInline.page.pageIndex = val;
      this.getList();
    },
    getList() {
      this.$api.billingApi
        .getBalanceUnionSum({ ...this.formInline })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.tableData = result.content;
            this.formInline.page.totalElements = res.data.totalElements;
            this.formInline.page.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getPlatformSum() {
      this.$api.billingApi.getWithdrawalSum().then((res) => {
        console.log("res", res);
        if (res.code === 0) {
          const { adminSettleData = {}, unionSettleData = {} } = res.data
            ? res.data
            : {};
          this.adminSettleData = adminSettleData;
          this.unionSettleData = unionSettleData;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getGameList() {
      this.$api.organizationApi
        .getUnionList({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content.list : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    goSecond(data) {
      this.$router.push({
        path: "/billing/bm/cash/detail",
        query: {
          unionId: data.unionId,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  .overage {
    // padding: 10px 20px;
    // background: #f5f5f5;
    // border-radius: 8px;
  }
  .topBar {
    padding: 10px 20px;
    background: #f5f5f5;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
  }
  .dataWrap {
    padding-right: 40px;
    width: 50vw;

    .dataItem {
      // padding-bottom: 12px;
      line-height: 30px;
      display: flex;
      justify-content: flex-start;

      & > span {
        flex: 1;
      }
    }
  }
}
</style>
