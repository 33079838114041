<template>
  <div class="game-apply ground-white">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="游戏资源" name="first">
        <el-form :inline="true" :model="queryGameFrom">
          <el-form-item label="游戏名称" prop="gameName">
            <el-input
              v-model="queryGameFrom.gameName"
              placeholder="游戏名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="
                querySubGameFrom.pageIndex = 1;
                queryGameFrom.pageIndex = 1;
                queryConfigFrom.pageIndex = 1;
                queryMoneyConfigFrom.pageIndex = 1;
                getGamePackage();
                getGameSupportConfig();
              "
              >查询</el-button
            >
          </el-form-item>
          <el-form-item label="上传游戏">
            <el-button
              type="primary"
              size="small"
              @click="
                dialogFormVisible = true;
                resetForm('gameForm');
              "
              >选择文件</el-button
            >
          </el-form-item>
        </el-form>
        <p style="margin: 16px 1px; font-size: 18px; clear: both">更新包列表</p>
        <el-table
          v-loading="$store.state.loadingStatus"
          :data="gameData"
          :border="true"
          style="width: 100%"
          :header-cell-style="
            () =>
              'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
          "
        >
          <el-table-column align="center" prop="createTime" label="上传时间">
            <template slot-scope="scope">
              {{ scope.row.createTime.split("T")[0] }}
            </template> </el-table-column
          ><el-table-column align="center" prop="gameName" label="游戏名字">
          </el-table-column
          ><el-table-column align="center" prop="gameVersion" label="版本号">
          </el-table-column
          ><el-table-column align="center" prop="gameSize" label="游戏大小">
            <template slot-scope="scope">
              {{ (scope.row.gameSize / 1024 / 1024).toFixed(2) + "MB" }}
            </template> </el-table-column
          ><el-table-column align="center" prop="nickName" label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="deleteItem(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column></el-table
        >
        <el-pagination
          style="float: right; margin-top: 20px"
          layout="prev, pager, next, jumper"
          :page-size="queryGameFrom.pageSize"
          :current-page.sync="queryGameFrom.pageIndex"
          @current-change="gameHandleCurrentChange"
          :total="queryGameFrom.totalElements"
        >
        </el-pagination>
        <p style="margin: 16px 1px; font-size: 18px; clear: both">
          游戏扶持额度比例
        </p>
        <el-table
          v-loading="$store.state.loadingStatus"
          :data="configData"
          :border="true"
          style="width: 100%"
          :header-cell-style="
            () =>
              'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
          "
        >
          <el-table-column align="center" prop="gameName" label="游戏名字">
          </el-table-column
          ><el-table-column align="center" prop="nickName" label="发放比例">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="
                  dialogFormVisibleNew = true;
                  configForm.chargeNum = scope.row.chargeNum;
                  configForm.transferNum = scope.row.transferNum;
                  editId = scope.row.id;
                "
                >{{
                  `${scope.row.chargeNum}:${scope.row.transferNum}`
                }}</el-button
              >
            </template>
          </el-table-column></el-table
        >
        <!-- {{ queryConfigFrom }} -->
        <el-pagination
          style="float: right; margin-top: 20px"
          layout="prev, pager, next, jumper"
          :page-size="queryConfigFrom.pageSize"
          :current-page.sync="queryConfigFrom.pageIndex"
          @current-change="configHandleCurrentChange"
          :total="queryConfigFrom.totalElements"
        >
        </el-pagination>
      </el-tab-pane>
      <!--  -->
      <el-tab-pane label="游戏申请" name="second">
        <p style="margin: 16px 1px; font-size: 18px; clear: both">下级分包</p>
        <el-form :inline="true" :model="queryApplyGameForm">
          <el-form-item label="审批状态">
            <el-select
              v-model="queryApplyGameForm.applyStatus"
              placeholder="审批状态"
            >
              <el-option :key="null" :label="'全部'" :value="null"> </el-option>
              <el-option :key="'0'" :label="'待审核'" :value="0"> </el-option>
              <el-option :key="'1'" :label="'审批通过'" :value="1"> </el-option>
              <el-option :key="'2'" :label="'已拒绝'" :value="2"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="
                querySubGameFrom.pageIndex = 1;
                queryGameFrom.pageIndex = 1;
                queryConfigFrom.pageIndex = 1;
                queryMoneyConfigFrom.pageIndex = 1;
                queryApplyGameForm.pageIndex = 1;
                getApplyGameList();
              "
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <el-table
          v-loading="$store.state.loadingStatus"
          :data="applyData"
          :border="true"
          style="width: 100%"
          :header-cell-style="
            () =>
              'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
          "
        >
          <el-table-column align="center" prop="gameName" label="游戏名字">
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="申请时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.createTime).format("YYYY-MM-DD") }}
            </template> </el-table-column
          ><el-table-column align="center" prop="unionName" label="申请下级">
          </el-table-column>
          <el-table-column
            align="center"
            prop="applyAccountId"
            label="申请人ID"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="applyAccountName"
            label="申请人"
          >
          </el-table-column>
          <el-table-column align="center" prop="updateTime" label="审批时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.updateTime).format("YYYY-MM-DD") }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="applyStatus" label="审批状态">
            <template slot-scope="scope">
              <span v-if="scope.row.applyStatus === 0">待审批</span>
              <span v-else-if="scope.row.applyStatus === 1">审批完成</span>
              <span v-else>已拒绝</span>
            </template> </el-table-column
          ><el-table-column align="center" prop="" label="操作">
            <template slot-scope="scope">
              <el-button
                v-if="1"
                :disabled="scope.row.applyStatus !== 0"
                type="text"
                size="small"
                @click="doApply({ status: 1, id: scope.row.id })"
                >同意</el-button
              >
              <el-button
                :disabled="scope.row.applyStatus !== 0"
                v-if="1"
                type="text"
                size="small"
                @click="
                  doApply({
                    status: 2,
                    id: scope.row.id,
                  })
                "
                >拒绝</el-button
              ></template
            >
          </el-table-column></el-table
        >
        <el-pagination
          style="float: right; margin-top: 20px"
          layout="prev, pager, next, jumper"
          :page-size="queryApplyGameForm.pageSize"
          :current-page.sync="queryApplyGameForm.pageIndex"
          @current-change="subHandleCurrentChange"
          :total="queryApplyGameForm.totalElements"
        >
        </el-pagination>
        <p style="margin: 16px 1px; font-size: 18px; clear: both">结算比例</p>
        <el-form :inline="true" :model="queryMoneyConfigFrom">
          <el-form-item label="公会名称">
            <el-input
              v-model="queryMoneyConfigFrom.unionName"
              placeholder="公会名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="
                querySubGameFrom.pageIndex = 1;
                queryGameFrom.pageIndex = 1;
                queryConfigFrom.pageIndex = 1;
                queryMoneyConfigFrom.pageIndex = 1;
                getMoneyConfigList();
              "
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <el-table
          v-loading="$store.state.loadingStatus"
          :data="moneyConfigData"
          :border="true"
          style="width: 100%"
          :header-cell-style="
            () =>
              'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
          "
        >
          <el-table-column align="center" prop="gameName" label="游戏名字">
          </el-table-column>
          <el-table-column align="center" prop="unionName" label="申请下级">
          </el-table-column>
          <el-table-column
            align="center"
            prop="unionAccountId"
            label="公会超管账号ID"
            width="160"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="unionAccountName"
            label="公会超管账号名称"
          >
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="申请时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.createTime).format("YYYY-MM-DD") }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="updateTime" label="审批时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.updateTime).format("YYYY-MM-DD") }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="" label="安卓/%">
            <template slot-scope="scope">
              <!-- {{
                (scope.row.androidRatio /
                  (scope.row.iosRatio + scope.row.androidRatio).toFixed(1)) *
                  100 +
                "%"
              }} -->
              {{ `${scope.row.androidRatio}%` }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="" label="IOS/%">
            <template slot-scope="scope">
              <!-- {{
                (scope.row.iosRatio /
                  (scope.row.iosRatio + scope.row.androidRatio).toFixed(1)) *
                  100 +
                "%"
              }} -->
              {{ `${scope.row.iosRatio}%` }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="" label="次日安卓/%">
            <template slot-scope="scope">
              {{
                scope.row.nextAndroidRatio
                  ? `${scope.row.nextAndroidRatio}%`
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="" label="次日IOS/%">
            <template slot-scope="scope">
              {{ scope.row.nextIosRatio ? `${scope.row.nextIosRatio}%` : "" }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="" label="操作">
            <template slot-scope="scope">
              <el-button
                v-if="1"
                type="text"
                size="small"
                @click="openEditMoney(scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column></el-table
        >
        <el-pagination
          style="float: right; margin-top: 20px"
          layout="prev, pager, next, jumper"
          :page-size="queryMoneyConfigFrom.pageSize"
          :current-page.sync="queryMoneyConfigFrom.pageIndex"
          @current-change="applyHandleCurrentChange"
          :total="queryMoneyConfigFrom.totalElements"
        >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
    <!-- 上传包体弹窗dialog -->
    <el-dialog :visible.sync="dialogFormVisible">
      <!-- {{ gameForm }} -->
      <el-form :inline="true" :model="gameForm" ref="gameForm">
        <el-form-item label="游戏名称" prop="gameId">
          <el-select v-model="gameForm.gameId" placeholder="请选择游戏名称">
            <el-option
              v-for="item in getGameListData"
              :key="item.id"
              :label="item.gameName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="游戏类型">
          <el-input
            v-model="gameForm.gameType"
            placeholder="游戏类型"
          ></el-input>
        </el-form-item>
        <el-form-item label="核心玩法">
          <el-input
            v-model="gameForm.playType"
            placeholder="核心玩法"
          ></el-input>
        </el-form-item>
        <el-form-item label="游戏区服">
          <el-input
            v-model="gameForm.gameArea"
            placeholder="游戏区服"
          ></el-input>
        </el-form-item>
        <el-form-item label="游戏题材">
          <el-input
            v-model="gameForm.gameTheme"
            placeholder="游戏题材"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="包版本号" prop="gameVersion">
          <el-input
            v-model="gameForm.gameVersion"
            placeholder="包版本号"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="默认分成：安卓">
          <el-input
            v-model="gameForm.androidRatio"
            placeholder="默认分成：安卓"
          ></el-input>
        </el-form-item>
        <el-form-item label="默认分成：IOS">
          <el-input
            v-model="gameForm.iosRatio"
            placeholder="默认分成：IOS"
          ></el-input>
        </el-form-item>
        <el-form-item label="游戏扶持额度比例">
          <el-input
            v-model="gameForm.chargeNum"
            placeholder="游戏扶持额度比例"
          ></el-input>
          :<el-input
            v-model="gameForm.transferNum"
            placeholder="游戏扶持额度比例"
          ></el-input>
        </el-form-item>
        <el-form-item label="游戏logo">
          <el-upload
            class="avatar-uploader"
            :action="$api.gameApi.uploadResourc()"
            :show-file-list="true"
            :on-success="handleAvatarSuccess"
            :headers="headersObj"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item> -->
        <el-form-item label="上传游戏包" prop="gameSize"> </el-form-item>
        <el-form-item label="上传游戏包" prop="uploadUrl">
          <el-upload
            class="avatar-uploader"
            :action="$api.gameApi.uploadResourc()"
            :show-file-list="true"
            :file-list="gameForm.fileList"
            :on-success="handlePackageSuccess"
            :headers="headersObj"
            :before-upload="beforeUpload"
            :limit="1"
            ref="upload"
            accept=".apk"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传apk文件，且不超过2GB
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addGame">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 扶持比例dialog -->
    <el-dialog :visible.sync="dialogFormVisibleNew">
      <!-- {{ configForm }} -->
      <el-form :inline="true" :model="configForm">
        <el-form-item label="扶持比例" prop="chargeNum">
          <el-input-number
            v-model="configForm.chargeNum"
            type="number"
            :min="0"
            :precision="1"
            disabled
            placeholder="扶持比例"
          ></el-input-number> </el-form-item
        >:
        <el-form-item label="" prop="transferNum">
          <el-input-number
            v-model="configForm.transferNum"
            :min="0"
            :precision="1"
            placeholder="扶持比例"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleNew = false">取 消</el-button>
        <el-button type="primary" @click="updateGameSupportConfig"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 结算比例dialog -->
    <el-dialog :visible.sync="dialogFormVisibleMoney">
      <!-- {{ Form }} -->
      <el-form :inline="true" :model="moneyForm">
        <el-form-item label="Android" prop="androidRatio">
          <el-input-number
            v-model="moneyForm.androidRatio"
            type="number"
            min="0"
            placeholder="Android"
          ></el-input-number> </el-form-item
        >:
        <el-form-item label="IOS" prop="iosRatio">
          <el-input-number
            v-model="moneyForm.iosRatio"
            placeholder="IOS"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleMoney = false">取 消</el-button>
        <el-button type="primary" @click="editMoney">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "loginPage",
  data() {
    return {
      headersObj: {
        Authorization: localStorage.getItem("token"),
      },
      uploadUrl: "",
      imageUrl: "",
      editId: "",
      dialogFormVisible: false,
      dialogFormVisibleNew: false,
      dialogFormVisibleMoney: false,
      activeName: "first",
      uploadForm: {},
      querySubGameFrom: {
        applyStatus: "",
        packageStatus: "",
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      queryGameFrom: {
        gameName: "",
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      queryConfigFrom: {
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      queryMoneyConfigFrom: {
        unionName: "",
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      gameForm: {
        // packageSize: 1,
        // gameName: "",
        // packageUrl: "",
        //   gamePic: "",
        //   gameLevel: "",
        //   gameType: 1,
        //   playType: 1,
        //   gameArea: "",
        //   gameTheme: "",
        uploadUrl: "",
        gameSize: "",
        gameId: "",
        gameVersion: "",
        fileList: [],
        // androidRatio: 1,
        // iosRatio: 1,
        // chargeNum: 1,
        // transferNum: 1,
      },
      configForm: {
        chargeNum: 1,
        transferNum: 1,
      },
      moneyForm: {
        id: 0,
        androidRatio: 1,
        iosRatio: 1,
      },
      queryApplyGameForm: {
        applyStatus: "",
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
      },
      formInline: {
        user: "",
        region: "",
      },
      permissionForm: {},
      tableData: [],
      getGameListData: [],
      moneyConfigData: [],
      gameData: [],
      applyData: [],
      subGameData: [],
      configData: [],
      rules: {
        accountName: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
        accountPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    dialogFormVisible(v) {
      if (!v) {
        this.gameForm = {
          uploadUrl: "",
          gameSize: "",
          gameId: "",
          gameVersion: "",
          fileList: [],
        };
      }
    },
  },
  mounted() {
    this.freshPage();
    this.freshPageRight();
    this.getGameList();
  },
  methods: {
    resetUpload(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].clearFiles();
        }
      });
    },
    beforeUpload(file) {
      console.log(file);
      // const isJPG = file.type === "image/jpg";
      // const isPng = file.type === "image/png";
      // const isJpeg = file.type === "image/jpeg";

      //1MB=1024*1024(1MB=1024KB 1KB=1024MB)
      const is10M = file.size / 1024 / 1024 < 2048;

      //限制文件上传类型
      // if (!isJPG && !isPng && !isJpeg) {
      //   this.$message.error("游戏图片只能是 png,jpg,jpeg 格式!");
      //   return false;
      // }

      //限制文件上传大小
      if (!is10M) {
        this.$message.error("包体大小限制2GB!");
        return false;
      }

      return true;
    },
    getGameList() {
      this.$api.gameApi
        .queryPackage({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    openEditMoney(data) {
      this.dialogFormVisibleMoney = true;
      this.moneyForm.id = data.id;
      this.moneyForm.androidRatio = data.androidRatio;
      this.moneyForm.iosRatio = data.iosRatio;
    },
    editMoney() {
      this.$api.gameApi.updateMoneyConfig(this.moneyForm).then((res) => {
        console.log("updateMoneyConfig", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.dialogFormVisibleMoney = false;
          this.getMoneyConfigList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    doApply(data) {
      this.$api.gameApi.doApply(data).then((res) => {
        console.log("doApply", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.getApplyGameList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getApplyGameList() {
      this.$api.gameApi
        .queryApplyGame({
          ...this.queryApplyGameForm,
        })
        .then((res) => {
          console.log("queryApplyGame", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.applyData = result;
            this.queryApplyGameForm.totalElements = res.data.totalElements;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getMoneyConfigList() {
      this.$api.gameApi
        .queryMoneyConfig(this.queryMoneyConfigFrom)
        .then((res) => {
          console.log("queryMoneyConfig", res);
          if (res.code === 0) {
            console.log();
            const result = res.data.content ? res.data.content : [];
            this.moneyConfigData = result;
            this.queryMoneyConfigFrom.totalElements = res.data.totalElements;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    freshPage() {
      this.getGamePackage();
      this.getGameSupportConfig();
    },
    freshPageRight() {
      this.getApplyGameList();
      this.getMoneyConfigList();
    },
    getGameSupportConfig() {
      this.$api.gameApi
        .queryGameSupportConfig({
          gameName: this.queryGameFrom.gameName,
          ...this.queryConfigFrom,
        })
        .then((res) => {
          console.log("queryPackage", res);
          if (res.code === 0) {
            console.log();
            const result = res.data.content ? res.data.content : [];
            this.configData = result;
            this.queryConfigFrom.totalElements = res.data.totalElements;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    updateGameSupportConfig() {
      this.$api.gameApi
        .updateGameSupportConfig({
          id: this.editId,
          ...this.configForm,
        })
        .then((res) => {
          console.log("updateGameSupportConfig", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.dialogFormVisibleNew = false;
            this.freshPage();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    deleteItem(pId) {
      this.$confirm("确定要删除数据吗？", "", {
        type: "error",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        // TODO 删除
        this.deleteGame(pId);
      });
    },
    addGame() {
      this.$api.gameApi.addUpdatePackage(this.gameForm).then((res) => {
        console.log("queryPackage", res);
        if (res.code === 0) {
          this.$message.success("操作成功!");
          this.dialogFormVisible = false;
          this.freshPage();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    deleteGame(data) {
      this.$api.gameApi
        .deleteUpdatePackage({
          id: data.id,
        })
        .then((res) => {
          console.log("queryPackage", res);
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.freshPage();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGamePackage() {
      this.$api.gameApi
        .queryUpdatePackage({
          ...this.queryGameFrom,
        })
        .then((res) => {
          console.log("queryPackage", res);
          if (res.code === 0) {
            console.log();
            const result = res.data.content ? res.data.content : [];
            this.gameData = result;
            this.queryGameFrom.totalElements = res.data.totalElements;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleCurrentChange() {},
    applyHandleCurrentChange(val) {
      this.queryMoneyConfigFrom.pageIndex = val;
      this.getMoneyConfigList();
    },
    gameHandleCurrentChange(val) {
      this.queryGameFrom.pageIndex = val;
      this.getGamePackage();
    },
    configHandleCurrentChange(val) {
      this.queryConfigFrom.pageIndex = val;
      this.getGameSupportConfig();
    },
    subHandleCurrentChange(val) {
      this.queryApplyGameForm.pageIndex = val;
      this.getApplyGameList();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.login();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      this.imageUrl = URL.createObjectURL(file.raw);
      // this.imageUrl = res.data.uploadUrl;
      this.gameForm.gamePic = res.data.uploadUrl;
    },
    handlePackageSuccess(res, file) {
      console.log(res, file);
      if (res.code === 0) {
        this.imageUrl = URL.createObjectURL(file.raw);
        // this.imageUrl = res.data.uploadUrl;
        this.gameForm.uploadUrl = res.data.uploadUrl;
        this.gameForm.gameSize = res.data.fileSize;
      } else {
        this.$message.error(res.msg);
      }
    },
    onSubmit() {},
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  /deep/ .avatar-uploader .el-upload {
    // border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/.avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  /deep/.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  /deep/.avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
