<template>
  <div class="unionList ground-white">
    <el-page-header
      @back="$router.back()"
      v-if="$choosePlantorm().platformType === 0"
    >
    </el-page-header>
    <br />
    <!-- <el-radio-group v-model="gameRoleId" size="mini" @input="getPage">
      <el-radio-button
        v-for="(item, index) in getGameListData"
        :key="index"
        :label="item.id"
        >{{ item.gameName }}</el-radio-button
      >
    </el-radio-group> -->

    <!-- {{ formInline }} -->
    <el-form :inline="true" :model="formInline">
      <!-- <el-form-item>
        <el-select
          v-model="formInline.gameId"
          clearable
          placeholder="汇总维度"
          v-if="$choosePlantorm().platformType === 1"
        >
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item label="选择游戏">
        <el-radio-group
          v-model.number="formInline.gameId"
          size="mini"
          @input="changeGame"
        >
          <el-radio-button
            v-for="(item, index) in getGameListData"
            :key="index"
            :label="item.id"
            >{{ item.gameName }}</el-radio-button
          >
        </el-radio-group>
      </el-form-item>
      <br />
      <el-divider></el-divider>
      <el-form-item label="选择区服" v-if="formInline.gameId">
        <el-radio-group
          v-model.number="formInline.gameServiceId"
          @input="changeArea"
          size="mini"
        >
          <el-tooltip
            v-for="(item, index) in getAreaListData"
            :key="index"
            class="item"
            effect="dark"
            :disabled="!item.content"
            :content="item.content"
            placement="top"
          >
            <el-radio-button :label="item.id">{{
              item.serviceName
            }}</el-radio-button>
          </el-tooltip>
        </el-radio-group>
      </el-form-item>
      <el-divider v-if="formInline.gameId"></el-divider>
      <el-form-item label="">
        <el-select
          v-model.number="formInline.gameRoleId"
          clearable
          placeholder="角色名"
          filterable
          remote
          :remote-method="remoteMethodNew"
          :loading="loadingNew"
        >
          <el-option
            v-for="item in gameRoleListData"
            :key="item.id"
            :label="item.roleName"
            :value="+item.roleId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model.number="formInline.gameAccountId"
          placeholder="游戏账号ID"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="formInline.time"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="首次申请开始时间"
          end-placeholder="首次申请结束时间"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select v-model="formInline.roleStatus" clearable placeholder="状态">
          <el-option :label="`正常`" :value="0"> </el-option>
          <el-option :label="`冻结`" :value="1"> </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item>
        <el-select v-model="formInline.gameId" clearable placeholder="角色标记">
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.page.pageIndex = 1;
            getList();
            virtualGameRole();
          "
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div class="data_bar">
      <p>
        发放角色：<span>{{ `${releaseAccount || 0}` }}</span>
      </p>
      <p>
        发放账号：<span>{{ `${testAccount || 0}` }}</span>
      </p>
    </div>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="firstApplyTime"
        label="首次申请时间"
      >
      </el-table-column>
      <el-table-column align="center" prop="gameAccountId" label="游戏账号ID">
      </el-table-column>
      <el-table-column
        align="center"
        prop="inviterAdminAccountName"
        label="游戏信息"
      >
        <template slot-scope="scope"
          >{{ scope.row.gameName }}/{{ scope.row.gameServerName }}</template
        >
      </el-table-column>
      <el-table-column align="center" prop="gameRoleName" label="角色">
      </el-table-column>
      <el-table-column align="center" prop="sumRechargeAmount" label="累计充值">
      </el-table-column>
      <el-table-column align="center" prop="roleStatus" label="角色状态">
        <template slot-scope="scope">{{
          scope.row.roleStatus === 0 ? "正常" : "冻结"
        }}</template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="firstApplyAdminAccountName"
        label="首次申请人"
      >
      </el-table-column>
      <el-table-column align="center" prop="createTime" label="角色创建时间">
      </el-table-column>

      <el-table-column align="center" prop="" label="操作" width="80">
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="handleClick(scope.row)"
            >充值明细</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.page.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.page.totalElements"
    >
    </el-pagination>

    <!-- dialog -->
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      value1: "",
      rechargeStatusList: [
        { name: "支付失败", id: 200 },
        { name: "交货中", id: 300 },
        { name: "已完成", id: 400 },
        { name: "已取消", id: 500 },
      ],
      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      getGameListData: [],
      getAreaListData: [],
      gameRoleListData: [],
      staffListData: [],
      dialogFormVisible: false,
      loadingNew: false,
      editDialogShow: false,
      loading: false,
      editUnionId: "",
      formInline: {
        rechargeStatus: null,
        gameRoleId: null,
        gameAccountId: null,
        gameServiceId: null,
        gameId: null,
        inviterAdminAccountIdList: [],
        opPlatform: null,
        startTime: "",
        endTime: "",
        page: { pageIndex: 1, pageSize: 20, totalElements: 0 },
        time: ["", ""],
        time1: ["", ""],
        time2: ["", ""],
      },
      queryForm: {
        serviceTime: "",
        gameId: null,
        capacity: 1,
        serviceName: "",
      },
      tableData: [],
      totalData: {},
      releaseAccount: "",
      testAccount: "",
    };
  },
  mounted() {
    // this.getPermission();
    this.getGameList();
    this.getList();
    // this.getListSum();
    this.virtualGameRole();
  },
  methods: {
    remoteMethodNew(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryGameRole({
            roleName: query,
            // accountIdList: [0],
            firstChargeInfo: true,
            // gameAccountIdList: [0],
            gameIdList: this.formInline.gameId
              ? [this.formInline.gameId]
              : null,
            gameServerIdList: this.formInline.gameServiceId
              ? [parseInt(this.formInline.gameServiceId)]
              : null,
            needAccountInfo: true,
            // roleIdList: ["string"],
            // roleName: "string",
          })
          .then((res) => {
            console.log("getGameList", res);
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.gameRoleListData = result;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.gameRoleListData = [];
      }
    },
    getAreaList(id) {
      this.$api.organizationApi
        .queryAreaPlan({
          gameId: id,
          startTime: null,
          endTime: null,
          pageIndex: 1,
          pageSize: 10000,
        })
        .then((res) => {
          console.log("getAreaList", res);
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : {};
            this.getAreaListData = result.map((item) => {
              return {
                ...item,
                content: `开服时间：${this.$moment(item.serviceTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}`,
              };
            });
            this.getAreaListData.unshift({
              id: null,
              serviceName: "全部",
              content: "",
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    changeArea() {
      this.formInline.gameRoleId = null;
      this.gameRoleListData = [];
      this.getList();
      this.virtualGameRole();
    },
    changeGame(id) {
      console.log(id);
      this.formInline.gameRoleId = null;
      this.gameRoleListData = [];

      if (id === null) {
        this.getAreaListData = [
          {
            id: null,
            serviceName: "全部",
          },
        ];
        this.formInline.gameServiceId = null;
        this.getList();
        return;
      }
      this.formInline.gameServiceId = null;
      this.getAreaList(id);
      this.getList();
      this.virtualGameRole();
    },
    exportList() {
      this.formInline.page.pageSize = 50000;
      // 获取公会列表
      this.$api.dataApi
        .gameAccountExport({
          ...this.formInline,
          accountCreateStartTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          accountCreateEndTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[0])
            : "",
          rechargeStartTime: this.formInline.time1
            ? this.$dateUtil.dayToStartUnix(this.formInline.time1[0])
            : "",
          rechargeEndTime: this.formInline.time1
            ? this.$dateUtil.dayToEndUnix(this.formInline.time1[0])
            : "",
          recentLoginStartTime: this.formInline.time2
            ? this.$dateUtil.dayToStartUnix(this.formInline.time2[0])
            : "",
          recentLoginEndTime: this.formInline.time2
            ? this.$dateUtil.dayToEndUnix(this.formInline.time2[0])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          this.formInline.page.pageSize = 20;
          // 导出Excel
          this.$exportExcel(
            res,
            `已出数据_${this.$moment().format(
              "YYYY-MM-DD"
            )}_${this.$moment().unix()}.xlsx`
          );
        });
    },
    getListSum() {
      // 获取公会列表
      this.$api.dataApi
        .inviterStatisTotal({
          ...this.formInline,
          accountCreateStartTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          accountCreateEndTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[0])
            : "",
          rechargeStartTime: this.formInline.time1
            ? this.$dateUtil.dayToStartUnix(this.formInline.time1[0])
            : "",
          rechargeEndTime: this.formInline.time1
            ? this.$dateUtil.dayToEndUnix(this.formInline.time1[0])
            : "",
          recentLoginStartTime: this.formInline.time2
            ? this.$dateUtil.dayToStartUnix(this.formInline.time2[0])
            : "",
          recentLoginEndTime: this.formInline.time2
            ? this.$dateUtil.dayToEndUnix(this.formInline.time2[0])
            : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.totalData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      const item = this.getAreaListData.find(
        (item) => item.id === this.formInline.gameServiceId
      );

      // 获取公会列表
      this.$api.billingApi
        .virRechargeQueryNew({
          ...this.formInline,
          gameServiceId: item?.serviceId || null,
          applyStartTime: this.formInline.time
            ? this.$dateUtil.dayToStartUnix(this.formInline.time[0])
            : "",
          applyEndTime: this.formInline.time
            ? this.$dateUtil.dayToEndUnix(this.formInline.time[0])
            : "",
          gameServerIdList: this.formInline.gameServiceId
            ? [+this.formInline.gameServiceId]
            : null,
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.page.totalElements = res.data.totalElements;
            this.formInline.page.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.gameApi
        .queryGameList({
          unionId: this.$route.query.unionId,
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
            this.getGameListData.unshift({
              id: null,
              gameName: "全部",
            });
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryStaff({
            nickName: query,
          })
          .then((res) => {
            console.log("getGameList", res);
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.staffListData = result;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.staffListData = [];
      }
    },
    handleCurrentChange(val) {
      this.formInline.page.pageIndex = val;
      this.getList();
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/billing/bm/recharge/role/details",
        query: {
          // gameAccountId: e.gameAccountId,
          gameRoleId: e.gameRoleId,
          unionId: +this.$route.query.unionId,
        },
      });
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
    virtualGameRole() {
      // const item = this.getAreaListData.find(
      //   (item) => item.id === this.formInline.gameServiceId
      // );
      const params = {
        gameId: this.formInline.gameId || null,
        gameServiceId: this.formInline.gameServiceId || null,
      };
      this.$api.organizationApi.virTualUnionSum({ ...params }).then((res) => {
        if (res.code === 0) {
          const info = res.data ? res.data : {};
          this.releaseAccount = info.gameRoleIdCount;
          this.testAccount = info.gameAccountIdCount;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-radio-button--mini .el-radio-button__inner {
  padding: 7px 15px;
  font-size: 12px;
  border-radius: 0;
  margin: 5px;
  border: 1px #dcdfe6 solid !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}
.unionList {
  .mr15 {
    margin-right: 15px;
  }
  .data_bar {
    margin-bottom: 20px;
    padding: 0 16px;
    background: #eff5ff;
    line-height: 40px;
    width: calc(100vw - 258px);
    margin-left: -16px;
    display: flex;
    justify-content: flex-start;
    gap: 24px;

    span {
      // padding-right: 1vw;
      color: #255ec0;
    }
  }
}
</style>
