export const qqtxConfig = {
  tit: "权倾天下",
  title: "盛世龙城之权倾天下",
  tabTitle: "盛世龙城之权倾天下",
  content: "权倾天下--激情攻沙，万人同屏",
  iosDownloadUrl: "http://f.zhongyiyf.com/17game",
  logo: "https://web.zhongyiyf.com/img/qqtx_log.4beedadb.png",
  bigBg: "https://web.zhongyiyf.com/img/qqtx.1b5ca4a4.jpg",
  swiperList: [
    "https://pic.zhongyiyf.com/img/01.fe1b8d71.jpg",
    "https://pic.zhongyiyf.com/img/02.5b619dba.jpg",
    "https://pic.zhongyiyf.com/img/03.0f226f85.jpg",
    "https://pic.zhongyiyf.com/img/04.f83b95b8.jpg",
    "https://pic.zhongyiyf.com/img/05.646e24ba.jpg",
  ],
};

export const ztxConfig = {
  tit: "征战天下",
  title: "征战天下-激情国战，万人同屏",
  tabTitle: "《征战天下》- 征战天下-激情国战，万人同屏",
  content: "匡扶正道统一大业 感受震撼国战体验",
  androidDownloadUrl: "http://f.zhongyiyf.com/m1tsr8",
  iosDownloadUrl: "http://f.zhongyiyf.com/3erxf1",
  video:
    "https://yunding-admin-file.oss-cn-shenzhen.aliyuncs.com/img/%E3%80%8A%E8%BF%9C%E5%BE%81%E3%80%8B%E5%9B%BD%E7%8E%8B%E4%BA%89%E9%9C%B8.mp4",
  logo: "https://web.zhongyiyf.com/img/ztx_logo.7508bae4.png",
  bigBg: "https://web.zhongyiyf.com/img/v_ztx.eeeb08b6.png",
  swiperList: [
    "https://pic.zhongyiyf.com/img/banner1-1.2311be6a.jpg",
    "https://pic.zhongyiyf.com/img/banner1-2.658ce224.jpg",
    "https://pic.zhongyiyf.com/img/banner1-3.3abe1f71.jpg",
  ],
};

export const hdxConfig = {
  tit: "北凉悍刀行",
  title: "激情国战，万人同屏",
  tabTitle: "激情国战，万人同屏",
  content: "北凉悍刀行-激情国战，万人同屏",
  androidDownloadUrl: "http://f.zhongyiyf.com/a24pz5",
  iosDownloadUrl: "http://f.zhongyiyf.com/3ma8bh",
  video: "https://web.zhongyiyf.com/img/hdx_video.mp4",
  logo: "https://web.zhongyiyf.com/img/hdx_logo.png",
  bigBg: "https://web.zhongyiyf.com/img/hdx_big_bg.jpg",
  swiperList: [
    "https://pic.zhongyiyf.com/img/hdx_li1.jpg",
    "https://pic.zhongyiyf.com/img/hdx_li2.jpg",
    "https://pic.zhongyiyf.com/img/hdx_li3.jpg",
    "https://pic.zhongyiyf.com/img/hdx_li4.jpg",
    "https://pic.zhongyiyf.com/img/hdx_li5.jpg",
  ],
};

export const gameIdList = [10012, 10013, 10014, 100013];

export const GAME_CONFIG = {
  10012: qqtxConfig,
  10013: ztxConfig,
  10014: hdxConfig,
  100013: hdxConfig,
};
