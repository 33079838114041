<template>
  <div class="unionList ground-white">
    <el-page-header
      @back="$router.back()"
      v-if="$choosePlantorm().platformType === 0"
    >
    </el-page-header>
    <br />
    <!-- {{ formInline }} -->
    <el-form :inline="true" :model="formInline">
      <!-- <el-form-item>
        <el-select
          v-model="formInline.gameId"
          clearable
          placeholder="汇总维度"
          v-if="$choosePlantorm().platformType === 1"
        >
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item prop="afterInviterAdminAccountId">
        <el-select
          v-model="formInline.headAccountId"
          clearable
          placeholder="请输入团长"
          filterable
          remote
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in staffListData"
            :key="item.id"
            :label="item.nickName"
            :value="item.accountId"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item prop="inviterAccountIdList" label="">
        <treeselect
          v-model="accountForm.inviterAccountIdList"
          :multiple="false"
          :disable-branch-nodes="true"
          placeholder="请选择团长"
          :options="inviterOptions"
          style="width: 180px"
          @input="changeTreeValues"
          noOptionsText="暂无选项"
        />
      </el-form-item> -->
      <InviterSelect
        label=""
        propId="headAccountId"
        v-model="formInline.headAccountId"
        :multiple="false"
        placeholder="请选择团长"
        :options="inviterOptions"
        :disable-branch-nodes="true"
      />
      <el-form-item>
        <el-select
          v-model="formInline.opPlatform"
          clearable
          placeholder="双端合并"
          @change="changeOpPlatform"
        >
          <el-option label="双端合并" :value="null"> </el-option>
          <el-option label="分端显示" value="all"> </el-option>
          <el-option label="Android" value="Android"> </el-option>
          <el-option label="Ios" value="iOS"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-select
          v-model="formInline.gameId"
          clearable
          placeholder="请选择游戏"
          @change="changeGame"
        >
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="gameArea">
        <!-- <el-input v-model="roleForm.gameArea"></el-input> -->
        <el-select
          v-model="formInline.gameArea"
          clearable
          placeholder="请选择区服"
        >
          <el-option
            v-for="item in areaListData"
            :key="item.id"
            :label="item.serviceName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <!-- <el-form-item>
        <el-select v-model="formInline.gameId" clearable placeholder="角色标记">
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-input v-model="formInline.taskId" placeholder="任务ID"></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="formInline.time"
          type="daterange"
          align="right"
          clearable
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>

      <!-- <el-form-item>
        <el-select v-model="formInline.gameId" clearable placeholder="地区筛选">
          <el-option
            v-for="item in getGameListData"
            :key="item.id"
            :label="item.gameName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item>
        <el-button
          type="primary"
          v-if="1"
          @click="
            formInline.pageIndex = 1;
            getList();
            getListSum();
          "
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div class="data_bar">
      <p>
        团长数：<span>{{ totalData.totalHeadAccount }}</span>
      </p>
      <p>
        任务数：<span>{{ totalData.totalTask }}</span>
      </p>
      <p>
        充值总额：<span>{{ totalData.totalChargeAmount }}</span>
      </p>
    </div>
    <el-table
      v-loading="$store.state.loadingStatus"
      :data="tableData"
      :border="true"
      style="width: 100%"
      :header-cell-style="
        () =>
          'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
      "
    >
      <el-table-column
        align="center"
        prop="statisDate"
        label="时间"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column align="center" prop="headAccountName" label="团长">
        <template slot-scope="scope">
          <MemberShow
            :accountName="scope.row.headAccountName"
            :inviterAccountId="scope.row.headAccountId"
            :departmentName="scope.row.departmentName"
            :roleName="scope.row.roleName"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="gameName"
        label="游戏"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <GamePlatform
            :gameName="scope.row.gameName"
            :opPlatform="scope.row.opPlatform"
          />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="serviceTime" label="任务">
        <template slot-scope="scope">
          任务ID:{{ scope.row.taskId }} 游戏:{{ scope.row.gameName }}/{{
            scope.row.gameServiceName
          }}
          <br />
          开服时间:{{ scope.row.serviceStartTime }} 开始时间:{{
            scope.row.taskStartTime
          }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="taskDays" label="带团天数">
      </el-table-column>
      <el-table-column align="center" prop="chargePeopleNum" label="充值用户数">
      </el-table-column>
      <el-table-column align="center" prop="chargeAmount" label="充值总额">
      </el-table-column>
      <el-table-column align="center" prop="updateTime" label="更新时间">
      </el-table-column>

      <!-- <el-table-column align="center" prop="" label="详情" width="150">
        <template slot-scope="scope">
          <el-button
            v-if="1"
            type="text"
            size="small"
            @click="openEditDialog(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      style="float: right; margin-top: 20px"
      layout="prev, pager, next, jumper"
      :page-size="20"
      :current-page.sync="formInline.pageIndex"
      @current-change="handleCurrentChange"
      :total="formInline.totalElements"
    >
    </el-pagination>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      value1: "",
      rechargeStatusList: [
        { name: "支付失败", id: 200 },
        { name: "交货中", id: 300 },
        { name: "已完成", id: 400 },
        { name: "已取消", id: 500 },
      ],
      pickerOptions: {
        shortcuts,
      },
      unionMemberNum: "",
      unionNum: "",
      permissionCode: [],
      getGameListData: [],
      staffListData: [],
      dialogFormVisible: false,
      editDialogShow: false,
      loading: false,
      editUnionId: "",
      formInline: {
        headAccountId: null,
        gameArea: null,
        gameRoleId: null,
        unionId: +this.$route.query.unionId,
        gameAccountId: null,
        gameId: null,
        inviterAccountIdList: [],
        opPlatform: null,
        startTime: "",
        endTime: "",
        pageIndex: 1,
        pageSize: 20,
        totalElements: 0,
        time: ["", ""],
      },
      queryForm: {
        serviceTime: "",
        gameId: null,
        capacity: 1,
        serviceName: "",
      },
      tableData: [],
      areaListData: [],
      totalData: {},
    };
  },
  mounted() {
    // this.getPermission();
    this.getGameList();
    this.getList();
    this.getListSum();
    this.getAllInviter();
  },
  methods: {
    changeGame(e) {
      console.log(e);
      this.formInline.gameArea = null;
      this.getArea(e);
    },
    getArea(id) {
      this.$api.organizationApi
        .queryAreaPlan({
          gameId: id,
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.data ? res.data.data : [];
            this.areaListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    exportList() {
      this.formInline.pageSize = 50000;
      // 获取公会列表
      this.$api.dataApi
        .roleAreaExport({
          ...this.formInline,
          startTime: this.formInline.time ? this.formInline.time[0] : "",
          endTime: this.formInline.time ? this.formInline.time[1] : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          this.formInline.pageSize = 20;
          // 导出Excel
          this.$exportExcel(
            res,
            `已出数据_${this.$moment().format(
              "YYYY-MM-DD"
            )}_${this.$moment().unix()}.xlsx`
          );
        });
    },
    getListSum() {
      // 获取公会列表
      this.$api.dataApi
        .taskSumSum({
          ...this.formInline,
          startTime: this.formInline.time ? this.formInline.time[0] : "",
          endTime: this.formInline.time ? this.formInline.time[1] : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data ? res.data : {};
            this.totalData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      // 获取公会列表
      this.$api.dataApi
        .taskSumQuery({
          ...this.formInline,
          startTime: this.formInline.time ? this.formInline.time[0] : "",
          endTime: this.formInline.time ? this.formInline.time[1] : "",
        })
        .then((res) => {
          console.log("getUnionList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.tableData = result;
            this.formInline.totalElements = res.data.totalElements;
            this.formInline.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGameList() {
      this.$api.dataApi
        .getTaskSum({
          pageIndex: 1,
          pageSize: 1000,
        })
        .then((res) => {
          console.log("getGameList", res);
          if (res.code === 0) {
            const result = res.data.content ? res.data.content : [];
            this.getGameListData = result;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.$api.organizationApi
          .queryStaff({
            nickName: query,
          })
          .then((res) => {
            console.log("getGameList", res);
            if (res.code === 0) {
              const result = res.data ? res.data : [];
              this.staffListData = result;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.staffListData = [];
      }
    },
    handleCurrentChange(val) {
      this.formInline.pageIndex = val;
      this.getList();
    },
    handleClick(e) {
      console.log(e);
      this.$router.push({
        path: "/data/union/commander/detail",
        query: {
          gameRoleId: e.gameRoleId,
        },
      });
    },
    getPermission() {
      // 获取页面按钮级别权限
      this.$api.loginApi
        .treeDetail({ id: this.$route.query.pageId })
        .then((res) => {
          console.log("treeDetail", res);
          this.permissionCode = res.data;
        });
    },
    resetForm(formName) {
      this.$nextTick(() => {
        // console.log(this.$refs[formName].resetFields);
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      });
    },
    changeOpPlatform(val) {
      if (!val) {
        this.formInline.opPlatform = null;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.unionList {
  .mr15 {
    margin-right: 15px;
  }
}
</style>
