<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  mounted() {},
  methods: {},
};
</script>
<style lang="less">
#app {
  width: 100%;
  height: 100%;
  .el-table {
    /deep/ th {
      padding: 0;
    }
    /deep/ td {
      padding: 0;
    }
    /deep/ th.el-table__cell {
      padding: 6px 0;
    }
  }
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;

  .data_bar {
    margin-bottom: 20px;
    padding: 0 16px;
    background: #eff5ff;
    line-height: 40px;
    width: calc(100vw - 258px);
    margin-left: -16px;
    display: flex;
    justify-content: flex-start;
    gap: 24px;

    span {
      // padding-right: 1vw;
      color: #255ec0;
    }
  }
}
</style>
