/* eslint-disable */
export const shortcuts = [
	{
		text: '今天',
		onClick(picker) {
			const start = new Date();
			picker.$emit('pick', [start, start]);
		}
	},
	{
		text: '本周',
		onClick(picker) {
			// 获取当前日期
			var today = new Date();
			// 获取当前是星期几
			var day = today.getDay();
			if (day == 0) {
				// 计算本周第一天的日期
				var startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day - 6);
				// 计算本周最后一天的日期
				var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day);
			} else {
				var startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day + 1);
				var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day + 7);
			}
			// 返回本周的日期范围
			picker.$emit('pick', [startDate, endDate]);
		}
	},
	{
		text: '最近一周',
		onClick(picker) {
			const end = new Date();
			const start = new Date();
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
			picker.$emit('pick', [start, end]);
		}
	},
	{
		text: '本月',
		onClick(picker) {
			const today = new Date();
			const start = new Date(today.getFullYear(), today.getMonth(), 1);
			picker.$emit('pick', [start, today]);
		}
	},
	{
		text: '上一个月',
		onClick(picker) {
			const today = new Date();
			const start = new Date(today.getFullYear(), today.getMonth()-1, 1);
			const end = new Date(today.getFullYear(), today.getMonth(), 0);
			picker.$emit('pick', [start, end]);
		}
	},
	{
		text: '最近一个月',
		onClick(picker) {
			const end = new Date();
			const start = new Date();
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
			picker.$emit('pick', [start, end]);
		}
	},
	{
		text: '最近三个月',
		onClick(picker) {
			const end = new Date();
			const start = new Date();
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
			picker.$emit('pick', [start, end]);
		}
	}
];
