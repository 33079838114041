import { render, staticRenderFns } from "./privacy.vue?vue&type=template&id=49142cfc&scoped=true&"
var script = {}
import style0 from "./privacy.vue?vue&type=style&index=0&id=49142cfc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.8.1_vue-template-compiler@2.7.14_webpack@5.88.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49142cfc",
  null
  
)

export default component.exports