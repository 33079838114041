<template>
  <div class="game-apply ground-white">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <!-- 公会余额 开始 -->
      <el-tab-pane label="公会余额" name="first">
        <div>
          <div class="topBar">
            <div class="bar">
              <div class="icon_wrap">
                <img src="@/assets/img/totalAssets.png" alt="总资产" />
              </div>
              <div class="asset_bd">
                <span>总资产</span>
                <div>
                  <strong>{{ assetInfo.totalAmount }}</strong
                  >元
                </div>
                <em>当前组织所有资金综合</em>
              </div>
            </div>
            <div class="bar">
              <div class="icon_wrap">
                <img src="@/assets/img/overage.png" alt="账户余额" />
              </div>
              <div class="asset_bd">
                <span>账户余额</span>
                <div>
                  <strong>{{ assetInfo.balanceAmount || 10000.0 }}</strong
                  ><span>元</span
                  ><el-button
                    v-if="isPermission(permissionCode, withdrawal)"
                    type="success"
                    size="mini"
                    @click="handleShowPop"
                    >提现</el-button
                  >
                </div>
                <em>可用于提现等操作的余额</em>
              </div>
            </div>
            <div class="bar">
              <div class="icon_wrap">
                <img src="@/assets/img/toSettle.png" alt="待结算资金" />
              </div>
              <div class="asset_bd">
                <span>待结算资金</span>
                <div>
                  <strong>{{ assetInfo.unsettledAmount }}</strong
                  >元
                </div>
                <em>即将结算至可用余额的资金</em>
              </div>
            </div>
            <div class="bar">
              <div class="icon_wrap">
                <img src="@/assets/img/frozenMoney.png" alt="冻结资金" />
              </div>
              <div class="asset_bd">
                <span>冻结资金</span>
                <div>
                  <strong>{{ assetInfo.frozenAmount }}</strong
                  >元
                </div>
                <em>暂不可用资金，一般为提现中的资金</em>
              </div>
            </div>
          </div>
          <el-form :inline="true" :model="formInline" label-width="80px">
            <el-form-item label="">
              <el-date-picker
                v-model="formInline.time"
                type="daterange"
                align="right"
                clearable
                value-format="yyyy-MM-dd"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="formInline.withdrawProgressStatus"
                clearable
                filterable
                placeholder="提现进度"
                @change="changeWithdrawStatus"
              >
                <el-option
                  v-for="item in withdrawProcess"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                v-if="1"
                @click="
                  formInline.page.pageIndex = 1;
                  getList();
                "
                >查询</el-button
              >

              <el-button type="primary" @click="exportData">导出</el-button>
            </el-form-item>
          </el-form>
          <el-table
            v-loading="$store.state.loadingStatus"
            :data="tableData"
            :border="true"
            style="width: 100%"
            :header-cell-style="
              () =>
                'background:#F7F9FD;text-align:center;font-weight:bold;height:40px;padding:0;color: #95A3BB;'
            "
          >
            <el-table-column
              v-for="column in transferConfig"
              :key="column.prop"
              align="center"
              :prop="column.prop"
              :label="column.label"
              :show-overflow-tooltip="true"
            />
          </el-table>
          <div class="page_footer">
            <el-pagination
              style="float: right; margin-top: 20px"
              layout="prev, pager, next, jumper"
              :page-size="formInline.page.pageSize"
              :current-page.sync="formInline.page.pageIndex"
              @current-change="handleCurrentChange"
              :total="totalElements"
            >
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="账户管理" name="second">
        <div class="box">
          <el-card class="box-card flex1">
            <div class="flex">
              <h3>支付密码</h3>
              <div class="tips">用于提现，修改收款账户等敏感操作</div>
              <el-button type="success" size="small" @click="modifyPassword"
                >修改密码</el-button
              >
            </div>
            <div class="add" @click="addPayType">+ 添加</div>
          </el-card>
          <el-card
            class="box-card carditem"
            v-for="item in listData"
            :key="item.id"
            :style="`border-top: 2px solid ${topLineColor[item.cardType]}`"
          >
            <p class="title" :style="`color: ${topLineColor[item.cardType]}`">
              {{
                item.cardType === 2
                  ? item.cardChannel
                  : item.cardType === 0
                  ? "微信账号"
                  : "支付宝账号"
              }}
            </p>
            <p>开户人：{{ item.encryptCardAccountName }}</p>
            <p>卡号：{{ item.encryptCardNo }}</p>
            <div class="btn" @click="delAccount(item)">删除</div>
          </el-card>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 申请提现 -->
    <el-dialog
      title="自行提现"
      :visible.sync="isShowWithdrawPop"
      width="40%"
      :before-close="handleClosePop"
    >
      <div class="dialog_wrap">
        <div>将使用组织的收款账户进行提现</div>
        <el-form
          :inline="false"
          ref="withdrawForm"
          :model="withdrawForm"
          label-width="140px"
        >
          <el-form-item
            label="选择收款账户："
            prop="cardId"
            :rules="[
              { required: true, message: '请选择提现账户', trigger: 'blur' },
            ]"
          >
            <el-radio-group
              v-if="payTypes.length > 0"
              v-model="withdrawForm.cardId"
              @change="changeWithdrawType"
              class="type_wrap"
            >
              <el-radio v-for="(item, i) in payTypes" :key="i" :label="item.id"
                >{{ item.cardChannel }} {{ item.encryptCardAccountName }}
                {{ item.encryptCardNo }}</el-radio
              >
            </el-radio-group>
            <div v-else>请去添加提现账户</div>
          </el-form-item>
          <el-form-item
            label="提现金额："
            prop="withdrawalAmount"
            :rules="[
              { required: true, message: '请输入提现额度', trigger: 'blur' },
            ]"
          >
            <div class="form_item">
              <el-input
                v-model="withdrawForm.withdrawalAmount"
                :min="1"
                :max="50000"
                oninput="value=value.replace(/[^0-9.]/g,'').replace(/^\./g, '').replace('.', 'dollar#dollar').replace(/\./g, '').replace('dollar#dollar', '.')"
                style="width: 220px; margin-right: 20px"
                clearable
              ></el-input>
              <el-button type="text" @click="handleWithdrawAll"
                >全部提现</el-button
              >
            </div>
            <div class="form_info">
              账户余额：<span>{{ assetInfo.balanceAmount }}</span
              >元，本次服务费：
              <strong>{{ withdrawForm.withdrawFee || 1 }}元</strong>
            </div>
          </el-form-item>
          <el-form-item
            label="支付密码："
            prop="password"
            :rules="[
              { required: true, message: '请输入密码', trigger: 'blur' },
            ]"
          >
            <el-input
              style="width: 220px"
              type="password"
              clearable
              v-model="withdrawForm.password"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="isShowWithdrawPop = false"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="handleApproval"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 修改密码 -->
    <el-dialog
      title="修改密码"
      :visible.sync="isShowPasswordPop"
      width="660px"
      :before-close="handleClosePasswordPop"
    >
      <el-form
        :inline="false"
        ref="passwordForm"
        :model="passwordForm"
        label-width="140px"
      >
        <el-form-item
          label="旧密码："
          prop=""
          :rules="[
            { required: true, message: '请选输入旧密码', trigger: 'blur' },
          ]"
        >
          <el-input style="width: 240px" v-model="passwordForm.oldPassword" />
        </el-form-item>
        <el-form-item
          label="新密码："
          prop=""
          :rules="[
            { required: true, message: '请选输入新密码', trigger: 'blur' },
          ]"
        >
          <el-input style="width: 240px" v-model="passwordForm.newPassword" />
        </el-form-item>
        <el-form-item label="">
          <div>验证手机：{{ mobileInfo.encryptMobile }}</div>
        </el-form-item>
        <el-form-item
          label="短信验证码："
          prop=""
          :rules="[
            { required: true, message: '请选输入短信验证码', trigger: 'blur' },
          ]"
        >
          <el-input style="width: 240px" v-model="passwordForm.smsCode" />
          <el-button
            style="margin-left: 20px"
            type="primary"
            :disabled="Boolean(passwordSec)"
            @click="sendPwdSMSCode"
          >
            <span v-if="passwordSec">{{ passwordSec }}秒后再次点击</span>
            <span v-else>获取验证码</span>
          </el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="isShowPasswordPop = false"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="handleFixPassword"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 添加账号 -->
    <el-dialog
      title="添加账号"
      :visible.sync="isShowPayTypePop"
      width="660px"
      :before-close="handleClosePayTypePop"
    >
      <div class="pay_type_wrap">
        <el-radio-group v-model="cardType" size="mini">
          <el-radio-button :label="1">支付宝</el-radio-button>
          <el-radio-button :label="2">银行卡</el-radio-button>
        </el-radio-group>
        <br />
        <el-form
          :inline="false"
          ref="payTypeForm"
          :model="payTypeForm"
          label-width="140px"
        >
          <el-form-item
            v-if="cardType === 1"
            label="支付宝账号："
            prop=""
            :rules="[
              {
                required: true,
                message: '请选输入支付宝账号',
                trigger: 'blur',
              },
            ]"
          >
            <el-input style="width: 240px" v-model="payTypeForm.cardNo" />
          </el-form-item>
          <el-form-item
            v-if="cardType === 2"
            label="开户行："
            prop=""
            :rules="[
              {
                required: true,
                message: '请选输入开户行',
                trigger: 'blur',
              },
            ]"
          >
            <el-input style="width: 240px" v-model="payTypeForm.cardChannel" />
          </el-form-item>
          <el-form-item
            v-if="cardType === 2"
            label="卡号："
            prop=""
            :rules="[
              {
                required: true,
                message: '请选输入银行卡号',
                trigger: 'blur',
              },
            ]"
          >
            <el-input style="width: 240px" v-model="payTypeForm.cardNo" />
          </el-form-item>
          <el-form-item
            label="开户名："
            prop=""
            :rules="[
              {
                required: true,
                message: '请选输入开户名',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              style="width: 240px"
              v-model="payTypeForm.cardAccountName"
            />
          </el-form-item>
          <el-form-item label="">
            <div>验证手机：{{ mobileInfo.encryptMobile }}</div>
            <div style="font-size: 12px; margin-top: -22px; color: #aaa">
              开户人仅可以为组织最高权限人绑定手机
            </div>
          </el-form-item>
          <el-form-item
            label="短信验证码："
            prop=""
            :rules="[
              {
                required: true,
                message: '请选输入短信验证码',
                trigger: 'blur',
              },
            ]"
          >
            <el-input style="width: 240px" v-model="payTypeForm.smsCode" />
            <el-button
              style="margin-left: 20px"
              type="primary"
              :disabled="Boolean(accountSec)"
              @click="sendSMSCode"
            >
              <span v-if="accountSec">{{ accountSec }}秒后再次点击</span>
              <span v-else>获取验证码</span>
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="isShowPayTypePop = false"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="handleAddAccount"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { shortcuts } from "@/constants/index.js";
export default {
  data() {
    return {
      activeName: "first",
      formInline: {
        endTime: null,
        startTime: null,
        page: {
          pageIndex: 1,
          pageSize: 20,
        },
        withdrawProgressStatus: null,
        time: ["", ""],
      },
      totalElements: 0,
      assetInfo: {},
      unionId: null,
      isShowWithdrawPop: false,
      tableData: [],
      withdrawProcess: [
        {
          label: "全部",
          value: null,
        },
        {
          label: "审核中",
          value: 0,
        },
        {
          label: "审核拒绝",
          value: 1,
        },
        {
          label: "提现中",
          value: 2,
        },
        {
          label: "打款成功",
          value: 3,
        },
        {
          label: "打款失败",
          value: 4,
        },
      ],
      pickerOptions: {
        shortcuts,
      },
      paymentStatus: [
        {
          label: "全部",
          value: 0,
        },
        {
          label: "待打款",
          value: 1,
        },
        {
          label: "打款完成",
          value: 2,
        },
        {
          label: "打款失败",
          value: 3,
        },
      ],
      transferConfig: [
        {
          label: "创建时间",
          prop: "applyTime",
        },
        {
          label: "流水ID",
          prop: "id",
        },
        {
          label: "提现方式",
          prop: "withdrawTypeStr",
        },
        {
          label: "收款信息",
          prop: "recipientInfo",
        },
        {
          label: "提现金额",
          prop: "withdrawAmount",
        },
        {
          label: "服务费",
          prop: "feeAmount",
        },
        {
          label: "提现进度",
          prop: "withdrawProgressStatus",
        },
        {
          label: "申请人",
          prop: "applyAccountName",
        },
      ],
      withdrawForm: {
        applyAdminAccountId: null,
        assetAccountId: null,
        cardId: null,
        password: "",
        remark: "",
        withdrawType: null,
        withdrawalAmount: null,
        withdrawalFee: 1,
      },
      payTypes: [
        {
          cardChannel: "string",
          encryptCardAccountName: "string",
          encryptCardNo: "string",
          id: 0,
        },
      ],
      listData: [],
      isShowPasswordPop: false,
      passwordForm: {
        mobile: "",
        newPassword: "",
        oldPassword: "",
        smsCode: "",
        smsCodeType: null,
      },
      isShowPayTypePop: false,
      payTypeForm: {
        bankConfigId: null,
        cardAccountName: "",
        cardChannel: "",
        cardNo: "",
        cardType: null,
        mobile: "",
        smsCode: "",
        smsCodeType: null,
      },
      mobileInfo: {},
      cardType: 1,
      accountSec: "",
      passwordSec: "",
      topLineColor: ["#04BE02", "#1678ff", "#ff5e65"],
      assetAccountId: null,
    };
  },
  watch: {
    isShowWithdrawPop(v) {
      if (!v) {
        this.withdrawForm = {
          applyAdminAccountId: null,
          cardId: null,
          password: "",
          remark: "",
          withdrawType: null,
          withdrawalAmount: null,
          withdrawalFee: 1,
        };
      }
    },
    isShowPasswordPop(v) {
      if (!v) {
        this.passwordForm = {
          mobile: "",
          newPassword: "",
          oldPassword: "",
          smsCode: "",
          smsCodeType: null,
        };
      }
    },
    isShowPayTypePop(v) {
      if (!v) {
        this.payTypeForm = {
          bankConfigId: null,
          cardAccountName: "",
          cardChannel: "",
          cardNo: "",
          cardType: null,
          mobile: "",
          smsCode: "",
          smsCodeType: null,
        };
      }
    },
  },
  mounted() {
    this.getAssetInfo(); // 获取支付银行卡或支付宝账号结算账户信息
    this.queryPayTypeList(); // 获取支付银行卡或支付宝账号列表
    this.getList(); // 查询申请列表
    this.getMobileNum(); // 获取手机号码
    this.getBtnPermission(this.$route.query.pageId); // 获取页面权限
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getList() {
      const [startTime, endTime] = this.formInline.time || ["", ""];

      this.$api.billingApi
        .getWithdrawApprovalList({
          ...this.formInline,
          startTime: startTime
            ? this.$dateUtil.dayToStartUnix(startTime)
            : null,
          endTime: endTime ? this.$dateUtil.dayToEndUnix(endTime) : null,
        })
        .then((res) => {
          if (res.code === 0) {
            const result = res.data?.content ? res.data?.content : [];
            this.tableData = result;
            this.totalElements = res.data.totalElements;
            this.formInline.page.pageIndex = res.data.pageIndex;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    exportData() {
      const [startTime, endTime] = this.formInline.time || ["", ""];

      this.$api.billingApi
        .exportApprovalList({
          ...this.formInline,
          startTime: startTime
            ? this.$dateUtil.dayToStartUnix(startTime)
            : null,
          endTime: endTime ? this.$dateUtil.dayToEndUnix(endTime) : null,
        })
        .then((res) => {
          console.log("getUnionList", res);
          // 导出Excel
          this.$exportExcel(
            res,
            `已出数据_${this.$moment().format(
              "YYYY-MM-DD"
            )}_${this.$moment().unix()}.xlsx`
          );
        });
    },
    handleCurrentChange(p, t) {
      console.log("pt, t", p, t);
      this.formInline.page.pageIndex = p;
      this.getList();
    },
    queryPayTypeList() {
      this.$api.billingApi
        .queryPayTypes({ page: { pageIndex: 1, pageSize: 1000 } })
        .then((res) => {
          if (res.code === 0) {
            const list = res.data?.content ? res.data?.content : [];
            this.payTypes = list;
            this.listData = list;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getAssetInfo() {
      this.$api.billingApi.getUnionAccountDetail({}).then((res) => {
        if (res.code === 0) {
          this.assetInfo = res.data ? res.data : {};
          this.withdrawForm.assetAccountId = res.data
            ? res.data?.asserAccountId
            : "";
          this.assetAccountId = res.data?.asserAccountId;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleShowPop() {
      this.isShowWithdrawPop = true;
    },
    handleClosePop() {
      this.isShowWithdrawPop = false;
    },
    handleApproval() {
      if (this.withdrawForm.withdrawalAmount === 0) {
        this.$message.warning("提现金额不能为0元");
        return;
      }

      if (this.withdrawForm.withdrawalAmount > 50000) {
        this.$message.warning("提现金额不能大于50000元");
        return;
      }

      this.withdrawForm.applyAdminAccountId = JSON.parse(
        localStorage.getItem("userInfo")
      ).accountId;

      this.$refs.withdrawForm.validate((v) => {
        if (v) {
          const withdrawalAmount = +this.withdrawForm.withdrawalAmount;
          console.log("withdrawalAmount", withdrawalAmount);
          this.$api.billingApi
            .withdrawalApply({
              ...this.withdrawForm,
              assetAccountId: this.assetAccountId,
              withdrawalAmount: withdrawalAmount.toFixed(2),
            })
            .then((res) => {
              if (res.code === 0) {
                this.$message.success("操作成功!");
                this.isShowWithdrawPop = false;
                this.getList();
                this.getAssetInfo();
              } else {
                this.$message.error(res.msg);
              }
            });
        }
      });
    },
    modifyPassword() {
      this.isShowPasswordPop = true;
    },
    handleClosePasswordPop() {
      this.isShowPasswordPop = false;
    },
    handleFixPassword() {
      this.activeName = "second";
      this.$refs.passwordForm.validate((v) => {
        if (v) {
          this.$api.billingApi
            .handleFixPayPassword({
              ...this.passwordForm,
              mobile: this.mobileInfo.mobile,
              smsCodeType: 9,
            })
            .then((res) => {
              if (res.code === 0) {
                this.$message.success("操作成功!");
                this.isShowPasswordPop = false;
                this.queryPayTypeList();
                this.activeName = "second";
              } else {
                this.$message.error(res.msg);
              }
            });
        }
      });
    },
    addPayType() {
      this.isShowPayTypePop = true;
    },
    handleClosePayTypePop() {
      this.isShowPayTypePop = false;
    },
    getMobileNum() {
      this.$api.billingApi.getMobileNum({}).then((res) => {
        if (res.code === 0) {
          const result = res.data ? res.data : {};
          this.mobileInfo = result;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleAddAccount() {
      this.$api.billingApi
        .addPayAccount({
          ...this.payTypeForm,
          smsCodeType: 11,
          cardType: this.cardType,
          mobile: this.mobileInfo.mobile,
        })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.isShowPayTypePop = false;
            this.queryPayTypeList();
            this.activeName = "second";
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    countDown(counts, type) {
      this[type] = counts;
      this.timer = setTimeout(() => {
        counts--;
        this.countDown(counts, type);
      }, 1000);

      if (counts === 0) {
        clearTimeout(this.timer);
        this.counts = 0;
      }
    },
    sendSMSCode() {
      this.$api.billingApi
        .unionSendSmsCode({
          mobile: this.mobileInfo.mobile,
          smsCodeType: 10,
        })
        .then((res) => {
          if (res.code === 0) {
            this.countDown(60, "accountSec");
            this.$message.success("验证码发送成功!");
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    sendPwdSMSCode() {
      this.$api.billingApi
        .unionSendSmsCode({
          mobile: this.mobileInfo.mobile,
          smsCodeType: 8,
        })
        .then((res) => {
          if (res.code === 0) {
            this.countDown(60, "passwordSec");
            this.$message.success("验证码发送成功!");
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    delAccount(item) {
      this.$confirm("确定要删除支付账号吗吗？", "", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        this.$api.billingApi.delPayAccount({ id: item.id }).then((res) => {
          if (res.code === 0) {
            this.$message.success("操作成功!");
            this.queryPayTypeList();
            this.activeName = "second";
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    changeWithdrawType(val) {
      this.withdrawForm.withdrawType = this.payTypes.find(
        (item) => item.id === val
      ).cardType;
    },
    handleWithdrawAll() {
      const amount = Number(this.assetInfo.balanceAmount);

      if (amount !== 0 && amount > 1 && amount < 50001) {
        this.withdrawForm.withdrawalAmount = this.assetInfo.balanceAmount - 1;
      } else if (amount >= 50001) {
        this.withdrawForm.withdrawalAmount = 50000;
      } else {
        this.$message.warning("余额不足！");
      }
    },
    changeWithdrawStatus(val) {
      if (val === "") {
        this.formInline.withdrawProgressStatus = null;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.game-apply {
  .overage {
    // padding: 10px 20px;
    // background: #f5f5f5;
    // border-radius: 8px;
  }
  .topBar {
    background: #f9f9f9;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;

    .bar {
      flex: 1;
      display: flex;
      justify-content: center;
      align-content: center;
      padding: 20px 0px;
      border-right: 1px solid #efefef;
      &:last-of-type {
        border-right: none;
      }
      &:nth-of-type(2) {
        & > .asset_bd {
          & > div {
            color: #17c988;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            /deep/ .el-button--mini {
              margin-left: 10px;
              padding: 0;
              width: 40px;
              height: 20px;
            }
          }
        }
      }
      .icon_wrap {
        width: 68px;
        height: 68px;
        text-align: center;
        line-height: 68px;
        background: #fff;
        border-radius: 100%;

        img {
          width: 68px;
          height: 68px;
        }
      }
      .asset_bd {
        padding-left: 20px;
        // flex: 1;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        & > span {
          font-size: 14px;
          color: #888;
        }
        & > em {
          font-size: 12px;
          color: #aaa;
        }
        & > div {
          font-size: 22px;
          color: #333;
        }
      }
    }
  }
  .page_footer {
    padding-top: 10px;
    // display: flex;
    // justify-content: space-between;
    // align-content: center;
  }
  .form_item {
    display: flex;
    justify-content: flex-start;
  }
  .dataWrap {
    padding-right: 40px;
    width: 50vw;

    .dataItem {
      // padding-bottom: 12px;
      line-height: 30px;
      display: flex;
      justify-content: flex-start;

      & > span {
        flex: 1;
      }
    }
  }
  .box {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    .flex1 {
      display: flex;
      flex-direction: column;
      .add {
        cursor: pointer;
        text-align: center;
        height: 50px;
        border: 1px #666 dashed;
        margin-top: 10px;
        font-size: 16px;
        line-height: 50px;
      }
      .flex {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .tips {
        padding: 0 0 10px 0;
        color: #999;
        font-size: 12px;
      }
    }

    .carditem {
      /deep/ .el-card__body {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        box-sizing: border-box;
      }
    }
    .box-card {
      p {
        font-size: 13px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        color: #666666;
      }
      .title {
        font-size: 16px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }

      .btn {
        width: 100%;
        height: 35px;
        line-height: 35px;
        text-align: center;
        color: #255ec0;
        font-size: 15px;
        background: #eff5ff;
        border-radius: 4px;
        cursor: pointer;
      }
      width: calc(25% - 12px);
      height: 180px;
      margin-right: 10px;
      margin-bottom: 12px;
      & > div {
        height: 100%;
      }
    }
  }
  .pay_type_wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .type_wrap {
    border: 1px solid #ddd;

    /deep/ .el-radio {
      padding: 10px;
      border-bottom: 1px solid #ddd;
      // margin-right: 0;
      box-sizing: border-box;
      width: 100%;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
